import { Dialog, DialogContent } from '@mui/material';

import { observer } from 'mobx-react-lite';

import { useStore } from '../../stores/store';

function ModalContainer() {
    const { modalStore } = useStore();

    return (
        <div>
            <Dialog fullWidth className={modalStore.modal.className ?? ""} maxWidth={modalStore.modal.size} open={modalStore.modal.open} onClose={() => {
                if (!modalStore.modal.persistent) {
                    modalStore.closeModal();
                }
            }} >
                <DialogContent>
                    {modalStore.modal.body}
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default observer(ModalContainer);