import { makeAutoObservable, runInAction } from "mobx";

import agent from "../utils/agent";
import { Tenant, TenantFormValues, UserTenant, UserTenantDto } from "../data/models/tenant";
import { store } from "./store";

export default class TenantStore {
    tenants: Map<string, Tenant> = new Map<string, Tenant>();
    selectedTenant?: UserTenant;
    userSettingsTenant?: UserTenant;
    userTenantAdmins: Map<string, UserTenantDto> = new Map<string, UserTenantDto>();
    loading = false;
    editMode = false;

    constructor() {
        makeAutoObservable(this)
    }

    get tenantAdmins() {
        return Array.from(this.userTenantAdmins.values());
    }

    set isEditMode(value: boolean) {
        this.editMode = value;
    }

    get tenantList() {
        return Array.from(this.tenants.values());
    }

    setDefaultTenant = (userTenants: UserTenant[]) => {
        if (!this.selectedTenant && userTenants.length > 0) {
            if (store.commonStore.selectedTenant) {
                this.selectedTenant = userTenants.find(t => t.id === store.commonStore.selectedTenant);
            }
            else {
                const defaultTenant = userTenants.find(t => t.isDefault);
                if (defaultTenant) {
                    this.selectedTenant = defaultTenant;
                } else {
                    this.selectedTenant = userTenants[0];
                }
                store.commonStore.setSelectedTenant(this.selectedTenant.id);
            }
        }
    }

    loadTenant = (tenantId: string) => {
        this.selectedTenant = store.profileStore.userTenants.get(tenantId);
        this.userSettingsTenant = this.selectedTenant;
        store.commonStore.setSelectedTenant(tenantId);
    }

    loadUserSettingsTenant = (tenantId: string) => {
        this.userSettingsTenant = store.profileStore.userTenants.get(tenantId);
        const defaultInstitution = this.userSettingsTenant!.institutions.find(i => i.isDefault);

        if (defaultInstitution) {
            store.institutionStore.setSelectedUserInstitution(defaultInstitution.id);
        } else {
            store.institutionStore.setSelectedUserInstitution(this.userSettingsTenant!.institutions[0].id!);
        }
        this.loadTenant(tenantId);
    }

    getTenants = async () => {
        store.loadingStore.startLoading(this.getTenants);
        this.loading = true;
        try {
            const tenants: Tenant[] = await agent.Tenants.get();
            runInAction(() => {
                this.tenants.clear();
                tenants.forEach((tenant) => this.tenants.set(tenant.id, tenant));

                this.loading = false
            });
            store.loadingStore.stopLoading(this.getTenants);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getTenants);
            throw error;
        }
    }

    getTenant = async (id: string) => {
        store.loadingStore.startLoading(this.getTenant);
        this.loading = true;
        try {
            const tenant = await agent.Tenants.getById(id);
            runInAction(() => {
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getTenant);

            return tenant;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getTenant);
            throw error;
        }
    }

    createTenant = async (tenant: TenantFormValues) => {
        store.loadingStore.startLoading(this.createTenant);
        this.loading = true;
        try {
            await agent.Tenants.create(tenant);
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.createTenant);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.createTenant);
            throw error;
        }
    }

    updateTenat = async (tenant: TenantFormValues) => {
        store.loadingStore.startLoading(this.updateTenat);
        this.loading = true;
        try {
            await agent.Tenants.update(tenant);
            runInAction(() => {
                this.tenants.set(tenant.id!, tenant as Tenant);
                this.loading = false
            });
            store.loadingStore.stopLoading(this.updateTenat);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.updateTenat);
            throw error;
        }
    }

    deleteTenant = async (id: string) => {
        store.loadingStore.startLoading(this.deleteTenant);
        this.loading = true;
        try {
            await agent.Tenants.delete(id);
            runInAction(() => {
                this.tenants.delete(id);
                this.loading = false
            });
            store.loadingStore.stopLoading(this.deleteTenant);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.deleteTenant);
            throw error;
        }
    }
}