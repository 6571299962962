// StudyOverviewFilter.tsx
import DoneIcon from "@mui/icons-material/Done";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../stores/store";
import { buttonStyle } from "../../../Booking/Options/CalendarFilter";

const StudyOverviewFilter: React.FC<{ onGroupChange: (groups: string[]) => void, isLoading?: boolean }> = ({ onGroupChange, isLoading }) => {
    const { t } = useTranslation();
    const { studyOverviewStore } = useStore();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleGroupSelect = (group: string) => {
        const updatedGroups = selectedGroups.includes(group)
            ? selectedGroups.filter((g) => g !== group)
            : [...selectedGroups, group];

        setSelectedGroups(updatedGroups);
        onGroupChange(updatedGroups);
    };

    const isActive = () => {
        return selectedGroups.length > 0;
    };

    return (
        <Box sx={{ marginLeft: 1 }}>
            <Button onClick={handleMenuClick}
                className={`${isActive() ? "selected-item" : ""}`}
                sx={{ ...buttonStyle, color: isActive() ? "#03b3ff" : "#616161" }}
                data-testid="coordination-overview-filter-button"
                disabled={isLoading}
            >
                <FilterListOutlinedIcon fontSize="small" sx={{ marginRight: "5px" }} />
                {t("GENERAL_FILTERS")}
            </Button>
            <>
                <Menu anchorEl={anchorEl}
                    open={Boolean(anchorEl)} onClose={handleMenuClose} sx={{
                        height: "265px",
                        width: "240px",
                    }}
                >
                    {
                        studyOverviewStore.studyOverviewGroups.map((group) => (
                            < MenuItem disabled={isLoading} key={group} onClick={() => handleGroupSelect(group)} data-testid={`${group}`} className="menu-item-option">
                                <Typography sx={{ paddingRight: "10px", fontSize: "14px" }}>
                                    {group}
                                </Typography>
                                <DoneIcon fontSize="small" sx={{ color: selectedGroups.includes(group) ? "#03b2ff" : "transparent" }} />
                            </MenuItem>
                        ))
                    }
                </Menu>
            </>
        </Box >
    );
};

export default observer(StudyOverviewFilter);
