import { CircularProgress, Container, Grid, Typography } from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { useEffect, useRef, useState } from "react";

import { RoundButton } from "../../../../_styles/StyledButtons";
import { useStore } from "../../../../stores/store";
import { useParams } from "react-router-dom";
import { router } from "../../../../router/Routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { sessionCodeSchema } from "../../../../_validators/schemas/sessionCode.schema";
import { observer } from "mobx-react-lite";
import "./styles.css";
import { SessionAttempt } from "../../../../data/models/session";
import { useTranslation } from "react-i18next";

function SessionCodeStart() {
    const { t } = useTranslation();
    const AuthInputRef = useRef<AuthCodeRef>(null);
    const { sessionStore } = useStore();
    const { verifySession, getAttempts, loadingCode } = sessionStore;
    const { control, handleSubmit, watch } = useForm({
        resolver: yupResolver(sessionCodeSchema()),
    });

    const [attempts, setAttempts] = useState<SessionAttempt>({
        attemptLimit: 1,
        numberOfAttempts: 0,
    });
    const locked = attempts.numberOfAttempts >= attempts.attemptLimit;

    const { sessionId } = useParams();
    const code = watch("code");

    useEffect(() => {
        if (sessionId) {
            getAttempts(sessionId).then((attempts) => setAttempts(attempts));
        }
    }, [getAttempts, sessionId])

    useEffect(() => {
        if (code && code.length >= 5) {
            const verify = () => {
                if (sessionId) {
                    verifySession(sessionId, code.toUpperCase())
                        .then((token) => {
                            if (token) {
                                router.navigate(`/screening/session?token=${token}`);
                            }
                        })
                        .finally(() => {
                            getAttempts(sessionId).then((attempts) => {
                                setAttempts(attempts);
                            });
                            AuthInputRef.current?.clear()
                        });
                }
            };

            verify();
        }
    }, [code, getAttempts, sessionId, verifySession]);

    const onSubmit = async (data: any) => {
        if (sessionId) {
            const token = await verifySession(sessionId, data.code.toUpperCase());
            if (token) {
                router.navigate(`/screening/session?token=${token}`);
            }
        }
    };

    return (
        <Container className="page-container" maxWidth="sm">
            <Grid
                container
                component={"form"}
                onSubmit={handleSubmit(onSubmit)}
                className="session-info-container"
                sx={{
                    width: "100%",
                    boxShadow: "none",
                    border: "1px solid #ddd",
                    justifyContent: "center",
                    minHeight: "260px",
                }}
            >
                <Grid item lg={8} md={10} xs={12}>
                    <Typography
                        fontSize={"1rem"}
                        textAlign={"center"}
                        sx={{ color: "#414141" }}
                    >
                        {t("SESSION_CODE_INPUT")}
                    </Typography>
                </Grid>
                <Grid item lg={8} md={10} xs={12} position={"relative"}>
                    <Controller
                        name={"code"}
                        control={control}
                        defaultValue={""}
                        render={({
                            field: { onChange, value = "" },
                            fieldState: { error },
                        }) => (
                            <>
                                <AuthCode
                                    ref={AuthInputRef}
                                    disabled={locked}
                                    onChange={onChange}
                                    inputClassName={`code-input ${error ? "code-error" : ""}`}
                                    containerClassName="code-container"
                                    length={5}
                                    autoFocus={true}
                                />
                                <Typography
                                    sx={{
                                        color: "#f44336",
                                        fontSize: "0.75rem",
                                        ml: "4px",
                                        mt: "4px",
                                    }}
                                >
                                    {locked
                                        ? t("SESSION_CODE_LOCK")
                                        : error?.message}
                                </Typography>
                            </>
                        )}
                    />
                </Grid>
                <Grid item lg={8} md={10} xs={12}>
                    <RoundButton
                        variant={"contained"}
                        sx={{ width: "100%" }}
                        type="submit"
                        disabled={loadingCode || locked}
                    >
                        {loadingCode ? <CircularProgress size={25} /> : t("2FA_VERIFY")}
                    </RoundButton>
                </Grid>
            </Grid>
        </Container>
    );
}

export default observer(SessionCodeStart);
