import { Box, IconButton, Link, List, ListItem, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RestorePageOutlinedIcon from "@mui/icons-material/RestorePageOutlined";

import { CloudFile } from "../data/models/client";
import React from "react";

interface CloudFileProps {
    files: CloudFile[];
    setFiles?: (files: CloudFile[]) => void;
    canDelete?: boolean;
    labels?: {
        header?: React.ReactNode;
        emptyText?: React.ReactNode;
    }
}

function CloudFileInfo({ files, setFiles, canDelete = true, labels }: CloudFileProps) {
    function formatBytes(bytes: number, decimals: number = 2) {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const getFileStyling = (file: CloudFile) => {
        const ext = getExtension(file);

        return ext.toLowerCase();
    };

    const getExtension = (file: CloudFile) => {
        return file!.fileName.split(".").pop()!.toUpperCase();
    };

    const updateFile = (file: CloudFile, isDeleted: boolean) => {
        if (!canDelete) return;

        const updatedFiles = files.map((f) => {
            if (f.id === file.id) {
                return {
                    ...f,
                    isDeleted,
                };
            }
            return f;
        });

        setFiles && setFiles(updatedFiles);
    };

    return (
        <Box className="file-info-container">
            <Box display="flex" alignItems="center">
                <Typography
                    fontWeight={500}
                    fontSize="16px"
                    sx={{ marginRight: "10px" }}
                >
                    {labels?.header ?? "Previously uploaded files"}
                </Typography>
            </Box>
            {files.length === 0 && (
                <Typography
                    fontSize="13px"
                    sx={{ color: "#a2a2a2", marginLeft: "1rem" }}
                >
                    {labels?.emptyText ?? "No files were uploaded this task."}
                </Typography>
            )}
            {files.length > 0 && (
                <List
                    className="file-info-list"
                    sx={{ padding: files.length > 0 ? "8px 0" : 0 }}
                >
                    {files.map((file, index) => (
                        <ListItem key={index} className="file-info-item">
                            <Box
                                className={"file-info-details " + getFileStyling(file)}
                                sx={{ opacity: file.isDeleted ? "0.4" : "1" }}
                            >
                                <Typography fontSize="11px" fontWeight={600}>
                                    {getExtension(file)}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                                sx={{ opacity: file.isDeleted ? "0.4" : "1" }}
                            >
                                <Typography
                                    className="file-info-name"
                                    fontSize={"11px"}
                                    fontWeight={600}
                                    title={file.fileName}
                                    sx={{ margin: "0 7px" }}
                                >
                                    {file.fileName}
                                </Typography>
                                <Box display="flex" alignItems="center" sx={{ pr: canDelete ? 0 : "8px" }}>
                                    <Link sx={{ textDecoration: "none", fontSize: "13px" }} href={file.url} target="_blank" rel="noopener">
                                        View
                                    </Link>
                                    <Typography fontSize={"9px"} fontWeight={400} sx={{ marginLeft: '1rem' }}>
                                        {formatBytes(file.size)}
                                    </Typography>
                                </Box>
                            </Box>
                            {canDelete
                                &&
                                <IconButton
                                    sx={{ marginLeft: "auto" }}
                                    onClick={() => updateFile(file, !file.isDeleted)}
                                    title={file.isDeleted ? "Restore" : "Delete"}
                                >
                                    {file.isDeleted ? (
                                        <RestorePageOutlinedIcon sx={{ fontSize: "15px" }} />
                                    ) : (
                                        <CloseIcon sx={{ fontSize: "15px" }} />
                                    )}
                                </IconButton>
                            }
                        </ListItem>
                    ))}
                </List>
            )}
        </Box>
    );
}

export default CloudFileInfo;
