import { Box, Divider, IconButton, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import { format } from "date-fns";

import ActivityLayout from "./ActivityLayout";
import { useStore } from "../../../stores/store";
import { useEffect, useState } from "react";
import { CalendarEvent } from "../../../data/models/event";
import ClientEvents from "./ClientEvents";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import ClientDetailSessionTable from "../Form/ClientDetailSessionTable";
import { ClientDetailSessionsDto } from "../../../data/models/client";
import { observer } from "mobx-react-lite";
import { SessionFile } from "../../../data/models/session";
import i18n from "../../../data/translations/i18n";
import { getDateAndTimeFormatByPlatformLanguage, getDateFormatByPlatformLanguage } from "../../../utils/utils";
import ClientSessionTimelineOverview from "../Form/ClientSessionTimelineOverview/ClientSessionTimelineOverview";
import { UserStatus } from "../../../data/models/user";

function ClientAbout() {
    const { t, i18n } = useTranslation();
    const { clientStore, bookingStore, institutionStore, loadingStore } = useStore();
    const { client, getSessionsOnClientDetail, generalInfo, getGeneraInfo } = clientStore;
    const { getClientEvents } = bookingStore;
    const { selectedUserInstitution } = institutionStore
    const navigate = useNavigate();
    const [events, setEvents] = useState<CalendarEvent[]>([]);
    const [sessions, setSessions] = useState<ClientDetailSessionsDto[]>([]);
    const [ICFiles, setICFiles] = useState<SessionFile[]>([]);

    useEffect(() => {
        const fetchEvents = async () => {
            if (client) {
                const events = await getClientEvents(client!.id!);
                setEvents(events!);
                const clientSessions = await getSessionsOnClientDetail(client.id, selectedUserInstitution?.institutionId!)
                setSessions(clientSessions);
            }
        };

        fetchEvents();
    }, [client, getClientEvents]);

    const handleSessionUpdate = async () => {
        setTimeout(async () => {
            if (client) {
                const clientSessions = await getSessionsOnClientDetail(client.id, selectedUserInstitution?.institutionId!)
                setSessions(clientSessions);
            }
        }, 400);
    };

    return (
        <>
            <Box className="about-container">
                <ActivityLayout
                    showFlag={false}
                    className="general-information-container"
                >
                    <Box className="general-information">
                        <Box className="general-information-header">
                            <Typography fontSize="20px" fontWeight="500">
                                {t("CLIENT_GENERAL_INFO")}
                            </Typography>
                            {
                                !loadingStore.isLoading(getGeneraInfo) &&
                                    (generalInfo?.status !== UserStatus.ANON) && client !== null && !selectedUserInstitution?.roles.some(r => ["HELPER", "CLIENT"].includes(r.toUpperCase())) ?
                                    <IconButton disabled={loadingStore.isLoading(getGeneraInfo)} onClick={() => navigate(`/users/${client!.id}/update`)}>
                                        <EditIcon sx={{ fontSize: "18px" }} />
                                    </IconButton>
                                    : ''
                            }
                        </Box>
                        <Box className="client-personal-info">
                            <Box className="about-information">
                                <Typography fontSize="16px" fontWeight={500}>
                                    {t("USER_FULL_NAME")}
                                </Typography>
                                <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                    {generalInfo?.fullName ?? "No information."}
                                </Typography>
                            </Box>
                            <Box className="about-information">
                                <Typography fontSize="16px" fontWeight={600}>
                                    {t("USER_BIRTHDATE")}
                                </Typography>
                                <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                    {generalInfo?.birthDate ?
                                        getDateFormatByPlatformLanguage(new Date(generalInfo.birthDate), i18n.language) :
                                        "No information."}
                                </Typography>
                            </Box>
                            <Box className="about-information">
                                <Typography fontSize="16px" fontWeight={500}>
                                    {t("USER_GENDER")}
                                </Typography>
                                <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                    {generalInfo?.gender ?? "No information."}
                                </Typography>
                            </Box>
                            <Box className="about-information">
                                <Typography fontSize="16px" fontWeight={500}>
                                    {t("USER_EMAIL")}
                                </Typography>
                                <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                    {generalInfo?.email ?? "No information."}
                                </Typography>
                            </Box>
                            <Box className="about-information">
                                <Typography fontSize="16px" fontWeight={500}>
                                    {t("GENERAL_PHONE_NUMBER")}
                                </Typography>
                                <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                    {generalInfo?.phoneNumber ?? "No information."}
                                </Typography>
                            </Box>
                            <Box className="about-information">
                                <Typography fontSize="16px" fontWeight={600}>
                                    {t("CLIENT_HEALTH_INSURANCE")}
                                </Typography>
                                <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                    {generalInfo?.healthInsurance ?? "No information."}
                                </Typography>
                            </Box>
                            <Box className="about-information">
                                <Typography fontSize="16px" fontWeight={600}>
                                    {t("CLIENT_AHV")}
                                </Typography>
                                <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                    {client?.ahvNumber ?? "No information."}
                                </Typography>
                            </Box>
                            <Box className="about-information">
                                <Typography fontSize="16px" fontWeight={600}>
                                    {t("USER_COMM_LANG")}
                                </Typography>
                                <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                    {generalInfo?.communicationLanguage.toLocaleUpperCase() ?? "No information."}
                                </Typography>
                            </Box>
                            <Box className="about-information">
                                <Typography fontSize="16px" fontWeight={600}>
                                    {t("CLIENT_AGENCY_NAME")}
                                </Typography>
                                <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                    {generalInfo?.contactAgency?.agencyName ?? "No information."}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </ActivityLayout>
                <Box className="session-container">
                    <Box className="session-header">
                        <Typography fontSize="20px" fontWeight={500}>{t("SESSIONS_LABEL")}</Typography>
                    </Box>
                    <ClientDetailSessionTable sessions={sessions} updateSession={handleSessionUpdate} />
                </Box>
                <Box className="schedule-container">
                    <ClientEvents events={events} clientId={client?.id!} />
                    <Box className="helper-list-container">
                        <Typography fontSize="18px" fontWeight={600}>
                            {t("USER_RESPONSIBLE_PEOPLE")}
                        </Typography>
                        <Box className="helper-list">
                            {client?.responsibleUsers && client!.responsibleUsers!.length > 0 ? (
                                client!.responsibleUsers.map((element) => (
                                    <Box key={element.responsibleUserId}>
                                        <Typography>{element.responsibleUserName}</Typography>
                                    </Box>
                                ))
                            ) : (
                                <Typography fontSize={"14px"}>
                                    {t("CLIENT_NO_RESPONSIBLE_PEOPLE")}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
                {selectedUserInstitution?.roles.some(r => ["Admin", "Field Coordinator", "Field Coordinator Light", "USZ Coordinator"].includes(r)) &&
                    <Box sx={{
                        width: {
                            sm: '99%', marginBottom: "75px"
                        },
                    }}>
                        <ClientSessionTimelineOverview />
                    </Box>}
            </Box >
        </>
    );
}


export default observer(ClientAbout);
