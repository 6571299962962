import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    Typography,
} from "@mui/material";
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

import { useEffect } from "react";

import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

function SurveySection() {
    const { t } = useTranslation();
    const {
        surveyStore: { getSurveyList, surveyList },
    } = useStore();

    const viewSurveyDetails = (id: string) => {
        window.open(`${window.location.origin}/survey/${id}`)
    };

    useEffect(() => {
        const fetchData = async () => {
            await getSurveyList();
        };

        fetchData();
    }, [getSurveyList]);

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex" }}>
                {/* <Box sx={{ display: "inline-block", marginRight: 3, marginTop: 3 }}>
                    <Card
                        onClick={() => navigate("/screening/questionnaire")}
                        className="create-card"
                    >
                        <CardContent>
                            <AddIcon color="secondary" />
                        </CardContent>
                    </Card>
                </Box> */}
                <Box
                    sx={{
                        display: "flex",
                        marginRight: 3,
                        marginTop: 3,
                        flexWrap: "wrap",
                        gap: 1,
                    }}
                >
                    {surveyList.map((survey) => (
                        <Card key={survey.id} className="survey-card">
                            <CardHeader
                                action={
                                    <IconButton onClick={() => viewSurveyDetails(survey.id)} title={"Open in new tab"}>
                                        <OpenInNewRoundedIcon sx={{ fontSize: "18px" }} />
                                    </IconButton>
                                }
                                sx={{ p: "8px" }}
                                title={
                                    <Typography
                                        title={survey.name}
                                        className="survey-title"
                                        color="text.secondary"
                                        sx={{ width: "100px" }}
                                    >
                                        {survey.name}
                                    </Typography>
                                }
                            />
                            <Divider />
                            <CardContent sx={{ p: "8px" }}>
                                <Typography
                                    sx={{ mb: 1.5, height: "150px", overflowY: "auto" }}
                                    color="text.secondary"
                                    fontSize="12px"
                                >
                                    {survey.description === "" || survey.description === null
                                        ? t("EVENT_NO_DESCRIPTION")
                                        : survey.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

export default observer(SurveySection);
