import { Chip, Typography } from "@mui/material";
import { TFunction } from "i18next";


interface StatusChipProps<StatusType> {
    title?: string;
    status: StatusType;
    date?: string;
    t: TFunction<"translation", undefined>;
    dueDate: Date;
    getStatusProps: (status: StatusType, dueDate: Date) => { icon: JSX.Element; color: string };
}

export function StatusChip<StatusType extends string>({ title, status, t, dueDate, getStatusProps }: StatusChipProps<StatusType>): JSX.Element {
    const { icon, color } = getStatusProps(status, dueDate);

    return (
        <Chip
            title={t(`${title} - ${t(`${status}`)}`)}
            icon={icon}
            label={
                <>
                    {title && (
                        <Typography
                            fontSize={11}
                            fontWeight={500}
                            sx={{ color: "#333" }}
                            lineHeight={.8}
                            marginTop={1}
                        >
                            {t(`${title}`)}
                        </Typography>
                    )}
                    {status &&
                        <Typography
                            fontSize={11}
                            fontWeight={500}
                            sx={{
                                color: title ? "#777" : "#333", display: "block", whiteSpace: "normal"
                            }}
                        >
                            {t(`${status}`)}
                        </Typography>
                    }
                </>
            }
            data-test-id={`status- chip - ${title ?? status}`}
            sx={{ bgcolor: color, height: title ? "36px" : "26px", }}
        />
    );
}
