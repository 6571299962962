import {
    Box
} from "@mui/material";
import ClientSurveyTable from "./ClientSurveyTable";

import { useContext, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStore } from "../../../../../stores/store";
import {
    SessionTimeline,
} from "../../../../../data/models/session";
import SessionStatusCards from "./SessionStatusCards";
import SessionDashboardHeader from "./SessionDashboardHeader";
import Sidebar from "../../../../../components/sidebar/Sidebar";
import DashboardActions from "./DashboardActions";
import { SessionOverviewContext } from "./SessionOverview";
import { ClientConsent } from "./SessionClientInfo";

function SessionDashboard() {
    const { width } = useContext(SessionOverviewContext);
    const { sessionStore } = useStore();
    const {
        loadingTimelines,
        session
    } = sessionStore;

    const [selectedTimeline, setSelectedTimeline] =
        useState<SessionTimeline | null>(null);

    const onTimelineChange = (timeline: SessionTimeline) => {
        setSelectedTimeline(timeline);
    };

    const isSameOrPastDate = (date: Date) => {
        const currentDate = new Date();
        return (
            date.getFullYear() === currentDate.getFullYear() &&
            date.getMonth() === currentDate.getMonth() &&
            date.getDate() === currentDate.getDate()
        ) || date < currentDate;
    };

    const isActive = !!session?.timelineStartTime && isSameOrPastDate(new Date(session.timelineStartTime));

    return (
        <Box className="table-container">
            <Sidebar />
            <Box className="session-stats">
                <Box sx={{ flexGrow: 3 }}>
                    <SessionDashboardHeader selectedTimeline={selectedTimeline} onTimelineChange={onTimelineChange} />
                    {width <= 1100 && <Box className="session-action-container">
                        <DashboardActions isActive={isActive} />
                    </Box>
                    }
                    <ClientConsent active={true}></ClientConsent>
                    <Box className="session-events" sx={{ flexGrow: 1 }}>
                        <SessionStatusCards timeline={selectedTimeline} />
                    </Box>
                </Box>
            </Box>
            <Box display={"flex"} flexGrow={1}>
                <ClientSurveyTable
                    timeline={selectedTimeline}
                    loading={loadingTimelines}
                />
            </Box>
        </Box>
    );
}

export default observer(SessionDashboard);
