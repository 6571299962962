import { Box, FormControl, TextField } from "@mui/material";
import { QuestionProps } from "./QuestionProps";
import { SurveyText } from "../../../../_styles/survey/TextInput";
import { Controller } from "react-hook-form";
import { SurveyFieldError } from "../SurveyFields/SurveyField";
import { useStore } from "../../../../stores/store";
import { AnswerSubmit } from "../../../../data/models/survey";
import { useLocation, useParams } from "react-router-dom";
import debounce from "lodash.debounce";
import { useCallback } from "react";

interface SurveyTextProps extends QuestionProps {
    multiline?: boolean;
}

export function TextInput(props: SurveyTextProps) {
    const { surveyStore: { getAnswer, setAnswer, submitAnswer, incrementCount, decrementCount } } = useStore();
    const answer = getAnswer(props.id)?.answer;

    const { sessionId, surveyId, timelineId } = useParams();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');


    const handleSave = () => {
        if (props.preview) return;

        const response = getAnswer(props.id)?.answer;
        const answ: AnswerSubmit = {
            questionId: props.id,
            surveyId: surveyId!,
            timelineId: timelineId!,
            sessionId: sessionId!,
            text: response === "" ? null : response
        }

        submitAnswer(answ, token ?? undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceFn = useCallback(debounce(handleSave, 400), []);

    return (
        <Box>
            <Controller
                name={`${props.id}.response`}
                control={props.control}
                defaultValue={""}
                render={({
                    field: { onChange, value = "" },
                    fieldState: { error },
                }) => (
                    <>
                        <SurveyText
                            placeholder={""}
                            value={answer ?? ""}
                            onChange={(e) => {
                                onChange(e);
                                setAnswer(props.id, { id: props.id, answer: e.target.value });
                                incrementCount();
                                setTimeout(() => decrementCount(), 400);
                                debounceFn()
                            }}
                            variant="filled"
                            multiline={props.multiline}
                            rows={props.multiline ? 3 : 1}
                            size="small"
                            disabled={props.disabled}
                        />
                        {!!error && <SurveyFieldError message="This field is required" />}
                    </>
                )}
            />
        </Box>
    );
}

export function TextCreator(props: SurveyTextProps) {
    return (
        <FormControl sx={{ width: props.multiline ? "85%" : "55%" }}>
            <TextField
                name={`questions.${props.id}.titleee`}
                placeholder={`${props.multiline ? "Long" : "Short"} text answer`}
                variant="filled"
                sx={{
                    backgroundColor: "transparent",
                    "& .Mui-disabled": {
                        backgroundColor: "transparent"
                    },
                    marginBottom: "1.5rem"
                }}
                inputProps={{
                    style: {
                        padding: "0",
                        fontSize: "16px",
                    },
                }}
                size="small"
                disabled
            />
        </FormControl>
    );
}