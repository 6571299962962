import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material"
import { observer } from "mobx-react-lite"
import { useStore } from "../../../stores/store";
import { SessionAction, SessionDialogData } from "../../../data/models/session";
import { RoundButton } from "../../../_styles/StyledButtons";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import FormInputSelect from "../../../components/form/FormInputSelect";
import { useForm } from "react-hook-form";
import { brightDialogTimelines, spiritDialogTimelines } from "../../../data/static/booking";
import { FormInputText } from "../../../components/form/FormInputText";

const buttonStlyes = {
    width: 'auto'
}

function ClientSessionActionDialog() {
    const { t } = useTranslation();
    const {
        dialogStore: { dialog },
    } = useStore();
    const {
        title,
        message,
        open,
        param,
        onCancel,
        onConfirm,
        button,
        action
    } = dialog;
    const [loading, setLoading] = useState(false);
    const options = [
        { id: 1, name: 'During' },
        { id: 2, name: 'After' }
    ];
    const {
        control,
        watch,
        reset
    } = useForm({
        defaultValues: {
            timeframe: 1,
            reason: '',
            timeline: param && param.study === 'BRIGHT' ? brightDialogTimelines[0].name : spiritDialogTimelines[0].name,
        }
    });
    const optionSelection = watch("timeframe")
    const selectedTimeline = watch("timeline");
    const reasonDescription = watch("reason");

    const handleConfirm = async () => {
        const selectedOption = options.find(option => option.id === optionSelection);
        const selectedTimeframe = selectedOption ? selectedOption.name : '';

        setLoading(true)
        if (action === SessionAction.DEACTIVATE) {
            const requestObject: SessionDialogData = {
                action: action,
                sessionId: param.id,
                sessionData: {
                    reason: reasonDescription,
                    timeframe: selectedTimeframe,
                    timeline: selectedTimeline
                }
            }

            try {
                await onConfirm(requestObject);
            } catch (error) {
                onCancel();
                throw error;
            } finally {
                setLoading(false);
                reset();
            }
        }
        else if (action === SessionAction.EMERGENCY) {
            const requestObject: SessionDialogData = {
                action: action,
                sessionId: param.id,
                sessionData: {
                    emergencyHold: true,
                    reason: reasonDescription,
                    timeframe: selectedTimeframe,
                    timeline: selectedTimeline
                }
            }

            try {
                await onConfirm(requestObject);
            } catch (error) {
                onCancel();
                throw error;
            } finally {
                setLoading(false);
                reset();
            }
        }
        else {
            setLoading(false);
            reset();
        }
    }

    const handleClose = () => {
        reset();
        onCancel();
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            PaperProps={{
                style: { padding: '1rem' }
            }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ width: '100%', paddingTop: '20px' }}>
                        <Typography variant="body2">{`${t("DIALOG_CLIENT_SESSION_TIMEFRAME")} *`}</Typography>
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
                        <FormInputSelect
                            name="timeframe"
                            options={options}
                            valueKey="id"
                            labelKey="name"
                            control={control}
                        />
                        <FormInputSelect
                            name="timeline"
                            options={param && param.study === 'BRIGHT' ? brightDialogTimelines : spiritDialogTimelines}
                            valueKey="name"
                            labelKey="name"
                            control={control}
                        />
                    </Box>
                </Box>
                <Box>
                    <FormInputText name="reason" control={control} label={`${t("DIALOG_CLIENT_SESSION_REASON")}`} rows={3} />
                </Box>
            </DialogContent>
            <DialogActions>
                <RoundButton variant="text" color="inherit" sx={buttonStlyes} onClick={handleClose} title="Cancel" data-testid="dialog-cancel">
                    <Typography fontSize="13px">{t("GENERAL_CANCEL")}</Typography>
                </RoundButton>
                <RoundButton variant="contained" color={button.color} sx={buttonStlyes} onClick={handleConfirm} disabled={loading} title="Delete" data-testid="dialog-confirm">
                    {loading ? (
                        <CircularProgress color="secondary" size={25} />
                    ) : (
                        <Typography fontSize="13px">{button.text}</Typography>
                    )}
                </RoundButton>
            </DialogActions>
        </Dialog>
    )
}

export default observer(ClientSessionActionDialog)