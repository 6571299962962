import { Box, Stack, Typography } from "@mui/material";

import { RoundButton } from "../../../../_styles/StyledButtons";
import "./styles.css";
import { useStore } from "../../../../stores/store";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

interface FailAttemptOptionsProps {
    sessionId: string;
}

function FailAttemptOptions({ sessionId }: FailAttemptOptionsProps) {
    const { t } = useTranslation();
    const { sessionStore, modalStore } = useStore();
    const { resetSessionCode, loading } = sessionStore;
    const { closeModal } = modalStore;

    const onReset = async () => {
        await resetSessionCode(sessionId);
        closeModal();
        toast.success(t("TOAST_SESSION_NEW_CODE"));
    };

    return (
        <Stack>
            <Box display={"flex"} justifyContent={"center"}>
                <Typography
                    sx={{
                        textAlign: "start",
                        color: "#404040",
                    }}
                >
                    {t("SESSION_FAIL_CODE")}
                </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
                {/* <Typography className="loading-text" fontWeight={600}>Generating new code...</Typography> */}
                <RoundButton
                    disableTouchRipple
                    disabled={loading}
                    variant="contained"
                    sx={{ width: "100%", mt: 3 }}
                    onClick={onReset}
                >
                    {loading ? (
                        <Typography
                            className="loading-text"
                            fontSize={"13px"}
                            fontWeight={500}
                            sx={{ color: "#808080" }}
                        >
                            {t("SESSION_CODE_LOAD")}...
                        </Typography>
                    ) : (
                        t("SESSION_CODE_NEW")
                    )}
                </RoundButton>
            </Box>
        </Stack>
    );
}

export default observer(FailAttemptOptions);
