import {
    Grid,
    Box,
    CircularProgress,
    Divider,
    Typography,
} from "@mui/material";

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";

import { FormInputText } from "../../../components/form/FormInputText";
import { RoundButton } from "../../../_styles/StyledButtons";
import { RoomFormValues } from "../../../data/models/room";
import FormInputSelect from "../../../components/form/FormInputSelect";
import { useStore } from "../../../stores/store";
import { Institution } from "../../../data/models/institution";
import {
    roomFormSchema,
    roomUpdateFormSchema,
} from "../../../_validators/schemas/roomForm.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

interface RoomFormProps {
    room?: RoomFormValues;
}

function RoomForm({ room }: RoomFormProps) {
    const { t } = useTranslation();
    const defaultValues = {
        resourceType: room?.resourceType ?? "room",
        name: room?.name ?? "",
        capacity: room?.capacity ?? 6,
        notes: room?.notes ?? "",
        city: room?.city ?? "",
        street: room?.street ?? "",
        number: room?.number ?? "",
        zipCode: room?.zipCode ?? "",

    };
    const {
        institutionStore: { getInstitutions, institutionList },
        tenantStore: { userSettingsTenant },
        roomStore: { loading, getRooms, updateRoom, createRoom },
        sidebarStore: { closeSidebar },
    } = useStore();

    const editMode = room !== undefined;
    const { handleSubmit, control } = useForm<any>({
        resolver: yupResolver(editMode ? roomUpdateFormSchema() : roomFormSchema()),
        defaultValues: defaultValues,
    });

    const onSubmit = async (data: any) => {
        const roomData: RoomFormValues = {
            ...data,
            id: room?.id,
            institutionId: data.institutionId,
        };

        const saveAction = editMode ? updateRoom : createRoom;

        await saveAction(roomData);
        closeSidebar();
        toast.success(editMode ? t("TOAST_ROOM_UPDATE") : t("TOAST_ROOM_CREATE"));
        await getRooms(userSettingsTenant!.id);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (userSettingsTenant) {
                getInstitutions(userSettingsTenant!.id);
            }
        };

        fetchData();
    }, [getInstitutions, userSettingsTenant]);

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 3, mb: "1.5rem", display: "flex", flexDirection: "column" }}
        >
            <Grid container columnSpacing={1}>
                <Grid item xs={12}>
                    <FormInputSelect
                        name="resourceType"
                        control={control}
                        label={`${t("ROOM_RESOURCE_TYPE")} *`}
                        options={[{ label: "Room", value: "room" }]}
                        labelKey={"label"}
                        valueKey={"value"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormInputText name="name" control={control} label={`${t("GENERAL_NAME")} *`} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputText
                        type="number"
                        name="capacity"
                        control={control}
                        label={`${t("ROOM_SIZE")} *`}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormInputSelect<Institution>
                        name="institutionId"
                        control={control}
                        options={institutionList}
                        valueKey="id"
                        labelKey="name"
                        label={`${t("ROOM_INST_PICK")} *`}
                        defaultValue={room?.institutionId}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInputText name="city" control={control} label={`${t("GENERAL_CITY")} *`} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInputText name="street" control={control} label={`${t("GENERAL_STREET_ADDRESS")} *`} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInputText name="number" control={control} label={`${t("GENERAL_STREET_NUMBER")} *`} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInputText name="zipCode" control={control} label={`${t("GENERAL_ZIP")} *`} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputText
                        name="notes"
                        control={control}
                        label={`${t("GENERAL_NOTES")}`}
                        rows={3}
                    />
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: "flex", justifyContent: "end" }}>
                <RoundButton
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, alignSelf: 'flex-end' }}
                    disabled={loading}
                    title="Submit"
                    data-testid="submit-button"
                >
                    {loading
                        ? (
                            <CircularProgress size={25} />
                        )
                        : (
                            editMode
                                ? <Typography fontSize="13px">{t("GENERAL_UPDATE")}</Typography>
                                : <Typography fontSize="13px">{t("GENERAL_SUBMIT")}</Typography>
                        )}
                </RoundButton>
            </Box>
        </Box>
    );
}

export default observer(RoomForm);
