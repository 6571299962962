import { ButtonPropsColorOverrides } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { makeAutoObservable } from "mobx";
import { SessionAction } from "../data/models/session";

interface Dialog {
    open: boolean;
    title: string;
    message: string | JSX.Element;
    reason?: string;
    param?: any;
    action?: SessionAction,
    onConfirm: (data?: any) => void | Promise<void>;
    onCancel: () => void;
    button: DialogButton
}

interface DialogButton {
    text: string;
    color: OverridableStringUnion<"inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning", ButtonPropsColorOverrides>;
}

export class DialogStore {
    dialog: Dialog;

    constructor() {
        this.dialog = {
            open: false,
            title: "",
            message: "",
            reason: "",
            param: null,
            action: undefined,
            onConfirm: (data?) => { },
            onCancel: () => { },
            button: {
                text: "Delete",
                color: "error",
            },
        };
        makeAutoObservable(this);
    }

    openDialog = (
        title: string,
        message: string,
        onConfirm: (data?: any) => void | Promise<void>,
        onCancel: () => void,
        button?: DialogButton,
        param?: any,
        action?: SessionAction,
        reason?: string) => {
        this.dialog = {
            open: true,
            title,
            message,
            onConfirm,
            onCancel,
            button: button ? button : { text: "Delete", color: "error" },
            param,
            action,
            reason,
        };
    }

    setDescription = (reason: string) => {
        this.dialog.reason = reason;
    }

    closeDialog = () => {
        this.dialog.open = false;
    }
}