import { Box, IconButton } from "@mui/material";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";

import { observer } from "mobx-react-lite";
import { format, formatDistance } from "date-fns";

import { SessionSurveyStatus, SessionTimeline } from "../../../../../data/models/session";
import GenericTable from "../../../../../components/GenericTable";
import { TableHeader } from "../../../../../_styles/table/TableHeader";
import { TableText } from "../../../../../_styles/table/TableText";
import { getSurveyStatusChip } from "../statusProps";
import { useTranslation } from "react-i18next";
import { getDateFnsLocalizer } from "../../../../../utils/utils";

interface ClientSurveyTableProps {
    timeline: SessionTimeline | null;
    loading?: boolean;
}

function ClientSurveyTable({ timeline, loading = false }: ClientSurveyTableProps) {
    const { t, i18n } = useTranslation();

    return (
        <Box display="flex" flexDirection="column" flexGrow={1}>
            <GenericTable<{
                id: string;
                name: string;
                status: SessionSurveyStatus;
                completedAt: Date | null;
            }>
                columns={[
                    {
                        header: <TableHeader>{t("GENERAL_NAME")}</TableHeader>,
                        key: "name",
                        render: (survey) => <TableText>{survey.name}</TableText>,
                        className: "session-survey-cell",
                        sort: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                    },
                    {
                        header: <TableHeader>{t("CLIENT_PROCESS_STATUS")}</TableHeader>,
                        key: "status",
                        render: (survey) => <Box>{getSurveyStatusChip(survey.status, t)}</Box>,
                        className: "session-survey-cell"
                    },
                    {
                        header: <TableHeader>{t("BL_SUBMISSION")}</TableHeader>,
                        key: "submissionTime",
                        render: (survey) => (
                            <TableText
                                title={
                                    survey.completedAt
                                        ? format(new Date(survey.completedAt), "dd MMMM, yyyy 'at' HH:mm:ss XX", { locale: getDateFnsLocalizer(i18n.language) })
                                        : "Not completed"
                                }
                            >
                                {survey.completedAt
                                    ? formatDistance(new Date(survey.completedAt), new Date(), {
                                        addSuffix: true,
                                        locale: getDateFnsLocalizer(i18n.language),
                                    })
                                    : "-"}
                            </TableText>
                        ),
                        className: "session-survey-cell",
                        sort: (a, b) => {
                            if (a.completedAt) {
                                if (b.completedAt) {
                                    return a.completedAt === b.completedAt ? 0 : a.completedAt > b.completedAt ? 1 : -1;
                                }
                                return 1;
                            }
                            return -1;
                        },
                    },
                    {
                        header: <></>,
                        key: "actions",
                        render: (survey) => (
                            <IconButton
                                sx={{ p: 0, color: "#52b2ff" }}
                                onClick={() => {
                                    window.open(
                                        `${window.location.origin}/timeline/${timeline?.timelineId}/survey/${survey.id}`
                                    );
                                }}
                                title={"Open in new tab"}
                            >
                                <OpenInNewRoundedIcon sx={{ color: "#457b9d", fontSize: "18px" }} />
                            </IconButton>
                        ),
                        className: "session-survey-cell"
                    },
                ]}
                rows={
                    timeline?.surveys.map((survey) => ({
                        id: survey.id,
                        name: survey.name,
                        status: survey.status,
                        completedAt: survey.completedAt,
                    })) || []
                }
                props={{
                    containerProps: { className: "survey-table-container session-survey-table" },
                    tableContainerProps: { className: "survey-table-container-data", style: { border: "none" } },
                    tableHeadProps: { className: "survey-table-header survey-header" },
                    rowProps: { className: "survey-row" },
                    paginationProps: { className: "survey-footer" },
                }}
                rowKey="id"
                usePagination
            />
        </Box>
    );
}

export default observer(ClientSurveyTable);
