import { Container, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { TableHeader } from "../../_styles/table/TableHeader";
import { TableText } from "../../_styles/table/TableText";
import ConfirmDialog from "../../components/ConfirmDialog";
import FormInputDate from "../../components/form/FormInputDate";
import GenericTable from "../../components/GenericTable";
import { NotificationResultLog } from "../../data/models/notificationLog";
import { Pagination } from "../../data/models/pagination";
import { DateRange } from "../../data/models/stats";
import { useStore } from "../../stores/store";
import { useTranslation } from "react-i18next";
import { getDateAndTimeFormatByPlatformLanguage } from "../../utils/utils";

function NotificationLogsTable() {
    const { i18n } = useTranslation();

    const {
        notificationLogStore: { getNotificationLogs, logList, loading },
        commonStore: { selectedInstitution }
    } = useStore();

    const date = new Date();
    const { control, watch, setValue } = useForm({
        defaultValues: {
            startDate: new Date(date.getFullYear(), date.getMonth() - 1, date.getDate()),
            endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
        },
    });

    const paginationString = sessionStorage.getItem("notification-log-pagination");
    const paginationParams = paginationString
        ? JSON.parse(paginationString!)
        : null
    const [pagination, setPagination] = useState<Pagination>({
        page: 1,
        pageSize: paginationParams?.PageSize ?? 10,
    });
    const [isDateChange, setIsDateChange] = useState(false);
    const isInstitutionChangedRef = useRef(false);

    const firstDay = watch('startDate');
    const lastDay = watch('endDate');

    const handlePageChange = async (page: number, pageSize: number) => {
        setPagination({ page: page + 1, pageSize });
    }
    useEffect(() => {
        const fetchData = async () => {
            if (selectedInstitution) {
                isInstitutionChangedRef.current = true;
                await fetchNotificationLogs();
                isInstitutionChangedRef.current = false;
            }
        };
        fetchData();
    }, [selectedInstitution]);

    useEffect(() => {
        const fetchData = async () => {
            const now = new Date();
            now.setMonth(now.getMonth());

            const firstDate = new Date(firstDay);
            firstDate.setDate(firstDay.getDate() + 1)
            const lastDate = new Date(lastDay);
            lastDate.setDate(lastDate.getDate() + 1);

            const range: DateRange = {
                startDate: firstDate.toISOString(),
                endDate: lastDate.toISOString()
            }

            await getNotificationLogs(
                {
                    paging: {
                        page: pagination.page,
                        pageSize: pagination.pageSize,
                    },
                },
                selectedInstitution,
                range
            );
            setPagination({ page: 1, pageSize: 10 });

        }

        fetchData();
    }, []);

    useEffect(() => {
        fetchNotificationLogs();
    }, [pagination])

    const handleSelect = async () => {
        setIsDateChange(true);
        setPagination({ page: 1, pageSize: 10 });
    };

    const fetchNotificationLogs = async () => {
        const now = new Date();
        now.setMonth(now.getMonth());

        const firstDate = new Date(firstDay);
        firstDate.setDate(firstDay.getDate() + 1)
        const lastDate = new Date(lastDay);
        lastDate.setDate(lastDate.getDate() + 1);

        const range: DateRange = {
            startDate: firstDate.toISOString(),
            endDate: lastDate.toISOString()
        }
        await getNotificationLogs(
            {
                paging: {
                    page: pagination.page,
                    pageSize: pagination.pageSize,
                },
            },
            selectedInstitution,
            range, isDateChange, isInstitutionChangedRef.current

        );
        setIsDateChange(false);
    };

    return (
        <Container maxWidth="xl">
            <Typography variant="h5" component="h1" sx={{ py: 2 }} >{t("NOTIFICATIONS_LOGS")}</Typography>
            <Grid container component={"form"} sx={{ display: "flex", alignItems: "center", maxWidth: "500px", mb: 2 }}>
                <Grid item xs={4} mr={2}>
                    <FormInputDate
                        name="startDate"
                        control={control}
                        date={new Date(date.getFullYear(), date.getMonth() - 1, date.getDate())}
                        label="Start Date"
                        onSelect={handleSelect}
                    />
                </Grid>
                <Grid item xs={4} mr={2}>
                    <FormInputDate
                        name="endDate"
                        control={control}
                        date={new Date(date.getFullYear(), date.getMonth(), date.getDate())}
                        label="End Date"
                        onSelect={handleSelect}
                        dateLimit={firstDay}
                    />
                </Grid>
            </Grid>
            <GenericTable<NotificationResultLog>
                columns={[
                    {
                        header: <TableHeader>{t("RECIPIENT_FULL_NAME")}</TableHeader>,
                        key: "recipientFullName",
                        render: (log) => <TableText>{log.recipientFullName}</TableText>,
                        className: "process-cell",
                    },
                    {
                        header: <TableHeader>{t("TARGET")}</TableHeader>,
                        key: "target",
                        render: (log) => <TableText>{log.target}</TableText>,
                        className: "process-cell",
                    },
                    {
                        header: <TableHeader>{t("TEMPLATE_NAME")}</TableHeader>,
                        key: "templateName",
                        render: (log) => <TableText>{log.templateName}</TableText>,
                        className: "process-cell",
                    },
                    {
                        header: <TableHeader>{t("SUBJECT")}</TableHeader>,
                        key: "subject",
                        render: (log) => <TableText>{log.subject}</TableText>,
                        className: "process-cell",
                    },
                    {
                        header: <TableHeader>{t("SUCCEEDED")}</TableHeader>,
                        key: "succeeded",
                        render: (log) => (
                            <TableText
                                style={{
                                    color: log.succeeded ? "green" : "red",
                                    fontWeight: "bold",
                                }}
                            >
                                {log.succeeded ? t("SUCCESS") : t("GENERAL_FAILED")}
                            </TableText>
                        ), className: "process-cell",
                    },
                    {
                        header: <TableHeader>{t("LANGUAGE")}</TableHeader>,
                        key: "languageId",
                        render: (log) => <TableText>{log.languageId}</TableText>,
                        className: "process-cell",
                    },
                    {
                        header: <TableHeader>{t("CHANNEL")}</TableHeader>,
                        key: "channel",
                        render: (log) => <TableText>{log.channel}</TableText>,
                        className: "process-cell",
                    },
                    {
                        header: <TableHeader>{t("TIMESTAMP")}</TableHeader>,
                        key: "timestampUtc",
                        render: (log) => <TableText>{getDateAndTimeFormatByPlatformLanguage(new Date(log.timestampUtc), i18n.language)}</TableText>,
                        className: "process-cell",
                    },
                    {
                        header: <TableHeader>{t("INTERNAL_MESSAGE")}</TableHeader>,
                        key: "internalMessage",
                        render: (log) => <TableText>{log.internalMessage}</TableText>,
                        className: "process-cell",
                    },


                ]}
                rows={logList || []}
                props={{
                    containerProps: { className: "survey-table-container" },
                    tableContainerProps: { className: "survey-table-container-data" },
                    tableHeadProps: { className: "survey-table-header" },
                    rowProps: { className: "survey-row" },
                }}
                onPaginationModelChange={handlePageChange}
                rowCount={paginationParams?.TotalCount ?? logList.length}
                rowKey="id"
                usePagination
                loading={loading}
            />

            <ConfirmDialog />
        </Container >
    );
}

export default observer(NotificationLogsTable);
