import { makeAutoObservable, reaction } from "mobx";

import { PermissionDto } from "../data/models/role";
import { store } from "./store";
import { CalendarView } from "../data/models/event";
import { Pagination } from "../data/models/pagination";

export default class CommonStore {
    token: string | null = sessionStorage.getItem('jwt');
    userPermissions: string | null = sessionStorage.getItem('userPermissions');
    selectedInstitution: string | null = sessionStorage.getItem('selectedInstitution');
    selectedTenant: string | null = sessionStorage.getItem('selectedTenant');
    calendarView: string | null = sessionStorage.getItem('calendarView');
    viewType: string | null = sessionStorage.getItem('viewType');
    calendarDate: string | null = sessionStorage.getItem('calendarDate');
    clientPagination: string | null = sessionStorage.getItem('client-pagination');
    auditLogPagination: string | null = sessionStorage.getItem('log-pagination');
    notificationLogPagination: string | null = sessionStorage.getItem('notification-log-pagination');
    studyOverviewPagination: string | null = sessionStorage.getItem('study-overview-pagination');

    appLoaded = false;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => [
                this.token,
                this.userPermissions,
                this.selectedInstitution,
                this.selectedTenant,
                this.calendarView,
                this.calendarDate,
                this.viewType,
                this.clientPagination,
                this.studyOverviewPagination,
                this.auditLogPagination,
                this.notificationLogPagination
            ],
            ([token, userPermissions, selectedInstitution, selectedTenant, calendarView, calendarDate, viewType, clientPagination, studyOverviewPagination, auditLogPagination, notificationLogPagination]) => {
                if (token) {
                    sessionStorage.setItem('jwt', token)
                } else {
                    sessionStorage.removeItem('jwt')
                }
                if (userPermissions) {
                    sessionStorage.setItem('userPermissions', userPermissions)
                } else {
                    sessionStorage.removeItem('userPermissions')
                }
                if (selectedInstitution) {
                    sessionStorage.setItem('selectedInstitution', selectedInstitution)
                } else {
                    sessionStorage.removeItem('selectedInstitution')
                }
                if (selectedTenant) {
                    sessionStorage.setItem('selectedTenant', selectedTenant)
                } else {
                    sessionStorage.removeItem('selectedTenant')
                }
                if (calendarView) {
                    sessionStorage.setItem('calendarView', calendarView)
                } else {
                    sessionStorage.removeItem('calendarView')
                }
                if (calendarDate) {
                    sessionStorage.setItem('calendarDate', calendarDate)
                } else {
                    sessionStorage.removeItem('calendarDate')
                }
                if (viewType) {
                    sessionStorage.setItem('viewType', viewType)
                } else {
                    sessionStorage.removeItem('viewType')
                }
                if (clientPagination) {
                    sessionStorage.setItem("client-pagination", clientPagination)
                } else {
                    sessionStorage.removeItem('client-pagination')
                }
                if (studyOverviewPagination) {
                    sessionStorage.setItem("study-overview-pagination", studyOverviewPagination)
                }
                else {
                    sessionStorage.removeItem('study-overview-pagination');
                }
                if (auditLogPagination) {
                    sessionStorage.setItem("log-pagination", auditLogPagination)
                }
                else {
                    sessionStorage.removeItem('log-pagination');
                }

                if (notificationLogPagination) {
                    sessionStorage.setItem("notification-log-pagination", notificationLogPagination)
                }
                else {
                    sessionStorage.removeItem('notification-log-pagination');
                }
            },
        )
    }

    userConfig = () => {
        let userTenants = store.profileStore.userTenantList;
        store.institutionStore.setDefaultUserInstitution(userTenants);
        store.tenantStore.setDefaultTenant(userTenants);
        store.tenantStore.loadUserSettingsTenant(store.tenantStore.selectedTenant!.id);
    }

    currentUserConfig = () => {
        store.tenantStore.loadTenant(this.selectedTenant!);
        if (this.selectedInstitution) {
            store.institutionStore.setSelectedUserInstitution(this.selectedInstitution);
        } else {
            store.institutionStore.setDefaultUserInstitution(store.profileStore.userTenantList);
        }
    }

    setToken = (token: string | null) => {
        this.token = token;
    }

    setInstitution = (institutionsPermissions: PermissionDto | null) => {
        this.userPermissions = institutionsPermissions ? JSON.stringify(institutionsPermissions) : null;
    }

    setSelectedInstitution = (institutionId: string | null) => {
        this.selectedInstitution = institutionId;
    }

    setSelectedTenant = (tenantId: string | null) => {
        this.selectedTenant = tenantId;
    }

    setCalendarViews = (selectedViews: CalendarView[] | null) => {
        const filteredViews = selectedViews?.filter(view => view.id !== '');
        this.calendarView = filteredViews ? JSON.stringify(filteredViews) : null;
    }

    setCalendarDate = (selectedDate: Date | null) => {
        this.calendarDate = selectedDate ? selectedDate.toString() : null;
    }

    setViewType = (viewType: string | null) => {
        this.viewType = viewType;
    }

    getClientPagination = (): Pagination | null => {
        return this.clientPagination ? JSON.parse(this.clientPagination) : null;
    }
    setClientPagination = (pagination: string | null) => {
        this.clientPagination = pagination;
    }

    getAuditLogPagination = (): Pagination | null => {
        return this.auditLogPagination ? JSON.parse(this.auditLogPagination) : null;
    }
    getNotificationLogPagination = (): Pagination | null => {
        return this.notificationLogPagination ? JSON.parse(this.notificationLogPagination) : null;
    }

    setAuditLogPagination = (pagination: string | null) => {
        this.auditLogPagination = pagination;
    }

    setStudyOverviewPagination = (pagination: string | null) => {
        this.studyOverviewPagination = pagination;
    }

    getStudyOverviewPagination = (): Pagination | null => {
        return this.studyOverviewPagination ? JSON.parse(this.studyOverviewPagination) : null;
    }

    setNotificationLogPagination = (pagination: string | null) => {
        this.notificationLogPagination = pagination;
    };

    getCalendarViews = (): CalendarView[] => {
        return this.calendarView ? JSON.parse(this.calendarView!) : [];
    }


    clearSession = () => {
        this.setToken(null);
        this.setInstitution(null);
        this.setSelectedInstitution(null);
        this.setSelectedTenant(null);
        this.setCalendarViews(null);
        this.setCalendarDate(null);
        this.setViewType(null);
        this.setNotificationLogPagination(null);
        this.setAuditLogPagination(null);
        this.setStudyOverviewPagination(null);
        store.institutionStore.setSelectedUserInstitution(undefined);
        store.tenantStore.selectedTenant = undefined;
        store.institutionStore.institutions.clear();
        store.bookingStore.setUserEventsId("");
        store.profileStore.userTenants.clear();
        store.tenantStore.tenants.clear();
        store.tenantStore.userTenantAdmins.clear();
    }

    setAppLoaded = () => {
        this.appLoaded = true;
    }
}