import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../../stores/store";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import FormInputSelect from "../../../../../components/form/FormInputSelect";

interface HealthInsuranceEditFormProps {
    clientId?: string;
    value?: string;
}

function HealthInsuranceEditForm({ clientId, value = "" }: HealthInsuranceEditFormProps) {
    const { handleSubmit, control, watch } = useForm({
        defaultValues: {
            insurance: value
        }
    });
    const { t } = useTranslation();
    const { clientStore, institutionStore, masterDataStore } = useStore();
    const { updateClientFields, getClient } = clientStore;
    const { getHealthInsuranceOptions, healthInsuranceOptionsList } = masterDataStore;
    const { selectedUserInstitution } = institutionStore;
    const insurance = watch('insurance');

    useEffect(() => {
        if (insurance !== null) {
            onSubmit(insurance);
        }
    }, [insurance]);


    useEffect(() => {
        getHealthInsuranceOptions();
    }, []);

    const onSubmit = async (value: any) => {
        if (!clientId) {
            toast.error("Error loading the client info, please try again.");
            return;
        }

        await updateClientFields(clientId, [{
            op: "replace",
            path: "/healthInsurance",
            value: value
        }]);
        await getClient(clientId, selectedUserInstitution?.institutionId!);
    };

    return (
        <Box
            component={"form"}
            onSubmit={handleSubmit(onSubmit)}
            display={"flex"}
            width={"220px"}
        >
            <FormInputSelect
                name={"insurance"}
                control={control}
                hasClearOption
                options={healthInsuranceOptionsList}
                valueKey={"value"}
                labelKey={"name"}
                helperText=""
                loading={healthInsuranceOptionsList.length === 0}
                defaultValue={value}
            />
        </Box>
    );
}

export default observer(HealthInsuranceEditForm);