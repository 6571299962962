import { Box, Container, IconButton, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CachedIcon from "@mui/icons-material/Cached";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";

import { RoundButton } from "../../../../_styles/StyledButtons";
import { useStore } from "../../../../stores/store";
import {
    BreakDuration,
    FollowUpType,
    SessionDetailsDto,
    SessionStatus,
} from "../../../../data/models/session";
import LoadingComponent from "../../../../components/LoadingComponent";
import "./styles.css";
import { formatDistance } from "date-fns";
import { getDecodedToken } from "../../../../utils/jwtDecoder";
import CountdownTimer from "../../../../components/CountdownTimer ";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../../../components/navbar/LanguageSelector";

interface SessionInfoProps {
    session: SessionDetailsDto | null;
    onStart: () => void;
    clientId: string;
    refresh: () => void;
    loading?: boolean;
    sessionToken?: string;
}

interface SessionCompleteProps {
    session: SessionDetailsDto | null;
    clientId: string;
    completed: boolean;
}

function SessionComplete({
    session,
    clientId,
    completed,
}: SessionCompleteProps) {
    const { t } = useTranslation();
    const ended = ["EXCLUDED", "ABORT", "CLIENT_NO_SHOW_UP", "DEACTIVATED"].includes(session?.status ?? "");
    return (
        <Box className="session-info-container completed-container">
            <Box>
                {ended ? (
                    <RemoveCircleIcon sx={{ fontSize: "3rem", color: "#e56b6f" }} />
                ) : (
                    <CheckCircleIcon sx={{ fontSize: "3rem", color: "#2a9d8f" }} />
                )}
            </Box>
            <Box className="session-date">
                {!ended && session?.tokenStateInfo.timelineName === "Baseline" &&
                    <Typography fontSize={14} color="text.secondary" textAlign="center">
                        {t("END_SURVEY_THANK_YOU")}. <br />
                        {t("END_SURVEY_NEXT_STEPS")}.
                    </Typography>
                }
                {!ended && session?.tokenStateInfo.timelineName !== "Baseline" && session?.tokenStateInfo.timelineAssessmentType !== FollowUpType.ONLINE && (
                    <Typography fontSize={14} color="text.secondary" textAlign="center">
                        {t("END_SURVEY_THANK_YOU")}.
                        {session?.institutionalData.study === "BRIGHT" && session?.voucherData?.downloadLink && (
                            <>
                                <br />
                                <br />
                                {t("END_SURVEY_VOUCHER").replace("{placeholder}", session?.voucherData?.valueInCHF.toFixed(0))}:
                                <br />
                                <a href={session?.voucherData?.downloadLink} target="_blank">Download link</a>
                                <p>Code: {session?.voucherData?.authorizationCode}</p>
                                <br />
                            </>
                        )}
                        <br />
                        {t("END_SURVEY_NEXT_STEPS")}.
                    </Typography>
                )}

                {!ended && session?.tokenStateInfo.timelineName !== "Baseline" && session?.tokenStateInfo.timelineAssessmentType === FollowUpType.ONLINE
                    &&
                    <Typography fontSize={14} color="text.secondary" textAlign="center">
                        {t("END_SURVEY_THANK_YOU")}.
                        {session?.institutionalData.study === "BRIGHT" && session?.voucherData?.downloadLink && (
                            <>
                                <br /><br />
                                {t("END_SURVEY_VOUCHER").replace("{placeholder}", session?.voucherData?.valueInCHF.toFixed(0))}:
                                <br />
                                <a href={session?.voucherData?.downloadLink} target="_blank">Download link</a>
                                <p>Code: {session?.voucherData?.authorizationCode}</p>
                                <br /><br />
                            </>
                        )}
                        {t("END_SURVEY_ANY_QUESTIONS")}:
                        <br />
                        {t("GENERAL_PHONE_NUMBER")}: {session?.institutionalData.uszCoordinatorPhoneNumber} <br />
                        {t("GENERAL_EMAIL")}: {session?.institutionalData.uszCoordinatorEmail}  <br /> <br />
                        {t("END_SURVEY_YOUR_SPIRIT_TEAM")}
                    </Typography>
                }
            </Box>
            <Box className="session-date">
                <Typography fontSize={14} color="text.secondary">
                    {ended && !completed
                        && t("SESSION_ABORT_MSG")}
                </Typography>
            </Box>
            <Box className="session-date">
                <Typography fontSize={14} color="text.secondary">
                    {ended && !completed
                        && clientId === session?.clientId
                        ? t("SESSION_ABORT_DESC")
                        : ""
                    }
                </Typography>
            </Box>
        </Box>
    );
}

function SessionInfo({
    session,
    onStart,
    clientId,
    refresh,
    loading = false,
    sessionToken
}: SessionInfoProps) {
    const { t } = useTranslation();
    const { dialogStore, authStore, sessionStore } = useStore();
    const { openDialog, closeDialog } = dialogStore;
    const { startSessionBreak, endSessionBreak, getSessionBreak } = sessionStore;
    const { user } = authStore;

    const isSameOrPastDate = (date: Date) => {
        const currentDate = new Date();
        return (
            date.getFullYear() === currentDate.getFullYear() &&
            date.getMonth() === currentDate.getMonth() &&
            date.getDate() === currentDate.getDate()
        ) || date < currentDate;
    };

    const isActive = !!session?.timelineStartTime && isSameOrPastDate(new Date(session.timelineStartTime));


    const [sessionBreak, setSessionBreak] = useState<BreakDuration | null>(null);

    useEffect(() => {
        const startAndSetDuration = async (sessionId: string, breakId: string, token?: string) => {
            let sessionBreak: BreakDuration | null = await getSessionBreak(sessionId, breakId);
            if (!sessionBreak) {
                await startSessionBreak(sessionId, breakId, token);
                sessionBreak = await getSessionBreak(sessionId, breakId);
            } else if (sessionBreak.endTime) {
                sessionBreak = null;
            }
            setSessionBreak(sessionBreak);
        }

        if (session && session.clientBreaks.length > 0) {
            session.clientBreaks.forEach(b => {
                if (session.timelineId === b.timelineId && session.surveyId === b.surveyId) {
                    startAndSetDuration(session.sessionId, b.id, sessionToken);
                }
            });

            const breakStartTime = sessionStorage.getItem("session-break");
            if (!breakStartTime) {
                const breakEndTime = new Date();
                breakEndTime.setMinutes(breakEndTime.getMinutes() + 5);
                sessionStorage.setItem("session-break", breakEndTime.toString());
            }
        } else {
            sessionStorage.removeItem("session-break");
        }

        return () => {
            closeDialog();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeDialog, session]);

    const isDisabled = () => {
        if (clientId === session?.clientId) {
            return (
                session.currentStep === "SESSION_LOADED" ||
                session.currentStep === "CONSENT_AGREEMENT" ||
                ([FollowUpType.ONSITE, FollowUpType.ASSISTED].includes(session.assessmentType) &&
                    !session.startedByAssessor &&
                    /^(T[2-6]_READY|POST_INTERVENTION_READY)$/.test(session.currentStep ?? "")) ||
                ([FollowUpType.ONSITE, FollowUpType.ASSISTED].includes(session.assessmentType) &&
                    session.status === "AWAITING_EVENT_CREATION")
            );
        } else {
            return (
                session?.currentStep === "SESSION_LOADED"
            );
        }
    };

    const getRemainingBreakTime = () => {
        if (sessionBreak && !sessionBreak.endTime) {
            const breakEndTime = new Date(sessionBreak.startTime);
            breakEndTime.setSeconds(breakEndTime.getSeconds() + sessionBreak.duration);

            const remaining = (breakEndTime.getTime() - new Date().getTime()) / 1000;
            if (remaining < 0) {
                if (session) {
                    endSessionBreak(session.sessionId, sessionBreak.breakId, sessionToken)
                        .then(() => setSessionBreak(null));
                }
                return 0;
            }
            return Math.floor(remaining);
        }

        return 0;
    };

    const onBreakEnd = async () => {
        setSessionBreak(null);
        if (session && sessionBreak) {
            await endSessionBreak(session.sessionId, sessionBreak.breakId, sessionToken);
        }
    };

    return (
        <Box className="session-info-container">
            <ConfirmDialog />
            {!user && <Box sx={{
                position: "absolute",
                top: "22px",
                right: "42px",
            }}
            >
                <LanguageSelector />
            </Box>}
            {(isDisabled() || !isActive) && (
                <IconButton
                    sx={{
                        position: "absolute",
                        top: "16px",
                        right: "16px",
                    }}
                    onClick={refresh}
                >
                    <CachedIcon />
                </IconButton>
            )}
            {
                loading ?
                    <LoadingComponent />
                    :
                    sessionBreak ? (
                        <Box textAlign={"center"}>
                            <Typography fontWeight={500} paddingTop={5}>{t("SESSION_BREAK")}</Typography>
                            <Typography fontSize={13}>
                                {t("SESSION_BREAK_DESC")}
                            </Typography>
                            <CountdownTimer
                                initialCountdown={getRemainingBreakTime()}
                                onFinish={onBreakEnd}
                                view={(countdown) => (
                                    <Typography sx={{ color: "#808080", fontSize: "14pt" }}>
                                        ({Math.floor(countdown / 60)}:
                                        {countdown % 60 < 10 ? "0" + (countdown % 60) : countdown % 60})
                                    </Typography>
                                )}
                            />
                        </Box>
                    ) : (
                        <Box
                            className={`process-name ${!isActive ? "waiting-text" : ""}`}
                            sx={{ flexDirection: "column", alignItems: "center" }}
                        >
                            {isActive ? (
                                session.status === SessionStatus.EMERGENCY_HOLD ? (
                                    <WarningRoundedIcon sx={{ fontSize: '3rem' }} color="warning" />) : (
                                    <Typography fontSize={32} fontWeight={600} paddingTop={5} >
                                        {t("GENERAL_CONTINUE")}
                                    </Typography>
                                )
                            ) : session?.status === SessionStatus.EMERGENCY_HOLD ? (
                                <WarningRoundedIcon sx={{ fontSize: '3rem' }} color="warning" />) : ''}
                            <Typography fontSize={14}>
                                {session?.status === SessionStatus.EMERGENCY_HOLD
                                    ? t("SESSION_IS_HOLD")
                                    : (isActive
                                        ? session?.currentStep === "SESSION_LOADED"
                                            ? t("SESSION_WAIT_START")
                                            : t("SESSION_START_MSG")
                                        : t("SESSION_WAIT_START"))
                                }
                            </Typography>
                        </Box>
                    )}
            {!loading && session?.timelineStartTime && !isActive && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        flexGrow: 1,
                        mb: "2rem",
                        alignItems: "center",
                        mt: "1rem",
                    }}
                >
                    <Box display="flex" alignItems="center" mt="8px">
                        {session?.status !== SessionStatus.EMERGENCY_HOLD && (
                            <Typography fontSize="14px" sx={{ color: "#808080" }}>
                                (Starts&nbsp;
                                {formatDistance(new Date(session.timelineStartTime), new Date(), {
                                    addSuffix: true,
                                })}
                                )
                            </Typography>
                        )}
                    </Box>
                </Box>
            )}
            {!loading && isActive && (
                <Box className="session-actions">
                    {session?.status !== SessionStatus.EMERGENCY_HOLD &&
                        <RoundButton
                            variant="contained"
                            sx={{ width: "100%" }}
                            onClick={() => {
                                if (sessionBreak) {
                                    openDialog(
                                        t("SESSION_BREAK_POPUP"),
                                        t("SESSION_BREAK_NEXT"),
                                        async () => {
                                            await onBreakEnd();
                                            onStart();
                                        },
                                        closeDialog,
                                        { text: t("GENERAL_CONTINUE"), color: "primary" }
                                    );
                                } else {
                                    onStart();
                                }
                            }}
                            disabled={isDisabled()}
                        >
                            {t("GENERAL_START")}
                        </RoundButton>}
                    {isDisabled() && (
                        <Typography fontSize={12} sx={{ color: "#0d3b66", mt: "8px" }}>
                            {session.currentStep === "SESSION_LOADED"
                                ? t("SESSION_IC_MSG")
                                : session.currentStep === "CONSENT_AGREEMENT"
                                    || (session.assessmentType === "ONSITE" && !session.startedByAssessor)
                                    ? t("SESSION_WAIT")
                                    : ""}
                        </Typography>
                    )}
                </Box>
            )}
        </Box>
    );
}

function SessionStartPage() {
    const { t } = useTranslation();
    const { sessionStore, authStore } = useStore();
    const { loadSession, session, loading } = sessionStore;
    const { user } = authStore;

    const navigate = useNavigate();
    const location = useLocation();
    const [timelineCompleted, setTimelineCompleted] = useState(false);
    const [sessionId, setSessionId] = useState<string | null>(null);
    const [clientId, setClientId] = useState<string | null>(null);
    const [conflict, setConflict] = useState(false);

    const params = new URLSearchParams(location.search);
    const token = params.get("token") ?? undefined;
    const clientSessionId = params.get("id");

    useEffect(() => {
        if (token) {
            const { sessionId, clientId } = getDecodedToken(token);
            if (user && user.id !== clientId) {
                setConflict(true);
            } else {
                setSessionId(sessionId);
                setClientId(clientId);
                setConflict(false);
            }
        } else if (clientSessionId) {
            setSessionId(clientSessionId);
        }

        if (sessionId && !conflict) {
            const fetchData = async () => {
                try {
                    await loadSession(sessionId, token);
                } catch (error: any) {
                    if (error.response) {
                        if (error.response.status === 403 && clientSessionId) {
                            navigate('/screening/my-sessions');
                        }
                        if (error.response.status !== 400) throw error;

                        const completed = error.response.data === "INVALID_TOKEN";
                        if (!completed) throw error;

                        setTimelineCompleted(true);
                    }
                }
            };

            fetchData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadSession, sessionId]);

    useEffect(() => {
        if (session?.tokenStateInfo.timelineCompleted) {
            setTimelineCompleted(true)
        }
        if (session?.currentStep === "BL_COMPLETED" || session?.currentStep === "AWAITING_2ND_CONSENT_AGREEMENT") {
            setTimelineCompleted(true);
        }
        if (session && !clientId) {
            setClientId(session.clientId);
        }
        if (clientId === session?.clientId) {
            const isAssessmentSurvey =
                session?.currentStep === "ASSESSMENT_OF_SUICIDE_ACTIVE" ||
                session?.currentStep === "ASSESSMENT_OF_SUICIDE_COMPLETED";
            if (isAssessmentSurvey) {
                navigate(
                    `/screening/session/${session?.sessionId}/on-hold${token ? '?token=' + token : ""}`,
                    { state: { redirect: `/screening/session?token=${token}` } }
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session]);

    const onProcessStart = async () => {
        if (session?.consentAgreement) {
            await startSession();
        }
    };

    const startSession = async () => {
        if (sessionId && session?.status !== "EXCLUDED") {
            if (
                session?.currentStep === "SESSION_LOADED" ||
                session?.currentStep === "CONSENT_AGREEMENT"
            )
                return;

            navigate(
                `/timeline/${session?.timelineId}/survey/${session?.surveyId}/session/${session?.sessionId}?lang=${session?.languageId}${token ? "&token=" + token : ""}`,
                { state: { redirect: `/screening/session?token=${token}` } }
            );
        }
    };

    const refreshSession = async () => {
        if (sessionId) {
            try {
                await loadSession(sessionId, token);
            } catch (error: any) {
                if (error.response.status === 403 && clientSessionId) {
                    navigate('/screening/my-sessions');
                }
            }
        }
    };

    if (conflict) {
        return (
            <Box display={"flex"} justifyContent={"center"} mt={"7rem"}>
                <Typography fontSize={"2rem"} textAlign={"center"}>
                    {t("SESSION_LOGOUT")}
                </Typography>
            </Box>
        );
    }

    return (
        <Container className="page-container" maxWidth="lg">
            {timelineCompleted ||
                session?.currentStep === "PROCESS_COMPLETED" ||
                // bug here, in case of BL_ONLY and current step being 2nd 
                // consent rejected, this fails, enabling user to continue 
                // with surveys they have already filled
                (session?.status === "BL_ONLY" &&
                    session?.currentStep === "BL_COMPLETED") ||
                (session?.status === SessionStatus.EXCLUDED_SUICIDALITY &&
                    session?.currentStep === "BL_COMPLETED") ||
                session?.status === "EXCLUDED" ||
                session?.status === "ABORT" ||
                session?.status === "CLIENT_NO_SHOW_UP" ||
                session?.status === "DEACTIVATED" ? (
                <SessionComplete
                    session={session}
                    clientId={clientId!}
                    completed={timelineCompleted}
                />
            ) : (
                <SessionInfo
                    session={session}
                    onStart={onProcessStart}
                    clientId={clientId!}
                    refresh={refreshSession}
                    loading={loading}
                    sessionToken={token}
                />
            )}
        </Container>
    );
}

export default observer(SessionStartPage);
