import { Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import ColorLegend from "../ColorLegend";


const ClientSessionTimelineOverviewToolbar: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Grid container columnSpacing={3}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography fontSize="22px" fontWeight={500}>{t("SESSIONS_DETAILS_LABEL")}</Typography>
            </Grid>
        </Grid>
    )
};

export default ClientSessionTimelineOverviewToolbar;
