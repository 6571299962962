import { Box, Menu } from "@mui/material";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

import { useState } from "react";
import { observer } from "mobx-react-lite";

import { TenantMenuButton } from "../_styles/StyledButtons";
import { StyledMenuItem } from "../_styles/StyledMenu";
import { useStore } from "../stores/store";

const buttonStyle = {
    width: 'auto',
    paddingLeft: '15px',
    paddingRight: '15px',
}

const iconStyle = {
    fontSize: "18px",
    marginLeft: "14px",
}

function TenantMenu() {
    const { tenantStore: { loadUserSettingsTenant, userSettingsTenant }, sidebarStore: { closeSidebar }, profileStore: { userTenantList } } = useStore();
    const [anchorElement, setAnchorElement] = useState(null);
    const [expanded, setExpanded] = useState(false);

    const handleClick = (event: any) => {
        setAnchorElement(event.currentTarget);
        setExpanded(true);
    };

    const handleClose = (event: any) => {
        setAnchorElement(null);
        setExpanded(false);
        if (event.target.textContent !== '') {
            const tenant = userTenantList.find(tenant => tenant.name === event.target.textContent)!;
            loadUserSettingsTenant(tenant.id);
            closeSidebar();
        }
    };

    return (
        <Box ml="12px">
            <TenantMenuButton
                id="dropdown-button"
                aria-haspopup="true"
                sx={buttonStyle}
                onClick={handleClick}
                title="Tenants"
                disableRipple
            >
                {userSettingsTenant?.name}
                {expanded ? <KeyboardArrowUpRoundedIcon sx={iconStyle} /> : <KeyboardArrowDownRoundedIcon sx={iconStyle} />}
            </TenantMenuButton>
            <Menu
                id="dropdown-menu"
                anchorEl={anchorElement}
                open={Boolean(anchorElement)}
                transitionDuration={0}
                onClose={handleClose}
            >
                {userTenantList.map(tenant => (
                    <StyledMenuItem key={tenant.id} onClick={handleClose}>{tenant.name}</StyledMenuItem>
                ))}
            </Menu>
        </Box>
    );
}

export default observer(TenantMenu);