import { Box, Divider, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";

import "./styles.css";
import ProfileHeader from "./ProfileHeader";
import { profileOptions } from "./options";
import { Option } from "../../../_styles/layout/Container";
import { SideBar, SideBarItem } from "../../../_styles/ListItem";
import FlexContainer from "../../../_styles/layout/Container";
import { useStore } from "../../../stores/store";
import LoadingComponent from "../../../components/LoadingComponent";
import { useTranslation } from "react-i18next";

function ProfileDashboard() {
    const { t } = useTranslation();
    const {
        profileStore: { getProfile, loading },
    } = useStore();
    const [selectedOption, setSelectedOption] = useState<Option>(
        profileOptions[0]
    );
    const { option } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            await getProfile();
            const pathOption = profileOptions.find((opt) => opt.id === option);
            if (pathOption) {
                setSelectedOption(pathOption);
            } else {
                navigate("/profile/account");
            }
        };
        fetchData();
    }, [getProfile, navigate, option, setSelectedOption]);

    const handleOptionClick = (option: Option) => {
        setSelectedOption(option);
        navigate(`/profile/${option.id}`);
    };

    return (
        <Box style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <ProfileHeader
                handleChange={handleOptionClick}
                selected={selectedOption!}
                path={t(`PROFILE_${selectedOption.name}_NAME`)}
            />
            <Box style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>

                <SideBar
                    className="options"
                    sx={{
                        minWidth: "23%",
                        borderTop: "0.5px solid #0000001f",
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        bgcolor: "#fff"
                    }}
                >
                    {profileOptions.map((option) => (
                        <SideBarItem
                            key={option.id}
                            onClick={() => handleOptionClick(option)}
                            selected={selectedOption?.id === option.id}
                            sx={{
                                marginBottom: 0,
                                padding: "1.2rem 24px",
                                borderBottom: "0.5px solid #0000001f",
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                            }}
                            data-testid={`${option.id}-link`}
                        >
                            {selectedOption?.id === option.id ? option.selectedIcon : option.icon}
                            <Typography paddingLeft={1} fontSize="18px">
                                {t(`PROFILE_${option.name}_NAME`)}
                            </Typography>
                        </SideBarItem>
                    ))}
                </SideBar>
                <Divider orientation="vertical" flexItem />
                {selectedOption && (
                    loading ?
                        <LoadingComponent />
                        :
                        <Box className="settings-dashboard-container" sx={{ bgcolor: "transparent" }}>
                            <FlexContainer
                                selectedOption={selectedOption}
                                className="settings-flex-container"
                            >
                                {selectedOption.component}
                            </FlexContainer>
                        </Box>
                )}
            </Box>
        </Box>
    );
}

export default observer(ProfileDashboard);
