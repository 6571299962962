import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { useEffect, useState } from "react";

import { RoundButton } from "../../../_styles/StyledButtons";
import { useStore } from "../../../stores/store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { arrayToString } from "../../../utils/utils";

interface StatusFilterProps {
    institutionFilters: string[];
    setInstitutionFilters: (filters: string[]) => void;
}

function UserFilters({ institutionFilters, setInstitutionFilters }: StatusFilterProps) {
    const { t } = useTranslation();
    const { institutionStore, commonStore } = useStore();
    const { institutionList, getInstitutions } = institutionStore;
    const { selectedTenant } = commonStore;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setInstitutionFilters([]);
        const fetchData = () => {
            if (selectedTenant) {
                getInstitutions(selectedTenant)
            }
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getInstitutions, selectedTenant])

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onFilterChange = (institutionId: string) => {
        if (institutionFilters.includes(institutionId)) {
            setInstitutionFilters(institutionFilters.filter((f) => f !== institutionId));
        } else {
            setInstitutionFilters([...institutionFilters, institutionId]);
        }
    };

    return (
        <>
            <Button
                onClick={handleClick}
                className="filter-button"
                sx={{
                    color: anchorEl ? "#03b2ff" : "#808080",
                    bgcolor: anchorEl ? "#03b2ff0a" : "transparent"
                }}
            >
                <Box display={"flex"} alignItems={"center"} sx={{ fontWeight: 500 }}>
                    {t("GENERAL_INST")}
                    {institutionFilters.length > 0 && (
                        <Box className="filter-badge">{institutionFilters.length}</Box>
                    )}
                </Box>
                {anchorEl ? (
                    <KeyboardArrowUpIcon sx={{ fontSize: "16px", pt: "3px" }} />
                ) : (
                    <KeyboardArrowDownIcon sx={{ fontSize: "16px", pt: "3px" }} />
                )}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transitionDuration={0}
                MenuListProps={{
                    sx: {
                        "&& .Mui-selected": {
                            backgroundColor: "#0077b6 !important",
                            color: "#fff",
                            fontWeight: 500,
                        },
                        "&& .Mui-selected p": {
                            backgroundColor: "#0077b6 !important",
                            color: "#fff",
                            fontWeight: 500,
                        },
                        pt: 0,
                    },
                }}
                PaperProps={{
                    style: {
                        borderRadius: 0,
                    },
                }}
            >
                <Box className="filter-menu-container">
                    <Box>
                        <Typography fontSize={12} ml="8px">
                            {institutionFilters.length} selected
                        </Typography>
                        {institutionFilters.length > 0 && (
                            <Typography
                                className="selected-filters-label"
                                title={arrayToString(institutionFilters)}
                            >
                                {arrayToString(institutionFilters)}
                            </Typography>
                        )}
                    </Box>
                    <RoundButton
                        variant="text"
                        className="filter-clear-button"
                        onClick={() => {
                            if (institutionFilters.length > 0) {
                                setInstitutionFilters([]);
                            } else {
                                setInstitutionFilters(institutionList.map(i => i.name));
                            }
                        }}
                    >
                        {institutionFilters.length > 0 ? "Clear" : "All"}
                    </RoundButton>
                </Box>
                <Box>
                    {institutionList.map((institution) => (
                        <MenuItem
                            key={institution.id}
                            className="filter-menu-item"
                            disableRipple
                            onClick={() => onFilterChange(institution.name)}
                            selected={institutionFilters.includes(institution.name)}
                        >
                            {institution.name}
                        </MenuItem>
                    ))}
                </Box>
            </Menu>
        </>
    );
}

export default observer(UserFilters);
