import { Box } from "@mui/system";
import { TimelineEventDetails } from "../../../../../data/models/session";
import SessionEventItem from "./SessionEventItem";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface SessionEventListProps {
    timelineDetails: TimelineEventDetails;
}

function SessionEventList({ timelineDetails }: SessionEventListProps) {
    const { t } = useTranslation();
    return (
        <Box className="session-event-list">
            {timelineDetails.events.length === 0 && (
                <Typography
                    fontSize={"0.75rem"}
                    fontStyle={"italic"}
                >
                    {t("SESSION_NO_EVENTS")}
                </Typography>
            )}
            {timelineDetails.events.map((e) => (
                <SessionEventItem key={e.eventId} event={e} />
            ))}
        </Box>
    );
}

export default SessionEventList;
