import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";

import { Box, Grid, List, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText } from "@mui/material"
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DoneOutlineRoundedIcon from '@mui/icons-material/DoneOutlineRounded';

import FormInputSelect from "../../components/form/FormInputSelect"
import DropdownMenu from "../../components/DropdownMenu";
import TemplateDashboard from "./TemplateDashboard";
import { useStore } from "../../stores/store";
import { TemplateInstanceDto } from "../../data/models/notification";
import { surveyLanguages } from "../../data/static/survey";
import { TemplatePatchDto } from "../../data/models/notification"
interface TemplateVersionProps {
    selectedTemplateId: string
}

const TemplateVerions = ({ selectedTemplateId }: TemplateVersionProps) => {
    const { t } = useTranslation();
    const {
        templateStore,
        institutionStore: { selectedUserInstitution }
    } = useStore();
    const { control } = useForm();
    const [selectedVersion, setSelectedVersion] = useState<TemplateInstanceDto>();
    const [templateLanguage, setTemplateLanguage] = useState(surveyLanguages[0].code);

    const options = [
        {
            id: 'activate',
            name: t('GENERAL_ACTIVATE'),
            icon: <DoneOutlineRoundedIcon sx={{ position: 'absolute', right: 0, paddingRight: 1 }} color="primary" />,
            onClick: (template: TemplateInstanceDto) => handleActivate(template),
        },
        {
            id: 'delete',
            name: t('GENERAL_DELETE'),
            onClick: (template: TemplateInstanceDto) => handleDelete(template),
            icon: <DeleteForeverOutlinedIcon sx={{ position: 'absolute', right: 0, paddingRight: 1 }} color="error" />,
            customStyle: {
                backgroundColor: '#ffdfdf',
                color: '#FF0000',
                ":hover": { backgroundColor: '#FFABAB' }
            }
        }
    ]

    const handleDelete = async (template: TemplateInstanceDto) => {
        if (template.isDefault) {
            toast.error(t('TOAST_DEFAULT_CAN_NOT_DELETE'));
        }
        else if (template.active) {
            toast.error(t('TOAST_ACTIVE_CAN_NOT_DELETE'));
        }
        else {
            await templateStore.deleteTemplate(template.id);
            await templateStore.getTemplateInstances(selectedTemplateId, selectedUserInstitution?.institutionId!, templateLanguage);
            if (template.id === selectedVersion?.id) {
                setSelectedVersion(undefined)
            }
        }
    }

    const handleActivate = async (template: TemplateInstanceDto) => {
        if (template.active) {
            toast.warning(t('TOAST_ALREADY_ACTIVE'));
        }
        else {
            const patchDocument: TemplatePatchDto = { active: true };
            await templateStore.patchDocument(template.id, selectedUserInstitution?.institutionId!, patchDocument)
            await templateStore.getTemplateInstances(selectedTemplateId, selectedUserInstitution?.institutionId!, templateLanguage);
            setSelectedVersion(undefined);
        }
    }

    const handleSelection = (template: TemplateInstanceDto) => {
        setSelectedVersion(template);
    };

    const handleLanguageSelect = (tag: string) => {
        if (tag) {
            const language = surveyLanguages.find(l => l.code === tag);
            if (language) {
                setTemplateLanguage(language.code);
            }
        }
    }

    useEffect(() => {
        if (selectedTemplateId) {
            setSelectedVersion(undefined);
            templateStore.getTemplateInstances(selectedTemplateId, selectedUserInstitution?.institutionId!, templateLanguage)
            templateStore.reset();
        }
    }, [selectedTemplateId, templateLanguage, selectedUserInstitution, templateStore]);

    return (
        <>
            <Grid
                item md={2} lg={2} xl={1.65}
                className='vertical-divider'
                sx={{
                    width: selectedTemplateId ? '100%' : '0',
                    transition: 'width 0.8s ease-in-out',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    display: selectedTemplateId ? 'block' : 'none',
                }}
            >
                <Box className="templates-second-menu-content">
                    <Box sx={{ display: 'flex', marginLeft: 2, marginRight: 2, marginTop: 2 }}>
                        <FormInputSelect
                            options={surveyLanguages}
                            labelKey='name'
                            name='language'
                            defaultValue={templateLanguage}
                            valueKey={'code'}
                            control={control}
                            helperText=''
                            onSelect={(item) => handleLanguageSelect(item.target.value)}
                        />
                    </Box>
                    <List className='template-instances-list-content'>
                        {selectedTemplateId && templateStore.templateInstanceList.map((template, index) => (
                            <ListItem
                                key={index + template.title}
                                sx={{
                                    textAlign: 'center',
                                    '&:hover': { backgroundColor: '#f0f0f0', cursor: 'pointer' },
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    padding: 0
                                }}
                            >
                                <ListItemButton
                                    onClick={() => handleSelection(template)}
                                    selected={selectedVersion?.id === template.id}
                                    className="list-item-button"
                                    sx={{
                                        width: '100%',
                                        boxSizing: 'border-box'
                                    }}
                                >
                                    <ListItemText primary={
                                        <Typography component="span">
                                            {template.title}
                                            {template.active && (
                                                <DoneOutlineRoundedIcon
                                                    sx={{ position: 'relative', marginLeft: 1, bottom: '-5px' }}
                                                    color="primary"
                                                />
                                            )}
                                        </Typography>
                                    } />
                                </ListItemButton>
                                <ListItemSecondaryAction sx={{ right: 5 }}>
                                    <DropdownMenu
                                        options={options.map((option) => ({
                                            ...option,
                                            onClick: () => option.onClick(template),
                                        }))}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Grid>
            <TemplateDashboard selectedInstance={selectedVersion as TemplateInstanceDto} templateLanguage={templateLanguage} selectedTemplateId={selectedTemplateId} />
        </>
    )
}

export default observer(TemplateVerions);