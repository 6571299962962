import { TFunction } from "i18next";
import { Timeline } from "../models/client";

export const clients = [
    { id: 1, name: 'John Doe', roles: ['Developer', 'Designer'] },
    { id: 2, name: 'Jane Smith', roles: ['Manager', 'Team Lead'] },
    { id: 3, name: 'Michael Johnson', roles: ['Engineer'] },
    { id: 4, name: 'Emily Brown', roles: ['Analyst', 'Consultant'] },
    { id: 5, name: 'David Wilson', roles: ['Architect', 'Project Manager'] },
    { id: 6, name: 'Sarah Thompson', roles: ['Researcher', 'Scientist'] },
    { id: 7, name: 'Daniel Davis', roles: ['Tester'] },
    { id: 8, name: 'Olivia Martinez', roles: ['UI/UX Designer'] },
    { id: 9, name: 'Matthew Taylor', roles: ['Product Owner', 'Scrum Master'] },
    { id: 10, name: 'Sophia Anderson', roles: ['Content Writer', 'Social Media Manager'] }
];

export const accomodationOptions = [
    { value: "first phase cantonal housing", label: "First phase Cantonal Housing"},
    { value: "refugee housing", label: "Refugee Housing"},
    { value: "collective housing for unaccompanied minors", label: "Collective Housing for Unaccompanied Minors"},
    { value: "other", label: "Other"},
]

export function getAccomodationOptions(t?: TFunction<"translation", undefined>) {
    if (t) {
        accomodationOptions[0].label = t("CLIENT_ACCOM_OPT_1");
        accomodationOptions[1].label = t("CLIENT_ACCOM_OPT_2");
        accomodationOptions[2].label = t("CLIENT_ACCOM_OPT_3");
    }
    return accomodationOptions;
}

export const timeline: Timeline[] = [
    // {
    //     id: "1",
    //     title: "Jon Doe updated",
    //     type: "email",
    //     actionDate: new Date("2023-07-10 09:30:00"),
    //     fieldsChanged: { oldValue: 'email@test.test', newValue: 'email2@test.com' },
    //     institutionName: 'inst1',
    //     tenantName: 'tnt1'
    // },
    // {
    //     id: "2",
    //     title: "Clara Daw updated",
    //     type: "email",
    //     actionDate: new Date("2023-07-10 09:30:00"),
    //     fieldsChanged: { oldValue: 'email@test.test', newValue: 'email2@test.com' },
    //     institutionName: 'inst2',
    //     tenantName: 'tnt1'
    // },
    // {
    //     id: "3",
    //     title: "Jeremy Higgins deleted",
    //     type: "file",
    //     actionDate: new Date("2023-07-10 09:30:00"),
    //     fieldsChanged: { oldValue: 'dummy.txt', newValue: '-' },
    //     institutionName: 'inst1',
    //     tenantName: 'tnt1'
    // },
    // {
    //     id: "4",
    //     title: "Mat Doe updated",
    //     type: "user",
    //     actionDate: new Date("2023-07-10 09:30:00"),
    //     fieldsChanged: { oldValue: 'firstname: Maia', newValue: 'firstname: Maya' },
    //     institutionName: 'inst2',
    //     tenantName: 'tnt1'
    // },
    // {
    //     id: "5",
    //     title: "Barry Burton updated",
    //     type: "file",
    //     actionDate: new Date("2023-07-10 09:30:00"),
    //     fieldsChanged: { oldValue: 'resume.pdf', newValue: 'doe-resume.pdf' },
    //     institutionName: 'inst1',
    //     tenantName: 'tnt1'
    // },
    // {
    //     id: "6",
    //     title: "Jaina Doe updated",
    //     type: "user",
    //     actionDate: new Date("2023-07-10 09:30:00"),
    //     fieldsChanged: { oldValue: 'birthdate: 16/08/1999', newValue: 'birthdate: 16/08/1989' },
    //     institutionName: 'inst1',
    //     tenantName: 'tnt1'
    // },
];
