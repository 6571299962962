import { styled } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

export const CheckIcon = styled(CheckRoundedIcon)({
    color: "white",
    backgroundColor: "#2dc653",
    borderRadius: "50%",
    stroke: "#fff",
    strokeWidth: "3px",
    fontSize: "10px",
    padding: "5px",
});