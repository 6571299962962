import { Box, CircularProgress, Typography } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import FailAttemptOptions from "./FailAttemptOptions";
import { useStore } from "../../../../stores/store";
import { useEffect, useState } from "react";
import { SessionAttempt } from "../../../../data/models/session";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

interface SessionQRCodeProps {
    sessionId: string;
}

function SessionQRCodeGenerator({ sessionId }: SessionQRCodeProps) {
    const { t } = useTranslation();
    const { sessionStore } = useStore();
    const [attempts, setAttempts] = useState<SessionAttempt>({ attemptLimit: 1, numberOfAttempts: 0 });
    const { getAttempts, loadingCode } = sessionStore;
    const qrCode = `${window.location.origin}/session/${sessionId}/code`;

    useEffect(() => {
        const fetchData = async () => {
            const attempts = await getAttempts(sessionId);
            setAttempts(attempts);
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (loadingCode) return (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} minHeight={200}>
            <CircularProgress size={35} />
        </Box>
    )

    return (
        <>
            {attempts.numberOfAttempts >= attempts.attemptLimit ?
                <FailAttemptOptions sessionId={sessionId} />
                :
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} sx={{ p: "2rem 0" }}>
                    <Typography fontWeight={600} sx={{
                        mb: 1,
                        maxWidth: "250px",
                        textTransform: "uppercase",
                        textAlign: "center",
                        color: "#808080",
                    }}>{t("SESSION_QR_SCAN")}</Typography>
                    <QRCodeSVG value={qrCode} size={250} />
                </Box >}
        </>
    );
}

export default observer(SessionQRCodeGenerator);