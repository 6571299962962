import { Box, Typography } from "@mui/material";
import { TimelineEvent } from "../../../../../data/models/session";
import { format } from "date-fns";
import { capitalizeFirstLetter, getDateFnsLocalizer } from "../../../../../utils/utils";
import { useTranslation } from "react-i18next";

interface EventItemProps {
    event: TimelineEvent;
}

function SessionEventItem({ event }: EventItemProps) {
    const { i18n } = useTranslation();
    return (
        <Box className="session-event-item">
            <Typography fontSize={"0.85rem"}>{event.eventTitle}</Typography>
            <Box>
                <Typography fontSize={"0.75rem"} sx={{ color: "#808080" }}>{format(new Date(event.eventStartTime), "dd MMM, yyyy 'at' HH:mm", { locale: getDateFnsLocalizer(i18n.language) })} • {capitalizeFirstLetter(event.status)}</Typography>
            </Box>
        </Box>
    );
}

export default SessionEventItem;