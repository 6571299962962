import { Box, Grid, Typography } from "@mui/material";
import ClientEventForm from "./ClientEventForm";
import ClientEventList from "./ClientEventList";
import { CalendarEvent } from "../../../data/models/event";
import { RoundButton } from "../../../_styles/StyledButtons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ClientAvailabilityProps {
    next: () => void;
    back: () => void;
    display: boolean;
}

function ClientAvailability({ next, back, display }: ClientAvailabilityProps) {
    const { t } = useTranslation();
    const [events, setEvents] = useState<CalendarEvent[]>([]);

    useEffect(() => {
        const container = document.querySelector(
            ".client-form-container"
        ) as HTMLElement;
        container.style.height = "100% !imporant";

        return () => {
            container.style.height = "auto";
        };
    }, []);

    return (
        <Box sx={{ display: display ? "block" : "none" }}>
            <Grid
                container
                columnSpacing={1}
                marginTop="1rem"
                marginBottom="5px"
                padding="1rem 3rem"
            >
                <Grid item xs={12}>
                    <Typography>{t("CLIENT_AVAIL")}</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    className="client-event-form-container"
                    mb={2}
                    sx={{ padding: "10px" }}
                >
                    <ClientEventForm setEvents={setEvents} />
                </Grid>
                <Grid item xs={12} mb={2} display="flex" flexDirection="column">
                    <ClientEventList events={events} setEvents={setEvents} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <RoundButton
                        variant="text"
                        color="inherit"
                        onClick={back}
                        sx={{ marginRight: "1rem" }}
                    >
                        <Typography fontSize="13px">{t("GENERAL_BACK")}</Typography>
                    </RoundButton>
                    <RoundButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={next}
                    >
                        <Typography fontSize="13px">{t("GENERAL_NEXT")}</Typography>
                    </RoundButton>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ClientAvailability;
