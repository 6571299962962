import { TextField, FormControl, Select, MenuItem, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import { Controller } from "react-hook-form";
import { FormInputProps } from "../../../components/form/FormInputProps";
import { useEffect, useState } from "react";
import { convertMinutesToUnit } from "../../../utils/helpers/eventHelper";
import { useTranslation } from "react-i18next";

interface ReminderInputProps extends FormInputProps {
    onCancel: () => void;
    defaultValues?: any;
    disabled?: boolean;
}

const MenuProps = {
    PaperProps: {
        sx: {
            width: 100,
        },
    },
};

const flexStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '15px',
}

const itemStyle = {
    backgroundColor: "transparent",
    "&:hover": {
        backgroundColor: "transparent"
    },
    "&:focus": {
        backgroundColor: "transparent"
    },
    "&.Mui-selected": {
        backgroundColor: "white"
    },
    marginBottom: 0,
}

const options = [
    { value: 'minutes', label: 'MINUTES' },
    { value: 'hours', label: 'HOURS' },
    { value: 'days', label: 'DAYS' },
    { value: 'weeks', label: 'WEEKS' },
]

const ReminderInput = ({ control, name, onCancel, defaultValues, disabled = false }: ReminderInputProps) => {
    const { t } = useTranslation();
    const [maxValue, setMaxValue] = useState(40320);
    const { minutes = '', unit = 'minutes' } = defaultValues || {};

    const isValid = (value: any) => {
        return (value >= 0 && value <= maxValue) || value === '';
    }

    useEffect(() => {
        if (defaultValues && defaultValues.unit) {
            handleSelectChange(defaultValues.unit);
        }
    }, [defaultValues]);

    const handleSelectChange = (unit: any) => {
        if (unit === 'minutes') {
            setMaxValue(40320);
        } else if (unit === 'hours') {
            setMaxValue(672);
        } else if (unit === 'days') {
            setMaxValue(28);
        } else if (unit === 'weeks') {
            setMaxValue(4);
        }
    }

    const getErrorMessage = (value: any) => {
        return `Value must be between 0 and ${maxValue}`;
    }

    return (
        <FormControl sx={flexStyle}>
            <Controller
                name={`${name}.value`}
                control={control}
                defaultValue={convertMinutesToUnit(minutes, unit)}
                rules={{
                    required: true,
                }}
                render={({ field, fieldState: { isDirty, error } }) => (
                    <TextField
                        {...field}
                        disabled={disabled}
                        fullWidth
                        className="textFieldWithArrows"
                        error={!!error || (isDirty && !isValid(field.value))}
                        helperText={error?.message || (isDirty && !isValid(field.value) && getErrorMessage(field.value)) || ""}
                        type="number"
                        inputProps={{
                            'data-testid': `${name}.value`,
                            style: {
                                padding: '8px 10px',
                                fontSize: '13px'
                            },
                        }}
                        InputProps={{
                            sx: {
                                backgroundColor: 'white',
                            },
                        }}
                        sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                            marginRight: '10px'
                        }}
                    />
                )}
            />
            <Controller
                name={`${name}.unit`}
                control={control}
                defaultValue={unit}
                render={({ field }) => (
                    <FormControl fullWidth>
                        <Select
                            value={field.value}
                            MenuProps={MenuProps}
                            disabled={disabled}
                            onChange={(event) => {
                                field.onChange(event.target.value);
                                handleSelectChange(event.target.value);
                            }}
                            sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                                lineHeight: 'inherit'
                            }}
                            inputProps={{
                                'data-testid': `${name}.unit`,
                            }}
                            SelectDisplayProps={{
                                style: {
                                    padding: '8px 10px',
                                    backgroundColor: 'white',
                                    fontSize: '13px'
                                }
                            }}
                        >
                            {options.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    data-testid={`${name}.unit.${option.value}`}
                                    sx={{ ...flexStyle, ...itemStyle, fontSize: '13px' }}
                                >
                                    {t(`EVENT_REM_${option.label}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            />
            {!disabled && <IconButton onClick={onCancel} sx={{ marginLeft: '10px' }} title="Close">
                <CloseIcon fontSize="small" />
            </IconButton>}
        </FormControl>
    );
};

export default ReminderInput;
