import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import CrisisAlertRoundedIcon from "@mui/icons-material/CrisisAlertRounded";
import ForwardToInboxRoundedIcon from '@mui/icons-material/ForwardToInboxRounded';

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import { useStore } from "../../../../../stores/store";
import { RoundButton } from "../../../../../_styles/StyledButtons";
import CountdownTimer from "../../../../../components/CountdownTimer ";
import ConfirmDeleteDialog from "../../../../../components/ConfirmDialog";
import FollowUpForm from "../Forms/FollowUpForm";
import { FollowUpType, SessionStatus } from "../../../../../data/models/session";
import CrisisAssessmentForm from "../Forms/CrisisAssessmentForm";
import SessionCodeShow from "./SessionCodeShow";
import ExcludeClientForm from "../Forms/ExcludeClientForm";

interface DashboardActionsProps {
    isActive: boolean;
}

function DashboardActions({ isActive }: DashboardActionsProps) {
    const { t } = useTranslation();
    const { sessionStore, authStore, modalStore, dialogStore } = useStore();
    const { session, startUserSession, loadAssessorSession, loadingStart, noShowUp } =
        sessionStore;
    const { user } = authStore;
    const { openDialog, closeDialog } = dialogStore;
    const { openModal } = modalStore;

    const [isRefreshing, setIsRefreshing] = useState(false);

    const isDisabled = () => {
        if (user?.id === session?.clientId) {
            return (
                session?.consentAgreement && session.currentStep === "CONSENT_AGREEMENT"
            );
        } else {
            return session?.currentStep === "SESSION_LOADED";
        }
    };

    const showFollowUp = () => {
        const regex = /^(T[2-6]_READY|POST_INTERVENTION_READY)$/;

        return (
            session?.assessmentType !== FollowUpType.ONLINE &&
            session?.status === SessionStatus.AWAITING_EVENT_CREATION &&
            regex.test(session?.currentStep ?? "") &&
            session?.assessorId === user?.id
        );
    };

    const countdownActive =
        session?.currentStep !== "CONSENT_AGREEMENT" &&
        session?.status !== "EXCLUDED" &&
        session?.status !== "DEACTIVATED";

    const isReadyForStart =
        session?.assessorId === user?.id && (["CONSENT_AGREEMENT", "SESSION_LOADED"].includes(session?.currentStep ?? "") ||
            (/^(T[2-6]_READY|POST_INTERVENTION_READY)$/.test(session?.currentStep ?? "") &&
                session?.assessmentType !== FollowUpType.ONLINE && !session?.startedByAssessor
                && session?.status !== "CLIENT_NO_SHOW_UP" && session?.status !== SessionStatus.EMERGENCY_HOLD && session?.status !== SessionStatus.DEACTIVATED));

    const onRefresh = async () => {
        if (session?.sessionId) {
            await loadAssessorSession(session.sessionId);
        }
    };

    const onNoShow = async () => {
        openDialog(
            t("SESSION_NOSHOW_TITLE"),
            t("SESSION_NOSHOW_DESC"),
            async () => {
                if (session) {
                    await noShowUp(session?.sessionId);
                    await onRefresh();
                }
                closeDialog();
            },
            closeDialog,
            {
                text: t("GENERAL_CONFIRM"),
                color: "warning",
            }
        );
    };

    const handleResendDialog = async () => {
        openDialog(
            t("DIALOG_SESSION_RESEND_TITLE"),
            t("DIALOG_SESSION_RESEND_DESCRIPTION"),
            handleConfirm,
            closeDialog,
            {
                text: t("GENERAL_CONFIRM"),
                color: "warning",
            },
        );
    }

    const handleConfirm = async () => {
        await sessionStore.resendEmail(session?.sessionId!);
        closeDialog();
    }

    const noShow =
        session?.currentStep === "CLIENT_NO_SHOW_UP" && session.status === "ABORT";

    return (
        <Box className="dashboard-action-container">
            {countdownActive && !noShow && session?.currentStep !== "SESSION_LOADED" && session?.currentStep !== "CONSENT_AGREEMENT" && (
                <CountdownTimer
                    initialCountdown={10}
                    onFinish={onRefresh}
                    view={(countdown) => (
                        <Typography fontSize={12} mr={"8px"} mb={"4px"}>
                            {countdown === 0 ? t("SESSION_REFRESHING") : `${t("SESSION_AUTO_REFRESH")}: ${countdown}`}
                        </Typography>
                    )}
                    reset={isRefreshing}
                />
            )}
            <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                width="100%"
                sx={{
                    mt: !(countdownActive && !noShow) ? "18px" : 0,
                }}
            >
                {
                    (!['CONFIRM_BASELINE_RESULTS', 'AWAITING_2ND_CONSENT_AGREEMENT'].includes(session?.currentStep!) &&
                        ['ACTIVE', 'BL_ONLY', SessionStatus.EXCLUDED_SUICIDALITY].includes(session?.status!) &&
                        session?.timeline === 'Baseline' &&
                        session?.assessorId === user?.id) && (
                        <RoundButton
                            color="info"
                            variant="outlined"
                            sx={{ width: "auto", mr: 1 }}
                            onClick={handleResendDialog}
                        >
                            <Box display="flex" alignItems="center" mr="4px">
                                <ForwardToInboxRoundedIcon sx={{ fontSize: 18 }} />
                            </Box>
                            {t('SESSION_RESEND_INVITE_SCREENING_NOTIFICATION')}
                        </RoundButton>
                    )}
                {(!['CONFIRM_BASELINE_RESULTS', 'AWAITING_2ND_CONSENT_AGREEMENT'].includes(session?.currentStep!) &&
                    ['ACTIVE', 'BL_ONLY', SessionStatus.EXCLUDED_SUICIDALITY].includes(session?.status!) &&
                    session?.timeline === 'Baseline' &&
                    session?.assessorId === user?.id) && (
                        <RoundButton
                            color="info"
                            variant="outlined"
                            sx={{ width: "auto", mr: 1, whiteSpace: "nowrap" }}
                            onClick={() => {
                                if (session) {
                                    openModal(<SessionCodeShow />, {
                                        size: "xs",
                                        className: "modal-blur",
                                    });
                                }
                            }}>
                            {t("SHOW_SESSION_CODE")}
                        </RoundButton>
                    )}
                {(
                    ["SESSION_LOADED", "CONSENT_AGREEMENT", "POST_INTERVENTION_READY"].includes(session?.currentStep ?? "") ||
                    /^(T[2-6]_READY)$/.test(session?.currentStep ?? "")) &&
                    !session?.startedByAssessor &&
                    session?.status !== "CLIENT_NO_SHOW_UP" &&
                    session?.status !== SessionStatus.EMERGENCY_HOLD &&
                    session?.status !== SessionStatus.DEACTIVATED &&
                    [FollowUpType.ONSITE, FollowUpType.ASSISTED].includes(session?.assessmentType as FollowUpType ?? "") &&
                    session?.timelineStartTime &&
                    new Date(session.timelineStartTime) <= new Date() &&
                    session?.assessorId === user?.id && (
                        <RoundButton
                            color="warning"
                            variant="contained"
                            sx={{ width: "auto", mr: 1 }}
                            onClick={onNoShow}
                        >
                            {t("SESSION_NOSHOW")}
                        </RoundButton>
                    )}
                {session?.status !== SessionStatus.EMERGENCY_HOLD && session?.status !== SessionStatus.DEACTIVATED && showFollowUp() && (
                    <RoundButton
                        color="info"
                        variant="contained"
                        sx={{ width: "auto", mr: 1 }}
                        onClick={() => {
                            if (session) {
                                openModal(
                                    <FollowUpForm
                                        nextTimeline={session?.timeline}
                                        sessionId={session.sessionId}
                                        previousValue={session.assessmentType}
                                    />,
                                    { size: "xs" }
                                );
                            }
                        }}
                    >
                        {session?.timeline} {t("SESSION_FOLLOWUP")}
                    </RoundButton>
                )}
                {session?.status !== SessionStatus.EMERGENCY_HOLD &&
                    session?.status !== SessionStatus.DEACTIVATED &&
                    session?.isAssessmentOfSuicideCompleted &&
                    !session?.isAssessmentOfSuicideFormCompleted && (
                        <Box className="urgent-btn-container">
                            <RoundButton
                                onClick={() => {
                                    openModal(
                                        <CrisisAssessmentForm sessionId={session?.sessionId!} />,
                                        { size: "xs" }
                                    );
                                }}
                                variant="contained"
                                color="error"
                                sx={{ width: "auto", fontSize: "12px", mr: 1 }}
                            >
                                <CrisisAlertRoundedIcon fontSize="small" sx={{ mr: "4px" }} />
                                {t("SESSION_ATS_CON")}
                            </RoundButton>
                        </Box>
                    )}
                {session?.status !== SessionStatus.EMERGENCY_HOLD &&
                    session?.status !== SessionStatus.DEACTIVATED &&
                    session?.isImpairmentCompleted &&
                    !session?.isImpairmentFormCompleted && (
                        <Box className="urgent-btn-container">
                            <RoundButton
                                onClick={() => {
                                    openModal(
                                        <ExcludeClientForm sessionId={session?.sessionId!} />,
                                        { size: "xs" }
                                    );
                                }}
                                variant="contained"
                                color="warning"
                                sx={{ width: "auto", fontSize: "12px", mr: 1 }}
                            >
                                <CrisisAlertRoundedIcon fontSize="small" sx={{ mr: "4px" }} />
                                {t("IMPAIRMENT_CONCLUSION")}
                            </RoundButton>
                        </Box>
                    )}
                {(session?.status! !== SessionStatus.DEACTIVATED &&
                    <RoundButton
                        sx={{ display: "flex", alignItems: "center", width: 'auto' }}
                        onClick={async () => {
                            setIsRefreshing(true);
                            await onRefresh();
                            setIsRefreshing(false);
                        }}
                        variant="contained"
                    >
                        <Box display="flex" alignItems="center" mr="4px">
                            <CachedIcon sx={{ fontSize: 16 }} />
                        </Box>
                        {t("SESSION_REFRESH")}
                    </RoundButton>
                )}
                {!["EMERGENCY_HOLD", "DEACTIVATED"].includes(session?.status!) && isReadyForStart && isActive &&
                    (
                        <Tooltip
                            title={
                                session?.currentStep === "SESSION_LOADED"
                                    ? t("SESSION_IC_ASSESSOR")
                                    :
                                    session?.currentStep === "AWAITING_2ND_CONSENT_AGREEMENT" &&
                                        session.isImpairmentFormCompleted &&
                                        session.isAssessmentOfSuicideFormCompleted ?
                                        t("SESSION_IC2_ASSESSOR")
                                        :
                                        ""
                            }
                        >
                            <Box>
                                <RoundButton
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "#fff",
                                        ml: 1,
                                    }}
                                    onClick={async () => {
                                        if (session?.sessionId) {
                                            await startUserSession(session?.sessionId);
                                            onRefresh();
                                            toast.success(t("TOAST_SESSION_START"));
                                        }
                                    }}
                                    color="success"
                                    variant="contained"
                                    disabled={loadingStart || isDisabled()}
                                >
                                    {loadingStart ? <CircularProgress size={25} /> : t("GENERAL_START")}
                                </RoundButton>
                            </Box>
                        </Tooltip>
                    )}
            </Box>
            <ConfirmDeleteDialog />
        </Box>
    );
}

export default observer(DashboardActions);
