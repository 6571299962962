import { Checkbox, TextField, FormControl, Select, MenuItem } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';

import { InputHTMLAttributes, useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { FormInputProps } from "./FormInputProps";

interface CustomComponentProps extends FormInputProps {
    options: { value: number; label: string }[];
    defaultValue?: { isChecked: boolean, multiSelectValue: number[] | string[] };
    disabled?: boolean;
    onSelect?: (e: any) => void;
}

const MenuProps = {
    PaperProps: {
        sx: {
            width: 250,
        },
    },
};

const flexStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
}

const itemStyle = {
    backgroundColor: "transparent",
    "&:hover": {
        backgroundColor: "transparent"
    },
    "&:focus": {
        backgroundColor: "transparent"
    },
    "&.Mui-selected": {
        backgroundColor: "white"
    },
    marginBottom: 0,
}

const FormInputMultiSelect = ({ control, name, options, defaultValue, label, disabled = false, onSelect }: CustomComponentProps) => {
    const [isChecked, setIsChecked] = useState(defaultValue?.isChecked || false);

    useEffect(() => {
        if (defaultValue) {
            setIsChecked(defaultValue.isChecked);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleCheckChange = (event: any) => {
        setIsChecked(event.target.checked);
    };

    return (
        <FormControl sx={flexStyle}>
            <Controller
                name={`${name}.isChecked`}
                control={control}
                defaultValue={isChecked}
                render={({ field: { onChange } }) => (
                    <Checkbox
                        onChange={(e) => {
                            onSelect && onSelect(e);
                            onChange(e);
                        }}
                        inputProps={
                            {
                                'data-testid': `${name}.isChecked`,
                            } as InputHTMLAttributes<HTMLInputElement>
                        }
                        onClick={handleCheckChange}
                        name={name}
                        disabled={disabled}
                        checked={isChecked}
                        value={name}
                        color="primary"
                        sx={{ color: '#03b2ff', marginRight: '10px' }}
                        size="small"
                    />
                )}
            />
            <Controller
                name={`${name}.textFieldValue`}
                control={control}
                defaultValue={label}
                render={({ field }) => (
                    <TextField
                        disabled={!isChecked}
                        {...field}
                        fullWidth
                        inputProps={{
                            'data-testid': `${name}.textFieldValue`,
                            style: {
                                padding: '8px 10px',
                                fontSize: '13px'
                            }
                        }}
                        InputProps={{
                            readOnly: true,
                            sx: {
                                backgroundColor: 'white',
                            },
                        }}
                        sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                            marginRight: '10px'
                        }}
                    />
                )}
            />
            <Controller
                name={`${name}.multiSelectValue`}
                control={control}
                defaultValue={defaultValue?.multiSelectValue || []}
                render={({ field }) => (
                    <FormControl fullWidth>
                        <Select
                            disabled={!isChecked || disabled}
                            multiple
                            name={name}
                            value={field.value}
                            MenuProps={MenuProps}
                            renderValue={(selected) => selected.join(', ')}
                            onChange={(event) => field.onChange(event.target.value)}
                            sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                                lineHeight: 'inherit'
                            }}
                            data-testid={`${name}.multiSelectValue`}
                            SelectDisplayProps={{
                                style: {
                                    padding: '8px 10px',
                                    backgroundColor: 'white',
                                    fontSize: '13px'
                                }
                            }}
                        >
                            {options.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.label}
                                    data-testid={`${name}-option-${option.value}`}
                                    sx={{ ...flexStyle, ...itemStyle, fontSize: '13px' }}
                                >
                                    {option.label}
                                    {field.value.includes(option.label) && <DoneIcon color="primary" />}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )
                }
            />
        </FormControl >
    );
};

export default FormInputMultiSelect;
