import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { K10Stats } from "../../data/models/stats";
import { useTranslation } from "react-i18next";

interface K10TableProps {
    data: K10Stats | null;
    title?: string;
}

function K10Table({ data, title }: K10TableProps) {
    const { t } = useTranslation();

    const getTotal = () => {
        if (!data) return 0;

        return data.firstRange + data.secondRange + data.thirdRange + data.deactivated + data.emergencies + data.noShows;
    }

    return (
        <TableContainer component={Paper} sx={{ bgcolor: "#fff" }}>
            {title && <Box sx={{ p: 1 }}>
                <Typography component={"h2"} variant="h6">{title}</Typography>
            </Box>}
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ textAlign: "center" }}>{t(`STAT_TABLECOL1`) + (data?.timeline === 0 ? " " + t("STAT_TABLE0COL1") : "")}</TableCell>
                        {data?.timeline !== 0 ?
                            <>
                                <TableCell sx={{ textAlign: "center" }}>{t(`STAT_TABLE${data?.timeline}COL2`)}</TableCell>
                                <TableCell sx={{ textAlign: "center" }}>{t(`STAT_TABLE${data?.timeline}COL3`)}</TableCell>
                            </>
                            :
                            <TableCell sx={{ textAlign: "center" }}>{t("STAT_TABLE0COL2")}</TableCell>
                        }
                        {data?.timeline === 0 ?
                            <TableCell sx={{ textAlign: "center" }}>{t(`STAT_TABLE${data?.timeline}COL3`)}</TableCell>
                            :
                            <TableCell sx={{ textAlign: "center" }}>{t(`STAT_TABLE${data?.timeline}COL4`)}</TableCell>
                        }
                        <TableCell sx={{ textAlign: "center" }}>{t(`STAT_TABLE0COL6`)}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{t("STAT_TABLECOL4")}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{t("CARD_TOTAL")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{ textAlign: "center" }}>{data?.firstRange ?? "0"}</TableCell>
                        {data?.timeline !== 0 ?
                            <>
                                <TableCell sx={{ textAlign: "center" }}>{data?.secondRange ?? "0"}</TableCell>
                                <TableCell sx={{ textAlign: "center" }}>{data?.thirdRange ?? "0"}</TableCell>
                            </>
                            :
                            <TableCell sx={{ textAlign: "center" }}>{data?.secondRange + data?.thirdRange}</TableCell>
                        }
                        <TableCell sx={{ textAlign: "center" }}>{data?.emergencies ?? "0"}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{data?.deactivated ?? "0"}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{data?.noShows ?? "0"}</TableCell>
                        <TableCell sx={{ textAlign: "center", bgcolor: "#ffc10726", fontWeight: 600 }}>{getTotal()}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default K10Table;