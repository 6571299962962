import { Box } from "@mui/system";
import { RoundButton } from "../../../_styles/StyledButtons";
import { IconButton, Menu, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CSSProperties, useState } from "react";
import { useStore } from "../../../stores/store";
import CalendarDatePicker from "../Calendar/CalendarDatePicker";
import CalendarDashboardMenu from "../Calendar/CalendarDashboardMenu";
import { useTranslation } from "react-i18next";

const flexCenter = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
};

const datePickerStyle: CSSProperties = {
    backgroundColor: "#ffffff",
    width: "75%",
};

const Toolbar = ({ date, view, onNavigate }: any) => {
    const { t } = useTranslation();
    const {
        commonStore: { setCalendarDate },
    } = useStore();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let formattedDate;

    if (view === "day") {
        formattedDate = date.toLocaleString("default", {
            month: "long",
            day: "numeric",
            year: "numeric",
        });
    } else if (view === "week") {
        const startOfWeek = new Date(date.getTime());

        while (startOfWeek.getDay() !== 0) {
            // Get previous date until it's Sunday (0 represents Sunday)
            startOfWeek.setDate(startOfWeek.getDate() - 1);
        }
        const endOfWeek = new Date(startOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000);

        const startDate = startOfWeek.toLocaleString("default", {
            month: "long",
            day: "numeric",
        });
        const endDate = endOfWeek.toLocaleString("default", {
            month: "long",
            day: "numeric",
        });
        formattedDate = `${startDate} - ${endDate}`;
    } else {
        formattedDate = date.toLocaleString("default", {
            month: "long",
            year: "numeric",
        });
    }

    const handlePrevious = () => {
        if (view === "day") {
            const previousDay = new Date(date.getTime());
            previousDay.setDate(previousDay.getDate() - 1);
            onChange(previousDay);
        } else if (view === "week") {
            const previousWeek = new Date(date.getTime());
            previousWeek.setDate(previousWeek.getDate() - 7);
            onChange(previousWeek);
        } else {
            const previousMonth = new Date(
                date.getFullYear(),
                date.getMonth() - 1,
                date.getDate()
            );
            onChange(previousMonth);
        }
    };

    const handleNext = () => {
        if (view === "day") {
            const nextDay = new Date(date.getTime());
            nextDay.setDate(nextDay.getDate() + 1);
            onChange(nextDay);
        } else if (view === "week") {
            const nextWeek = new Date(date.getTime());
            nextWeek.setDate(nextWeek.getDate() + 7);
            onChange(nextWeek);
        } else {
            const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
            onChange(nextMonth);
        }
    };

    const onChange = (date: Date) => {
        onNavigate("DATE", date);
        setCalendarDate(date);
    };

    const handleDateChange = (date: Date) => {
        handleClose();
        onChange(date);
    };

    return (
        <Box
            sx={{
                ...flexCenter,
                border: "1px solid #ddd",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
            }}
        >
            <Box sx={{ ...flexCenter, width: "100%", margin: "10px" }}>
                <CalendarDashboardMenu date={date} displayDate={handleDateChange} />
                <RoundButton
                    variant="outlined"
                    onClick={() => onChange(new Date())}
                    sx={{ padding: "1rem", height: "1rem", width: "auto" }}
                    title="today"
                    data-testid="calendar-today"
                >
                    <Typography fontSize="13px">{t("EVENT_TODAY")}</Typography>
                </RoundButton>
                <Box sx={{ margin: "0 10px" }}>
                    <IconButton
                        sx={{ color: "#2e323e" }}
                        onClick={handlePrevious}
                        title="month-previous"
                        data-testid="calendar-previousMonth"
                    >
                        {view === "month" ? (
                            <KeyboardArrowUpIcon titleAccess="Previous" />
                        ) : (
                            <KeyboardArrowLeftIcon titleAccess="Previous" />
                        )}
                    </IconButton>
                    <IconButton
                        sx={{ color: "#2e323e" }}
                        onClick={handleNext}
                        title="month-next"
                        data-testid="calendar-nextMonth"
                    >
                        {view === "month" ? (
                            <KeyboardArrowDownIcon titleAccess="Next" />
                        ) : (
                            <KeyboardArrowRightIcon titleAccess="Next" />
                        )}
                    </IconButton>
                </Box>
                <RoundButton
                    sx={{ width: "auto", color: "#2e323e" }}
                    onClick={handleMenu}
                    title="Date"
                    data-testid="calendar-toolbarDatepicker"
                >
                    <Typography
                        fontWeight={600}
                        sx={{ color: "#2e323e" }}
                        marginLeft="0px"
                    >
                        {formattedDate}
                    </Typography>
                    <KeyboardArrowDownIcon />
                </RoundButton>
                <Menu
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    sx={{ scale: "0.85" }}
                    transitionDuration={0}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    data-testid={'calendar-toolbarDatepicker-menu'}
                    MenuListProps={{
                        style: {
                            padding: "0",
                        },
                    }}
                >
                    <Box>
                        <CalendarDatePicker
                            date={date}
                            displayDate={handleDateChange}
                            style={datePickerStyle}
                        />
                    </Box>
                </Menu>
            </Box>
        </Box>
    );
};

export default Toolbar;
