import * as Yup from 'yup';

export const toDoListSchema = () => {
    const schema = Yup.object().shape({
        title: Yup.string().trim().max(255, 'Name must be at most 255 characters').required('Title is required'),
        dueDate: Yup.date().required('Date is required').typeError('Date is required'),
        priority: Yup.string().required("Priority is required")
    });

    return schema;
}