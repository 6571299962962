import { Typography } from "@mui/material";
import GenericTable from "../../../../components/GenericTable";
import { UserInstitutionDto } from "../../../../data/models/user";
import { useTranslation } from "react-i18next";

interface AccountPermissionProps {
    institutions: UserInstitutionDto[];
}

function AccountPermissions({ institutions }: AccountPermissionProps) {
    const { t } = useTranslation();
    return (
        <GenericTable<UserInstitutionDto>
            columns={[
                {
                    header: <Typography color='black'>{t("GENERAL_INST")}</Typography>, key: 'institution',
                    render: (institution) => <Typography variant="body2">{institution.name}</Typography>
                },
                {
                    header: <Typography color='black'>{t("SETTINGS_ROLES_NAME")}</Typography>, key: 'roles',
                    render: (institution) => <Typography variant="body2">
                        {institution.roles.filter(role => role.isActive).map(role => role.name).join(', ')}
                    </Typography>
                },
            ]}
            rows={institutions.filter(institution => institution.belongsTo)}
            props={{
                tableBodyProps: { style: { backgroundColor: 'transparent' } },
                containerProps: { style: { paddingLeft: 0, paddingRight: 0 } },
            }}
            rowKey="id"
        />
    );
}

export default AccountPermissions;