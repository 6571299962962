import { CircularProgress, Divider, Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"

import { useLayoutEffect } from "react";
import { observer } from "mobx-react-lite"
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

import { FormInputText } from "../../../components/form/FormInputText";
import { ProcessFormValues } from "../../../data/models/process";
import { RoundButton } from "../../../_styles/StyledButtons";
import FormInputSwitch from "../../../components/form/FormInputSwitch";
import FormInputDate from "../../../components/form/FormInputDate";
import { useStore } from "../../../stores/store";
import FormInputChipSelect from "../../../components/form/FormInputChipSelect";
import FormInputTime from "../../../components/form/FormInputTime";
import { combineDateAndTime } from "../../../utils/utils";
import agent from "../../../utils/agent";
import FormInputSelect from "../../../components/form/FormInputSelect";

function ProcessAddForm() {
    const {
        surveyStore: { loading, surveyList },
        sidebarStore: { closeSidebar }
    } = useStore();

    const reminders = [
        { id: '1', name: '5 minutes' },
        { id: '2', name: '10 minutes' },
        { id: '3', name: '15 minutes' },
        { id: '4', name: '30 minutes' }
    ]
    const editMode = false;
    const { handleSubmit, control } = useForm<ProcessFormValues>({
    });

    useLayoutEffect(() => {
        return () => {
            closeSidebar();
        }
    })

    const onSubmit = (data: ProcessFormValues) => {
        const combinedStartDate = combineDateAndTime(data.startDate, dayjs(data.startTime).toDate());

        const combinedEndDate = combineDateAndTime(data.endDate, dayjs(data.endTime).toDate());

        data.startTime = combinedStartDate;
        data.endTime = combinedEndDate;
        agent.Process.addProcess(data);
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ display: 'flex', flexDirection: 'column' }}
        >
            <Typography component="h1" variant="h5" sx={{ mb: 3, fontWeight: '500', fontSize: "18px" }}>
                Basic info
            </Typography>
            <Grid container columnSpacing={1}>
                <Grid item xs={12}>
                    <FormInputText name="name" control={control} label="Name *" />
                </Grid>
                <Grid item xs={12}>
                    <FormInputChipSelect
                        name="surveyIds"
                        control={control}
                        label='Questionnaire'
                        options={surveyList.map(survey => {
                            return {
                                value: survey.id,
                                label: survey.name
                            }
                        })}
                        labelKey="label"
                        valueKey="value"
                        search={true}
                    />
                </Grid>
                <Grid item xs={12} sx={{ paddingBottom: 2 }}>
                    <FormInputSwitch name="isASAP" control={control} label="As soon as possible" defaultValue={false} />
                </Grid>
                <Grid item md={12} sx={{ display: 'flex', paddingBottom: 2 }} gap={2}>
                    <Box>
                        <FormInputDate
                            name="startDate"
                            label="Start date *"
                            date={new Date()}
                            control={control}
                            helperText=""
                        />
                    </Box>
                    <Box>
                        <FormInputTime
                            control={control}
                            name="startTime"
                            label="Start time *"
                            defaultTime={dayjs(new Date())}
                            helperText=""
                        />
                    </Box>
                </Grid>
                <Grid item md={12} sx={{ display: 'flex', paddingBottom: 2 }} gap={2}>
                    <Box>
                        <FormInputDate
                            name="endDate"
                            label="End date *"
                            date={new Date()}
                            control={control}
                            helperText=""
                        />
                    </Box>
                    <Box>
                        <FormInputTime
                            control={control}
                            name="endTime"
                            label="end time *"
                            defaultTime={dayjs(new Date())}
                            helperText=""
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <FormInputSelect
                        name="reminders"
                        label="Reminder"
                        control={control}
                        options={reminders}
                        labelKey={'name'}
                        valueKey={'id'} />
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <RoundButton
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2, mb: 4 }}
                    disabled={loading}
                    title="Submit"
                    data-testid="submit-button"
                >
                    {loading ? (
                        <CircularProgress size={25} />
                    ) : (
                        editMode ?
                            <Typography fontSize="13px">Update</Typography>
                            :
                            <Typography fontSize="13px">Create</Typography>
                    )}
                </RoundButton>
            </Box>
        </Box>
    );
}

export default observer(ProcessAddForm)