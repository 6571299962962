import { makeAutoObservable, runInAction } from "mobx";
import { StudyOverviewDTO } from "../data/models/studyOverview";
import agent from "../utils/agent";
import { store } from "./store";

export default class StudyOverviewStore {
    studyOverview: Map<string, StudyOverviewDTO> = new Map<string, StudyOverviewDTO>();
    groupFilters: string[] = [];
    constructor() {
        makeAutoObservable(this);
    }

    get studyOverviewList(): StudyOverviewDTO[] {
        return Array.from(this.studyOverview!.values());
    }
    get studyOverviewGroups(): string[] {
        return ['Group A', 'Group B', 'Group C', 'Group D'];
    }

    clearStudyOverview() {
        this.studyOverview?.clear();
    }

    getStudyOverview = async (
        institutionId: string,
        queryParams: {
            page: number;
            pageSize: number;
            searchTerm?: string;
            groupFilter?: string[];
        }, filterChange?: boolean
    ) => {
        store.loadingStore.startLoading(this.getStudyOverview);

        try {
            const response = await agent.StudyOverview.getStudyOverview(institutionId, queryParams);
            store.commonStore.setStudyOverviewPagination(JSON.stringify(response.pagination));
            runInAction(() => {
                if (filterChange) { this.clearStudyOverview() }
                response.data.forEach(studyOverview => {
                    this.studyOverview?.set(studyOverview.clientId, studyOverview);
                });
            });
        } catch (error) {
            console.error("Error fetching study overview:", error);
        } finally {
            store.loadingStore.stopLoading(this.getStudyOverview);
        }
    }

    getClientSessionHistory = async (clientId: string) => {
        store.loadingStore.startLoading(this.getClientSessionHistory);

        try {
            const response = await agent.StudyOverview.getClientSessionHistoryActions(clientId);

            return response;
        } catch (error) {
            console.error("Error fetching study overview:", error);
        } finally {
            store.loadingStore.stopLoading(this.getClientSessionHistory);
        }
    }

    addGroupFilter = (role: string) => {
        runInAction(() => this.groupFilters.push(role));
    }

    removeGroupFilter = (role: string) => {
        const index = this.groupFilters.indexOf(role);
        if (index > -1) {
            runInAction(() => this.groupFilters.splice(index, 1));
        }
    }

    clearGroupFilters = () => {
        runInAction(() => this.groupFilters = []);
    }

    reset() {
        this.clearStudyOverview();
    }
}