import { observer } from "mobx-react-lite";

import { useStore } from "../../../stores/store";
import { RoundButton } from "../../../_styles/StyledButtons";
import EventForm from "../Form/EventForm";
import { useTranslation } from "react-i18next";

function BookingMenu() {
    const { t } = useTranslation();
    const {
        modalStore: { openModal },
    } = useStore();

    const getStartDate = () => new Date();
    const getEndDate = () => {
        const date = new Date();
        date.setHours(date.getHours() + 1);
        return date;
    };

    return (
        <RoundButton
            variant="contained"
            color="primary"
            sx={{ width: "auto", fontSize: "11px" }}
            onClick={() =>
                openModal(
                    <EventForm
                        initialStartDate={getStartDate()}
                        initialEndDate={getEndDate()}
                    />,
                    { confirmClose: true }
                )
            }
            title="New Event"
            data-testid='newEvent-button'
        >
            {t("EVENT_NEW")}
        </RoundButton>
    );
}

export default observer(BookingMenu);
