import { makeAutoObservable, runInAction } from "mobx";
import agent from "../utils/agent";
import { AnswerSubmit, QuestionType, Survey, SurveyResponse, SurveyTranslateField, SurveyView } from "../data/models/survey";
import { ProcessDto } from "../data/models/process";
import { store } from "./store";

interface SessionAnswer {
    sessionId?: string;
    timelineId?: string;
    surveyId?: string;
    token?: string;
}

export default class SurveyStore {
    surveyAnswers: Map<string, any> = new Map<string, any>();
    surveys: Map<string, SurveyView> = new Map<string, SurveyView>();
    processList: Map<string, ProcessDto> = new Map<string, ProcessDto>();
    loading = false;
    loadingSubmit = false;
    saveError = false;
    submitAnswerReqCount = 0;

    constructor() {
        makeAutoObservable(this);
    }

    get surveyAnswerList() {
        const resultList = [];
        const entries = Array.from(this.surveyAnswers.entries());

        for (let [key, value] of entries) {
            resultList.push({ id: key, ...value })
        }

        return resultList;
    }

    get surveyList() {
        return Array.from(this.surveys!.values());
    }

    get listOfProcess() {
        return Array.from(this.processList!.values())
    }

    incrementCount = () => {
        runInAction(() => this.submitAnswerReqCount++);
    }

    decrementCount = () => {
        if (this.submitAnswerReqCount === 0) return;
        runInAction(() => this.submitAnswerReqCount--);
    }

    resetSurvey = () => {
        runInAction(() => {
            this.surveyAnswers.clear();
        })
    }

    setAnswer = (questionId: string, answer: any) => {
        runInAction(() => this.surveyAnswers.set(questionId, answer))
    }

    getAnswer = (questionId: string) => {
        return this.surveyAnswers.get(questionId);
    }

    loadAnswers = (survey: Survey) => {
        const radioTypes = [QuestionType.RADIO, QuestionType.RADIOWITHTEXT, QuestionType.LINEARSCALE];
        const textTypes = [QuestionType.TEXT, QuestionType.PARAGRAPH];

        survey.pages.forEach(page => {
            page.questions.forEach((question) => {
                if (question.subQuestions && question.subQuestions.length > 0) {
                    question.subQuestions?.forEach((sq) => {
                        this.setAnswer(sq.id, { id: sq.id, answer: sq?.options?.find(o => o.isSelected)?.id ?? null });
                    });
                } else {
                    if (radioTypes.includes(question.questionType as QuestionType)) {
                        this.setAnswer(question.id, { id: question.id, answer: question?.options?.find(o => o.isSelected)?.id ?? null, additional: question.answer.text });
                    } else if (textTypes.includes(question.questionType as QuestionType)) {
                        this.setAnswer(question.id, { id: question.id, answer: question.answer.text });
                    } else if (question.questionType === QuestionType.DATE) {
                        this.setAnswer(question.id, { id: question.id, answer: question.answer.date ? new Date(question.answer.date) : null });
                    }
                }
            });
        })
    }

    clearAnswer = (questionId: string, sessionAnswer: SessionAnswer = {}) => {
        const answer = this.surveyAnswers.get(questionId);
        const oldAnswer = answer?.answer;
        if (answer) {
            runInAction(() => {
                answer.answer = null;
                if (answer.additional) {
                    answer.additional = null;
                }

                const { timelineId, sessionId, surveyId, token } = sessionAnswer;
                if (timelineId && sessionId && surveyId && oldAnswer) {
                    this.submitAnswer({
                        questionId: questionId,
                        surveyId,
                        timelineId,
                        sessionId,
                        optionId: null,
                        text: null,
                        date: null,
                    }, token)
                }
                this.surveyAnswers.set(questionId, answer);
            })
        }
    }

    getSurveyList = async () => {
        store.loadingStore.startLoading(this.getSurveyList);
        this.loading = true;
        try {
            const surveyList = await agent.Questionnaire.list();
            runInAction(() => {
                surveyList.forEach(survey => {
                    this.surveys.set(survey.id, survey);
                });
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getSurveyList);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getSurveyList);
            throw error;
        }
    }

    getSurvey = async (surveyId: string, language: string = 'en') => {
        store.loadingStore.startLoading(this.getSurvey);
        this.loading = true;
        try {
            const survey = await agent.Questionnaire.get(surveyId, language);

            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getSurvey);
            return survey;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getSurvey);
            throw error;
        }
    }

    getTimelineSurvey = async (timelineId: string, surveyId: string, sessionId: string, token?: string, language: string = "en") => {
        store.loadingStore.startLoading(this.getTimelineSurvey);
        this.loading = true;
        try {
            const survey = await agent.Questionnaire.timelineSurvey(timelineId, surveyId, sessionId, token, language);
            this.loadAnswers(survey);

            runInAction(() => {
                this.loading = false;
                this.saveError = false;
            });
            store.loadingStore.stopLoading(this.getTimelineSurvey);
            return survey;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getTimelineSurvey);
            throw error;
        }
    }

    getSurveyPreview = async (timelineId: string, surveyId: string, language: string = "en") => {
        store.loadingStore.startLoading(this.getSurveyPreview);
        this.loading = true;
        try {
            const survey = await agent.Questionnaire.previewSurvey(timelineId, surveyId, language);

            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getSurveyPreview);
            return survey;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getSurveyPreview);
            throw error;
        }
    }

    getSurveyResponses = async (responseId: string, institutionId: string, language: string = "en") => {
        store.loadingStore.startLoading(this.getSurveyResponses);
        this.loading = true;
        try {
            const survey = await agent.Clients.surveyResponse(responseId, institutionId, language);
            this.loadAnswers(survey);

            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getSurveyResponses);
            return survey;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getSurveyResponses);
            throw error;
        }
    }

    submitAnswer = async (answer: AnswerSubmit, token?: string) => {
        this.incrementCount();
        store.loadingStore.startLoading(this.submitAnswer);
        try {
            await agent.Questionnaire.submitAnswer(answer, token);
            store.loadingStore.stopLoading(this.submitAnswer);
        } catch (error) {
            runInAction(() => this.saveError = true)
            store.loadingStore.stopLoading(this.submitAnswer);
            throw error;
        } finally {
            this.decrementCount();
        }
    }

    submitSurvey = async (survey: SurveyResponse, token?: string) => {
        store.loadingStore.startLoading(this.submitSurvey);
        this.loadingSubmit = true;
        try {
            await agent.Session.submit(survey, token);
            runInAction(() => this.loadingSubmit = false);
            store.loadingStore.stopLoading(this.submitSurvey);
        } catch (error) {
            runInAction(() => this.loadingSubmit = false);
            store.loadingStore.stopLoading(this.submitSurvey);
            throw error;
        }
    }

    translate = async (surveyId: string, translations: SurveyTranslateField[]) => {
        store.loadingStore.startLoading(this.translate);
        try {
            await agent.Questionnaire.translate(surveyId, translations);
            store.loadingStore.stopLoading(this.translate);
        } catch (error) {
            store.loadingStore.stopLoading(this.translate);
            throw error;
        }
    }
}