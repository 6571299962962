import { makeAutoObservable, runInAction } from "mobx";
import { LanguageDto } from "../data/models/language";
import agent from "../utils/agent";
import { store } from "./store";
import { languagesMain12 } from "../data/static/language";

export class LanguageStore {
    languages: Map<string, LanguageDto> = new Map<string, LanguageDto>();

    constructor() {
        makeAutoObservable(this);
    }

    get languageList() {
        return Array.from(this.languages.values());
    }

    // this uses only the 12 main languages of the application, defined in
    // { languagesMain12 } from "../data/static/language";
    get filterLanguageList(){
        return Array.from(languagesMain12.values());
    }

    getLanguages = async () => {
        store.loadingStore.startLoading(this.getLanguages);
        try {
            const languages: LanguageDto[] = await agent.Language.get();
            runInAction(() => {
                languages.forEach(language => {
                    this.languages.set(language.alpha2, language);
                })
            })
            store.loadingStore.stopLoading(this.getLanguages);
        } catch (error) {
            store.loadingStore.stopLoading(this.getLanguages);
            throw error;
        }
    }
}