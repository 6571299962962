import { Box, Typography } from "@mui/material";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import DropdownMenu from "../../../components/DropdownMenu";
import { useStore } from "../../../stores/store";
import GenericTable from "../../../components/GenericTable";
import { RoleDto } from "../../../data/models/role";
import RoleForm from "./RoleForm";
import { TableText } from "../../../_styles/table/TableText";
import { TableHeader } from "../../../_styles/table/TableHeader";
import "../styles.css";
import { useTranslation } from "react-i18next";

function RoleTable() {
    const { t } = useTranslation();
    const { roleStore, tenantStore, sidebarStore } = useStore();
    const { getRoles, roleList, getRole } = roleStore;
    const { userSettingsTenant } = tenantStore;
    const { openSidebar } = sidebarStore;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userSettingsTenant) {
            const fetchData = async () => {
                setLoading(true);
                await getRoles(userSettingsTenant.id);
                setLoading(false);
            }

            fetchData();
        }
    }, [getRoles, userSettingsTenant]);

    const viewRole = async (id: number) => {
        const role = await getRole(id);
        if (role) {
            role.id = id;
            openSidebar(<RoleForm role={role} />, t("VIEW_ROLE"));
        }
    }

    const options = [
        {
            id: 'view',
            name: t("GENERAL_VIEW"),
            icon: <RemoveRedEyeOutlinedIcon sx={{ position: 'absolute', right: 0, paddingRight: 1 }} color="primary" />,
            onClick: (id: number) => viewRole(id),
        },
    ]

    return (
        <GenericTable<RoleDto>
            columns={[
                {
                    header: <TableHeader>{t("GENERAL_NAME")}</TableHeader>, key: 'name',
                    render: (role) => <TableText>{role.name}</TableText>,
                    sort: (a, b) => {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    },
                },
                {
                    header: <TableHeader>{t("GENERAL_DESCRIPTION")}</TableHeader>, key: 'description',
                    render: (role) => <TableText>{role.description}</TableText>,
                    sort: (a, b) => {
                        return a.description.toLowerCase().localeCompare(b.description.toLowerCase());
                    },
                },
                {
                    header: <Typography></Typography>,
                    key: 'actions',
                    render: (role) => (
                        <Box mr={2}>
                            <DropdownMenu
                                options={options.map((option) => {
                                    return {
                                        ...option,
                                        onClick: () => option.onClick(role.id),
                                    };
                                })}
                            />
                        </Box>
                    ),
                    className: "settings-table-actions"
                },
            ]}
            rows={roleList}
            props={{
                containerProps: { className: "settings-table" },
                tableContainerProps: { className: "survey-table-container-data" },
                tableHeadProps: { className: "survey-table-header" },
                rowProps: { className: "survey-row" },
            }}
            usePagination
            rowKey="id"
            loading={loading}
        />
    );
}

export default observer(RoleTable);
