import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { useState } from "react";

import { SessionStatus, SessionStatusText } from "../../../data/models/session";
import "./styles.css";
import { RoundButton } from "../../../_styles/StyledButtons";
import { arrayToString } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

interface StatusFilterProps {
    statusFilters: SessionStatus[];
    setStatusFilters: (filters: SessionStatus[]) => void;
}

function StatusFilter({ statusFilters, setStatusFilters }: StatusFilterProps) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onFilterChange = (status: SessionStatus) => {
        if (statusFilters.includes(status)) {
            setStatusFilters(statusFilters.filter((f) => f !== status));
        } else {
            setStatusFilters([...statusFilters, status]);
        }
    };

    return (
        <>
            <Button
                onClick={handleClick}
                className="filter-button"
                sx={{
                    color: anchorEl ? "#03b2ff" : "#808080",
                    bgcolor: anchorEl ? "#03b2ff0a" : "transparent"
                }}
            >
                <Box display={"flex"} alignItems={"center"} sx={{ fontWeight: 500 }}>
                    {t("CLIENT_PROCESS_STATUS")}
                    {statusFilters.length > 0 && (
                        <Box className="filter-badge">{statusFilters.length}</Box>
                    )}
                </Box>
                {anchorEl ? (
                    <KeyboardArrowUpIcon sx={{ fontSize: "16px", pt: "3px" }} />
                ) : (
                    <KeyboardArrowDownIcon sx={{ fontSize: "16px", pt: "3px" }} />
                )}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transitionDuration={0}
                MenuListProps={{
                    sx: {
                        "&& .Mui-selected": {
                            backgroundColor: "#0077b6 !important",
                            color: "#fff",
                            fontWeight: 500,
                        },
                        "&& .Mui-selected p": {
                            backgroundColor: "#0077b6 !important",
                            color: "#fff",
                            fontWeight: 500,
                        },
                        pt: 0,
                    },
                }}
                PaperProps={{
                    style: {
                        borderRadius: 0,
                    },
                }}
            >
                <Box className="filter-menu-container">
                    <Box>
                        <Typography fontSize={12} ml="8px">
                            {statusFilters.length} selected
                        </Typography>
                        {statusFilters.length > 0 && (
                            <Typography
                                className="selected-filters-label"
                                title={arrayToString(
                                    statusFilters.map((s) => SessionStatusText[s])
                                )}
                            >
                                {arrayToString(statusFilters.map((s) => SessionStatusText[s]))}
                            </Typography>
                        )}
                    </Box>
                    <RoundButton
                        variant="text"
                        className="filter-clear-button"
                        onClick={() => {
                            if (statusFilters.length > 0) {
                                setStatusFilters([]);
                            } else {
                                const statusList = Object.keys(SessionStatusText).map(
                                    (s) => s as SessionStatus
                                );
                                setStatusFilters(statusList);
                            }
                        }}
                    >
                        {statusFilters.length > 0 ? "Clear" : "All"}
                    </RoundButton>
                </Box>
                <Box>
                    {Object.keys(SessionStatusText).map((key) => (
                        <MenuItem
                            key={key}
                            className="filter-menu-item"
                            disableRipple
                            onClick={() => onFilterChange(key as SessionStatus)}
                            selected={statusFilters.includes(key as SessionStatus)}
                        >
                            {SessionStatusText[key as SessionStatus]}
                        </MenuItem>
                    ))}
                </Box>
            </Menu>
        </>
    );
}

export default StatusFilter;
