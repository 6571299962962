import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";

import "./styles.css";

interface Props {
    label?: string;
}

function SidebarHeader({ label }: Props) {
    const {
        sidebarStore: { closeSidebar },
    } = useStore();

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="sidebar-content"
        >
            <Typography variant="h5" sx={{ fontWeight: "500", fontSize: "20px" }}>
                {label}
            </Typography>
            <IconButton onClick={closeSidebar} title="Close" data-testid="close-sidebar">
                <CloseIcon />
            </IconButton>
        </Box>
    );
}

export default observer(SidebarHeader);
