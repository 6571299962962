import * as Yup from 'yup';

export const sessionCodeSchema = () => {
    const schema = Yup.object().shape({
        code: Yup.string()
            .min(5, "Code must be 5 alphanumeric characters (letters and digits)")
            .max(5, "Code must be 5 alphanumeric characters (letters and digits)")
            .matches(/^[a-z0-9]+$/, "Code must be alphanumeric (letters and digits)"),
    });

    return schema;
}