import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import SearchIcon from "@mui/icons-material/Search";
import {
    Box,
    Chip,
    CircularProgress,
    Divider,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    styled,
    TextField,
} from "@mui/material";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";


interface GroupedOption<T> {
    group: string;
    items: T[];
}

interface GroupedChipSelectProps<T> extends FormInputProps {
    groupedOptions: GroupedOption<T>[];
    labelKey: keyof T;
    valueKey: keyof T;
    defaultValues?: string[];
    isLimited?: boolean;
    search?: boolean;
    isDisabled?: boolean;
    loading?: boolean;
    searchPlaceholder?: string;
}

const ExpandMoreIconUp = styled(ExpandMoreIcon)(() => ({
    transform: "rotate(180deg)",
}));

const ExpandMoreIconDown = styled(ExpandMoreIcon)(() => ({
    transform: "rotate(0deg)",
}));

function GroupedFormInputChipSelect<T extends { [key: string]: any }>(props: GroupedChipSelectProps<T>) {
    const {
        name,
        label,
        control,
        groupedOptions,
        valueKey,
        labelKey,
        defaultValues = [],
        isLimited = false,
        search = false,
        helperText = " ",
        isDisabled = false,
        loading = false,
        searchPlaceholder,

    } = props;

    const [selectedValues, setSelectedValues] = useState<string[]>(defaultValues);
    const [searchText, setSearchText] = useState("");
    const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set());

    const displayedOptions = useMemo(() => {
        return groupedOptions
            .map(group => ({
                group: group.group,
                items: group.items.filter(option =>
                    option[labelKey]?.toLowerCase().includes(searchText.trim().toLowerCase())
                )
            }))
            .filter(group => group.items.length > 0);
    }, [searchText, groupedOptions, labelKey]);

    const toggleGroup = (groupName: string) => {
        setExpandedGroups(prev => {
            const newExpandedGroups = new Set(prev);
            if (newExpandedGroups.has(groupName)) {
                newExpandedGroups.delete(groupName);
            } else {
                newExpandedGroups.add(groupName);
            }
            return newExpandedGroups;
        });
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValues}
            render={({ fieldState: { error }, field: { onChange } }) => (
                <Box>
                    <InputLabel sx={{ fontSize: "14px" }}>{label}</InputLabel>
                    <FormControl fullWidth>
                        <Select
                            multiple
                            error={!!error}
                            value={selectedValues}
                            data-testid={name}
                            input={<OutlinedInput label="Multiple Select" />}
                            disabled={isDisabled}
                            SelectDisplayProps={{
                                style: {
                                    padding: `${selectedValues.length > 0 ? '4.85px' : '8px'} 10px`,
                                    backgroundColor: "white",
                                    fontSize: "13px",
                                },
                            }}
                            MenuProps={{ sx: { width: 300, height: 400 }, autoFocus: false }}
                            sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                                lineHeight: "inherit",
                            }}
                            renderValue={(selected) => (
                                <Stack gap={1} direction="row" flexWrap="wrap">
                                    {groupedOptions
                                        .flatMap(group => group.items)
                                        .filter(option => selected.includes(option[valueKey]))
                                        .map(option => (
                                            <Chip
                                                key={option[valueKey]}
                                                label={option[labelKey]}
                                                data-testid={`${name}-chip-${option[valueKey]}`}
                                                sx={{ height: "25px", fontSize: "12px" }}
                                                onDelete={() => {
                                                    const newSelectedValues = selected.filter(
                                                        (value: string) => value !== option[valueKey]
                                                    );
                                                    setSelectedValues(newSelectedValues);
                                                    onChange(newSelectedValues);
                                                }}
                                                deleteIcon={
                                                    isLimited ? (
                                                        selected.length !== 1 ? (
                                                            <CancelIcon
                                                                onMouseDown={(event) => event.stopPropagation()}
                                                            />
                                                        ) : (
                                                            <NotInterestedIcon />
                                                        )
                                                    ) : (
                                                        <CancelIcon
                                                            onMouseDown={(event) => event.stopPropagation()}
                                                        />
                                                    )
                                                }
                                            />
                                        ))}
                                </Stack>
                            )}
                        >
                            {search && !loading && (
                                <ListSubheader>
                                    <TextField
                                        size="small"
                                        autoFocus
                                        placeholder={searchPlaceholder ? t(searchPlaceholder) : t('TYPE_TO_SEARCH')}
                                        fullWidth
                                        sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                        }}
                                        inputProps={{
                                            style: {
                                                fontSize: "13px",
                                                padding: "8px 0",
                                            },
                                            'data-testid': `${name}-search`,
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key !== "Escape") {
                                                e.stopPropagation();
                                            }
                                        }}
                                    />
                                </ListSubheader>
                            )}
                            {loading ? (
                                <MenuItem disabled sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress size={18} />
                                </MenuItem>
                            ) : (
                                displayedOptions.map((group) => (
                                    <div key={group.group}>
                                        <ListSubheader
                                            onClick={() => toggleGroup(group.group)}
                                            sx={{
                                                cursor: "pointer",
                                                lineHeight: "40px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",

                                            }}
                                        >
                                            {group.group}
                                            {expandedGroups.has(group.group) ? (
                                                <ExpandMoreIconUp />
                                            ) : (
                                                <ExpandMoreIconDown />
                                            )}
                                        </ListSubheader>
                                        {expandedGroups.has(group.group) && (
                                            <Box>
                                                {group.items.map((option) => (
                                                    <MenuItem
                                                        key={option[valueKey]}
                                                        value={option[valueKey]}
                                                        data-testid={`${name}-option-${option[valueKey]}`}
                                                        sx={{
                                                            justifyContent: "space-between", fontSize: "13px", padding: "7px", paddingLeft: "25px", paddingRight: "18px",
                                                            backgroundColor: selectedValues.includes(option[valueKey]) ? "rgba(103,176,208, 0.3)" : "transparent",
                                                        }}
                                                        onClick={
                                                            () => {
                                                                if (isDisabled) return;
                                                                const newSelectedValues = selectedValues.includes(option[valueKey])
                                                                    ? selectedValues.filter(value => value !== option[valueKey])
                                                                    : [...selectedValues, option[valueKey]];

                                                                if (isLimited && newSelectedValues.length === 0) return;

                                                                setSelectedValues(newSelectedValues);
                                                                onChange(newSelectedValues);
                                                            }}
                                                    >
                                                        {option[labelKey]}
                                                        {selectedValues.includes(option[valueKey]) ? (
                                                            <CheckIcon color="info" style={{ fontSize: 20 }} />
                                                        ) : null}
                                                    </MenuItem>
                                                ))}
                                            </Box>
                                        )}
                                        <Divider sx={{ marginY: "1px" }} />
                                    </div>
                                ))
                            )}
                        </Select>
                        <FormHelperText error={!!error}>
                            {error?.message || helperText}
                        </FormHelperText>
                    </FormControl>
                </ Box>
            )}
        />
    );
}

export default GroupedFormInputChipSelect;
