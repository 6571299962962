import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import { observer } from "mobx-react-lite";
import { NoEventStats } from "../../data/models/stats";
import { capitalizeFirstLetter } from "../../utils/utils";
import { useTranslation } from "react-i18next";


interface NoEventTableProps {
    data: NoEventStats[];
    title?: string;
}

function NoEventTable({ data, title }: NoEventTableProps) {
    const { t } = useTranslation();
    return (
        <TableContainer component={Paper} sx={{ bgcolor: "#fff" }}>
            {title && <Box sx={{ p: 1 }}>
                <Typography component={"h2"} variant="h6">{title}</Typography>
            </Box>}
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead>
                    <TableRow>
                        {data.map((row) =>
                            <TableCell key={row.gender} sx={{ textAlign: "center" }}>{capitalizeFirstLetter(row.gender)}</TableCell>
                        )}
                        <TableCell sx={{ textAlign: "center" }}>{t("CARD_TOTAL")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {data.map((row) =>
                            <TableCell key={row.gender} sx={{ textAlign: "center" }}>{row.total}</TableCell>
                        )}
                        <TableCell sx={{ textAlign: "center", bgcolor: "#ffc10726", fontWeight: 600 }}>
                            {data.reduce((acc, result) => {
                                return acc + result.total;
                            }, 0)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default observer(NoEventTable);