

export const residencePermitOptions =
    [
        { id: '1', name: 'EU/EFTA L permit (short-stay permit)' },
        { id: '2', name: 'EU/EFTA B permit (residence permit)' },
        { id: '3', name: 'EU/EFTA C permit (settlement permit)' },
        { id: '4', name: 'EU/EFTA Ci permit (residence permit with gainful employment)' },
        { id: '5', name: 'EU/EFTA G permit (cross-border commuter permit)' },
        { id: '6', name: 'B permit (residence permit)' },
        { id: '7', name: 'C permit (settlement permit))' },
        { id: '8', name: 'Ci permit (residence permit with gainful employment)' },
        { id: '9', name: 'G permit (cross-border commuter permit)' },
        { id: '10', name: 'L permit (short-stay permit)' },
        { id: '11', name: 'F permit (for temporarily admitted foreigners)' },
        { id: '12', name: 'N permit (for asylum seekers)' },
        { id: '13', name: 'S permit (provisional status for persons in need of protection)' },
        { id: '14', name: 'Swiss Resident' },
        { id: "15", name: "B-Flüchtling (B Refugee)" },
        { id: "16", name: "Abgewiesen Asylsuchende (Sans Papiers)" },
        { id: "17", name: "B-Familiennachzug (B Family Reunion)" },
    ]
