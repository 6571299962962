import { IconButton, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';

import { useEffect, useState } from "react";
import { TableSearch } from "../../../_styles/table/TableSearch";
import "../styles.css";

interface CalendarSearchProps {
    onSearch: (value: string) => void;
    handleChange: (open: boolean) => void;
}

function CalendarSearch({ onSearch, handleChange }: CalendarSearchProps) {
    const [open, setOpen] = useState<boolean>(false);
    const [value, setValue] = useState<string>("");

    useEffect(() => {
        onSearch(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (open) {
            setOpen(false);
            handleChange(false);
            setValue("");
        } else {
            setOpen(true);
            handleChange(true);
        }
    };

    return (
        <>
            <IconButton onClick={handleClick} sx={{ position: "absolute", top: 0, right: 0 }}>
                {open ?
                    <ZoomOutRoundedIcon />
                    :
                    <SearchIcon />
                }
            </IconButton>
            <Paper className={`people-calendar-search ${open || value !== "" ? "" : "hidden"}`} sx={{ width: "fit-content", mb: "8px" }}>
                <TableSearch
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    value={value}
                    placeholder={"Search by name..."}
                    title="Search by name"
                    sx={{
                        mr: 0,
                        width: "14rem",
                    }}
                    InputProps={{
                        endAdornment: <SearchIcon sx={{ color: "#ddd" }} />,
                        style: {
                            backgroundColor: "#fff",
                            justifyContent: "space-between"
                        }
                    }}
                />
            </Paper>
        </>
    );
}

export default CalendarSearch;