import { makeAutoObservable, runInAction } from "mobx";

import { Role, RoleDto } from "../data/models/role";
import agent from "../utils/agent";
import { store } from "./store";

export default class RoleStore {
    roles: Map<number, RoleDto> = new Map<number, RoleDto>();
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    get roleList() {
        return Array.from(this.roles.values());
    }

    getRoles = async (tenantId: string) => {
        store.loadingStore.startLoading(this.getRoles);
        try {
            const roles = await agent.Roles.get(tenantId);
            runInAction(() => {
                this.roles.clear();
                roles.forEach((role) => this.roles.set(role.id, role));
            });
            store.loadingStore.stopLoading(this.getRoles);
        } catch (error) {
            store.loadingStore.stopLoading(this.getRoles);
            throw error;
        }
    }

    getRole = async (id: number) => {
        store.loadingStore.startLoading(this.getRole);
        this.loading = true;
        try {
            const role = await agent.Roles.getById(id);
            runInAction(() => {
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getRole);
            return role;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getRole);
            throw error;
        }
    }

    createRole = async (Role: Role) => {
        this.loading = true;
        store.loadingStore.startLoading(this.createRole);
        try {
            await agent.Roles.create(Role);
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.createRole);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.createRole);
            throw error;
        }
    }

    updateRole = async (role: Role) => {
        store.loadingStore.startLoading(this.updateRole);
        this.loading = true;
        try {
            await agent.Roles.update(role);
            const newRole: RoleDto = { id: role.id!, name: role.name, description: role.description };
            runInAction(() => {
                this.roles.set(newRole.id, newRole);
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.updateRole);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.updateRole);
            throw error;
        }
    }

    deleteRole = async (id: number) => {
        store.loadingStore.startLoading(this.deleteRole);
        this.loading = true;
        try {
            await agent.Roles.delete(id);
            runInAction(() => {
                this.roles.delete(id);
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.deleteRole);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.deleteRole);
            throw error;
        }
    }
}