import Drawer from '@mui/material/Drawer/Drawer';
import { Box } from '@mui/material';

import { Fragment } from 'react';
import { observer } from 'mobx-react-lite';

import './styles.css';

interface Props {
    open: boolean;
    handleClose: () => void;
    children: JSX.Element;
    style?: any;
}

const sidebarStyle = {
    backgroundColor: 'white',
    '@media (max-width: 900px)': {
        padding: 0,
        alignItems: 'center',
        width: '64% !important',
        overflowX: 'hidden !important'
    }
}
function DrawerMenu({ open, children, handleClose, style }: Props) {
    return (
        <div>
            <Fragment >
                <Drawer
                    anchor={"left"}
                    open={open}
                    className='drawer-menu'
                    key={"left"}
                    onClose={handleClose}
                    PaperProps={{
                        sx: { ...sidebarStyle, ...style }
                    }}
                >
                    <Box className="drawer-menu-content">
                        {children}
                    </Box>
                </Drawer>
            </Fragment>
        </div>
    );
}

export default observer(DrawerMenu);