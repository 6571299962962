import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    Typography,
    Box,
    CircularProgress,
    CardActions,
    Divider,
} from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormInputText } from "../../../components/form/FormInputText";
import { resetPasswordFormSchema } from "../../../_validators/schemas/profileForm.schema";
import { useStore } from "../../../stores/store";
import { ResetPasswordDto } from "../../../data/models/profile";
import { RoundButton } from "../../../_styles/StyledButtons";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

function PasswordResetForm() {
    const { t } = useTranslation();
    const {
        profileStore: { resetPassword, loading },
        authStore: { user },
    } = useStore();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = decodeURIComponent(queryParams.get("token")!);
    const email = decodeURIComponent(queryParams.get("email")!);

    const { handleSubmit, reset, control } = useForm<any>({
        resolver: yupResolver(resetPasswordFormSchema()),
        defaultValues: {
            newPassword: "",
            confirmPassword: "",
        },
    });

    const onSubmit = async (data: any) => {
        if (!token) {
            toast.error(t("TOAST_USER_INV_TOKEN"));
        }

        const resetPasswordValues: ResetPasswordDto = {
            email: email,
            token: token!,
            newPassword: data.newPassword,
        };

        await resetPassword(resetPasswordValues);
        toast.success(t("TOAST_USER_PW_RESET"));
        navigate("/");
        reset();
    };

    return (
        <Box
            sx={{
                display: "flex",
                flex: 1,
                height: "100%",
                backgroundColor: "#ffffff",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
            {!user && (
                <Box sx={{ display: "flex", alignSelf: "center" }}>
                    <Typography
                        component="h1"
                        variant="h3"
                        color="#03b2ff"
                        fontWeight={500}
                        paddingBottom={3}
                    >
                        SPIRIT
                    </Typography>
                </Box>
            )}
            <Grid
                container
                justifyContent={"center"}
                sx={{ alignSelf: "center", backgroundColor: "#F1F4F9", padding: 5 }}
            >
                <Card
                    sx={{ maxWidth: 450, backgroundColor: "#ffffff" }}
                    variant="outlined"
                >
                    <CardHeader sx={{ display: "flex" }} title="Reset your password" />
                    <Divider />
                    <CardContent sx={{ paddingBottom: "5px !important" }}>
                        <Box
                            component="form"
                            onSubmit={handleSubmit(onSubmit)}
                            sx={{ mt: 2 }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormInputText
                                        name="newPassword"
                                        control={control}
                                        label={t("PW_NEW")}
                                        type="password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormInputText
                                        name="confirmPassword"
                                        control={control}
                                        label={t("PW_NEW_CONFIRM")}
                                        type="password"
                                        helperText={t("PASSWORD_POLICY")}
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                            <CardActions
                                disableSpacing
                                sx={{ justifyContent: "right", padding: 0, alignItems: "center" }}
                            >
                                <RoundButton
                                    color="inherit"
                                    variant="text"
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        mr: 1,
                                        "&:hover": { backgroundColor: "transparent" },
                                    }}
                                    disableTouchRipple
                                    onClick={() => navigate("/")}
                                    title="Go back"
                                    data-testid="go-back-button"
                                >
                                    <Typography fontSize="13px">{t("GENERAL_BACK")}</Typography>
                                </RoundButton>
                                <RoundButton
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={loading}
                                    title="Submit"
                                    data-testid="submit-button"
                                >
                                    {(loading && <CircularProgress size={25} />) || (
                                        <Typography fontSize="13px">{t("GENERAL_SUBMIT")}</Typography>
                                    )}
                                </RoundButton>
                            </CardActions>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Box>
    );
}

export default observer(PasswordResetForm);
