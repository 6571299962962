import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { InputLabel } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Dayjs } from 'dayjs';

import { Controller } from "react-hook-form";

import { FormInputProps } from "./FormInputProps";

export interface TimeInputProps extends FormInputProps {
    defaultTime?: Dayjs | null;
    disabled?: boolean;
}

const FormInputTime = ({ name, label, control, defaultTime = null, disabled = false, helperText = " " }: TimeInputProps) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
                control={control}
                name={name}
                defaultValue={defaultTime}
                render={({ field: { ref, onBlur, name, value = null, ...field }, fieldState: { error } }) => (
                    <>
                        <InputLabel htmlFor={name} sx={{ fontSize: '14px' }}>{label}</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                inputRef={ref}
                                {...field}
                                ampm={false}
                                value={value}
                                sx={{
                                    width: "100%",
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                    '& input': {
                                        backgroundColor: 'white',
                                        padding: '8px 10px',
                                        fontSize: '13px'
                                    },
                                }}
                                slotProps={{
                                    textField: {
                                        helperText: error?.message || helperText,
                                        error: !!error,
                                        fullWidth: true,
                                        inputProps: {
                                            'data-testid': name,
                                        }
                                    },
                                }}
                                disabled={disabled}
                            />
                        </LocalizationProvider>
                    </>
                )}
            />
        </LocalizationProvider>
    );
};

export default FormInputTime;
