import { Box, Collapse, List, ListItemButton, Typography } from "@mui/material";

import { observer } from "mobx-react-lite";

import { UserTenant } from "../../../data/models/tenant";
import { useStore } from "../../../stores/store";
import { InstitutionDto } from "../../../data/models/institution";
import { View } from "../../../data/models/event";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

interface Props {
    tenant: UserTenant;
    handleClose: () => void;
}

function TenantInstitutionList({ tenant, handleClose }: Props) {
    const { t } = useTranslation();
    const {
        institutionStore: { setSelectedUserInstitution, selectedUserInstitution },
        tenantStore: { loadTenant },
        commonStore: { setCalendarViews },
        bookingStore: { userEventsId },
        dialogStore: { openDialog, closeDialog },
    } = useStore();
    const navigate = useNavigate();

    const onSelectInstitution = async (institutionId: string) => {
        const regex =
            /^\/users\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/update$/;

        const isClientForm = regex.test(window.location.pathname);
        let discard = isClientForm;

        if (isClientForm) {
            openDialog(
                t("DIALOG_TITLE_ATTENTION"),
                t("DIALOG_MSG_INST_CHANGE") + " " + t("DIALOG_MSG_PROCEED"),
                () => {
                    navigate("/users");
                    handleClose();
                    closeDialog();
                },
                () => {
                    discard = false;
                    handleClose();
                    closeDialog();
                },
                { text: t("DIALOG_DISCARD"), color: "info" }
            );
        }
        if (!discard) {
            setSelectedUserInstitution(institutionId);
            loadTenant(tenant.id);
            setCalendarViews([{ id: userEventsId, type: View.USER }]);
            handleClose();
        }
    };

    const getTenantBgColor = (institutionId: string) => {
        const isSelectedTenant = tenant.institutions.find(
            (i) => i.id === institutionId
        );
        return isSelectedTenant ? "#4fb5f2a1" : "whitesmoke";
    };

    const getBackgroundColor = (institution: InstitutionDto) => {
        if (selectedUserInstitution) {
            if (institution.id === selectedUserInstitution?.institutionId) {
                return "#94a3b86b";
            } else {
                return "white";
            }
        }

        if (institution.isDefault) {
            return "#94a3b86b";
        }

        return "white";
    };

    return (
        <Box>
            <ListItemButton
                sx={{
                    backgroundColor: getTenantBgColor(
                        selectedUserInstitution?.institutionId!
                    ),
                    borderTop: "1px solid #ddd",
                    borderBottom: "1px solid #ddd",
                    "&:hover": {
                        backgroundColor: getTenantBgColor(
                            selectedUserInstitution?.institutionId!
                        ),
                        cursor: "context-menu",
                    },
                }}
                disableTouchRipple
                disableRipple
            >
                <Typography variant="inherit" fontSize={14} noWrap>
                    {tenant.name}
                </Typography>
            </ListItemButton>
            <Collapse in={true} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {tenant.institutions.map((institution) => (
                        <ListItemButton
                            key={institution.id}
                            onClick={() => onSelectInstitution(institution.id)}
                            selected={
                                selectedUserInstitution?.institutionId === institution.id
                            }
                            sx={{
                                backgroundColor: getBackgroundColor(institution),
                                pl: 3,
                                "&:hover": { backgroundColor: "#94a3b81f" },
                            }}
                        >
                            <Typography variant="inherit" fontSize={12} noWrap>
                                {institution.name}
                            </Typography>
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        </Box>
    );
}

export default observer(TenantInstitutionList);
