import { ModuleName, Permission, PermissionName } from "../../../data/models/role";

export const permissions: Permission[] = [
    {
        name: PermissionName.ACTIVATE,
        hasAccess: true,
    },
    {
        name: PermissionName.VIEW_ALL,
        hasAccess: true,
    },
    {
        name: PermissionName.VIEW_OWN,
        hasAccess: true,
    },
    {
        name: PermissionName.EDIT_ALL,
        hasAccess: true,
    },
    {
        name: PermissionName.EDIT_OWN,
        hasAccess: true,
    },
    {
        name: PermissionName.DELETE_ALL,
        hasAccess: true,
    },
    {
        name: PermissionName.DELETE_OWN,
        hasAccess: true,
    },
    {
        name: PermissionName.ECRF_PDF,
        hasAccess: true,
    },
    {
        name: PermissionName.RESEARCH_DATA_CSV,
        hasAccess: true,
    },
    {
        name: PermissionName.BRIDGE_TABLE_CSV,
        hasAccess: true,
    },
    {
        name: PermissionName.CONSENT_FORMS,
        hasAccess: true,
    },
];

const permissionGroups = [
    {
        name: 'Module',
        permissions: [permissions[0]],
    },
    {
        name: 'View',
        permissions: [permissions[1], permissions[2]],
    },
    {
        name: 'Edit',
        permissions: [permissions[3], permissions[4]],
    },
    {
        name: 'Delete',
        permissions: [permissions[5], permissions[6]],
    },
]

const niiGroups = [
    {
        name: '',
        permissions: [permissions[7], permissions[8]],
    },
]

const piiGroups = [
    {
        name: '',
        permissions: [permissions[9], permissions[10]],
    },
]

export const modules = [
    {
        moduleName: ModuleName.DASHBOARD,
        isActive: true,
        permissionGroups: permissionGroups,
    },
    {
        moduleName: ModuleName.RESOURCE_PLANNING,
        isActive: true,
        permissionGroups: permissionGroups,
    },
    {
        moduleName: ModuleName.SCREENING,
        isActive: true,
        permissionGroups: permissionGroups,
    },
    {
        moduleName: ModuleName.CLIENTS,
        isActive: true,
        permissionGroups: permissionGroups
    },
    {
        moduleName: ModuleName.DOWNLOAD_NII,
        isActive: true,
        permissionGroups: niiGroups
    },
    {
        moduleName: ModuleName.DOWNLOAD_PII,
        isActive: true,
        permissionGroups: piiGroups
    }
];

export const roleOptions = [
    {
        name: 'Admin',
        description: 'Admin',
        tenantId: 'f0234274-b2e8-41d6-b6a0-2d7270094e75',
        permissionGroups: permissionGroups
    },
];

export const ModuleLabel: Record<ModuleName, string> = {
    [ModuleName.CLIENTS]: "Users",
    [ModuleName.DASHBOARD]: "Dashboard",
    [ModuleName.DOWNLOAD_NII]: "Non-identifiable information (NII)",
    [ModuleName.DOWNLOAD_PII]: "Personal identifiable information (PII)",
    [ModuleName.RESOURCE_PLANNING]: "Resource planning",
    [ModuleName.SCREENING]: "Screening",
    [ModuleName.TEMPLATES]: "Templates",
};