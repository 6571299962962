export interface Pagination {
    page: number,
    pageSize: number,
    currentPage?: number,
    hasNext?: boolean,
    hasPrevious?: boolean,
    totalCount?: number
}

export class PaginatedResult<T> {
    data: T;
    pagination: Pagination;

    constructor(data: T, pagination: Pagination) {
        this.data = data;
        this.pagination = pagination;
    }
}