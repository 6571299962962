import { makeAutoObservable } from "mobx"

type Anchor = 'left' | 'right';

interface Sidebar {
    open: boolean;
    body: JSX.Element | null;
    label?: string;
    position: Anchor;
}

export default class SidebarStore {
    sidebar: Sidebar =
        {
            open: false,
            body: null,
            position: "right",
        }

    constructor() {
        makeAutoObservable(this);
    }

    openSidebar = (content: JSX.Element, label?: string) => {
        if (this.sidebar.open) {
            this.closeSidebar();
        }

        if (content.key !== this.sidebar.body?.key) {
            this.sidebar.open = true;
            this.sidebar.body = content;
            this.sidebar.label = label;
        }
    }

    closeSidebar = () => {
        this.sidebar.open = false;
        this.sidebar.body = null;
    }
}