import { Institution } from "../../data/models/institution";
import { RoleDto } from "../../data/models/role";
import { InstitutionRoleDto, UserDetailsDto, UserInstitutions } from "../../data/models/user";

export const getDefaultValues =
    (institution: Institution, user: UserDetailsDto): { isChecked: boolean, multiSelectValue: string[] } => {
        const userInstitution = user?.userInstitutions.find(i => i.id === institution.id);
        if (!userInstitution) {
            return { isChecked: false, multiSelectValue: [] };
        }

        const defaultValues: string[] = [];
        userInstitution.roles.forEach((role: InstitutionRoleDto) => {
            if (role.isActive) {
                defaultValues.push(role.name);
            }
        });
        const checkboxDefault = { isChecked: userInstitution.belongsTo };
        const defaultValuesObject = { isChecked: checkboxDefault.isChecked, multiSelectValue: defaultValues };
        return defaultValuesObject;
    }

export const getUserRoleIds = (institutionId: string, user: UserDetailsDto): number[] => {
    const userInstitution = user?.userInstitutions.find(i => i.id === institutionId);
    if (!userInstitution) {
        return [];
    }

    const roleIds: number[] = [];
    userInstitution.roles.forEach((role: InstitutionRoleDto) => {
        if (role.isActive) {
            roleIds.push(role.id);
        }
    });

    return roleIds;
}

export const getInstitutionRoles = (data: any, institutionList: Institution[], roleList: RoleDto[]): UserInstitutions[] => {
    const selectedInstitutionRoles: UserInstitutions[] = [];

    institutionList.forEach(institution => {
        if (!data[institution.id]["isChecked"]) return;
        const institutionId = institutionList.find(i => i.id === institution.id)?.id;
        const roles = data[institution.id]["multiSelectValue"];
        const institutionRoles: number[] = [];
        roles.forEach((roleName: any) => {
            const roleId = roleList.find(r => r.name === roleName)?.id;
            institutionRoles.push(roleId!);
        });

        selectedInstitutionRoles.push({
            institutionId: institutionId!,
            roleIds: institutionRoles!
        });
    });

    return selectedInstitutionRoles;
}

export const getFullPermissions = (institutionList: Institution[], roleList: RoleDto[]): UserInstitutions[] => {
    const selectedInstitutionRoles: UserInstitutions[] = [];

    institutionList.forEach(institution => {
        const institutionId = institution.id;
        const roles = roleList.map(role => role.id);

        selectedInstitutionRoles.push({
            institutionId: institutionId,
            roleIds: roles
        });
    });

    return selectedInstitutionRoles;
}
