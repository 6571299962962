import { Box, Divider, Link, Typography } from "@mui/material";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import CastConnectedRoundedIcon from "@mui/icons-material/CastConnectedRounded";
import CastOutlinedIcon from "@mui/icons-material/CastOutlined";
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';

import { Link as RouterLink, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { SideBarItem } from "../../_styles/ListItem";
import { useStore } from "../../stores/store";
import {
    hasModuleAccess,
    hasModulePermission,
    hasRoles,
} from "../../utils/permissionEvaluator";
import { ModuleName, PermissionName } from "../../data/models/role";
import { useTranslation } from "react-i18next";
import LogSelector from "./LogSelector";

const navLinkStyle = {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#2e323e"
};

const iconStyle = {
    marginRight: "5px",
    color: "gray",
};

const activeIcon = {
    ...iconStyle,
    color: "#004e7c",
};


interface Props {
    handleClose: () => void;
}

function NavbarOptions({ handleClose }: Props) {
    const { t } = useTranslation();
    const {
        institutionStore: { selectedUserInstitution },
        tenantStore: { selectedTenant },
        commonStore: { viewType },
        authStore: { user }
    } = useStore();
    const location = useLocation();
    const currentPath = location.pathname;

    const hasNotificationLogPermission = user?.isSuperAdmin || (() => {
        if (!selectedUserInstitution?.roles) return false;

        const allowedRoles = ["Admin", "Field Coordinator", "Field Coordinator Light", "USZ Coordinator"];
        return allowedRoles.some(role => selectedUserInstitution.roles.includes(role));
    })();
    const isInPath = (path: string) => {
        return currentPath.includes(path);
    };

    return (
        <Box className="module-menu-options">
            {/* {hasModuleAccess(ModuleName.DASHBOARD, selectedUserInstitution!) &&
                <Box>
                    <Link component={RouterLink} to="/dashboard" sx={navLinkStyle} data-testid="dashboard-menu-link">
                        <SideBarItem
                            key="dashboard"
                            onClick={handleClose}
                            sx={{ marginBottom: 0, padding: '1.2rem 24px' }}
                        >
                            {isInPath("/dashboard") ? <DashboardRoundedIcon sx={activeIcon} /> : <DashboardOutlinedIcon sx={iconStyle} />}
                            <Typography variant="h6" component="div" sx={{ color: isInPath("/dashboard") ? "#004e7c !important" : "#2e323e" }}>Dashboard</Typography>
                        </SideBarItem>
                    </Link>
                    <Divider />
                </Box>} */}
            {/* {
                (user?.isSuperAdmin
                    || hasRoles(["USZ Coordinator"], selectedUserInstitution?.roles ?? []))
                && <Box>
                    <Link
                        component={RouterLink}
                        to={"/templates"}
                        sx={navLinkStyle}
                        data-testid="templates-menu-link"
                    >
                        <SideBarItem
                            key="templates"
                            onClick={handleClose}
                            sx={{ marginBottom: 0, padding: "1.2rem 24px" }}
                        >
                            {isInPath("/templates") ? (
                                <TextSnippetRoundedIcon sx={activeIcon} />
                            ) : (
                                <TextSnippetOutlinedIcon sx={iconStyle} />
                            )}
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    color: isInPath("/templates")
                                        ? "#004e7c !important"
                                        : "#2e323e",
                                }}
                            >
                                {t('GENERAL_TEMPLATES')}
                            </Typography>
                        </SideBarItem>
                    </Link>
                    <Divider />
                </Box>
            } */}
            {hasModuleAccess(
                ModuleName.RESOURCE_PLANNING,
                selectedUserInstitution!,
                user?.isSuperAdmin
            ) &&
                hasModulePermission(
                    [PermissionName.VIEW_ALL, PermissionName.VIEW_OWN],
                    ModuleName.RESOURCE_PLANNING,
                    selectedUserInstitution!,
                    false
                ) && (
                    <Box>
                        <Link
                            component={RouterLink}
                            to={`/resource-planning/view/${viewType ?? "week"}${currentPath.includes("/resource-planning/view/")
                                ? location.search
                                : ""
                                }`}
                            sx={navLinkStyle}
                            data-testid="resource-planning-menu-link"
                        >
                            <SideBarItem
                                key="resource-planning"
                                onClick={handleClose}
                                sx={{ marginBottom: 0, padding: "1.2rem 24px" }}
                            >
                                {isInPath("/resource-planning") ? (
                                    <DateRangeRoundedIcon sx={activeIcon} />
                                ) : (
                                    <DateRangeOutlinedIcon sx={iconStyle} />
                                )}
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{
                                        color: isInPath("/resource-planning")
                                            ? "#004e7c !important"
                                            : "#2e323e",
                                    }}
                                >
                                    {t("ROLE_RESOURCE_PLANNING")}
                                </Typography>
                            </SideBarItem>
                        </Link>
                        <Divider />
                    </Box>
                )}
            {hasModuleAccess(ModuleName.CLIENTS, selectedUserInstitution!, user?.isSuperAdmin) &&
                hasModulePermission(
                    [PermissionName.VIEW_ALL, PermissionName.VIEW_OWN],
                    ModuleName.CLIENTS,
                    selectedUserInstitution!,
                    false
                ) && (
                    <Box>
                        <Link
                            component={RouterLink}
                            to={"/users"}
                            sx={navLinkStyle}
                            data-testid="clients-menu-link"
                        >
                            <SideBarItem
                                key="users"
                                onClick={handleClose}
                                sx={{ marginBottom: 0, padding: "1.2rem 24px" }}
                            >
                                {isInPath("/users") ? (
                                    <GroupsRoundedIcon sx={activeIcon} />
                                ) : (
                                    <GroupsOutlinedIcon sx={iconStyle} />
                                )}
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{
                                        color: isInPath("/users")
                                            ? "#004e7c !important"
                                            : "#2e323e",
                                    }}
                                >
                                    {t("ROLE_CLIENTS")}
                                </Typography>
                            </SideBarItem>
                        </Link>
                        <Divider />
                    </Box>
                )}
            {((hasModuleAccess(ModuleName.SCREENING, selectedUserInstitution!, user?.isSuperAdmin) &&
                hasModulePermission(
                    [PermissionName.VIEW_ALL, PermissionName.VIEW_OWN],
                    ModuleName.SCREENING,
                    selectedUserInstitution!,
                    false
                ))
                ||
                hasRoles(["Client"], selectedUserInstitution?.roles ?? [], false))
                && (
                    <Box>
                        <Link
                            display="none"
                            component={RouterLink}
                            to={"/screening"}
                            sx={navLinkStyle}
                            data-testid="screening-menu-link"
                        >
                            <SideBarItem
                                key="screening"
                                onClick={handleClose}
                                sx={{ marginBottom: 0, padding: "1.2rem 24px" }}
                            >
                                {isInPath("/screening") ? (
                                    <CastConnectedRoundedIcon sx={activeIcon} />
                                ) : (
                                    <CastOutlinedIcon sx={iconStyle} />
                                )}
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{
                                        color: isInPath("/screening")
                                            ? "#004e7c !important"
                                            : "#2e323e",
                                    }}
                                >
                                    {t("ROLE_SCREENING")}
                                </Typography>
                            </SideBarItem>
                        </Link>
                        <Divider />
                    </Box>
                )}
            {hasModuleAccess(ModuleName.DOWNLOAD_NII, selectedUserInstitution!, user?.isSuperAdmin) &&
                hasModulePermission(
                    [PermissionName.RESEARCH_DATA_CSV, PermissionName.ECRF_PDF],
                    ModuleName.DOWNLOAD_NII,
                    selectedUserInstitution!,
                    false
                ) && (
                    <Box>
                        <Link
                            component={RouterLink}
                            to={"/data-export"}
                            sx={navLinkStyle}
                            data-testid="data-export-menu-link"
                        >
                            <SideBarItem
                                key="data-export"
                                onClick={handleClose}
                                sx={{ marginBottom: 0, padding: "1.2rem 24px" }}
                            >
                                {isInPath("/data-export") ? (
                                    <UploadFileRoundedIcon sx={activeIcon} />
                                ) : (
                                    <UploadFileOutlinedIcon sx={iconStyle} />
                                )}
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{
                                        color: isInPath("/data-export")
                                            ? "#004e7c !important"
                                            : "#2e323e",
                                    }}
                                >
                                    {t("NAV_DE")}
                                </Typography>
                            </SideBarItem>
                        </Link>
                        <Divider />
                    </Box>
                )}
            {(user?.isSuperAdmin ||
                selectedTenant?.isAdmin ||
                hasRoles(["Field Coordinator"], selectedUserInstitution?.roles!))
                && <Box>
                    <Link
                        component={RouterLink}
                        to={"/dashboard"}
                        sx={navLinkStyle}
                        data-testid="dashboard-menu-link"
                    >
                        <SideBarItem
                            key="dashboard"
                            onClick={handleClose}
                            sx={{ marginBottom: 0, padding: "1.2rem 24px" }}
                        >
                            {isInPath("/dashboard") ? (
                                <AssessmentRoundedIcon sx={activeIcon} />
                            ) : (
                                <AssessmentOutlinedIcon sx={iconStyle} />
                            )}
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    color: isInPath("/dashboard")
                                        ? "#004e7c !important"
                                        : "#2e323e",
                                }}
                            >
                                {t("NAV_DASHBOARD")}
                            </Typography>
                        </SideBarItem>
                    </Link>
                    <Divider />
                </Box>}

            {hasNotificationLogPermission && (
                <>
                    <LogSelector
                        isSuperAdmin={user?.isSuperAdmin}
                        navLinkStyle={navLinkStyle}
                        iconStyle={iconStyle}
                        activeIcon={activeIcon}
                        onClick={handleClose}

                    />
                    <Divider />
                </>
            )
            }
            <Box >
                <Link
                    component={RouterLink}
                    to="coordination-overview"
                    sx={{
                        ...navLinkStyle,
                        color: isInPath("coordination-overview") ? "#004e7c" : "#2e323e",
                    }}
                    data-testid="study-overview-link"
                >
                    <SideBarItem
                        key="logs"
                        onClick={handleClose}
                        sx={{ marginBottom: 0, padding: "1.2rem 24px" }}
                    >
                        {isInPath("coordination-overview") ? (
                            <AssessmentRoundedIcon sx={activeIcon} />
                        ) : (
                            <AssessmentOutlinedIcon sx={iconStyle} />
                        )}
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                color: isInPath("coordination-overview")
                                    ? "#004e7c !important"
                                    : "#2e323e",
                            }}
                        >
                            {t("COORDINATION_OVERVIEW")}
                        </Typography>
                    </SideBarItem>
                </Link>
                <Divider />
            </Box>
        </Box>
    );
}

export default observer(NavbarOptions);
