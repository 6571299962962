import { makeAutoObservable, observable } from "mobx";

export class LoadingStore {
    loadingState = observable.map({});

    constructor() {
        makeAutoObservable(this);
    }


    setLoadingState = (key: string | Function, value: boolean) => {
        key = this.generateLabel(key) as string;
        this.loadingState.set(key, value);
    }

    startLoading = (key: string | Function) => {
        this.setLoadingState(key, true);
    }


    stopLoading = (key: string | Function) => {
        this.setLoadingState(key, false);
    }

    isLoading = (key: string | Function | string[] | Function[]) => {
        let labels = this.generateLabel(key);
        if (Array.isArray(labels)) {
            return labels.some(label => this.loadingState.get(label) === true);
        } else {
            return this.loadingState.get(labels) || false;
        }
    }

    generateLabel = (key: string | Function | string[] | Function[]) => {
        if (Array.isArray(key)) {
            return key.map(item => typeof item === 'function' ? item.name : item);
        } else {
            return typeof key === 'function' ? key.name : key;
        }
    };
}