import { List, ListItem, ListItemProps, styled } from "@mui/material";

interface OptionItemProps extends ListItemProps {
    key: string;
    selected?: boolean;
}

export const SideBar = styled(List)({
    width: '100%',
    maxWidth: 240,
    margin: 0,
    padding: 0,
});

export const SideBarItem = styled(ListItem)<OptionItemProps>(({ theme, selected }) => ({
    borderRadius: 4,
    marginBottom: theme.spacing(1),
    '&:hover': {
        backgroundColor: 'rgba(3, 178, 255, 0.08)',
        cursor: 'pointer',
    },
    borderRight: 0,
    ...(selected && {
        backgroundColor: "#f1f4f9 !important",
        borderBottom: "2px solid #004E7C !important",
        color: "#004E7C",
    }),
}));
