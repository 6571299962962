import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
} from "@mui/material";
import "./styles.css";
import { surveyLanguages } from "../../data/static/survey";
import { LanguageStats } from "../../data/models/stats";
import { capitalizeFirstLetter } from "../../utils/utils";
import { useTranslation } from "react-i18next";

interface LanguageTableProps {
    data: LanguageStats[];
    title?: string;
}

function LanguageTable({ data, title }: LanguageTableProps) {
    const { t } = useTranslation();
    if (data.length === 0)
        return (
            <>
                {title && (
                    <Typography component={"h2"} variant="h6">
                        {title}
                    </Typography>
                )}
                <Box ml={1} fontSize={"11px"}>
                    {t("GENERAL_NO_RESULT")}
                </Box>
            </>
        );

    const cities = Array.from(
        new Set(
            data
                .filter((result) => result.type === "city")
                .map((result) => result.location)
        )
    );
    const accomodations = Array.from(
        new Set(
            data
                .filter((result) => result.type === "accomodation")
                .map((result) => result.location)
        )
    );

    return (
        <TableContainer component={Paper} sx={{ bgcolor: "#fff" }}>
            {title && (
                <Box sx={{ p: 1 }}>
                    <Typography component={"h2"} variant="h6">
                        {title}
                    </Typography>
                </Box>
            )}
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        {surveyLanguages.map((lang) => (
                            <TableCell key={lang.code}>{lang.name}</TableCell>
                        ))}
                        <TableCell sx={{ textAlign: "center" }}>Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell className="sticky-cell">City</TableCell>
                        {Array.from({ length: surveyLanguages.length + 1 }).map(
                            (_, index) => (
                                <TableCell key={index} sx={{ textAlign: "center", bgcolor: "#ffc10726" }}>
                                    -
                                </TableCell>
                            )
                        )}
                    </TableRow>
                    {cities.map((city) => (
                        <TableRow key={city}>
                            <TableCell
                                className="sticky-cell"
                                sx={{ fontStyle: city ? "normal" : "italic" }}
                            >
                                {city ?? "Unspecified"}
                            </TableCell>
                            {surveyLanguages.map((lang) => {
                                const filteredResult = data.find(
                                    (d) =>
                                        d.location === city &&
                                        lang.code === d.language &&
                                        d.type === "city"
                                );
                                return (
                                    <TableCell
                                        key={`${lang.code}-${city}`}
                                        sx={{
                                            bgcolor:
                                                filteredResult && filteredResult.total !== 0
                                                    ? "#03b2ff17"
                                                    : "#fff",
                                        }}
                                    >
                                        {filteredResult?.total || 0}
                                    </TableCell>
                                );
                            })}
                            <TableCell sx={{ textAlign: "center", fontWeight: 600, bgcolor: "#ffc10726" }}>
                                {data
                                    .filter(
                                        (result) =>
                                            result.location === city && result.type === "city"
                                    )
                                    .reduce((acc, result) => {
                                        return acc + result.total;
                                    }, 0)}
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell className="sticky-cell">Accomodation</TableCell>
                        {Array.from({ length: surveyLanguages.length + 1 }).map(
                            (_, index) => (
                                <TableCell key={index} sx={{ textAlign: "center", bgcolor: "#ffc10726" }}>
                                    -
                                </TableCell>
                            )
                        )}
                    </TableRow>
                    {accomodations.map((acc) => (
                        <TableRow key={acc}>
                            <TableCell
                                className="sticky-cell"
                                sx={{ fontStyle: acc ? "normal" : "italic" }}
                            >
                                {acc ? capitalizeFirstLetter(acc) : "Unspecified"}
                            </TableCell>
                            {surveyLanguages.map((lang) => {
                                const filteredResult = data.find(
                                    (d) =>
                                        d.location === acc &&
                                        lang.code === d.language &&
                                        d.type === "accomodation"
                                );
                                return (
                                    <TableCell
                                        key={`${lang.code}-${acc}`}
                                        sx={{
                                            bgcolor:
                                                filteredResult && filteredResult.total !== 0
                                                    ? "#03b2ff17"
                                                    : "#fff",
                                        }}
                                    >
                                        {filteredResult?.total || 0}
                                    </TableCell>
                                );
                            })}
                            <TableCell sx={{ textAlign: "center", fontWeight: 600, bgcolor: "#ffc10726" }}>
                                {data
                                    .filter(
                                        (result) =>
                                            result.location === acc && result.type === "accomodation"
                                    )
                                    .reduce((acc, result) => {
                                        return acc + result.total;
                                    }, 0)}
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell sx={{ fontWeight: 600 }}>{t("CARD_TOTAL")}</TableCell>
                        {surveyLanguages.map((lang) => {
                            return (
                                <TableCell
                                    key={`${lang.code} total`}
                                    sx={{
                                        bgcolor: "#ffc10726",
                                        fontWeight: 600,
                                        textAlign: "center"
                                    }}
                                >
                                    {data
                                        .filter(res => res.language === lang.code)
                                        .map(res => res.userIds)
                                        .flat()
                                        .filter((value, index, self) => self.indexOf(value) === index)
                                        .length}
                                </TableCell>
                            );
                        })}
                        <TableCell
                            sx={{
                                bgcolor: "#ffc10726",
                                textAlign: "center",
                                fontWeight: 600
                            }}
                        >
                            {data
                                .map(res => res.userIds)
                                .flat()
                                .filter((value, index, self) => self.indexOf(value) === index)
                                .length}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default LanguageTable;
