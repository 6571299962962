import { Button, Container, Typography } from "@mui/material";
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';

import "./styles.css";

interface ErrorBannerProps {
    reloadAnswers: () => void;
}

function ErrorBanner({ reloadAnswers }: ErrorBannerProps) {
    return (
        <Container maxWidth="md" className="banner-container">
            <Typography className="banner-text">Unable to save your answer!</Typography>
            <Button variant="text" className="reload-answers-button" onClick={reloadAnswers}>
                Try again
                <ReplayRoundedIcon sx={{ fontSize: "1rem" }} />
            </Button>
        </Container>
    );
}

export default ErrorBanner;