import { Checkbox, FormControl, FormControlLabel, Typography } from "@mui/material";

import { InputHTMLAttributes, useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { QuestionProps } from "./QuestionProps";
import { AnswerSubmit, Question, QuestionOption } from "../../../../data/models/survey";
import { useStore } from "../../../../stores/store";
import { useLocation, useParams } from "react-router-dom";
import { FormInputText } from "../../../../components/form/FormInputText";
import { SurveyContext } from "../Form/SurveyInitializer";

type Orientation = "row" | "column";

interface RadioInputProps extends QuestionProps {
    options: QuestionOption[];
    orientation?: Orientation;
}

export function CheckboxInput({
    options,
    orientation = "column",
    required,
    control,
    id,
    preview,
    disabled
}: RadioInputProps) {
    const { surveyStore: { getAnswer, setAnswer, submitAnswer } } = useStore();
    const [selectedAnswers, setSelectedAnswers] = useState<any>([]);

    const { sessionId, surveyId, timelineId } = useParams();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    useEffect(() => {
        const selectedOptions = options.filter(o => o.isSelected);
        setSelectedAnswers(selectedOptions.map(opt => opt.id));
    }, [options]);

    const handleSelect = (value: any) => {

        const optionIndex = selectedAnswers.indexOf(value);

        let isSelected: boolean;
        if (optionIndex !== -1) {
            const remaining = selectedAnswers.filter((item: any) => item !== value);
            setSelectedAnswers(remaining);
            isSelected = false;
        } else {
            setSelectedAnswers((prevItems: any) => [...prevItems, value]);
            isSelected = true;
        }

        if (preview) return;

        const answer: AnswerSubmit = {
            questionId: id,
            optionId: value,
            surveyId: surveyId!,
            timelineId: timelineId!,
            sessionId: sessionId!,
            selected: isSelected,
        }

        submitAnswer(answer, token ?? undefined);
        setAnswer(id, { answer: selectedAnswers });
    };

    return (
        <FormControl size={"small"} variant={"outlined"}>
            <div style={{ display: "flex", flexDirection: orientation, flexWrap: 'wrap' }}>
                {options.map((option, index) => (
                    <FormControlLabel
                        key={option.id}
                        sx={{
                            marginLeft: "14px !important",
                            cursor: disabled || preview ? "default !important" : "pointer",
                        }}
                        control={
                            <Controller
                                name={`question_${id}.answer.option_${index + 1}`}
                                control={control}
                                defaultValue={null}
                                render={({
                                    field: { onChange, value = getAnswer(id)?.answer ?? null },
                                }) => (
                                    <Checkbox
                                        checked={selectedAnswers.includes(option.id)}
                                        onChange={(e) => {
                                            onChange(e);
                                            handleSelect(option.id);
                                        }}
                                        disabled={disabled}
                                        style={{
                                            color: disabled ? "#555555a8 !important" : "#03b2ff",
                                        }}
                                        inputProps={
                                            {
                                                'data-testid': `question_${id}.${option.id}`,
                                            } as InputHTMLAttributes<HTMLInputElement>
                                        }
                                        size="small"
                                    />
                                )}
                            />
                        }
                        label={<Typography fontSize="14px">{option.title}</Typography>}
                    />
                ))}
            </div>
        </FormControl>
    );
}

export function CheckboxCreator({
    options,
    orientation = "column",
    control,
    id,
    preview,
    disabled
}: RadioInputProps) {
    const { baseSurvey } = useContext(SurveyContext);
    const [question, setQuestion] = useState<Question>();

    useEffect(() => {
        if (baseSurvey) {
            baseSurvey.pages.forEach(page => {
                const currentQuestion = page.questions.find(q => q.id === id);
                if (currentQuestion) {
                    setQuestion(currentQuestion);
                }
            })
        }
    }, [baseSurvey, id]);

    return (
        <FormControl size={"small"} variant={"outlined"} sx={{ mb: 3 }}>
            <div style={{ display: "flex", flexDirection: orientation, flexWrap: 'wrap' }}>
                {options.map((option) => (
                    <FormControlLabel
                        key={option.id}
                        sx={{
                            marginLeft: "14px !important",
                            cursor: disabled || preview ? "default !important" : "pointer",
                        }}
                        control={
                            <Checkbox
                                checked={false}
                                disabled={true}
                                style={{
                                    color: "#555555a8 !important"
                                }}
                                size="small"
                            />
                        }
                        label={<FormInputText
                            name={`questions.${id}.options.${option.id}.title`}
                            control={control}
                            initValue={option.title}
                            helperText={question?.options?.find(o => o.id === option.id)
                                ?.title ?? ""}
                        />}
                    />
                ))}
            </div>
        </FormControl>
    );
}

export function CheckboxCreatorInput() {
    return (
        <div>CheckboxCreator</div>
    );
}
