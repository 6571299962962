import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";

import React from "react";
import { Controller } from "react-hook-form";

import { FormInputProps } from "./FormInputProps";

type Orientation = "row" | "column";
interface RadioProps extends FormInputProps {
    options: {
        label: string;
        value: any; // TODO: Change to generic type
    }[];
    orientation?: Orientation;
    defaultValue?: boolean;
}

export const FormInputRadio: React.FC<RadioProps> = ({
    name,
    control,
    label,
    options,
    orientation = "row",
    defaultValue = false
}) => {
    const generateRadioOptions = () => {
        return options.map((singleOption) => (
            <FormControlLabel
                key={singleOption.value}
                value={singleOption.value}
                label={<Typography sx={{ fontSize: '14px' }}>{singleOption.label}</Typography>}
                control={<Radio size="small" />}
            />
        ));
    };

    return (
        <FormControl component="fieldset">
            <FormLabel>{label}</FormLabel>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({
                    field: { onChange, value },
                    fieldState: { error },
                }) => (
                    <>
                        <RadioGroup sx={{ flexDirection: orientation, fontSize: '14px' }} defaultValue={options[0].value || null} value={value} onChange={onChange} >
                            {generateRadioOptions()}
                        </RadioGroup>
                        {error && <Typography fontSize={11} color="error">{error.message}</Typography>}
                    </>
                )}
            />
        </FormControl>
    );
};
