import * as Yup from 'yup';

export const passwordFormSchema = () => {
    const schema = Yup.object().shape({
        currentPassword: Yup.string().required('Current password is required').trim(),
        newPassword: Yup.string()
            .min(12, 'Password must be at least 12 characters long')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(
                /[!@#$%^&*(),.?":{}|<>]/,
                'Password must contain at least one special character'
            )
            .matches(/^\S+$/, 'Password cannot contain spaces at the beginning or the end')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword')], 'Passwords must match')
            .required('Confirm password is required'),
    });

    return schema;
}