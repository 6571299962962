import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

const WeekHeader = ({ date }: any) => {
    const day = dayjs(date);
    const dayNumberStyle = {
        fontSize: '24px',
        color: '#616161'
    };

    const dayNameStyle = {
        fontSize: '14px',
        marginLeft: '9px',
        color: '#616161'
    };

    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
            <Typography sx={dayNumberStyle}>{day.format('D')}</Typography>
            <Typography sx={dayNameStyle}> {day.format('ddd')}</Typography>
        </Box>
    );
};

export default WeekHeader;