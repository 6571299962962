import { Box, Fade, Popper } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ReactNode, useEffect } from "react";

interface Props {
    open: boolean;
    anchorElement: null | HTMLElement;
    children: ReactNode,
    close: () => void;
}

const popperModifiers = [
    {
        name: "flip",
        enabled: true,
        options: {
            altBoundary: true,
            rootBoundary: "document",
            padding: 8,
        },
    },
    {
        name: "preventOverflow",
        enabled: true,
        options: {
            altAxis: true,
            altBoundary: false,
            tether: true,
            rootBoundary: "viewport",
            padding: 8,
        },
    },
];

export default function CustomPopper({
    open = false,
    anchorElement = null,
    children,
    close
}: Props) {

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            const popperElement = document.querySelector("#popper-anchor");
            const menuActions = document.querySelector("#event-menu-actions");

            if (
                popperElement &&
                !popperElement.contains(event.target as Node) &&
                !menuActions
            ) {
                close();
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Popper
                id="popper-anchor"
                transition
                placement="bottom-start"
                disablePortal={true}
                modifiers={popperModifiers}
                open={open}
                anchorEl={anchorElement}
                className="event-popper"
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box
                            sx={{
                                p: 2, // Increased padding for better spacing
                                backgroundColor: "#ffffff",
                                display: "flex",
                                flexDirection: "column",
                                width: 350,
                                borderRadius: "8px", // Adding rounded corners
                                border: "1px solid #ddd", // Subtle border for separation
                                zIndex: 9999, // Ensure the popper appears above other elements
                                boxShadow: "0px 10px 24px rgba(0, 0, 0, 0.5)"
                            }}
                        >
                            {children}
                        </Box>
                    </Fade>
                )}
            </Popper>
        </>
    )
}