import { Controller } from "react-hook-form";
import { QuestionProps } from "./QuestionProps";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useState } from "react";

function SurveyDropdown(props: QuestionProps) {
    const [value, setValue] = useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value as string);
    };

    return (
        <Controller
            control={props.control}
            name={`${props.id}.response`}
            render={({ field: { ref, onBlur, name, ...field }, fieldState: { error } }) => (
                <Box width={"150px"}>
                    <Select
                        label=""
                        value={value}
                        onChange={handleChange}
                        disabled={props.disabled || props.preview}
                    >
                        {props.options?.map(o => (
                            <MenuItem value={o.id} key={o.id}>{o.title}</MenuItem>
                        ))}
                    </Select>
                </Box>
            )}
        />
    );
}

export default SurveyDropdown;