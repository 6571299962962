import { Box } from '@mui/material';
import { TFunction } from 'i18next';
import { TableHeader } from '../../../../_styles/table/TableHeader';
import { TableText } from '../../../../_styles/table/TableText';
import { ClientSessionTimelineOverviewItem, SessionCategory } from '../../../../data/models/client';
import { calculateDifferenceInWeeks } from '../../../../utils/utils';
import { getStatusChip } from '../../../Screening/Process/Session/bookingStatusChip';

export const getBrightTimeLineTableColumns = (sessionStartDate: Date, t: TFunction) => {
    let lastDisplayedWeek: number | null = null;

    const columns = [
        {
            header: (
                <TableHeader fontSize={"13px !important"}>{t("USER_RESPONSIBLE_PEOPLE")}</TableHeader>
            ),
            key: "responsiblePeopleNames",
            render: (row: ClientSessionTimelineOverviewItem) => (
                <TableText>{row.responsiblePeopleNames.join(", ")}</TableText>
            ),
            className: "process-cell",
        },
        {
            header: (
                <TableHeader fontSize={"13px !important"}>{t("EVENT_WEEK")}</TableHeader>
            ),
            key: "week",
            render: (row: ClientSessionTimelineOverviewItem) => {
                const currentWeek = calculateDifferenceInWeeks(sessionStartDate, row.date);
                return <TableText>{` Week ${currentWeek}`}</TableText>;

            },
            className: "process-cell"
        },
        {
            header: (
                <TableHeader fontSize={"13px !important"}>{t("ROLE_SCREENING")}</TableHeader>
            ),
            key: "screening",
            render: (row: ClientSessionTimelineOverviewItem) => {
                return row.category === SessionCategory.SCREENING ? (
                    <Box>
                        {getStatusChip(row, t)}
                    </Box>
                ) : null;
            },
            className: "process-cell",
        },
        {
            header: (
                <TableHeader fontSize={"13px !important"}>{t("PM")}</TableHeader>
            ),
            key: "pm",
            render: (row: ClientSessionTimelineOverviewItem) => {
                return row.category === SessionCategory.PM ? (
                    <Box>
                        {getStatusChip(row, t)}
                    </Box>
                ) : null;
            },
            className: "process-cell",
        },
        {
            header: (
                <TableHeader fontSize={"13px !important"}>{t("BOOSTER")}</TableHeader>
            ),
            key: "booster",
            render: (row: ClientSessionTimelineOverviewItem) => {
                return row.category === SessionCategory.BOOSTER ? (
                    <Box>
                        {getStatusChip(row, t)}
                    </Box>
                ) : null;
            },
            className: "process-cell",
        },
        {
            header: (
                <TableHeader fontSize={"13px !important"}>{t("ASSESSMENTS")}</TableHeader>
            ),
            key: "assessments",
            render: (row: ClientSessionTimelineOverviewItem) => {
                return row.category === SessionCategory.ASSESSMENT ? (
                    <Box>
                        {getStatusChip(row, t)}
                    </Box>
                ) : null;
            },
            className: "process-cell",
        },
    ];

    return columns
}