import * as Yup from 'yup';

export const roomFormSchema = () => {
    const schema = Yup.object().shape({
        resourceType: Yup.string().required('Resource type is required').trim(),
        name: Yup.string().required('Name is required').trim(),
        capacity: Yup.number().min(1, "Capacity should be greater than 1").required('Capacity is required'),
        institutionId: Yup.string().required('Institution is required').trim(),
        notes: Yup.string().trim(),
        city: Yup.string().required("City is required"),
        street: Yup.string().required("Street is required"),
        number: Yup.string().required("Number is required"),
        zipCode: Yup.string().required("Zip code is required"),
    });

    return schema;
}

export const roomUpdateFormSchema = () => {
    const schema = Yup.object().shape({
        resourceType: Yup.string().required('Resource type is required').trim(),
        name: Yup.string().trim().required('Name is required'),
        capacity: Yup.number().min(1, "Capacity should be greater than 1").required('Capacity is required'),
        notes: Yup.string().trim(),
        city: Yup.string().required("City is required"),
        street: Yup.string().required("Street is required"),
        number: Yup.string().required("Number is required"),
        zipCode: Yup.string().required("Zip code is required"),
    });

    return schema;
}