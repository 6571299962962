import { Divider, Grid } from "@mui/material";
import ChangePasswordForm from "./ChangePasswordForm";
import TwoFactorAuthDashboard from "./TwoFactorAuthDashboard";

function UserSecurity() {
    return (
        <Grid container>
            <Grid item xs={12} md={10} lg={8} marginBottom={2}>
                <ChangePasswordForm />
            </Grid>
            {/* <Grid item xs={12} md={8} lg={6} marginBottom={2}>
                <Divider />
            </Grid>
            <Grid item xs={12} md={10} lg={8} marginLeft={2} marginBottom={8}>
                <TwoFactorAuthDashboard />
            </Grid> */}
        </Grid>
    );
}

export default UserSecurity;