import { RRule, rrulestr } from "rrule";
import { Event, EventDetailsDto, Recurrence } from "../data/models/event";
import { recurrenceWeekDays } from "../data/static/weekDays";
import { Dayjs } from "dayjs";
import { combineDateAndTime } from "./utils";

export const assignRecurrence = (calendarEvent: Event): Recurrence => {
    if (calendarEvent.frequency === 9) return { type: 9 };

    const recurrence: Recurrence = {
        type: calendarEvent.frequency,
        startDate: getRecurrenceStartDate(calendarEvent.start, calendarEvent.startTime, calendarEvent.isAllDay),
        endDate: getRecurrenceEndDate(calendarEvent.recurrence?.endDate!, calendarEvent.endTime, calendarEvent.isAllDay),
        interval: calendarEvent.recurrence.interval,
        byDays: calendarEvent.frequency === 2 ? calendarEvent.recurrence.byDays : undefined,
        byMonthDay: calendarEvent.frequency === 1 ? calendarEvent.recurrence.byMonthDay : undefined,
    };

    return recurrence;
}

const getRecurrenceStartDate = (startDate: Date, startTime: Dayjs, allDay: boolean): Date => {
    const eventStartTime = new Date(startTime.toString());
    return allDay ?
        new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
        :
        combineDateAndTime(startDate, eventStartTime)
}

const getRecurrenceEndDate = (endDate: Date, endTime: Dayjs, allDay: boolean): Date => {
    const eventEndTime = new Date(endTime.toString());
    return allDay ?
        endDate
        :
        combineDateAndTime(endDate, eventEndTime)
}

export const parseRrule = (rrule: string, start: Date, end: Date): Recurrence => {
    if (rrule === 'no-repeat') return { type: 9 } as Recurrence;
    const pattern = RRule.fromString(rrule);
    const recurrence: Recurrence = {
        type: pattern.options.freq,
        startDate: start,
        endDate: combineDateAndTime(pattern.options.until!, end),
        interval: pattern.options.interval,
    }
    recurrence.byMonthDay = pattern.options.bymonthday.length > 0 ? pattern.options.bymonthday[0] : null;
    recurrence.byDays = (pattern.options.byweekday && getRecurrenceWeekDays(rrule)) || null;

    return recurrence;
}

export const getRRuleEditStartDate = (event: EventDetailsDto): Date | null => {
    if (event.recurrence === 'no-repeat') return event.start;
    const rule = rrulestr(event.recurrence as string);
    const date = rule.after(new Date(), true);
    // The date of occurence is parsed incorrectly by rrule when is monthly
    // needs further checks whether the rrule string is saved in wrong format 
    // or there is something wrong with rrule
    if (rule.options.freq === 1 && event.isAllDay) date?.setDate(date.getDate() - 1);

    return new Date(date?.toString()!);
}

export const getRRuleEditEndDate = (event: EventDetailsDto): Date | null => {
    if (event.recurrence === 'no-repeat') return event.end;
    const start = getRRuleEditStartDate(event);
    const duration = new Date(event.end).getTime() - new Date(event.start).getTime();

    return (start && new Date(start.getTime() + duration));
}

export const getFrequencyNumber = (rrule: string): number | null => {
    if (rrule === 'no-repeat') return null;
    const match = rrule.match(/FREQ=([^;]+)/);

    if (match) {
        const frequency = match[1];
        if (frequency === 'DAILY') {
            return 3;
        } else if (frequency === 'MONTHLY') {
            return 1;
        } else if (frequency === 'WEEKLY') {
            return 2;
        }
    }
    return 9;
}

export const getRecurrenceWeekDays = (rrule: string): string[] | null => {
    if (rrule === 'no-repeat' || !rrule) return null;
    const pattern = rrulestr(rrule);

    if (pattern.options.freq === 2) {
        return pattern.options.byweekday
            .map((day: number) => recurrenceWeekDays[day].tag);
    }

    return null;
}

export const getRecurrenceMonthDay = (rrule: string): number | undefined => {
    if (rrule === 'no-repeat' || !rrule) return undefined;
    const pattern = rrulestr(rrule);

    if (pattern.options.bymonthday.length > 0) {
        return pattern.options.bymonthday[0];
    }

    return undefined;
}

export const getRecurrenceInterval = (rrule: string): number | undefined => {
    if (rrule === 'no-repeat' || !rrule) return undefined;
    const pattern = rrulestr(rrule);

    if (pattern?.options?.interval) {
        return pattern.options.interval;
    }

    return undefined;
} 