import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import EventNoteIcon from '@mui/icons-material/EventNote';
import LibraryAddCheckRoundedIcon from '@mui/icons-material/LibraryAddCheckRounded';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Chip, darken, lighten, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { ClientSessionTimelineOverviewStatus } from "../../../../data/models/client";
import i18n from "../../../../data/translations/i18n";
import { getDateFormatByPlatformLanguage } from "../../../../utils/utils";

export function getStatusChip(item: any, t: TFunction<"translation", undefined>): JSX.Element {
    const { icon, color } = getStatusProps(item.status);
    const date = getDateFormatByPlatformLanguage(new Date(item.date), i18n.language);

    return (
        <Chip
            icon={icon}
            label={
                <>
                    <Typography
                        fontSize={12}
                        fontWeight={600}
                        sx={{ color: "#333", lineHeight: 1.3 }}
                    >
                        {item.title}
                    </Typography>
                    <Typography
                        fontSize={12}
                        fontWeight={500}
                        sx={{ color: "#777", display: "block", lineHeight: 1.1 }}
                    >
                        {`${date}`} - {t(`${item.status}`)}

                    </Typography>

                </>
            }
            sx={{
                bgcolor: color,
                height: "40px",
                px: 1,
                color: "#000",
            }}
        />
    );
}

export function getStatusProps(status: ClientSessionTimelineOverviewStatus): { icon: JSX.Element; color: string } {
    switch (status) {
        case ClientSessionTimelineOverviewStatus.COMPLETED:
            return {
                icon: <LibraryAddCheckRoundedIcon sx={{ fontSize: "18px !important ", color: `#588157 !important` }} />,
                color: lighten("#588157", 0.8),
            };
        case ClientSessionTimelineOverviewStatus.IN_PROGRESS:
            return {
                icon: <HourglassEmptyIcon sx={{ fontSize: "18px !important ", color: `${darken("#ffd500", 0.3)}!important` }} />,
                color: lighten("#ffd500", 0.5),
            };
        case ClientSessionTimelineOverviewStatus.PLANNED:
            return {
                icon: <EventNoteIcon sx={{ fontSize: "18px !important ", color: `  ${darken("#87CEEB", 0.4)}!important` }} />,
                color: lighten("#87CEEB", 0.3),
            };
        case ClientSessionTimelineOverviewStatus.NO_SHOW_UP:
            return {
                icon: <PersonOffRoundedIcon sx={{ fontSize: "18px !important", color: ` #354f52 !important` }} />,
                color: lighten("#354f52", 0.8)
            }
        case ClientSessionTimelineOverviewStatus.OVERDUE:
            return {
                icon: <AssignmentLateIcon sx={{ fontSize: "18px !important ", color: `  ${lighten("#FF0000", 0.3)}!important` }} />,
                color: lighten("#FF0000", 0.8),
            };
        case ClientSessionTimelineOverviewStatus.PROJECTED:
            return {
                icon: <TimelineIcon sx={{ fontSize: "18px !important ", color: `  ${darken("#B19CD9", 0.3)}!important` }} />,
                color: lighten("#B19CD9", 0.7),
            };
        default:
            return {
                icon: <></>,
                color: "#ffffff",
            };
    }
}