import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
    stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { IconButton, Typography } from "@mui/material";

import "./styles.css";

const Connector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 30,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#03b2ff",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#03b2ff",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
        borderRadius: 1,
    },
}));

const StepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
        backgroundColor: "#0077b6",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
        backgroundColor: "#03b2ff",
    }),
}));

interface IconProps {
    id: string;
}

function StepIcon(props: StepIconProps & StepperProps & IconProps) {
    const { active, completed, className } = props;

    return (
        <IconButton
            disabled={!active && !completed}
            onClick={() => props.onProcessChange(props.id)}
            sx={{
                backgroundColor: props.id === props.selectedProcess ? "#023e8a1c" : "transparent",
                borderRadius: "2rem"
            }}
        >
            <StepIconRoot
                ownerState={{ completed, active }}
                sx={{
                    backgroundColor:
                        props.id === props.selectedProcess ? "#0077b6" : !active && !completed ? "#ccc" : "#03b2ff",
                }}
                className={className}
            >
                <InsertDriveFileOutlinedIcon />
            </StepIconRoot>
        </IconButton >
    );
}

interface StepperProps {
    onProcessChange: (pId: string) => void;
    selectedProcess: string | null;
}

interface ProcessStepperProps extends StepperProps {
    steps: {
        id: string;
        name: string;
    }[];
}

export default function ProcessStepper({
    onProcessChange,
    selectedProcess,
    steps
}: ProcessStepperProps) {
    return (
        <Stepper alternativeLabel activeStep={steps.length} connector={<Connector />} className="process-stepper">
            {steps.map((step) => (
                <Step key={step.id}>
                    <StepLabel
                        StepIconComponent={(stepIconProps: StepIconProps) => (
                            <StepIcon
                                onProcessChange={onProcessChange}
                                id={step.id}
                                selectedProcess={selectedProcess}
                                {...stepIconProps}
                            />
                        )}
                    >
                        <Typography fontSize="13px" sx={{ mt: "-16px" }}>
                            {step.name}
                        </Typography>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}
