import { Box, Button, CircularProgress, Menu, SxProps } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

import { observer } from "mobx-react-lite";
import { useState } from "react";

import { useStore } from "../../../stores/store";
import "../styles.css";
import { useTranslation } from "react-i18next";
import { StyledMenuItem } from "../../../_styles/StyledMenu";
import { UserStatus } from "../../../data/models/user";
import { toPascalCase } from "../../../components/UserStatusChip";

const container: SxProps = {
    display: "flex",
};

export interface FilterProps {
    onFilterChange: (newState: string) => void;
}

function UserStatusFilter({ onFilterChange }: FilterProps) {
    const { t } = useTranslation();
    const {
        clientStore: { clientRoleList, getClientRoles },
        loadingStore: { isLoading }
    } = useStore();
    const [filterId, setFilterId] = useState<UserStatus>(UserStatus.ACTIVE);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getStatusOptions = () => {
        return [UserStatus.ACTIVE, UserStatus.DEACTIVATED, UserStatus.ANON]
    };

    function handleStatusChange(status: UserStatus): void {
        setFilterId(status);
        onFilterChange(status.toString());
        handleClose();
    }

    return (
        <Box sx={container}>
            <Button
                onClick={handleClick}
                className="filter-button"
                sx={{
                    color: anchorEl ? "#03b2ff" : "#808080",
                    bgcolor: anchorEl ? "#03b2ff0a" : "transparent",
                }}
            >
                <Box display={"flex"} alignItems={"center"} sx={{ fontWeight: 500 }}>
                    {toPascalCase(filterId)}
                    <CircleRoundedIcon sx={{ fontSize: "6px", mt: "3px", ml: "2px", "#03b2ff" : "inherit" }} />
                </Box>
                {anchorEl ? (
                    <KeyboardArrowUpIcon sx={{ fontSize: "16px", pt: "3px" }} />
                ) : (
                    <KeyboardArrowDownIcon sx={{ fontSize: "16px", pt: "3px" }} />
                )}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                transitionDuration={0}
                onClose={handleClose}
            >

                {isLoading(getClientRoles) ?
                    <StyledMenuItem disabled sx={{ width: 100, display: "flex", justifyContent: "center" }}>
                        <CircularProgress size={18} />
                    </StyledMenuItem>
                    :
                    getStatusOptions().map((status) => (
                        <StyledMenuItem
                            key={status}
                            value={status}
                            sx={{ fontSize: "13px" }}
                            disableTouchRipple
                            selected={status === filterId}
                            onClick={() => handleStatusChange(status)}
                        >
                            {toPascalCase(status)}
                        </StyledMenuItem>
                    ))}
            </Menu>
        </Box>
    );
}

export default observer(UserStatusFilter);
