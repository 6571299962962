import { Box, Typography } from "@mui/material";

import { observer } from "mobx-react-lite";

import { RoundButton } from "../../../../../_styles/StyledButtons";
import { useStore } from "../../../../../stores/store";
import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";

interface ExcludeClientFormProps {
    sessionId: string;
}

function ExcludeClientForm({ sessionId }: ExcludeClientFormProps) {
    const { t } = useTranslation();
    const { sessionStore, modalStore } = useStore();
    const { excludeClient, loadAssessorSession } = sessionStore;
    const { closeModal } = modalStore;
    const [loading, setLoading] = useState(false);

    const onExclusion = async (exclude: boolean) => {
        setLoading(true);
        try {
            await excludeClient(sessionId, exclude);
            await loadAssessorSession(sessionId);
            closeModal();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    }

    return (
        <Box>
            <Box>
                <Box sx={{ borderBottom: "1px solid #ddd", mb: 1, pb: 1 }}>
                    <Typography fontSize={18} fontWeight={600}>Exclude from study</Typography>
                </Box>
                <Typography fontSize={14}><Trans i18nKey={"EXCLUDE_TITLE"} /></Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="center" mt={4}>
                <RoundButton color="info" variant="text" sx={{ mr: 2 }} disabled={loading} onClick={() => onExclusion(false)}>
                    {t("GENERAL_NO")}
                </RoundButton>
                <RoundButton color="error" variant="text" disabled={loading} onClick={() => onExclusion(true)}>
                    {t("GENERAL_YES")}
                </RoundButton>
            </Box>
        </Box>
    );
}

export default observer(ExcludeClientForm);