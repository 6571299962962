import { CircularProgress, Divider, Box, Grid, Typography } from "@mui/material";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormInputText } from "../../../components/form/FormInputText";
import { RoundButton } from "../../../_styles/StyledButtons";
import { InstitutionDetailsDto, InstitutionFormValues } from "../../../data/models/institution";
import { useStore } from "../../../stores/store";
import { institutionFormSchema } from "../../../_validators/schemas/institutionForm.schema";
import { useEffect } from "react";
import FormInputMultiSelect from "../../../components/form/FormInputMultiSelect";
import { getDefaultValues, getUserRoles } from "../../../utils/helpers/institutionHelper";
import FormInputSelect from "../../../components/form/FormInputSelect";
import { countries } from "../../../data/static/countries";
import { useTranslation } from "react-i18next";

interface InstitutionRegisterFormProps {
    institution?: InstitutionDetailsDto;
}

const cantonOptions = [
    { label: 'Spirit', value: 'SPIRIT' },
    { label: 'Bright', value: 'BRIGHT' },
]

function InstitutionRegisterForm({ institution }: InstitutionRegisterFormProps) {
    const { t } = useTranslation();
    const {
        institutionStore: { createInstitution, getInstitutions, loading, getInstitutionUsers, institutionUserList, updateInstitution },
        tenantStore: { userSettingsTenant },
        roleStore: { getRoles, roleList },
        sidebarStore: { closeSidebar },
        profileStore: { getUserTenants }
    } = useStore();
    const { handleSubmit, control } = useForm<InstitutionFormValues>({
        resolver: yupResolver(institutionFormSchema()),
        defaultValues: institution
    });
    const editMode = institution !== undefined;

    useEffect(() => {
        const fetchData = async () => {
            if (userSettingsTenant) {
                await getInstitutionUsers(userSettingsTenant.id);
                await getRoles(userSettingsTenant.id);
            }
        };

        fetchData();
    }, [getInstitutionUsers, getRoles, userSettingsTenant]);

    const onSubmit = async (data: InstitutionFormValues) => {
        const userInstitutionRoles = getUserRoles(data, institutionUserList.filter(u => !u.isTenantAdmin), roleList);
        const hasZeroRoleIds = userInstitutionRoles.some((userRole) => {
            if (userRole.roleIds.length === 0) {
                const email = institutionUserList.find(i => i.id === userRole.userId)?.email;
                toast.error(t("TOAST_INSTITUTION_SELECT_ROLE", { email: email }));
                return true;
            }
            return false;
        });
        if (hasZeroRoleIds) {
            return;
        }

        if (userInstitutionRoles.some((institutionRole) => institutionRole.roleIds.length > 1)) {
            const hasMultipleRestrictedRoles = roleList.some((role) => {
                if (role.name === 'Researcher' || role.name === 'Contact Manager' || role.name === 'Client') {
                    return userInstitutionRoles.some((institutionRole) => {
                        return institutionRole.roleIds.length > 1 && institutionRole.roleIds.includes(role.id)
                    });
                }

                return false;
            })

            if (hasMultipleRestrictedRoles) {
                toast.error(t("REQ_ERROR_MULTIPLE_RESTRICTED_ROLE"));
                return;
            }
        }

        if (userSettingsTenant) {
            data.tenantId = userSettingsTenant.id;
        }
        data.id = institution?.id;
        data.assignedUsers = userInstitutionRoles;

        const saveAction = editMode ? updateInstitution : createInstitution;

        await saveAction(data);
        closeSidebar();
        toast.success(editMode ? t("TOAST_INSTITUTION_UPDATE") : t("TOAST_INSTITUTION_CREATE"));
        await getInstitutions(data.tenantId)
        await getUserTenants();
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ display: 'flex', flexDirection: 'column', mb: "1.5rem" }}
        >
            <Typography component="h1" variant="h5" sx={{ mb: 3, fontWeight: '500', fontSize: "18px" }}>
                {t("SETTINGS_BASIC_INFO")}
            </Typography>
            <Grid container columnSpacing={1}>
                <Grid item xs={12}>
                    <FormInputText name="name" control={control} label={`${t("GENERAL_NAME")} *`} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputText name="shortName" control={control} label={`${t("GENERAL_SHORT_NAME")} *`} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputText name="description" control={control} label={`${t("GENERAL_DESCRIPTION")} *`} rows={3} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputSelect
                        name="cantonTeam"
                        control={control}
                        label={`${t("INST_PROJECT")} *`}
                        options={cantonOptions}
                        labelKey="label"
                        valueKey="value"
                        defaultValue={editMode ? institution?.cantonTeam : "SPIRIT"}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInputSelect
                        name="country"
                        control={control}
                        label={`${t("GENERAL_COUNTRY")} *`}
                        options={countries}
                        labelKey="name"
                        valueKey="name"
                        defaultValue="Switzerland"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInputText name="city" control={control} label={`${t("GENERAL_CITY")} *`} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputText name="street" control={control} label={`${t("GENERAL_STREET_ADDRESS")} *`} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInputText name="number" control={control} label={`${t("GENERAL_STREET_NUMBER")} *`} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInputText name="zip" control={control} label={`${t("GENERAL_ZIP")}`} />
                </Grid>
                {institutionUserList.length > 0 && <Grid item xs={12} marginBottom="5px">
                    <Typography variant="h6" component="h6">{t("INST_TENANT_ADMINS")}</Typography>
                    <Typography component="p" fontSize="14px">{t("INST_TENANT_ADMINS_DESC")}</Typography>
                </Grid>}
                {institutionUserList.filter(u => u.isTenantAdmin).map((user) => (
                    <Grid item xs={12} key={"admins." + user.id}>
                        <FormInputMultiSelect name={"admins." + user.id}
                            label={user.email}
                            disabled
                            control={control}
                            defaultValue={{ isChecked: true, multiSelectValue: ["Full Access"] }}
                            options={roleList.map(role => {
                                return {
                                    value: role.id,
                                    label: role.name
                                }
                            })} />
                    </Grid>
                ))}
                {institutionUserList.length > 0 && <Grid item xs={12} marginBottom="5px">
                    <Typography variant="h6" component="h6">{t("INST_USERS_ROLES")}</Typography>
                </Grid>}
                {institutionUserList.filter(u => !u.isTenantAdmin).map((user) => (
                    <Grid item xs={12} key={user.id}>
                        <FormInputMultiSelect
                            name={user.id}
                            label={user.email}
                            control={control}
                            defaultValue={getDefaultValues(user, institution!, editMode)}
                            options={roleList.map(role => {
                                return {
                                    value: role.id,
                                    label: role.name
                                }
                            })} />
                    </Grid>
                ))}
                <Divider />
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <RoundButton
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2, mb: 4 }}
                    disabled={loading}
                    title="Submit"
                    data-testid="submit-button"
                >
                    {loading ? (
                        <CircularProgress size={25} />
                    ) : (
                        editMode ?
                            <Typography fontSize="13px">{t("GENERAL_UPDATE")}</Typography>
                            :
                            <Typography fontSize="13px">{t("GENERAL_CREATE")}</Typography>
                    )}
                </RoundButton>
            </Box>
        </Box>
    );
}

export default observer(InstitutionRegisterForm);