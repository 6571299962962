import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes } from "@lexical/html";

function HandleChangePlugin({ onChange }: { onChange: (htmlString: string) => void }): null {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        return editor.registerUpdateListener(() => {
            editor.update(() => {
                const htmlString = $generateHtmlFromNodes(editor, null);
                onChange(htmlString);
            });
        });
    }, [onChange, editor]);

    return null;
}

export default observer(HandleChangePlugin);