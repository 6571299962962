import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import LibraryAddCheckRoundedIcon from '@mui/icons-material/LibraryAddCheckRounded';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import { darken, lighten } from "@mui/material";
import { EventStatus } from '../../../../data/models/studyOverview';

export function getEventStatusProps(status: EventStatus): { icon: JSX.Element; color: string } {
    switch (status) {
        case EventStatus.OVERDUE:
            return {
                icon: <AssignmentLateIcon sx={{ fontSize: "18px !important ", ml: "10px !important", color: `  ${lighten("#FF0000", 0.3)}!important` }} />,
                color: lighten("#FF0000", 0.8),
            };
        case EventStatus.COMPLETED:
            return {
                icon: <LibraryAddCheckRoundedIcon sx={{ fontSize: "18px !important", ml: "10px !important", color: `#588157 !important` }} />,
                color: lighten("#588157", 0.8),
            };
        case EventStatus.IN_PROGRESS:
            return {
                icon: <HourglassEmptyIcon sx={{ fontSize: "18px !important ", ml: "10px !important", color: `${darken("#ffd500", 0.3)}!important` }} />,
                color: lighten("#ffd500", 0.5)
            };
        case EventStatus.CLIENT_NO_SHOW_UP:
            return {
                icon: <PersonOffRoundedIcon sx={{ fontSize: "18px !important", ml: "10px !important", color: ` #354f52 !important` }} />,
                color: lighten("#354f52", 0.8)
            }
        default:
            return {
                icon: <></>,
                color: "#ffffff",
            };
    }
}
