import { Typography, Box, Breadcrumbs } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { observer } from "mobx-react-lite";

import { Option } from "../../../_styles/layout/Container";
import ProfileMenu from "./ProfileMenu";
import './styles.css';
import { useTranslation } from "react-i18next";

interface ProfileHeaderProps {
    selected: Option;
    path: string;
    handleChange: (option: Option) => void;
}

function ProfileHeader({ selected, handleChange, path }: ProfileHeaderProps) {
    const { t } = useTranslation();

    return (
        <Box sx={{ bgcolor: "#fff" }}>
            <Box className="profile-header">
                <Box className="header-breadcrumbs">
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        <Typography>{t("PROFILE_LABEL")}</Typography>,
                        <Typography color="primary" fontWeight={500}>{path}</Typography>,
                    </Breadcrumbs>
                </Box>
                <Box className="header-menu-container" display="flex" flexDirection="row" flexGrow={2}>
                    <ProfileMenu handleChange={handleChange} selected={selected} />
                    <Typography className="settings-header-title" fontWeight={"bold"} fontSize={"30px"}>{t("PROFILE_LABEL")}</Typography>
                </Box>

            </Box>
        </Box >
    );
}

export default observer(ProfileHeader);