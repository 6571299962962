import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    Link,
    IconButton,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import CastConnectedRoundedIcon from "@mui/icons-material/CastConnectedRounded";
import CastOutlinedIcon from "@mui/icons-material/CastOutlined";
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { Link as RouterLink, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { useStore } from "../../stores/store";
import AccountMenu from "../AccountMenu";
import {
    hasModuleAccess,
    hasModulePermission,
    hasRoles,
    hasRolesv2,
} from "../../utils/permissionEvaluator";
import NavBarMenu from "./NavBarMenu";
import "./styles.css";
import { AppRoles, ModuleName, PermissionName } from "../../data/models/role";
import TenantListMenu from "../TenantListMenu";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";
import LogSelector from "./LogSelector";

const navLinkStyle = {
    display: "flex",
    alignItems: "center",
    color: "#2e323e",
    marginRight: "15px",
    textDecoration: "none",
    fontSize: "13px",
    height: "64px",
};

const fontStyle = {
    fontSize: "medium",
    fontWeight: 500,
    height: "64px",
};

const selectedRipple = {
    position: "absolute",
    width: "100%",
    height: "2px",
    background: "#004e7c",
    bottom: "-2px",
};

const iconStyle = {
    color: "#687180",
    marginRight: "4px",
    fontSize: "20px",
};

const activeIcon = {
    ...iconStyle,
    color: "#004e7c",
};

const navbarStyle = {
    backgroundColor: "white",
    boxShadow: "none",
    borderBottom: "2px solid #e2e2e2",
};

function NavBar() {
    const { t, i18n } = useTranslation();
    const {
        authStore: { isLoggedIn, logout, user },
        institutionStore: { selectedUserInstitution },
        tenantStore: { selectedTenant },
        commonStore: { viewType },
        profileStore: { userTenantList },
    } = useStore();
    const location = useLocation();
    const currentPath = location.pathname;

    const anyPermissions =
        selectedUserInstitution &&
        selectedUserInstitution.permissions.length > 0 &&
        userTenantList.length > 0;


    //TODO check if there is a function that uses constant values for checking roles!
    const hasNotificationLogPermission = user?.isSuperAdmin || (() => {
        if (!selectedUserInstitution?.roles) return false;

        const allowedRoles = ["Admin", "Field Coordinator", "Field Coordinator Light", "USZ Coordinator"];
        return allowedRoles.some(role => selectedUserInstitution.roles.includes(role));
    })();

    const isInPath = (path: string) => {
        return currentPath.includes(path);
    };

    const isUszCoordinator = selectedUserInstitution ? hasRolesv2([AppRoles.USZ_COORDINATOR], selectedUserInstitution.roles) : false;
    // 

    return (
        <Box>
            <AppBar sx={navbarStyle} position="static">
                <Toolbar className={anyPermissions ? "" : "toolbar-container"}>
                    {anyPermissions && <NavBarMenu />}
                    <Box sx={fontStyle} component="div" className="module-options">
                        {/* {
                            (user?.isSuperAdmin
                                || hasRoles(["USZ Coordinator"], selectedUserInstitution?.roles ?? []))
                            && <Box display={"flex"} position={"relative"}>
                                <Link
                                    component={RouterLink}
                                    to={"/templates"}
                                    sx={{
                                        ...navLinkStyle,
                                        marginLeft: 1,
                                        color: isInPath("/templates") ? "#004e7c" : "#2e323e",
                                    }}
                                    data-testid="templates-link"
                                >
                                    {isInPath("/templates") ? (
                                        <TextSnippetRoundedIcon sx={activeIcon} />
                                    ) : (
                                        <TextSnippetOutlinedIcon sx={iconStyle} />
                                    )}
                                    {t('GENERAL_TEMPLATES')}
                                </Link>
                                {isInPath("/templates") && <Box sx={selectedRipple}></Box>}
                            </Box>
                        } */}
                        {hasModuleAccess(
                            ModuleName.RESOURCE_PLANNING,
                            selectedUserInstitution!,
                            user?.isSuperAdmin
                        ) &&
                            hasModulePermission(
                                [PermissionName.VIEW_ALL, PermissionName.VIEW_OWN],
                                ModuleName.RESOURCE_PLANNING,
                                selectedUserInstitution!,
                                false
                            ) && (
                                <Box display={"flex"} position={"relative"}>
                                    <Link
                                        component={RouterLink}
                                        to={`/resource-planning/view/${viewType ?? "week"}${currentPath.includes("/resource-planning/view/")
                                            ? location.search
                                            : ""
                                            }`}
                                        sx={{
                                            ...navLinkStyle,
                                            color: isInPath("/resource-planning")
                                                ? "#004e7c"
                                                : "#2e323e",
                                        }}
                                        data-testid="resource-planning-link"
                                    >
                                        {isInPath("/resource-planning") ? (
                                            <DateRangeRoundedIcon sx={activeIcon} />
                                        ) : (
                                            <DateRangeOutlinedIcon sx={iconStyle} />
                                        )}
                                        {t("ROLE_RESOURCE_PLANNING")}
                                    </Link>
                                    {isInPath("/resource-planning") && <Box sx={selectedRipple}></Box>}
                                </Box>
                            )}
                        {((hasModuleAccess(ModuleName.SCREENING, selectedUserInstitution!, user?.isSuperAdmin) &&
                            hasModulePermission(
                                [PermissionName.VIEW_ALL, PermissionName.VIEW_OWN],
                                ModuleName.SCREENING,
                                selectedUserInstitution!,
                                false
                            ))
                            ||
                            hasRoles(["Client"], selectedUserInstitution?.roles ?? [], false))
                            && (
                                <Box display={"flex"} position={"relative"}>
                                    <Link
                                        component={RouterLink}
                                        to={hasRoles(["Client"], selectedUserInstitution?.roles ?? [], false) ? "/screening/my-sessions" : "/screening"}
                                        sx={{
                                            ...navLinkStyle,
                                            marginLeft: 1,
                                            color: isInPath("/screening") ? "#004e7c" : "#2e323e",
                                        }}
                                        data-testid="screening-link"
                                    >
                                        {isInPath("/screening") ? (
                                            <CastConnectedRoundedIcon sx={activeIcon} />
                                        ) : (
                                            <CastOutlinedIcon sx={iconStyle} />
                                        )}
                                        {t("ROLE_SCREENING")}
                                    </Link>
                                    {isInPath("/screening") && <Box sx={selectedRipple}></Box>}
                                </Box>
                            )}
                        {hasModuleAccess(ModuleName.CLIENTS, selectedUserInstitution!, user?.isSuperAdmin) &&
                            hasModulePermission(
                                [PermissionName.VIEW_ALL, PermissionName.VIEW_OWN],
                                ModuleName.CLIENTS,
                                selectedUserInstitution!,
                                false
                            ) && <Box display={"flex"} position={"relative"}>
                                <Link
                                    component={RouterLink}
                                    to="/users"
                                    sx={{
                                        ...navLinkStyle,
                                        marginLeft: 1,
                                        color: !isInPath("/settings/users") && isInPath("/users") ? "#004e7c" : "#2e323e",
                                    }}
                                    data-testid="users-link"
                                >
                                    {!isInPath("/settings/users") && isInPath("/users") ? (
                                        <GroupsRoundedIcon sx={activeIcon} />
                                    ) : (
                                        <GroupsOutlinedIcon sx={iconStyle} />
                                    )}
                                    {t("ROLE_CLIENTS")}
                                </Link>
                                {!isInPath("/settings/users") && isInPath("/users") && <Box sx={selectedRipple}></Box>}
                            </Box>}
                        {hasModuleAccess(ModuleName.DOWNLOAD_NII, selectedUserInstitution!, user?.isSuperAdmin) &&
                            hasModulePermission(
                                [PermissionName.RESEARCH_DATA_CSV, PermissionName.ECRF_PDF],
                                ModuleName.DOWNLOAD_NII,
                                selectedUserInstitution!,
                                false
                            ) && <Box display={"flex"} position={"relative"}>
                                <Link
                                    component={RouterLink}
                                    to="/data-export"
                                    sx={{
                                        ...navLinkStyle,
                                        marginLeft: 1,
                                        color: isInPath("/data-export") ? "#004e7c" : "#2e323e",
                                    }}
                                    data-testid="users-link"
                                >
                                    {isInPath("/data-export") ? (
                                        <UploadFileRoundedIcon sx={activeIcon} />
                                    ) : (
                                        <UploadFileOutlinedIcon sx={iconStyle} />
                                    )}
                                    {t("NAV_DE")}
                                </Link>
                                {isInPath("/data-export") && <Box sx={selectedRipple}></Box>}
                            </Box>}
                        {(user?.isSuperAdmin ||
                            selectedTenant?.isAdmin
                            || hasRoles(["Field Coordinator"], selectedUserInstitution?.roles ?? []))
                            && <Box display={"flex"} position={"relative"}>
                                <Link
                                    component={RouterLink}
                                    to="/dashboard"
                                    sx={{
                                        ...navLinkStyle,
                                        marginLeft: 1,
                                        color: isInPath("/dashboard") ? "#004e7c" : "#2e323e",
                                    }}
                                    data-testid="dashboard-link"
                                >
                                    {isInPath("/dashboard") ? (
                                        <AssessmentRoundedIcon sx={activeIcon} />
                                    ) : (
                                        <AssessmentOutlinedIcon sx={iconStyle} />
                                    )}
                                    {t("NAV_DASHBOARD")}
                                </Link>
                                {isInPath("/dashboard") && <Box sx={selectedRipple}></Box>}
                            </Box>}
                        {/* {(user?.isSuperAdmin)
                            && <Box display={"flex"} position={"relative"}>
                                <Link
                                    component={RouterLink}
                                    to="/logs"
                                    sx={{
                                        ...navLinkStyle,
                                        marginLeft: 1,
                                        color: isInPath("/logs") ? "#004e7c" : "#2e323e",
                                    }}
                                    data-testid="logs-link"
                                >
                                    {isInPath("/logs") ? (
                                        <AssessmentRoundedIcon sx={activeIcon} />
                                    ) : (
                                        <AssessmentOutlinedIcon sx={iconStyle} />
                                    )}
                                    {t("NAV_LOGS")}
                                </Link>
                                {isInPath("/logs") && <Box sx={selectedRipple}></Box>}
                            </Box>
                        } */}
                        {hasNotificationLogPermission &&
                            <LogSelector
                                isSuperAdmin={user?.isSuperAdmin}
                                navLinkStyle={navLinkStyle}
                                iconStyle={iconStyle}
                                activeIcon={activeIcon}
                                selectedRipple={selectedRipple}
                            />
                        }
                        {
                            selectedUserInstitution?.roles.some(r => ["Admin", "Field Coordinator", "Field Coordinator Light", "USZ Coordinator"].includes(r)) &&
                            (<Box display={"flex"} position={"relative"}>
                                <Link
                                    component={RouterLink}
                                    to="coordination-overview"
                                    sx={{
                                        ...navLinkStyle,
                                        marginLeft: 1,
                                        color: isInPath("coordination-overview") ? "#004e7c" : "#2e323e",
                                    }}
                                    data-testid="study-overview-link"
                                >
                                    {isInPath("coordination-overview") ? (
                                        <AssessmentRoundedIcon sx={activeIcon} />
                                    ) : (
                                        <AssessmentOutlinedIcon sx={iconStyle} />
                                    )}
                                    <Typography fontSize={14} fontWeight={500}>
                                        {t("COORDINATION_OVERVIEW")}
                                    </Typography>
                                </Link>
                                {isInPath("coordination-overview") && <Box sx={selectedRipple}></Box>}
                            </Box>)
                        }
                    </Box>
                    {isLoggedIn ? (
                        <div style={navLinkStyle}>
                            <LanguageSelector />
                            {userTenantList.length > 0 && <TenantListMenu />}
                            {userTenantList.length > 0 && <AccountMenu isInPath={isInPath} />}
                            {((selectedTenant && selectedTenant.isAdmin) || hasRoles(["USZ Coordinator"], selectedUserInstitution?.roles ?? [])) && (
                                <Box display={"flex"} position={"relative"} height={"100%"} alignItems={"center"}>
                                    <Link
                                        component={RouterLink}
                                        to={isUszCoordinator ? "/settings/templates" : "/settings/tenants"}
                                        sx={{ color: "white" }}
                                        data-testid="settings-link"
                                    >
                                        <IconButton aria-label="settings" title="Settings" disableRipple>
                                            {isInPath("/settings") ? (
                                                <SettingsRoundedIcon sx={{ ...activeIcon, mr: 0 }} />
                                            ) : (
                                                <SettingsOutlinedIcon sx={{ ...iconStyle, mr: 0 }} />
                                            )}
                                        </IconButton>
                                    </Link>
                                    {isInPath("/settings") && <Box sx={{ ...selectedRipple, zIndex: 2 }}></Box>}
                                </Box>
                            )}
                            <IconButton
                                aria-label="logout"
                                color="secondary"
                                onClick={() => {
                                    i18n.changeLanguage("en");
                                    logout();
                                }}
                                title="Logout"
                                data-testid="logout-button"
                            >
                                <ExitToAppIcon sx={{ color: "#66717d", fontSize: "20px" }} />
                            </IconButton>
                        </div>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Link
                                component={RouterLink}
                                to="/login"
                                sx={{ color: "#66717d" }}
                                data-testid="login-link"
                            >
                                <Typography component="p">{t("LOGIN_LABEL")}</Typography>
                            </Link>
                        </Box>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default observer(NavBar);
