import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import GppGoodRoundedIcon from '@mui/icons-material/GppGoodRounded';

import { Option } from '../../../_styles/layout/Container';
import AccountForm from './Account/AccountForm';
import UserSecurity from './Security/UserSecurity';

const iconStyle = {
    color: '#004e7c',
};

export const profileOptions: Option[] = [
    {
        id: 'account',
        name: 'ACCOUNT',
        component: <AccountForm />,
        icon: <AccountBoxOutlinedIcon sx={iconStyle} fontSize='small' />,
        selectedIcon: <AccountBoxRoundedIcon sx={{ color: "#004e7c" }} fontSize='small' />
    },
    {
        id: 'security',
        name: 'SECURITY',
        component: <UserSecurity />,
        icon: <GppGoodOutlinedIcon sx={iconStyle} fontSize='small' />,
        selectedIcon: <GppGoodRoundedIcon sx={{ color: "#004e7c" }} fontSize='small' />
    },
];
