import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Typography, CircularProgress } from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { yupResolver } from "@hookform/resolvers/yup";
import { getDynamicSchema } from "../../../../../_validators/schemas/contactAgency.schema";
import { toast } from "react-toastify";
import { FormInputText } from "../../../../../components/form/FormInputText";
import { useStore } from "../../../../../stores/store";

interface ClientInfoEditFormProps {
    clientId?: string;
    fieldName: string;
    fieldValue?: string;
    updateField: (clientId: string, value: string) => Promise<void>;
    isLoading: boolean;
    label: string;
    noInfoMessage?: string;
    onDetailsUpdate: (details: any) => void;
}

function ClientInfoEditForm({
    clientId,
    fieldName,
    fieldValue,
    updateField,
    isLoading,
    noInfoMessage,
    onDetailsUpdate
}: ClientInfoEditFormProps) {
    const [editMode, setEditMode] = useState(false);
    const validationSchema = getDynamicSchema(fieldName);

    const { handleSubmit, control, reset } = useForm({
        defaultValues: { [fieldName]: fieldValue },
        resolver: yupResolver(validationSchema)
    });
    const { t } = useTranslation();
    const { clientStore, institutionStore } = useStore();
    const { getClientDetails } = clientStore;
    const { selectedUserInstitution } = institutionStore;

    useEffect(() => {
        reset({ [fieldName]: fieldValue });
    }, [fieldValue, fieldName, reset]);

    const onSubmit = async (data: any) => {
        if (!clientId) {
            toast.error("Error loading the client info, please try again.");
            return;
        }

        try {
            await updateField(clientId, data[fieldName].trim());

            const updatedDetails = await getClientDetails(clientId, selectedUserInstitution?.institutionId!);

            onDetailsUpdate(updatedDetails);

            setEditMode(false);
        } catch (error) {
            toast.error("Failed to update. Please try again.");
        }
    };

    if (editMode) {
        return (
            <Box
                component={"form"}
                onSubmit={handleSubmit(onSubmit)}
                display="flex"
                width="300px"
            >
                <FormInputText
                    name={fieldName}
                    control={control}
                    helperText=""
                />
                <Box display="flex">
                    <IconButton type="submit" disabled={isLoading}>
                        {isLoading ? <CircularProgress size={25} /> : <DoneRoundedIcon />}
                    </IconButton>
                    <IconButton onClick={() => {
                        setEditMode(false);
                        reset({ [fieldName]: fieldValue });
                    }} disabled={isLoading}>
                        <CloseRoundedIcon />
                    </IconButton>
                </Box>
            </Box>
        );
    }

    return (
        <Box display="flex" alignItems="center">
            <Typography
                fontSize="14px"
                sx={{ color: !fieldValue ? "#f44336" : "inherit" }}
            >
                {fieldValue || noInfoMessage || t("CLIENT_NO_INFO")}
            </Typography>
            <IconButton onClick={() => setEditMode(true)}>
                <EditRoundedIcon sx={{ fontSize: "18px" }} />
            </IconButton>
        </Box>
    );
}

export default ClientInfoEditForm;
