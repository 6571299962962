import { Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface CountdownTimerProps {
    initialCountdown?: number;
    onFinish?: Function | Promise<any>;
    view?: (countdown: number) => React.ReactNode;
    reset?: boolean;
}

function CountdownTimer({ initialCountdown = 10, onFinish, view, reset = false }: CountdownTimerProps) {
    const [countdown, setCountdown] = useState(initialCountdown);
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        const countdownInterval = setInterval(updateCountdown, 1000);

        return () => {
            clearInterval(countdownInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countdown]);

    useEffect(() => {
        if (reset) {
            setCountdown(initialCountdown);
            setIsPaused(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset])

    const updateCountdown = async () => {
        if (isPaused) {
            return;
        }

        if (countdown <= 0) {
            setIsPaused(true)
            await executeFunction();
            setCountdown(initialCountdown);
            setIsPaused(false);
        } else {
            setCountdown(countdown - 1);
        }
    };

    const executeFunction = async () => {
        if (onFinish) {
            if (onFinish instanceof Function) {
                onFinish();
            } else if (onFinish instanceof Promise) {
                await onFinish;
            }
        }
    }


    return (
        <>
            {view ? view(countdown) : <Typography>{countdown}</Typography>}
        </>
    );
}


export default CountdownTimer;