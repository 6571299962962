import { LanguageDto } from "../models/language";

export const portalLanguages: LanguageDto[] = [
    { name: "English", alpha2: "en", alpha3b: "eng" },
    { name: "German", alpha2: "de", alpha3b: "ger" },
    { name: "French", alpha2: "fr", alpha3b: "fre" },
];

export const languagesMain12: LanguageDto[] = [
    {
        name: "English",
        alpha2: "en",
        alpha3b: "eng"
    },
    {
        name: "German",
        alpha2: "de",
        alpha3b: "ger"
    },
    {
        name: "French",
        alpha2: "fr",
        alpha3b: "fre"
    },
    {
        name: "Russian",
        alpha2: "ru",
        alpha3b: "rus"
    },
    {
        name: "Farsi/Dari",
        alpha2: "fay",
        alpha3b: "fay"
    },
    {
        name: "Kurmanji",
        alpha2: "kurk",
        alpha3b: "kurk"
    },
    {
        name: "Ukrainian",
        alpha2: "uk",
        alpha3b: "ukr"
    },
    {
        name: "Arabic",
        alpha2: "ar",
        alpha3b: "ara"
    },
    {
        name: "Pashto",
        alpha2: "ps",
        alpha3b: "pus"
    },
    {
        name: "Tamil",
        alpha2: "ta",
        alpha3b: "tam"
    },
    {
        name: "Tigrinya",
        alpha2: "ti",
        alpha3b: "tir"
    },
    {
        name: "Turkish",
        alpha2: "tr",
        alpha3b: "tur"
    }
]