import { useState } from "react";
import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";
import { FormControlLabel, Switch, Typography } from "@mui/material";

interface FormInputSwitchProps extends FormInputProps {
    defaultValue?: boolean;
    readOnly?: boolean;
}

function FormInputSwitch({ name, control, label, defaultValue = false, readOnly = false }: FormInputSwitchProps) {
    const [isChecked, setIsChecked] = useState(defaultValue);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, onBlur } }) => (
                <FormControlLabel
                    control={
                        <Switch
                            checked={isChecked}
                            onChange={(e) => {
                                setIsChecked(e.target.checked);
                                onChange(e.target.checked);
                            }}
                            readOnly={readOnly}
                            onBlur={onBlur}
                            color="primary"
                        />
                    }
                    label={<Typography fontSize={"14px"}>{label}</Typography>}
                />
            )}
        />
    );
}

export default FormInputSwitch;
