import { Box, Container, Typography } from "@mui/material";

import LoginForm from "./LoginForm";
import './styles.css';
import { useTranslation } from "react-i18next";

const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    overflowY: 'auto'
}

const messageStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: '#03b2ff',
    height: '100%',
    width: '50%',
    paddingLeft: '50px',
}

export default function LoginContainer() {
    const { t } = useTranslation();
    return (
        <Container className="login-container" sx={containerStyle} maxWidth={false}>
            <Box
                sx={{
                    backgroundColor: 'white',
                    width: '50%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}
                className="login-form-container"
            >
                <LoginForm />
            </Box>
            <Box sx={messageStyle} className="login-message">
                <Box>
                    <Typography fontSize={46} color="white" fontWeight={700} width="58%">{t("LOGIN_WELCOME_MSG")}</Typography>
                    <Typography component="p" color="white" marginTop={3}>{t("LOGIN_WELCOME_DESC")}</Typography>
                </Box>
            </Box>
        </Container >
    );
}