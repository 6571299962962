import * as Yup from 'yup';
import { combineDateAndTime } from '../../utils/utils';
import { t } from 'i18next';

export const eventFormSchema = (screeningTypes: string[]) => {
    const schema = Yup.object().shape({
        eventType: Yup.string().required(t('SCHEMA_SELECT_EVENT_TYPE')),
        title: Yup.string().required(t('SCHEMA_TITLE_REQUIRED')),
        frequency: Yup.number().required(t('SCHEMA_SELECT_FREQUENCY')),
        start: Yup.date().required(t('SCHEMA_START_TIME_REQUIRED')),
        end: Yup.date()
            .test('is-after-start', t('SCHEMA_AFTER_START_DATE'), function (endDate): boolean {
                const startDate: Date = this.resolve(Yup.ref('start'));
                return startDate <= endDate!;
            }),
        isAllDay: Yup.boolean().required(t('SCHEMA_SELECT_VALUE')),
        startTime: Yup.string()
            .when('isAllDay', {
                is: false,
                then: (schema) => schema
                    .typeError(t('SCHEMA_INVALID_START_TIME'))
                    .required(t('SCHEMA_START_TIME_REQUIRED')),
            }),
        endTime: Yup.string()
            .when('isAllDay', {
                is: false,
                then: (schema) => schema
                    .typeError(t('SCHEMA_INVALID_END_TIME'))
                    .required(t('SCHEMA_START_TIME_REQUIRED'))
                    .test('is-after-start', t('SCHEMA_ATER_START_TIME'), function (endTime): boolean {
                        const startTime: Date = this.resolve(Yup.ref('startTime'));
                        const startDate: Date = this.resolve(Yup.ref('start'));
                        const startTimeDate = combineDateAndTime(startDate, new Date(startTime));

                        const endDate: Date = this.resolve(Yup.ref('end'));
                        const endTimeDate = combineDateAndTime(endDate, new Date(endTime));

                        return endTimeDate > startTimeDate;
                    }),
            }),
        reminders: Yup.array().of(
            Yup.object().shape({
                value: Yup.string().required(t('SCHEMA_ADD_VALUE_OR_REMOVE')),
                unit: Yup.string().required(t('SCHEMA_SELECT_UNIT_OR_REMOVE')),
            })
        ),
        roomId: Yup.string().trim()
            .when('eventType', {
                is: (eventType: string) => {
                    return screeningTypes.includes(eventType);
                },
                then: (schema) => schema
                    .typeError(t('SCHEMA_ROOM_REQUIRED'))
                    .required(t('SCHEMA_ROOM_REQUIRED'))
                    .test('is-not-none', t('SCHEMA_ROOM_REQUIRED'), value => value !== 'none'),
            }),
        recurrence: Yup.object().shape({
            endDate: Yup.date().test('is-after-start', t('SCHEMA_AFTER_START_DATE'), function (endDate): boolean {
                const parent: any = this.options;
                const startDate = parent.from[1].value.start;
                const frequency = parent.from[1].value.frequency;

                if (frequency === 9) {
                    return true;
                }

                return startDate <= endDate!;
            }),
            byMonthDay: Yup.number().test('is-monthly', t('SCHEMA_SELECT_RECURRENCE_DATE'), function (value): boolean {
                const parent: any = this.options;
                const frequency = parent.from[1].value.frequency;

                if (frequency === 1) {
                    return value ? value >= 1 && value <= 30 : false;
                }

                return true;
            }),
            byDays: Yup.array().test('is-weekly', t('SCHEMA_SELECT_A_DAY'), function (values): boolean {
                const parent: any = this.options;
                const frequency = parent.from[1].value.frequency;

                if (frequency === 2) {
                    return values ? values.length > 0 : false;
                }

                return true;
            }),
            interval: Yup.number().test('is-recurrent', t('SCHEMA_SELECT_INTERVAL'), function (value): boolean {
                const parent: any = this.options;
                const frequency = parent.from[1].value.frequency;

                if (frequency === 9) {
                    return true;
                } else if ([1, 2, 3].includes(frequency)) {
                    return value ? value >= 1 && value <= 5 : false;
                }

                return true;
            })
        })
    });

    return schema;
}

export const eventUpdateFormSchema = (screeningTypes: string[]) => {
    const schema = Yup.object().shape({
        eventType: Yup.string().required(t('SCHEMA_SELECT_EVENT_TYPE')).trim(),
        title: Yup.string().required(t('SCHEMA_TITLE_REQUIRED')).trim(),
        start: Yup.date().required(t('SCHEMA_START_TIME_REQUIRED')),
        end: Yup.date()
            .test('is-after-start', t('SCHEMA_AFTER_START_DATE'), function (endDate): boolean {
                const startDate: Date = this.resolve(Yup.ref('start'));
                return startDate <= endDate!;
            }),
        startTime: Yup.string().trim()
            .when('isAllDay', {
                is: false,
                then: (schema) => schema
                    .typeError(t('SCHEMA_INVALID_START_TIME'))
                    .required(t('SCHEMA_START_TIME_REQUIRED')),
            }),
        endTime: Yup.string().trim()
            .when('isAllDay', {
                is: false,
                then: (schema) => schema
                    .typeError(t('SCHEMA_INVALID_END_TIME'))
                    .required(t('SCHEMA_START_TIME_REQUIRED'))
                    .test('is-after-start', t('SCHEMA_ATER_START_TIME'), function (endTime): boolean {
                        const startTime: Date = this.resolve(Yup.ref('startTime'));
                        const startDate: Date = this.resolve(Yup.ref('start'));
                        const startTimeDate = combineDateAndTime(startDate, new Date(startTime));

                        const endDate: Date = this.resolve(Yup.ref('end'));
                        const endTimeDate = combineDateAndTime(endDate, new Date(endTime));

                        return endTimeDate > startTimeDate;
                    }),
            }),
        reminders: Yup.array().of(
            Yup.object().shape({
                value: Yup.string().required(t('SCHEMA_ADD_VALUE_OR_REMOVE')),
                unit: Yup.string().required(t('SCHEMA_SELECT_UNIT_OR_REMOVE')),
            })
        ),
        roomId: Yup.string().trim()
            .when('eventType', {
                is: (eventType: string) => {
                    return screeningTypes.includes(eventType);
                },
                then: (schema) => schema
                    .typeError(t('SCHEMA_ROOM_REQUIRED'))
                    .required(t('SCHEMA_ROOM_REQUIRED'))
                    .test('is-not-none', t('SCHEMA_ROOM_REQUIRED'), value => value !== 'none'),
            }),
        recurrence: Yup.object().shape({
            byMonthDay: Yup.number().test('is-monthly', t('SCHEMA_SELECT_RECURRENCE_DATE'), function (value): boolean {
                const parent: any = this.options;
                const frequency = parent.from[1].value.frequency;

                if (frequency === 1) {
                    return value ? value >= 1 && value <= 30 : false;
                }

                return true;
            }).nullable(),
            byDays: Yup.array().test('is-weekly', t('SCHEMA_SELECT_A_DAY'), function (values): boolean {
                const parent: any = this.options;
                const frequency = parent.from[1].value.frequency;

                if (frequency === 2) {
                    return values ? values.length > 0 : false;
                }

                return true;
            }).nullable(),
            endDate: Yup.date().test('is-after-start', t('SCHEMA_AFTER_START_DATE'), function (endDate): boolean {
                const parent: any = this.options;
                const startDate = parent.from[1].value.start;
                const frequency = parent.from[1].value.frequency;

                if (frequency === 9) {
                    return true;
                }

                return startDate <= endDate!;
            }).nullable(),
            interval: Yup.number().test('is-recurrent', t('SCHEMA_SELECT_INTERVAL'), function (value): boolean {
                const parent: any = this.options;
                const frequency = parent.from[1].value.frequency;

                if (frequency === 9) {
                    return true;
                } else if ([1, 2, 3].includes(frequency)) {
                    return value ? value >= 1 && value <= 5 : false;
                }

                return true;
            }).nullable()
        })
    });

    return schema;
}