import { Box, CircularProgress, Collapse, List, ListItemButton, ListItemText, Typography } from "@mui/material"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';

import { useEffect, useRef, useState } from "react";

import { useStore } from "../../../stores/store";
import { generateColor } from "../../../utils/colorGenerator";
import { observer } from "mobx-react-lite";
import { hasModulePermission } from "../../../utils/permissionEvaluator";
import { ModuleName, PermissionName } from "../../../data/models/role";
import { View } from "../../../data/models/event";
import { useTranslation } from "react-i18next";

const itemButtonStyle = {
    '&:hover': {
        backgroundColor: '#94a3b81f',
    },
    py: 0,
    px: 2,
}

function RoomsCalendar() {
    const { t } = useTranslation();
    const {
        institutionStore: { selectedUserInstitution },
        commonStore: { setCalendarViews, getCalendarViews },
        bookingStore: {
            removeEventsById,
            clearEvents,
            getInstitutionRooms,
            getRoomEvents,
            getUserEvents,
            roomCalendarList,
            loading,
            userEventsId
        },
    } = useStore()
    const [expanded, setExpanded] = useState(true);
    const targets = useRef([] as string[]);
    const selectedViews = getCalendarViews();

    const handleExpand = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        const fetchData = async () => {
            clearEvents();
            await getInstitutionRooms(selectedUserInstitution!.institutionId);
            const roomViews = getCalendarViews().filter(view => view.type === View.ROOM);

            for (const view of roomViews) {
                await getRoomEvents(view.id);
            };
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserInstitution]);

    const handleOnChange = async (viewId: string) => {
        const isSelected = selectedViews.find(view => view.id === viewId);

        if (isSelected) {
            if (getCalendarViews().length === 1) {
                setCalendarViews([{ id: userEventsId, type: View.USER }]);
                removeEventsById(viewId);
                await getUserEvents();
            } else {
                removeEventsById(viewId);
                const updatedSelectedViews = selectedViews.filter(view => view.id !== viewId);
                setCalendarViews(updatedSelectedViews);
            }
        } else {
            targets.current.push(viewId);
            await getRoomEvents(viewId);
            targets.current = targets.current.filter(target => target !== viewId);
            const updatedSelectedViews = [...selectedViews, { id: viewId, type: View.ROOM }];
            setCalendarViews(updatedSelectedViews);
        }
    }

    return (
        <Box>
            {hasModulePermission([PermissionName.VIEW_ALL], ModuleName.RESOURCE_PLANNING, selectedUserInstitution!, false) && <List>
                <ListItemButton onClick={handleExpand} sx={{
                    '&:hover': {
                        backgroundColor: '#94a3b81f',
                    },
                }}>
                    {expanded ? <KeyboardArrowDownIcon sx={{ pr: 2 }} /> : <KeyboardArrowRightIcon sx={{ pr: 2 }} />}
                    <ListItemText primary={<Typography fontSize={"0.90rem"}>{t("RP_ROOM_CAL")}</Typography>} />
                </ListItemButton>
                <Collapse in={expanded} timeout="auto" className="calendar-view-list" unmountOnExit>
                    <List component="div" disablePadding>
                        {roomCalendarList.length === 0 ? (
                            <Typography sx={{ pl: 8 }} fontSize="12px">{t("RP_CAL_NO_ROOMS")}</Typography>
                        ) : (
                            roomCalendarList.map((room) => (
                                <ListItemButton sx={itemButtonStyle} key={room.id} onClick={() => handleOnChange(room.id)}>
                                    {loading && targets.current.includes(room.id) ? (
                                        <Box marginRight="4px">
                                            <CircularProgress size={18} sx={{ color: generateColor(room.id) }} />
                                        </Box>
                                    ) : (
                                        selectedViews.find(view => view.id === room.id) ? (
                                            <CheckCircleIcon sx={{ color: generateColor(room.id), fontSize: "1.3rem" }} />
                                        ) : (
                                            <RadioButtonUncheckedOutlinedIcon sx={{ color: generateColor(room.id), fontSize: "1.3rem" }} />
                                        )
                                    )}
                                    <ListItemText sx={{ marginLeft: 2 }} primary={<Typography className="calendar-lists-text" fontSize={"0.85rem"}>{room.name}</Typography>} />
                                </ListItemButton>
                            ))
                        )}
                    </List>
                </Collapse>
            </List>}
        </Box>
    )
}

export default observer(RoomsCalendar);