import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    List,
    ListItem,
    ListItemText,
    Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { observer } from 'mobx-react-lite';

const SubAccordion: React.FC<{ title: string; items: string[] }> = ({ title, items }) => {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    {items.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemText primary={item} />
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

const ProcessList = () => {
    const subAccordionData = [
        { title: 'Sub Accordion 1', items: ['Item 1', 'Item 2', 'Item 3'] },
        { title: 'Sub Accordion 2', items: ['Item A', 'Item B', 'Item C'] },
    ];

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Parent Accordion</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    {subAccordionData.map((subAccordion, index) => (
                        <SubAccordion
                            key={index}
                            title={subAccordion.title}
                            items={subAccordion.items}
                        />
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default observer(ProcessList);
