import {
    Box,
    Checkbox,
    CircularProgress,
    Collapse,
    List,
    ListItemButton,
    ListItemText,
    Typography,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";

import { InputHTMLAttributes, useEffect, useRef, useState } from "react";

import { useStore } from "../../../stores/store";
import { generateColor } from "../../../utils/colorGenerator";
import { observer } from "mobx-react-lite";
import {
    hasModulePermission,
    hasOtherRoles,
} from "../../../utils/permissionEvaluator";
import { ModuleName, PermissionName } from "../../../data/models/role";
import { View } from "../../../data/models/event";
import { generateNewUuid } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import CalendarSearch from "../Options/CalendarSearch";
import { TenantUserDto } from "../../../data/models/user";

const itemButtonStyle = {
    "&:hover": {
        backgroundColor: "#94a3b81f",
    },
    py: 0,
    px: 2,
};

function PeoplesCalendar() {
    const { t } = useTranslation();
    const {
        institutionStore: { selectedUserInstitution },
        commonStore: { setCalendarViews, getCalendarViews },
        bookingStore: {
            getEvents,
            removeEventsById,
            userCalendarList,
            loading,
            getUsersCalendar,
            clearEvents,
            userEventsId,
            getUserEvents,
            setUserEventsId,
            getEventAttendees,
            roleFilters
        },
        authStore: { user },
    } = useStore();

    const [users, setUsers] = useState<TenantUserDto[]>([]);

    const [searchValue, setSearchValue] = useState("");
    const [expanded, setExpanded] = useState(true);
    const targets = useRef([] as string[]);
    const selectedViews = getCalendarViews();

    const handleExpand = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (getCalendarViews().length === 0 && userEventsId && user) {
            setCalendarViews([
                { id: userEventsId, type: View.USER },
                { id: user.id, type: View.USER },
            ]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCalendarViews, userEventsId, user]);

    useEffect(() => {
        setUserEventsId(generateNewUuid(user?.id!));
        const fetchData = async () => {
            clearEvents();
            const searchParams = new URLSearchParams(window.location.search);
            const languages = searchParams.getAll("languages");
            await getUsersCalendar(languages);
            const userViews = getCalendarViews().filter(
                (view) => view.type === View.USER
            );

            for (const view of userViews) {
                if (userEventsId !== "") {
                    if (userEventsId && view.id === userEventsId) {
                        await getUserEvents();
                    } else {
                        await getEvents(view.id);
                    }
                }
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserInstitution, userEventsId]);

    useEffect(() => {
        if (selectedUserInstitution) {
            const fetchAttendees = async () => {
                getEventAttendees(selectedUserInstitution.institutionId);
            };
            fetchAttendees();
        }
    }, [selectedUserInstitution, getEventAttendees]);

    useEffect(() => {
        onSearchFilter(searchValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCalendarList])

    const onSearchFilter = (value: string) => {
        setSearchValue(value);
        if (value === "") {
            setUsers(userCalendarList);
            return;
        };

        const filteredList = userCalendarList.filter(user =>
            `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`.includes(value.toLowerCase().trim()) ||
            `${user.lastName.toLowerCase()} ${user.firstName.toLowerCase()}`.includes(value.toLowerCase().trim())
        );

        setUsers(filteredList);
    }

    const handleOnChange = async (viewId: string) => {
        const isSelected = selectedViews.find((view) => view.id === viewId);
        if (isSelected) {
            if (getCalendarViews().length === 1) {
                if (userEventsId !== viewId) {
                    setCalendarViews([{ id: userEventsId, type: View.USER }]);
                    removeEventsById(viewId);
                    await getUserEvents();
                }
            } else {
                removeEventsById(viewId);
                const updatedSelectedPeople = selectedViews.filter(
                    (view) => view.id !== viewId
                );
                setCalendarViews(updatedSelectedPeople);
            }
        } else {
            targets.current.push(viewId);
            if (viewId === userEventsId) {
                await getUserEvents();
            } else {
                await getEvents(viewId);
            }
            targets.current = targets.current.filter((target) => target !== viewId);
            const updatedSelectedPeople = [
                ...selectedViews,
                { id: viewId, type: View.USER },
            ];
            setCalendarViews(updatedSelectedPeople);
        }
    };

    const getUserList = () => {
        if (roleFilters.length === 0) {
            return users;
        } else {
            return users.filter(user => user.roles.some(role => roleFilters.includes(role)))
        }
    }

    return (
        <Box>
            <ListItemButton
                sx={itemButtonStyle}
                key={user!.id}
                onClick={() => handleOnChange(user!.id)}
            >
                {loading && targets.current.includes(user!.id) ? (
                    <Box marginRight="4px">
                        <CircularProgress
                            size={18}
                            sx={{ color: generateColor(user!.id) }}
                        />
                    </Box>
                ) : (
                    <Checkbox
                        inputProps={
                            {
                                "data-testid": "my-calendar-checkbox",
                            } as InputHTMLAttributes<HTMLInputElement>
                        }
                        checked={
                            selectedViews.find((view) => view.id === user!.id) ? true : false
                        }
                        sx={{ color: generateColor(user!.id), padding: 0 }}
                        icon={
                            <RadioButtonUncheckedOutlinedIcon
                                sx={{ color: generateColor(user!.id), fontSize: "1.3rem" }}
                            />
                        }
                        checkedIcon={
                            <CheckCircleIcon
                                sx={{ color: generateColor(user!.id), fontSize: "1.3rem" }}
                            />
                        }
                    />
                )}
                <ListItemText
                    sx={{ marginLeft: 2 }}
                    primary={
                        <Typography fontSize={"0.85rem"}>{t("RP_MY_CAL")}</Typography>
                    }
                />
            </ListItemButton>
            <ListItemButton
                sx={itemButtonStyle}
                key={userEventsId}
                onClick={() => handleOnChange(userEventsId)}
            >
                {loading && targets.current.includes(userEventsId) ? (
                    <Box marginRight="4px">
                        <CircularProgress
                            size={18}
                            sx={{ color: generateColor(userEventsId) }}
                        />
                    </Box>
                ) : (
                    <Checkbox
                        inputProps={
                            {
                                "data-testid": "organized-by-me-checkbox",
                            } as InputHTMLAttributes<HTMLInputElement>
                        }
                        checked={
                            selectedViews.find((view) => view.id === userEventsId)
                                ? true
                                : false
                        }
                        sx={{ color: generateColor(userEventsId), padding: 0 }}
                        icon={
                            <RadioButtonUncheckedOutlinedIcon
                                sx={{ color: generateColor(userEventsId), fontSize: "1.3rem" }}
                            />
                        }
                        checkedIcon={
                            <CheckCircleIcon
                                sx={{ color: generateColor(userEventsId), fontSize: "1.3rem" }}
                            />
                        }
                    />
                )}
                <ListItemText
                    sx={{ marginLeft: 2 }}
                    primary={
                        <Typography fontSize={"0.85rem"}>{t("RP_ORG_BY_ME")}</Typography>
                    }
                />
            </ListItemButton>
            {hasModulePermission(
                [PermissionName.VIEW_ALL, PermissionName.VIEW_OWN],
                ModuleName.RESOURCE_PLANNING,
                selectedUserInstitution!,
                false
            ) &&
                hasOtherRoles(
                    ["Helper", "Assessor"],
                    selectedUserInstitution!.roles
                ) && (
                    <List>
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            position={"relative"}
                        >
                            <ListItemButton
                                disableTouchRipple
                                onClick={handleExpand}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >
                                {expanded ? (
                                    <KeyboardArrowDownIcon sx={{ pr: 2 }} />
                                ) : (
                                    <KeyboardArrowRightIcon sx={{ pr: 2 }} />
                                )}
                                <ListItemText
                                    primary={
                                        <Typography fontSize={"0.90rem"}>
                                            {t("RP_PEOPLE_CAL")}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                            <CalendarSearch
                                handleChange={(open) => {
                                    if (open) {
                                        setExpanded(true);
                                    } else {
                                        onSearchFilter("");
                                    }
                                }}
                                onSearch={onSearchFilter}
                            />
                        </Box>
                        <Collapse
                            in={expanded}
                            timeout={400}
                            className="calendar-view-list"
                        >
                            <List component="div" disablePadding>
                                {getUserList().length === 0 ? (
                                    <Typography sx={{ pl: 8 }} fontSize="12px">
                                        {t("RP_CAL_NO_USERS")}
                                    </Typography>
                                ) : (
                                    getUserList().map((user) => (
                                        <ListItemButton
                                            sx={itemButtonStyle}
                                            key={user.id}
                                            disableTouchRipple
                                            onClick={() => handleOnChange(user.id)}
                                        >
                                            {loading && targets.current.includes(user.id) ? (
                                                <Box marginRight="4px">
                                                    <CircularProgress
                                                        size={18}
                                                        sx={{ color: generateColor(user.id) }}
                                                    />
                                                </Box>
                                            ) : (
                                                <Checkbox
                                                    inputProps={
                                                        {
                                                            "data-testid": `${user.id}-checkbox`,
                                                        } as InputHTMLAttributes<HTMLInputElement>
                                                    }
                                                    checked={
                                                        selectedViews.find((view) => view.id === user.id)
                                                            ? true
                                                            : false
                                                    }
                                                    sx={{ color: generateColor(user.id), padding: 0 }}
                                                    icon={
                                                        <RadioButtonUncheckedOutlinedIcon
                                                            sx={{
                                                                color: generateColor(user!.id),
                                                                fontSize: "1.3rem",
                                                            }}
                                                        />
                                                    }
                                                    checkedIcon={
                                                        <CheckCircleIcon
                                                            sx={{
                                                                color: generateColor(user.id),
                                                                fontSize: "1.3rem",
                                                            }}
                                                        />
                                                    }
                                                />
                                            )}
                                            <ListItemText
                                                sx={{ marginLeft: 2 }}
                                                primary={
                                                    <Typography
                                                        className="calendar-lists-text"
                                                        fontSize={"0.85rem"}
                                                    >
                                                        {user.firstName} {user.lastName}
                                                    </Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    ))
                                )}
                            </List>
                        </Collapse>
                    </List>
                )}
        </Box>
    );
}

export default observer(PeoplesCalendar);
