import {
    Box,
    FormControl,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { QuestionProps } from "./QuestionProps";
import { Controller } from "react-hook-form";
import { AnswerSubmit, SubQuestion } from "../../../../data/models/survey";
import { useStore } from "../../../../stores/store";
import { RoundButton } from "../../../../_styles/StyledButtons";
import { SurveyFieldError } from "../SurveyFields/SurveyField";
import { observer } from "mobx-react-lite";

import "./styles.css";
import { useLocation, useParams } from "react-router-dom";
import { surveyLanguages } from "../../../../data/static/survey";
import { FormInputText } from "../../../../components/form/FormInputText";
import { useContext, useEffect, useState } from "react";
import { SurveyContext } from "../Form/SurveyInitializer";

function SurveyMatrix({
    title,
    description,
    required,
    control,
    id,
    subQuestions,
    disabled = false,
    preview,
}: QuestionProps) {
    const {
        surveyStore: { getAnswer, setAnswer, submitAnswer },
    } = useStore();
    const searchParams = new URLSearchParams(window.location.search);
    const language = searchParams.get("lang");
    const { width } = useContext(SurveyContext);

    const { sessionId, surveyId, timelineId } = useParams();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    const onAnswerSelect = async (
        questionId: string,
        optionId: string | null
    ) => {
        if (preview) return;

        const answerSubmit: AnswerSubmit = {
            questionId: questionId,
            optionId: optionId ?? undefined,
            surveyId: surveyId!,
            timelineId: timelineId!,
            sessionId: sessionId!,
        };
        await submitAnswer(answerSubmit, token ?? undefined);
    };

    const generateRadioOptions = (question: SubQuestion) => {
        return question?.options?.map((singleOption) => (
            <FormControlLabel
                sx={{ m: 0 }}
                key={singleOption.id}
                value={singleOption.id}
                labelPlacement={width < 800 ? "end" : "top"}
                label={
                    <Typography fontSize="12px" className="matrix-option-label">
                        {singleOption.title}
                    </Typography>
                }
                control={<Radio disabled={disabled} />}
            />
        ));
    };

    const isRtl = (): boolean => {
        return surveyLanguages.find((l) => l.code === language)
            ?.direction === "rtl"
    }

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-evenly"
                width="55%"
                ml={isRtl() ? "inherit" : "auto"}
                mr={isRtl() ? "auto" : "inherit"}
                mb={3}
                className="matrix-options"
            >
                {subQuestions &&
                    subQuestions[0].options?.map((o) => (
                        <Typography
                            key={o.id}
                            sx={{ textAlign: "center", wordBreak: "keep-all", overflowWrap: "normal" }}
                            width="62px"
                            fontSize="13px"
                        >
                            {o.title}
                        </Typography>
                    ))}
            </Box>
            {subQuestions?.map((q) => (
                <FormControl
                    key={q.id}
                    component="fieldset"
                    sx={{ width: "100%", mb: 3 }}
                >
                    <Box className="matrix-question" display="flex" width="100%">
                        <Box className="matrix-question-title">
                            <Typography fontSize="13px">{q.title} {required ? <span style={{ color: 'red', lineHeight: '1' }}>*</span> : ""}</Typography>
                        </Box>
                        <Controller
                            name={`${q.id}.response`}
                            control={control}
                            defaultValue={getAnswer(q.id)?.answer ?? null}
                            render={({
                                field: { onChange, value = getAnswer(q.id)?.answer ?? null },
                                fieldState: { error },
                            }) => (
                                <>
                                    <RadioGroup
                                        className="matrix-radio-group"
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                            const newAnswer = { id: q.id, answer: e.target.value };
                                            setAnswer(q.id, newAnswer);
                                            onAnswerSelect(q.id, e.target.value);
                                        }}
                                    >
                                        {generateRadioOptions(q)}
                                        {!disabled && getAnswer(q.id)?.answer && (
                                            <IconButton
                                                className={`matrix-clear-icon ${isRtl() && "matrix-clear-icon-rtl"}`}
                                                onClick={() => {
                                                    onChange(null);
                                                    setAnswer(q.id, { answer: null });
                                                    onAnswerSelect(q.id, null);
                                                }}
                                            >
                                                <DeleteOutlineRoundedIcon />
                                            </IconButton>
                                        )}
                                        {!disabled && getAnswer(q.id)?.answer && (
                                            <RoundButton
                                                variant="text"
                                                className={`matrix-clear-button ${isRtl() && "matrix-clear-button-rtl"} `}
                                                onClick={() => {
                                                    onChange(null);
                                                    setAnswer(q.id, { answer: null });
                                                    onAnswerSelect(q.id, null);
                                                }}
                                            >
                                                Clear section
                                            </RoundButton>
                                        )}
                                    </RadioGroup>
                                    {!!error && (
                                        <SurveyFieldError message={
                                            <Box display="flex" alignItems="center" className="matrix-error-message">
                                                <ErrorOutlineIcon sx={{ fontSize: "14px", color: "#d62828", mr: 0.5 }} />
                                                <Typography fontSize="11px" fontWeight={400} sx={{ color: "#d62828" }}>This field is required</Typography>
                                            </Box>
                                        } />
                                    )}
                                </>
                            )}
                        />
                    </Box>
                </FormControl>
            ))}
        </>
    );
}

export function MatrixCreator({
    id,
    required,
    control,
    subQuestions,
    disabled = false,
    preview,
}: QuestionProps) {
    const searchParams = new URLSearchParams(window.location.search);
    const language = searchParams.get("lang");
    const { baseSurvey } = useContext(SurveyContext);
    const [questions, setQuestions] = useState<SubQuestion[]>([]);

    useEffect(() => {
        if (baseSurvey) {
            baseSurvey.pages.forEach(page => {
                page.questions.forEach(question => {
                    if (question.id === id && question.subQuestions) {
                        setQuestions(prevState => [...prevState, ...question.subQuestions as SubQuestion[]]);
                    }
                });
            });
        }
    }, [baseSurvey, id]);

    const generateRadioOptions = (question: SubQuestion) => {
        return question?.options?.map((singleOption) => (
            <FormControlLabel
                sx={{ m: 0 }}
                key={singleOption.id}
                value={singleOption.id}
                label={
                    <Typography fontSize="12px" className="matrix-option-label">
                        {singleOption.title}
                    </Typography>
                }
                control={<Radio disabled={true} />}
            />
        ));
    };

    const isRtl = (): boolean => {
        return (
            surveyLanguages.find((l) => l.code === language)?.direction === "rtl"
        );
    };

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-evenly"
                width="55%"
                ml={isRtl() ? "inherit" : "auto"}
                mr={isRtl() ? "auto" : "inherit"}
                mb={3}
                className="matrix-options"
            >
                {subQuestions &&
                    subQuestions[0].options?.map((o) =>
                        <FormInputText
                            key={o.id}
                            name={`questions.${id}.options.${o.id}.title`}
                            control={control}
                            initValue={o.title}
                            helperText={
                                questions.find(
                                    // Retrieve the title of the option associated with the first subQuestion,
                                    // if found; otherwise, return an empty string
                                    question => question.id === subQuestions[0].id)
                                    ?.options
                                    ?.find(option => option.id === o.id)
                                    ?.title ?? ""
                            }
                        />
                    )}
            </Box>
            {subQuestions?.map((q) => (
                <Box key={q.id} className="matrix-question" display="flex" width="100%">
                    <Box className="matrix-question-title" mb={1.5}>
                        <FormInputText
                            name={`questions.${id}.${q.id}.title`}
                            control={control}
                            initValue={q.title}
                            rows={3}
                            helperText={questions.find(sq => sq.id === q.id)?.title ?? ""}
                        />
                    </Box>
                    <RadioGroup
                        className="matrix-radio-group"
                        value={false}
                    >
                        {generateRadioOptions(q)}
                    </RadioGroup>
                </Box>
            ))}
        </>
    );
}

export default observer(SurveyMatrix);
