import { Box, Grid, Typography, Divider } from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormInputText } from "../../../components/form/FormInputText";
import { Permission, Role } from "../../../data/models/role";
import { useStore } from "../../../stores/store";
import { modules } from './roleOptions';
import { roleFormSchema } from "../../../_validators/schemas/roleForm.schema";
import { RoleInputMultiCheckbox } from "./RoleMultiCheckbox";
import { toast } from "react-toastify";
import { getDefaultPermissions, getModulePermissions } from "../../../utils/helpers/roleHelper";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

interface RoleFormProps {
    role?: Role;
}

function RoleForm({ role }: RoleFormProps) {
    const { t } = useTranslation();
    const {
        tenantStore: { userSettingsTenant },
        roleStore: { createRole, getRoles, updateRole },
        sidebarStore: { closeSidebar }
    } = useStore();
    const { handleSubmit, control, setValue } = useForm({
        resolver: yupResolver(roleFormSchema()),
        defaultValues: role
    });
    const editMode = role !== undefined;

    const onSubmit = async (data: any) => {
        const roleData: Role = {
            id: role?.id,
            name: data.name,
            description: data.description,
            tenantId: userSettingsTenant?.id!,
            access: getModulePermissions(data),
        };

        const saveAction = editMode ? updateRole : createRole;

        await saveAction(roleData);
        closeSidebar();
        toast.success(editMode ? t("TOAST_ROLE_UPDATE") : t("TOAST_ROLE_CREATE"));
        await getRoles(userSettingsTenant?.id!);
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mb: "1.5rem" }}
        >
            <Typography component="h1" variant="h5" sx={{ mb: 3, fontWeight: '500', fontSize: "18px" }}>
                {t("SETTINGS_BASIC_INFO")}
            </Typography>
            <Grid container spacing={1} className="disable-content">
                <Grid item xs={12}>
                    <FormInputText name="name" control={control} label={`${t("GENERAL_NAME")}`} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputText name="description" control={control} label={`${t("GENERAL_DESCRIPTION")}`} />
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 500 }}>{t("ROLE_PERMISSION")}</Typography>
                    <Divider sx={{ display: 'flex', flex: 1, margin: 0, mt: 2 }} />
                </Grid>
                {modules.map((module: any) => (
                    <Grid item xs={12} key={module.moduleName}>
                        <RoleInputMultiCheckbox<Permission>
                            name={module.moduleName === 'DOWNLOAD_PII' || module.moduleName === 'DOWNLOAD_NII' ? '' : `module_${module.moduleName}`}
                            control={control}
                            setValue={setValue}
                            label={t(`ROLE_${module.moduleName}`)}
                            options={module.permissionGroups}
                            labelKey="name"
                            valueKey="name"
                            defaultValue={getDefaultPermissions(module.moduleName, role!)}
                        />
                    </Grid>
                ))}
            </Grid>
            {/* Currently the form is readonly until furthure notice 
            <Divider sx={{ my: 2 }} />
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <RoundButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, mb: 2 }}
                    disabled={loading}
                    title="Submit"
                    data-testid="submit-button"
                >
                    {loading ? (
                        <CircularProgress size={25} />
                    ) : (
                        editMode ?
                            <Typography fontSize="13px">Update</Typography>
                            :
                            <Typography fontSize="13px">Create</Typography>
                    )}
                </RoundButton>
            </Grid> */}
        </Box>
    );
}

export default observer(RoleForm);