import { Box, Divider, Grid, Typography } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";

import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { FormInputText } from "../../../../components/form/FormInputText";
import FormInputSelect from "../../../../components/form/FormInputSelect";
import { useStore } from "../../../../stores/store";
import { RoundButton } from "../../../../_styles/StyledButtons";
import AccountPermissions from "./AccountPermissions";
import FormInputChipSelect from "../../../../components/form/FormInputChipSelect";
import { UserInstitutionDto } from "../../../../data/models/user";
import "./styles.css";
import { ProfileUpdateDto } from "../../../../data/models/profile";
import { portalLanguages } from "../../../../data/static/language";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { profileFormSchema } from "../../../../_validators/schemas/profileForm.schema";
import { Trans, useTranslation } from "react-i18next";
import { surveyLanguages } from "../../../../data/static/survey";

function AccountForm() {
    const { t } = useTranslation();
    const {
        userStore: { getAssignedPermissions },
        languageStore: { getLanguages, languageList },
        profileStore: {
            profile,
            updateProfile,
            getProfile,
            defaultUserInstitution,
            getUserTenants,
        },
        tenantStore: { selectedTenant },
        authStore: { user, getCurrentUser },
        institutionStore: { setSelectedUserInstitution }
    } = useStore();
    const [userInstitutions, setUserInstitutions] = useState<
        UserInstitutionDto[] | undefined
    >(undefined);

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(profileFormSchema()),
        defaultValues: {
            firstName: profile?.firstName || "",
            lastName: profile?.lastName || "",
            title: profile?.title || "",
            email: profile?.email || "",
            phoneNumber: profile?.phoneNumber || "0",
            portalLanguage: profile?.platformLanguage?.alpha2 || "en",
            communicationLanguage: profile?.communicationLanguage,
            defaultInstitution: defaultUserInstitution?.id || "",
            languages: profile?.userLanguages.map(
                (language) => language.alpha2
            ),
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            await getLanguages();
            const institutionPermissions = await getAssignedPermissions(
                user?.id!,
                selectedTenant?.id!
            );
            const filteredInstitutions = institutionPermissions
                .filter((institution) => institution.belongsTo)
                .map((institution) => ({
                    ...institution,
                    roles: institution.roles.filter((role) => role.isActive),
                }));
            setUserInstitutions(filteredInstitutions);
        };
        fetchData();
    }, [
        setUserInstitutions,
        getLanguages,
        getAssignedPermissions,
        selectedTenant?.id,
        user?.id,
    ]);

    const onSubmit = async (data: any) => {
        const { languages, portalLanguage, communicationLanguage, defaultInstitution, ...remainingData } =
            data;
        const profileData: ProfileUpdateDto = {
            ...remainingData,
            userLanguages: languages.map((language: string) => language),
            platformLanguageAlpha2: portalLanguage,
            communicationLanguage: communicationLanguage,
            defaultInstitutionId: defaultInstitution,
        };

        await updateProfile(profileData);
        await getProfile();
        await getUserTenants();
        setSelectedUserInstitution(data.defaultInstitution);
        await getCurrentUser();
        toast.success(t("TOAST_PROFILE_UPDATE"));
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                columnGap={5}
                rowSpacing={1}
                className="account-container"
            >
                <Grid item xs={12} md={12} marginBottom={2}>
                    <Typography fontSize="24px" fontWeight="500">
                        {t("ACC_LABEL")}
                    </Typography>
                    <Typography component="p" variant="body2" color="#808080">
                        {t("ACC_INFO")}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormInputText
                        name="firstName"
                        label={`${t("USER_FIRST_NAME")} *`}
                        control={control}
                        icon={<AccountCircleOutlinedIcon color="secondary" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormInputText
                        name="lastName"
                        label={`${t("USER_LAST_NAME")} *`}
                        control={control}
                        icon={<AccountCircleOutlinedIcon color="secondary" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormInputText
                        name="title"
                        label={`${t("ACC_TITLE")}`}
                        control={control}
                        icon={<BusinessCenterOutlinedIcon color="secondary" />}
                    />
                </Grid>
                <Grid item xs={12} md={8.4} my={2}>
                    <Divider className="divider" />
                </Grid>
                <Grid item xs={12} md={12} mb={2}>
                    <Typography fontSize="24px" fontWeight="500">
                        {t("ACC_PI")}
                    </Typography>
                    <Typography fontSize="14px" color="gray">
                        <Trans i18nKey={"ACC_PI_INFO"} values={{ connect: "TSA" }} />
                        {t("ACC_PI_INFO", { connect: "yoo" })}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormInputText
                        name="email"
                        label={`${t("GENERAL_EMAIL")}`}
                        control={control}
                        icon={<EmailOutlinedIcon color="secondary" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormInputText
                        name="phoneNumber"
                        label={`${t("GENERAL_PHONE_NUMBER")}`}
                        control={control}
                        icon={<PhoneInTalkOutlinedIcon color="secondary" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormInputSelect
                        name="portalLanguage"
                        label={`${t("USER_PORTAL_LANG")}`}
                        control={control}
                        options={portalLanguages}
                        labelKey="name"
                        valueKey="alpha2"
                        icon={<LanguageOutlinedIcon color="secondary" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormInputSelect
                        name="communicationLanguage"
                        control={control}
                        label={`${t("USER_COMM_LANG")} *`}
                        options={surveyLanguages}
                        labelKey="name"
                        valueKey="code"
                    // defaultValue={user?.communicationLanguage}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormInputChipSelect
                        name="languages"
                        control={control}
                        label={`${t("USER_LANG")}`}
                        options={languageList}
                        labelKey="name"
                        valueKey="alpha2"
                        search={true}
                        defaultValues={profile?.userLanguages.map(
                            (language) => language.alpha2
                        )}
                    />
                </Grid>
                {!user?.isSuperAdmin && (
                    <>
                        <Grid item xs={12} md={8.4}>
                            <Divider className="divider" />
                        </Grid>
                        <Grid item xs={12} md={9} marginBottom={3}>
                            <Typography fontSize="24px" fontWeight="500">
                                {t("ACC_DEFAULT_INST")}
                            </Typography>
                            <Typography fontSize="14px" color="gray">
                                {t("ACC_INST_INFO")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8.4} marginBottom={2}>
                            {userInstitutions && (
                                <FormInputSelect
                                    options={userInstitutions}
                                    labelKey="name"
                                    valueKey="id"
                                    name="defaultInstitution"
                                    control={control}
                                    defaultValue={defaultUserInstitution?.id}
                                />
                            )}
                        </Grid>
                    </>
                )}
                <Grid item xs={12} md={8.4} marginBottom={5}>
                    {userInstitutions && userInstitutions.length > 0 && (
                        <AccountPermissions institutions={userInstitutions} />
                    )}
                </Grid>
                <Grid item xs={12} md={8.4}>
                    <Box display={"flex"} justifyContent="flex-end" marginBottom="6rem">
                        <RoundButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            title="Save"
                            data-testid="submit-button"
                        >
                            <Typography fontSize="13px">{t("GENERAL_SAVE")}</Typography>
                        </RoundButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default observer(AccountForm);
