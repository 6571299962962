import { Box, IconButton, Typography } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import { useNavigate } from "react-router-dom";

import { useStore } from "../stores/store";
import "./styles.css";

const selectedRipple = {
    position: "absolute",
    width: "100%",
    height: "2px",
    background: "#004e7c",
    bottom: "-2px",
};

interface AccountMenuProps {
    isInPath: (path: string) => boolean;
}

function AccountMenu({ isInPath }: AccountMenuProps) {
    const {
        authStore: { user },
    } = useStore();
    const navigate = useNavigate();

    return (
        <Box position={"relative"} display={"flex"} alignItems={"center"} height={"100%"}>
            {isInPath("/profile") && <Box sx={selectedRipple}></Box>}
            {user !== null && user !== undefined ? (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "baseline",
                        cursor: "pointer",
                        fontWeight: "bold",
                    }}
                    onClick={() => navigate("/profile/account")}
                >
                    <IconButton sx={{ borderRadius: "1rem" }} disableRipple>
                        {isInPath("/profile") ? <AccountCircleRoundedIcon sx={{ color: "#004e7c", fontSize: "20px" }} /> : <AccountCircleOutlinedIcon sx={{ color: "#66717d", fontSize: "20px" }} />}
                        <Typography
                            variant="caption"
                            sx={{ paddingLeft: 1, color: isInPath("/profile") ? "#004e7c !important" : "#66717d", fontWeight: "500", fontSize: "13px" }}
                            className="navbar-menu-font navbar-user-name"
                        >
                            {user?.firstName} {user?.lastName}
                        </Typography>
                    </IconButton>
                </Box>
            ) : (
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => navigate("/profile")}
                    data-testid="navbar-menu-avatar"
                >
                    <AccountCircle sx={{ color: "#66717d" }} />
                </IconButton>
            )}
        </Box>
    );
}

export default AccountMenu;
