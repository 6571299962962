import { Box, Button, Menu, MenuItem, SvgIcon } from "@mui/material";
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';

import { FunctionComponent, SVGProps, useEffect, useState } from "react";

import { ReactComponent as FrenchIcon } from "./flags/french.svg";
import { ReactComponent as GermanIcon } from "./flags/german.svg";
import { ReactComponent as EnglandIcon } from "./flags/england.svg";

import { useTranslation } from "react-i18next";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";

interface Language {
    img: FunctionComponent<SVGProps<SVGSVGElement>>;
    key: string;
    label: string;
}

const languages: Language[] = [
    {
        img: EnglandIcon,
        key: "en",
        label: "English"
    },
    {
        img: GermanIcon,
        key: "de",
        label: "German",
    },
    {
        img: FrenchIcon,
        key: "fr",
        label: "French",
    },

];

function LanguageSelector() {
    const { authStore, profileStore } = useStore();
    const { user } = authStore;
    const { changeLanguage } = profileStore;
    const { i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState<Language>(languages[0]);

    useEffect(() => {
        const portalLanguages = ["en", "de", "fr"];
        const userLang = user?.platformLanguageId ?? "en";
        if (portalLanguages.includes(userLang)) {
            i18n.changeLanguage(userLang);
            const selectedLang = languages.find(l => l.key === userLang) ?? languages[0];
            setSelectedLang(selectedLang);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (lang: Language) => {
        if (user) {
            changeLanguage(lang.key);
        }
        setSelectedLang(lang);
        i18n.changeLanguage(lang.key)
            .catch(err => console.log("Error changing language, ", err));
        handleClose();
    }

    return (
        <Box>
            <Button sx={{ p: "5px", minWidth: "40px", height: "25px", mr: "16px" }} onClick={handleClick} disableTouchRipple>
                <SvgIcon component={selectedLang.img} viewBox="0 0 600 476.6" sx={{ height: "20px", width: "20px" }} />
                {open ?
                    <ArrowDropUpRoundedIcon sx={{ color: "#66717d" }} />
                    :
                    <ArrowDropDownRoundedIcon sx={{ color: "#66717d" }} />
                }
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transitionDuration={0}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {languages.map(lang => (
                    <MenuItem
                        key={lang.key}
                        onClick={() => handleChange(lang)}
                        className="lang-img-item"
                        disableTouchRipple
                    >
                        <SvgIcon component={lang.img} viewBox="0 0 600 476.6" sx={{ height: "18px", width: "18px", pr: "4px" }} />
                        {lang.label}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}

export default observer(LanguageSelector);