import { makeAutoObservable, runInAction } from 'mobx';
import { store } from './store';
import agent from '../utils/agent';
import { TemplateContentDto, TemplateDefinitionDto, TemplateInstanceCreateDto, TemplateInstanceDto, TemplatePatchDto } from '../data/models/notification';
import { toast } from 'react-toastify';
import { t } from "i18next";
export default class TemplateStore {
    templates: Map<string, TemplateDefinitionDto> = new Map<string, TemplateDefinitionDto>();
    templateInstances: Map<string, TemplateInstanceDto> = new Map<string, TemplateInstanceDto>();
    contents: Map<string, TemplateContentDto> = new Map<string, TemplateContentDto>();
    content: string = '';
    hasAllChannels: boolean = true;
    commChannels = [
        { value: 'EMAIL', name: t('USER_EMAIL') },
        { value: 'PHONE', name: 'Whatsapp' }
    ];

    templateCommChannels = [{ value: '', name: '' }]

    constructor() {
        makeAutoObservable(this);
    }

    get templateList() {
        return Array.from(this.templates.values());
    }

    get templateInstanceList() {
        return Array.from(this.templateInstances.values());
    }

    get contentList() {
        return Array.from(this.contents.values());
    }

    get htmlContent() {
        return this.content;
    }

    setContent(value: string) {
        if (value !== undefined) {
            this.content = value;
        }
    }

    reset() {
        this.content = '';
    }

    getTemplates = async () => {
        try {
            store.loadingStore.startLoading(this.getTemplates);
            const listOfTemplates = await agent.Notifications.get();
            runInAction(() => {
                const sortedTemplates = listOfTemplates.sort((a, b) => a.name.localeCompare(b.name));
                sortedTemplates.forEach((template) => {
                    this.templates.set(template.id, template);
                });
            })
        } catch (error) {
            store.loadingStore.stopLoading(this.getTemplates)
            throw error;
        }
    }

    getTemplateInstances = async (templateInstanceId: string, institutionId: string, languageId?: string) => {
        try {
            store.loadingStore.startLoading(this.getTemplateInstances);
            const listOfInstances = await agent.Notifications.getInstances(templateInstanceId, institutionId, languageId);
            runInAction(() => {
                this.templateInstances.clear()
                listOfInstances.forEach((instance) => {
                    this.templateInstances.set(instance.id, instance);
                });
                store.loadingStore.stopLoading(this.getTemplateInstances);
            })
        } catch (error) {
            store.loadingStore.stopLoading(this.getTemplateInstances)
            throw error;
        }
    }

    getTemplateContent = async (templateInstanceId: string) => {
        try {
            store.loadingStore.startLoading(this.getTemplateContent)
            const listOfContents = await agent.Notifications.getContent(templateInstanceId);

            if (!listOfContents.some(c => c.commChannel === 'PHONE')) {
                this.templateCommChannels = this.commChannels.filter(c => c.value !== 'PHONE');
                this.hasAllChannels = false;
            }
            else {
                this.templateCommChannels = this.commChannels;
                this.hasAllChannels = true;
            }

            runInAction(() => {
                this.contents.clear()
                listOfContents.forEach(content => {
                    this.contents.set(content.id, content)
                    store.loadingStore.stopLoading(this.getTemplateContent)
                });
            })
        } catch (error) {
            store.loadingStore.stopLoading(this.getTemplateContent)
            throw error;
        }
    }

    createTemplate = async (templateDefinitionId: string, templateInstanceForCreate: TemplateInstanceCreateDto) => {
        try {
            store.loadingStore.startLoading(this.createTemplate)
            await agent.Notifications.createInstance(templateDefinitionId, templateInstanceForCreate)
            runInAction(() => {
                store.loadingStore.stopLoading(this.createTemplate)
            })
        } catch (error) {
            store.loadingStore.stopLoading(this.createTemplate)
            throw error;
        }
    }

    updateTemplate = async (templateInstanceId: string, templateInstanceForUpdate: TemplateInstanceCreateDto) => {
        try {
            store.loadingStore.startLoading(this.updateTemplate)
            await agent.Notifications.updateContent(templateInstanceId, templateInstanceForUpdate)
            runInAction(() => {
                store.loadingStore.stopLoading(this.updateTemplate)
            })
        } catch (error) {
            store.loadingStore.stopLoading(this.updateTemplate)
            throw error;
        }
    }

    patchDocument = async (templateInstanceId: string, institutionId: string, patchDocument: TemplatePatchDto) => {
        try {
            store.loadingStore.startLoading(this.patchDocument)
            await agent.Notifications.patchInstance(templateInstanceId, institutionId, patchDocument)
            runInAction(() => {
                store.loadingStore.stopLoading(this.patchDocument)
            })
        } catch (error) {
            store.loadingStore.stopLoading(this.patchDocument)
            throw error;
        }
    }

    deleteTemplate = async (templateInstanceId: string) => {
        try {
            store.loadingStore.startLoading(templateInstanceId)
            await agent.Notifications.deleteInstance(templateInstanceId)
            runInAction(() => {
                store.loadingStore.stopLoading(this.deleteTemplate);
                toast.success(t('TOAST_TEMPLATE_DELETED'))
            })
        } catch (error) {
            store.loadingStore.stopLoading(templateInstanceId)
            throw error;
        }
    }
}
