import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GenericTable from '../../../../components/GenericTable';
import { useClearBrightTimeline } from '../../../../components/hooks/clientCustomHooks';
import { ClientSessionTimelineOverviewItem } from '../../../../data/models/client';
import { useStore } from '../../../../stores/store';
import { getGenericTableStyling } from '../../../../utils/utils';
import ClientSessionTimelineOverviewToolbar from './ClientSessionTimelineOverviewToolbar';
import { getBrightTimeLineTableColumns } from './ClientSessionTimelineOverviewColumns';

const ClientSessionTimeLineOverview: React.FC = () => {
    const { clientStore: { brightTimelineList, sessionStartDate, loading } } = useStore();
    const { t } = useTranslation();
    useClearBrightTimeline()

    return (
        <GenericTable<ClientSessionTimelineOverviewItem>
            toolbar={< ClientSessionTimelineOverviewToolbar />}
            rowKey={'id'}
            columns={getBrightTimeLineTableColumns(sessionStartDate, t)}
            rows={brightTimelineList}
            loading={loading}
            props={getGenericTableStyling()}
            noDataMessage={`${t("PLEASE_SELECT_A_SESSION_FOR_DETAILS")}`}
        />
    );
};

export default observer(ClientSessionTimeLineOverview);