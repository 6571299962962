import { useEffect, useState } from "react";
import {
    Box,
    Divider,
    Grid,
} from "@mui/material";
import { useStore } from "../../stores/store";
import {
    DateRange,
    K10Stats,
    NoEventStats,
    PmStats,
    ScreeningStats,
} from "../../data/models/stats";
import { observer } from "mobx-react-lite";
import { Container } from "@mui/system";
import "./styles.css";
import { useForm } from "react-hook-form";
import FormInputDate from "../../components/form/FormInputDate";
import { RoundButton } from "../../_styles/StyledButtons";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { statsFormSchema } from "../../_validators/schemas/statsForm.schema";
import NoEventTable from "./NoEventTable";
import PMTable from "./PMTable";
import K10Table from "./K10Table";
import DemographicTable from "./DemographicTable";
import LanguageTable from "./LanguageTable";
import { useTranslation } from "react-i18next";

function StatsTable() {
    const { t } = useTranslation();
    const { institutionStore, dataExportStore, loadingStore } = useStore();
    const { selectedUserInstitution } = institutionStore;
    const {
        getSessionStats,
        getInterventionStats,
        getNoEventUsers,
        getCompletedPMEvents,
        getK10Stats,
    } = dataExportStore;
    const { isLoading } = loadingStore;
    const { control, handleSubmit, setValue } = useForm({
        resolver: yupResolver(statsFormSchema()),
    });

    const [sessionData, setSessionData] = useState<ScreeningStats | null>(null);
    const [interventionData, setinterventionData] =
        useState<ScreeningStats | null>(null);
    const [noEventStats, setNoEventStats] = useState<NoEventStats[]>([]);
    const [pmStats, setPmStats] = useState<PmStats[]>([]);
    const [blStats, setBlStats] = useState<K10Stats | null>(null);
    const [t2Stats, setT2Stats] = useState<K10Stats | null>(null);
    const [t3Stats, setT3Stats] = useState<K10Stats | null>(null);

    let date = new Date();
    const [firstDay, setFirstDay] = useState(
        dayjs(new Date(date.getFullYear(), date.getMonth(), 1))
    );
    const [lastDay, setLastDay] = useState(
        dayjs(new Date(date.getFullYear(), date.getMonth() + 1, 0))
    );

    useEffect(() => {
        setValue("startDate", firstDay.toDate());
        setValue("endDate", lastDay.toDate());
        const fetchData = async () => {
            const now = new Date();
            now.setMonth(now.getMonth() + 1);

            const range: DateRange = {
                startDate: firstDay.toISOString(),
                endDate: lastDay.toISOString(),
            };
            onSubmit(range);
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserInstitution]);

    const onSubmit = async (data: any) => {
        const startDate = dayjs(data.startDate).add(dayjs(data.startDate).utcOffset(), 'minute').toISOString();
        const endDate = dayjs(data.endDate).add(dayjs(data.endDate).utcOffset(), 'minute')
            .add(23, 'hours')
            .add(59, 'minutes')
            .add(59, 'seconds')
            .toISOString();

        setFirstDay(dayjs(data.startDate));
        setLastDay(dayjs(data.endDate));

        const range: DateRange = {
            startDate,
            endDate,
        };

        if (selectedUserInstitution) {
            const institutionId = selectedUserInstitution.institutionId;
            const sessionStats = await getSessionStats(institutionId, range);
            const interventionStats = await getInterventionStats(
                institutionId,
                range
            );
            const noEventStats = await getNoEventUsers(institutionId, range);
            const pmStats = await getCompletedPMEvents(institutionId, range);
            const blStats = await getK10Stats(institutionId, 0, range);
            const t2Stats = await getK10Stats(institutionId, 1, range);
            const t3Stats = await getK10Stats(institutionId, 2, range);

            sessionStats.demographicStats.forEach(stat => {
                stat.location = stat.location?.trim();
            });

            sessionStats.languageStats.forEach(stat => {
                stat.location = stat.location?.trim();
            });
            setSessionData(sessionStats);
            setinterventionData(interventionStats);
            setNoEventStats(noEventStats);
            setPmStats(pmStats);
            setBlStats(blStats);
            setT2Stats(t2Stats);
            setT3Stats(t3Stats);
        }
    };

    if (!sessionData || !interventionData) return <div>Loading...</div>;

    return (
        <Container maxWidth="xl" className="stats-container">
            <Grid
                container
                component={"form"}
                sx={{ display: "flex", alignItems: "center", maxWidth: "500px" }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid item xs={4} mr={2}>
                    <FormInputDate
                        name="startDate"
                        control={control}
                        date={firstDay.toDate()}
                        label="Start date"
                    />
                </Grid>
                <Grid item xs={4} mr={2}>
                    <FormInputDate
                        name="endDate"
                        control={control}
                        date={lastDay.toDate()}
                        label="End date"
                    />
                </Grid>
                <Grid item xs={2}>
                    <RoundButton
                        variant="text"
                        type="submit"
                        disabled={isLoading([
                            getCompletedPMEvents,
                            getInterventionStats,
                            getK10Stats,
                            getSessionStats,
                            getNoEventUsers,
                        ])}
                    >
                        Apply
                    </RoundButton>
                </Grid>
            </Grid>
            <Box width={"100%"}>
                <DemographicTable
                    data={sessionData.demographicStats}
                    title={t("STAT_T1_TITLE")}
                />
                <LanguageTable data={sessionData.languageStats} title={t("STAT_T1_LANG_TITLE")} />
            </Box>
            <Divider
                sx={{ borderBottomWidth: "medium", mt: "2rem", width: "100%" }}
            />
            <Box width={"100%"} mt={"2rem"}>
                <DemographicTable
                    data={interventionData.demographicStats}
                    title={t("STAT_T2_TITLE")}
                />
                <LanguageTable
                    data={interventionData.languageStats}
                    title={t("STAT_T2_LANG_TITLE")}
                />
            </Box>
            <Divider
                sx={{ borderBottomWidth: "medium", mt: "2rem", width: "100%" }}
            />
            <Box width={"100%"} mt={"2rem"}>
                <NoEventTable data={noEventStats} title={t("STAT_T3_TITLE")} />
            </Box>
            <Divider
                sx={{ borderBottomWidth: "medium", mt: "2rem", width: "100%" }}
            />
            <Box width={"100%"} mt={"2rem"}>
                <PMTable data={pmStats} title={t("STAT_T4_TITLE")} />
            </Box>
            <Divider
                sx={{ borderBottomWidth: "medium", mt: "2rem", width: "100%" }}
            />
            <Box width={"100%"} mt={"2rem"}>
                <K10Table data={blStats} title="5A" />
            </Box>
            <Box width={"100%"} mt={"2rem"}>
                <K10Table data={t2Stats} title="5B" />
            </Box>
            <Box width={"100%"} mt={"2rem"}>
                <K10Table data={t3Stats} title="5C" />
            </Box>
        </Container>
    );
}

export default observer(StatsTable);
