import { makeAutoObservable, runInAction } from "mobx";

import agent from "../utils/agent";
import { CalendarEvent, EventDetailsDto, EventDeleteDto, EventStatusDto, EventType, Helper, CalendarRoom, EventUpdateDto, EventCreateDto, EventDisplay, OtherCalendarEvent } from "../data/models/event";
import { datetime, rrulestr } from "rrule";
import moment from "moment";
import { store } from "./store";
import { getISODate, compareStringDateIfExist } from "../utils/utils";
import { EventAttendee, TenantUserDto } from "../data/models/user";
import { t } from "i18next";
import dayjs from "dayjs";

type ViewType = 'month' | 'week' | 'work_week' | 'day';

export default class BookingStore {
    bookings: Map<string, CalendarEvent[]> | undefined = new Map<string, CalendarEvent[]>();
    usersCalendar: Map<string, TenantUserDto> = new Map<string, TenantUserDto>();
    roomsCalendar: Map<string, CalendarRoom> = new Map<string, CalendarRoom>();
    viewType: ViewType = sessionStorage.getItem('viewType') as ViewType || 'week';
    selectedDate: Date = new Date();
    bookingEvents: CalendarEvent[] = [];
    eventTypes: EventType[] = [];
    helpers: Helper[] = [];
    userEventsId: string = '';
    assessmentId: string | undefined;
    interventionId: string | undefined;
    editMode = false;
    loading: boolean = false;
    loadingTypes: boolean = false;
    eventAttendees: Map<string, EventAttendee> = new Map<string, EventAttendee>();
    roleFilters: string[] = [];

    constructor() {
        makeAutoObservable(this)
    }

    get bookingList() {
        const bookings = Array.from(this.bookings!.values());
        return bookings.flat();
    }

    get userCalendarList() {
        return Array.from(this.usersCalendar!.values());
    }

    get roomCalendarList() {
        return Array.from(this.roomsCalendar!.values());
    }

    get eventAttendeeList() {
        return Array.from(this.eventAttendees!.values())
    }

    addRoleFilter = (role: string) => {
        runInAction(() => this.roleFilters.push(role));
    }

    removeRoleFilter = (role: string) => {
        const index = this.roleFilters.indexOf(role);
        if (index > -1) {
            runInAction(() => this.roleFilters.splice(index, 1));
        }
    }

    clearRoleFilters = () => {
        runInAction(() => this.roleFilters = []);
    }

    setUserEventsId = (id: string) => {
        this.userEventsId = id;
    }

    getEventsByUserId = (userId: string) => {
        return this.bookings?.get(userId) ?? [];
    }

    getClientEvents = async (clientId: string, allSeries: boolean = true) => {
        store.loadingStore.startLoading(this.getClientEvents);
        try {
            const institutionId = store.institutionStore.selectedUserInstitution?.institutionId;
            let calendarEvents: CalendarEvent[] = await agent.Events.getClientEvents(clientId, institutionId!)
            if (!allSeries) {
                calendarEvents = calendarEvents.map((event) => {
                    return { ...event, start: new Date(event.start), end: new Date(event.end) }
                });
                return calendarEvents;
            }

            this.applyEventsToCalendar(calendarEvents, clientId);
            const allUserEvents = this.bookings?.get(clientId);
            this.bookings?.delete(clientId);

            const futureUserEvents = allUserEvents!.filter(e => new Date(e.start).getTime() >= new Date().getTime());
            store.loadingStore.stopLoading(this.getClientEvents);
            return futureUserEvents;
        } catch (error) {
            store.loadingStore.stopLoading(this.getClientEvents);
            throw error;
        }
    }

    getEvents = async (userId: string) => {
        store.loadingStore.startLoading(this.getEvents);
        this.loading = true;
        try {
            const institutionId = store.institutionStore.selectedUserInstitution?.institutionId;
            const calendarEvents: EventDisplay = await agent.Events.get(userId, institutionId!);
            runInAction(() => {
                this.applyEventsToCalendar(calendarEvents.currentInstitutionEvents, userId, calendarEvents.differentInstitutionEvents);
                this.loading = false;
            });

            store.loadingStore.stopLoading(this.getEvents);
        } catch (error) {
            store.loadingStore.stopLoading(this.getEvents);
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    getUsersCalendar = async (languages?: string[]) => {
        store.loadingStore.startLoading(this.getUsersCalendar);
        this.loading = true;
        try {
            const institutionId = store.institutionStore.selectedUserInstitution?.institutionId;
            const usersCalendar: TenantUserDto[] = await agent.Events.getUsersCalendar(institutionId!, languages);
            runInAction(() => {
                this.usersCalendar.clear();
                usersCalendar.forEach((user) => {
                    this.usersCalendar?.set(user.id, user);
                });
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getUsersCalendar);
        } catch (error) {
            store.loadingStore.stopLoading(this.getUsersCalendar);
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    createEvent = async (bookingEvent: EventCreateDto) => {
        store.loadingStore.startLoading(this.createEvent);

        this.loading = true;
        try {
            await agent.Events.create(bookingEvent);
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.createEvent);
        } catch (error: any) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.createEvent);
            throw error;
        }
    }

    createClientEvent = async (bookingEvent: EventCreateDto) => {
        store.loadingStore.startLoading(this.createClientEvent);
        this.loading = true;
        try {
            await agent.Events.createClientEvent(bookingEvent);
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.createClientEvent);
        } catch (error: any) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.createClientEvent);
            throw error;
        }
    }

    getEventById = async (eventId: string) => {
        store.loadingStore.startLoading(this.getEventById);
        this.loading = true;
        try {
            const eventDetails: EventDetailsDto = await agent.Events.getById(eventId);
            runInAction(() => {
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getEventById);

            return eventDetails;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getEventById);

            throw error;
        }
    }

    updateEvent = async (bookingEvent: EventUpdateDto) => {
        store.loadingStore.startLoading(this.updateEvent);
        this.loading = true;
        try {
            const institutionId = store.institutionStore.selectedUserInstitution?.institutionId;
            await agent.Events.update(bookingEvent, institutionId!);
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.updateEvent);
        } catch (error: any) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.updateEvent);
            throw error;
        }
    }

    updateExceptionEvent = async (bookingEvent: EventUpdateDto, exceptionDate: string) => {
        store.loadingStore.startLoading(this.updateExceptionEvent);
        this.loading = true;
        try {
            await agent.Events.updateExceptionEvent(bookingEvent, exceptionDate);
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.updateExceptionEvent);
        } catch (error: any) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.updateExceptionEvent);
            throw error;
        }
    }

    deleteEvent = async (eventObject: EventDeleteDto, date: string | null) => {
        store.loadingStore.startLoading(this.deleteEvent);
        this.loading = true;
        try {
            const institutionId = store.institutionStore.selectedUserInstitution?.institutionId;
            await agent.Events.deleteEvent(institutionId!, eventObject);

            runInAction(() => {
                this.bookings?.forEach((events, userId) => {
                    if (events.find(event => event.id === eventObject.eventId)) {
                        const filteredEvents = events.filter(event => event.id !== eventObject.eventId);
                        const filteredOtherEvents = filteredEvents.filter(event => event.id !== '' && event.start !== eventObject.startDate);
                        this.bookings?.set(userId, filteredOtherEvents);
                    }
                });

                this.loading = false
            });

            store.loadingStore.stopLoading(this.deleteEvent);

        } catch (error: any) {
            runInAction(() => { this.loading = false });
            store.loadingStore.stopLoading(this.deleteEvent);
            throw error;
        }
    }

    deleteClientEvent = async (eventObject: EventDeleteDto, date: string | null) => {
        store.loadingStore.startLoading(eventObject.eventId);
        this.loading = true;
        try {
            const institutionId = store.institutionStore.selectedUserInstitution?.institutionId;
            await agent.Events.deleteClientEvent(institutionId!, eventObject);
            runInAction(() => {
                this.bookings?.forEach((events, userId) => {
                    if (events.find(event => event.id === eventObject.eventId)) {
                        const filteredEvents = events.filter(event =>
                            date ? getISODate(event.start) !== date || event.id !== eventObject.eventId : event.start < new Date() || event.id !== eventObject.eventId);
                        this.bookings?.set(userId, filteredEvents);
                    }
                });
                this.loading = false
            });
            store.loadingStore.stopLoading(eventObject.eventId);
        } catch (error: any) {
            runInAction(() => { this.loading = false });
            store.loadingStore.stopLoading(eventObject.eventId);
            throw error;
        } finally {
            store.loadingStore.stopLoading(eventObject.eventId);
        }
    }

    addExceptionEvent = async (eventId: string, exceptionDate: string | null) => {
        store.loadingStore.startLoading(this.addExceptionEvent);
        this.loading = true;
        try {
            await agent.Events.addEventException(eventId, exceptionDate);
            runInAction(() => {
                this.bookings?.forEach((events, userId) => {
                    if (events.find(event => event.id === eventId)) {
                        const filteredEvents = events.filter(event =>
                            exceptionDate ? getISODate(event.start) !== exceptionDate || event.id !== eventId : event.start < new Date() || event.id !== eventId);
                        this.bookings?.set(userId, filteredEvents);
                    }
                });
                this.loading = false
            });
            store.loadingStore.stopLoading(this.addExceptionEvent);
        } catch (error: any) {
            runInAction(() => { this.loading = false });
            store.loadingStore.stopLoading(this.addExceptionEvent);
            throw error;
        }
    }

    getEventTypes = async (tenantId: string) => {
        store.loadingStore.startLoading(this.getEventTypes);
        this.loadingTypes = true;
        try {
            const eventTypes = await agent.Events.getEventTypes(tenantId);

            eventTypes.forEach(element => {
                element.translatedName = t(element.name);
            });

            runInAction(() => {
                this.assessmentId = eventTypes.find(e => e.name === "ASSESSMENT")?.id;
                this.interventionId = eventTypes.find(e => e.name === "INTERVENTION")?.id;
                this.eventTypes = eventTypes;
                this.loadingTypes = false
            });
            store.loadingStore.stopLoading(this.getEventTypes);
        } catch (error) {
            runInAction(() => this.loadingTypes = false);
            store.loadingStore.stopLoading(this.getEventTypes);
            throw error;
        }
    }

    getUserEvents = async () => {
        store.loadingStore.startLoading(this.getUserEvents);
        this.loading = true;
        try {
            const institutionId = store.institutionStore.selectedUserInstitution?.institutionId;
            const calendarEvents: CalendarEvent[] = await agent.Events.getCurrentUserEvents(institutionId!);

            runInAction(() => {
                if (this.userEventsId) {
                    this.applyEventsToCalendar(calendarEvents, this.userEventsId, undefined);
                }
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getUserEvents);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getUserEvents);
            throw error;
        }
    }

    changeEventStatus = async (eventStatusDto: EventStatusDto) => {
        store.loadingStore.startLoading(this.changeEventStatus);
        this.loading = true;
        try {
            await agent.Events.changeEventStatus(eventStatusDto);
            runInAction(() => { this.loading = false });
            store.loadingStore.stopLoading(this.changeEventStatus);
        } catch (error: any) {
            runInAction(() => { this.loading = false });
            store.loadingStore.stopLoading(this.changeEventStatus);
            throw error;
        }
    }

    setViewType = (viewType: ViewType) => {
        this.viewType = viewType;
        store.commonStore.setViewType(viewType);
    }

    removeEventsById = (id: string) => {
        this.bookings?.delete(id);
    }

    clearEvents = () => {
        this.bookings?.clear();
    }

    filterEvents = (userIds: string[]) => {
        const ids = Array.from(this.bookings?.keys()!);

        ids.forEach((id) => {
            if (!userIds.includes(id)) {
                this.bookings?.delete(id);
            }
        });
    }

    // check whether date is on daylight saving time or not
    isDST = (date: Date) => {
        let jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
        let jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();

        return Math.max(jan, jul) !== date.getTimezoneOffset();
    }

    applyEventsToCalendar = async (calendarEvents: CalendarEvent[], viewId: string, otherCalendarEvents?: OtherCalendarEvent[]) => {
        this.bookingEvents = [];

        //For other events
        if (otherCalendarEvents && otherCalendarEvents.length > 0) {
            otherCalendarEvents.forEach((otherCalendarEvent, index) => {
                otherCalendarEvent.endTime = moment(otherCalendarEvent.endTime).toDate();
                otherCalendarEvent.startTime = moment(otherCalendarEvent.startTime).toDate();

                if (otherCalendarEvent.recurrence === 'no-repeat') {
                    const startDate = datetime(
                        otherCalendarEvent.startTime.getUTCFullYear(),
                        otherCalendarEvent.startTime.getUTCMonth() + 1,
                        otherCalendarEvent.startTime.getUTCDate(),
                        otherCalendarEvent.startTime.getUTCHours(),
                        otherCalendarEvent.startTime.getUTCMinutes());

                    const endDate = datetime(
                        otherCalendarEvent.endTime.getUTCFullYear(),
                        otherCalendarEvent.endTime.getUTCMonth() + 1,
                        otherCalendarEvent.endTime.getUTCDate(),
                        otherCalendarEvent.endTime.getUTCHours(),
                        otherCalendarEvent.endTime.getUTCMinutes());
                    const transformedEvent = transformToCalendarEvent(otherCalendarEvent, viewId, startDate, endDate)
                    this.bookingEvents.push(transformedEvent);
                }
                else {
                    otherCalendarEvent.rruleObject = rrulestr(otherCalendarEvent.recurrence)
                    const eventDuration = otherCalendarEvent.endTime.getTime() - otherCalendarEvent.startTime.getTime();
                    const freq = otherCalendarEvent.rruleObject.options.freq;

                    otherCalendarEvent.rruleObject.all().forEach((ruleDate: Date) => {
                        if (!compareStringDateIfExist(ruleDate.toISOString(), otherCalendarEvent.eventExceptions)) {
                            const eventStart = datetime(ruleDate.getUTCFullYear(), ruleDate.getUTCMonth() + 1, freq !== 3
                                && otherCalendarEvent.isAllDay
                                ? ruleDate.getUTCDate() - 1
                                : ruleDate.getUTCDate(), ruleDate.getUTCHours(), ruleDate.getUTCMinutes());
                            const eventEnd = new Date(eventStart.getTime() + eventDuration);

                            if (!this.isDST(otherCalendarEvent.startTime) && this.isDST(ruleDate)) {
                                eventStart.setHours(eventStart.getHours() - 1);
                                eventEnd.setHours(eventEnd.getHours() - 1);
                            }
                            else if (this.isDST(otherCalendarEvent.endTime) && !this.isDST(ruleDate)) {
                                eventStart.setHours(eventStart.getHours() + 1);
                                eventEnd.setHours(eventEnd.getHours() + 1);
                            }

                            const transformedEvent = transformToCalendarEvent(otherCalendarEvent, viewId, eventStart, eventEnd)
                            this.bookingEvents.push(transformedEvent);
                        }
                    })
                }
            })
        }

        //For current events
        calendarEvents.forEach((calendarEvent) => {
            const { start, end, ...remaniningCalendarEvent } = calendarEvent;
            calendarEvent.end = moment(calendarEvent.end).toDate();
            calendarEvent.start = moment(calendarEvent.start).toDate();

            if (calendarEvent.recurrence === 'no-repeat') {
                const $event: CalendarEvent = {
                    start: datetime(calendarEvent.start.getUTCFullYear(), calendarEvent.start.getUTCMonth() + 1, calendarEvent.start.getUTCDate(), calendarEvent.start.getUTCHours(), calendarEvent.start.getUTCMinutes()),
                    end: datetime(calendarEvent.end.getUTCFullYear(), calendarEvent.end.getUTCMonth() + 1, calendarEvent.end.getUTCDate(), calendarEvent.end.getUTCHours(), calendarEvent.end.getUTCMinutes()),
                    ...remaniningCalendarEvent,
                    viewId
                };
                this.bookingEvents.push($event)
            }
            else {
                calendarEvent.rruleObject = rrulestr(calendarEvent.recurrence)
                const eventDuration = calendarEvent.end.getTime() - calendarEvent.start.getTime();
                const freq = calendarEvent.rruleObject.options.freq;

                calendarEvent.rruleObject.all().forEach((date: Date) => {
                    if (!compareStringDateIfExist(date.toISOString(), calendarEvent.eventExceptions)) {
                        const eventStart = datetime(date.getUTCFullYear(), date.getUTCMonth() + 1, freq !== 3
                            && calendarEvent.isAllDay
                            ? date.getUTCDate() - 1
                            : date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes());
                        const eventEnd = new Date(eventStart.getTime() + eventDuration);

                        if (!this.isDST(calendarEvent.start) && this.isDST(date)) {
                            eventStart.setHours(eventStart.getHours() - 1);
                            eventEnd.setHours(eventEnd.getHours() - 1);
                        }
                        else if (this.isDST(calendarEvent.start) && !this.isDST(date)) {
                            eventStart.setHours(eventStart.getHours() + 1);
                            eventEnd.setHours(eventEnd.getHours() + 1);
                        }

                        const $event: CalendarEvent = {
                            start: eventStart,
                            end: eventEnd,
                            ...remaniningCalendarEvent,
                            viewId
                        }

                        this.bookingEvents.push($event)
                    }
                })
            }
        });

        this.bookings?.set(viewId, this.bookingEvents);
    };

    getInstitutionRooms = async (institutionId: string) => {
        store.loadingStore.startLoading(this.getInstitutionRooms);
        this.loading = true;
        try {
            const roomsCalendar: CalendarRoom[] = await agent.Events.getRooms(institutionId);
            runInAction(() => {
                this.roomsCalendar.clear();
                roomsCalendar.forEach((roomCalendar) => {
                    this.roomsCalendar?.set(roomCalendar.id, roomCalendar);
                });
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getInstitutionRooms);
        } catch (error) {
            runInAction(() => { this.loading = false });
            store.loadingStore.stopLoading(this.getInstitutionRooms);
            throw error;
        }
    }

    getRoomEvents = async (roomId: string) => {
        store.loadingStore.startLoading(this.getRoomEvents);
        this.loading = true;
        try {
            const institutionId = store.institutionStore.selectedUserInstitution?.institutionId;
            const roomEvents = await agent.Events.getRoomEvents(roomId, institutionId!);
            runInAction(() => {
                this.applyEventsToCalendar(roomEvents, roomId);
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getRoomEvents);
        } catch (error) {
            runInAction(() => { this.loading = false });
            store.loadingStore.stopLoading(this.getRoomEvents);
            throw error;
        }
    }

    getHelpers = async (institutionId: string) => {
        store.loadingStore.startLoading(this.getHelpers);
        this.loading = true;
        try {
            this.helpers = await agent.Events.getHelpers(institutionId);
            runInAction(() => { this.loading = false });
            store.loadingStore.stopLoading(this.getHelpers);
        } catch (error) {
            runInAction(() => { this.loading = false });
            store.loadingStore.stopLoading(this.getHelpers);
            throw error;
        }
    }

    noShowUp = async (eventId: string) => {
        store.loadingStore.startLoading(this.noShowUp);
        try {
            await agent.Events.noShow(eventId);
            store.loadingStore.stopLoading(this.noShowUp);
        } catch (error) {
            store.loadingStore.stopLoading(this.noShowUp);
            throw error;
        }
    }

    confirmScheduledAppointment = async (eventId: string, institutionId: string) => {
        store.loadingStore.startLoading(this.confirmScheduledAppointment);
        try {
            await agent.Events.cofirmSchedule(eventId, institutionId);
            store.loadingStore.stopLoading(this.confirmScheduledAppointment);
        } catch (error) {
            store.loadingStore.stopLoading(this.confirmScheduledAppointment);
            throw error;
        }
    }

    getEventAttendees = async (institutionId: string) => {
        store.loadingStore.startLoading(this.getEventAttendees);
        try {
            const response = await agent.Events.getEventAttendees(institutionId);
            store.loadingStore.stopLoading(this.getEventAttendees)
            runInAction(() => {
                this.eventAttendees.clear()
                response.forEach(user => {
                    this.eventAttendees?.set(user.id, user);
                });
            })
        } catch (error) {
            store.loadingStore.stopLoading(this.getEventAttendees)
            throw error;
        }
    }

    getPMEventsToCreate = async (attendees: string[], startDate: string, endDate: string, institutionId: string, subType: string, timezone: string) => {
        store.loadingStore.startLoading(this.getPMEventsToCreate);
        try {
            const queryString = attendees.map(attendee => `attendees=${encodeURIComponent(attendee)}`).join('&');

            const response = await agent.Events.getPMSessionEventsToCreate(queryString, startDate, endDate, institutionId, subType, timezone);
            store.loadingStore.stopLoading(this.getPMEventsToCreate)

            return response;
            // runInAction(() => {
            //     return response;
            // })
        } catch (error) {
            store.loadingStore.stopLoading(this.getPMEventsToCreate)
            throw error;
        }
    }

    createPMSessionEvents = async (pmSessionEventsToCreate: EventCreateDto[]) => {
        store.loadingStore.startLoading(this.createEvent);

        this.loading = true;
        try {
            await agent.Events.createPMSessionEvents(pmSessionEventsToCreate);
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.createEvent);
        } catch (error: any) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.createEvent);
            throw error;
        }
    }
}

function transformToCalendarEvent(differentEvent: OtherCalendarEvent, viewId: string, eventStart: Date, eventEnd: Date): CalendarEvent {
    return {
        id: '',
        title: '',
        isAllDay: false,
        attendees: [],
        start: eventStart,
        startTime: dayjs(differentEvent.startTime),
        end: eventEnd,
        endTime: dayjs(differentEvent.endTime),
        reminder: '',
        description: '',
        frequency: 1,
        recurrence: differentEvent.recurrence,
        eventExceptions: differentEvent.eventExceptions,
        rruleObject: null,
        roomName: '',
        eventType: differentEvent.eventType,
        eventStatus: '',
        resource: undefined,
        viewId: viewId,
        tenantName: '',
        attendeesStatus: [],
        isConfirmed: false,
        room: '',
        roomNotes : ''
    };
}