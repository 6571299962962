import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { useForm } from "react-hook-form";
import { FormInputNumPattern } from "../../../../../components/form/FormInputNumber";
import { yupResolver } from "@hookform/resolvers/yup";
import { ahvNumberSchema } from "../../../../../_validators/schemas/ahvNumber.schema";
import { toast } from "react-toastify";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../../stores/store";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

interface AhvNumberProps {
    clientId?: string;
}

function AhvEditForm({ clientId }: AhvNumberProps) {
    const [editMode, setEditMode] = useState(false);
    const { handleSubmit, control, resetField } = useForm({
        resolver: yupResolver(ahvNumberSchema())
    });
    const { t } = useTranslation();
    const { clientStore, institutionStore } = useStore();
    const { updateAhvNumber, getClient, ahvLoading } = clientStore;
    const { selectedUserInstitution } = institutionStore;

    const onSubmit = async (data: any) => {
        if (!clientId) {
            toast.error("Error loading the client info, please try again.");
        }

        const isEmpty = data.ahvNumber.match(/^756\.____\.____\.__|^$/)
        const isValid = data.ahvNumber && !isEmpty;
        if (isValid && clientId) {
            await updateAhvNumber(clientId, data.ahvNumber);
            await getClient(clientId, selectedUserInstitution?.institutionId!);
            setEditMode(false);
        } else if (!isEmpty) {
            toast.error("Something went wrong with AHV validation, please try again.");
        }
    };

    if (editMode) {
        return (
            <Box
                component={"form"}
                onSubmit={handleSubmit(onSubmit)}
                display={"flex"}
                width={"220px"}
            >
                <FormInputNumPattern
                    name={"ahvNumber"}
                    control={control}
                    helperText=""
                    format="756.####.####.##"
                    emptyFormatting
                />
                <Box display={"flex"}>
                    <IconButton
                        type="submit"
                        disabled={ahvLoading}
                    >
                        {ahvLoading ?
                            <CircularProgress size={25} />
                            :
                            <DoneRoundedIcon />
                        }
                    </IconButton>
                    <IconButton onClick={() => {
                        setEditMode(false)
                        resetField("ahvNumber");
                    }}
                        disabled={ahvLoading}
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                </Box>
            </Box>
        );
    }

    return (
        <Box display={"flex"} alignItems={"center"}>
            <Typography fontSize={"14px"} sx={{ color: "#f44336" }}>{t("CLIENT_NO_INFO")}</Typography>
            <IconButton onClick={() => setEditMode(true)}>
                <EditRoundedIcon sx={{ fontSize: "18px" }} />
            </IconButton>
        </Box>
    );
}

export default observer(AhvEditForm);