import * as Yup from 'yup';

export const statsFormSchema = () => {
    const schema = Yup.object().shape({
        startDate: Yup.date().required('Please provide a start range'),
        endDate: Yup.date()
            .test('is-after-start', 'Must be after start date', function (endDate): boolean {
                const startDate: Date = this.resolve(Yup.ref('startDate'));
                return startDate <= endDate!;
            }),
    })

    return schema;
}