import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { CSSProperties, useLayoutEffect, useState, } from "react";
import { useStore } from "../../../stores/store";
import 'dayjs/locale/en-gb';
dayjs.locale('en-gb');

interface DatePickerProps {
    displayDate: (e: any) => void;
    date: Date;
    style?: CSSProperties;
}

function CalendarDatePicker({ displayDate, date, style }: DatePickerProps) {
    const { bookingStore: { viewType } } = useStore();
    const [viewValue, setViewValue] = useState<Dayjs | null>(dayjs(date));

    useLayoutEffect(() => {
        const updateDivs = () => {
            const divs = document.querySelectorAll('.MuiDayCalendar-weekContainer') as NodeListOf<HTMLElement>;
            divs.forEach(div => {
                const buttons = div.querySelectorAll('button') as NodeListOf<HTMLElement>;
                div.classList.add('week-view');
                if (viewType === 'week') {
                    buttons.forEach(button => button.classList.add('button-week-view'));
                    const hasSelectedButton = Array.from(buttons).some(button => button.getAttribute('aria-selected') === 'true');
                    if (hasSelectedButton) {
                        div.classList.add('week-view-container');
                    } else {
                        div.classList.remove('week-view-container');
                    }
                } else {
                    buttons.forEach(button => button.classList.remove('button-week-view'));
                    div.classList.remove('week-view-container');
                    div.classList.remove('week-view');
                }
            });
        };

        const timeoutId = setTimeout(() => {
            updateDivs();
        }, 1);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [viewType, date, viewValue]);

    const handleMonthChange = (date: Dayjs) => {
        setViewValue(dayjs(date));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
                sx={style}
                value={dayjs(date)}
                slots={{
                    toolbar: () => null,
                    actionBar: () => null,
                }}
                data-testid="calendar-date-picker"
                showDaysOutsideCurrentMonth={true}
                onChange={(e: any) => displayDate(e.$d)}
                onMonthChange={handleMonthChange}
            />
        </LocalizationProvider>
    );
}

export default CalendarDatePicker;