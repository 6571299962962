import { Box, BoxProps, IconButton, Typography } from "@mui/material";
import QrCode2RoundedIcon from "@mui/icons-material/QrCode2Rounded";

import { useTranslation } from "react-i18next";

import { SessionDetailsDto } from "../../../../../data/models/session";
import { useStore } from "../../../../../stores/store";
import SessionQRCodeGenerator from "../SessionQRCodeGenerator";
import "./styles.css";

interface SessionQrCodeProps {
    session: SessionDetailsDto;
}

function SessionQrCode<C extends React.ElementType>(props: BoxProps<C, { component?: C }> & SessionQrCodeProps) {
    const { t } = useTranslation();
    const { authStore, modalStore } = useStore();
    const { user } = authStore;
    const { openModal } = modalStore;

    const session = props.session;

    const showQrCode =
        !["ABORT", "EXCLUDED"].includes(session?.status ?? "") &&
        !["ASSISTED", "ONLINE"].includes(session?.assessmentType ?? "") &&
        session?.assessorId === user?.id;

    return showQrCode ? (
        <Box className="session-qr-code-container" {...props}>
            <IconButton
                className="qr-code-button"
                disableTouchRipple
                onClick={() => {
                    if (session) {
                        openModal(<SessionQRCodeGenerator sessionId={session.sessionId} />, {
                            size: "xs",
                            className: "modal-blur",
                        });
                    }
                }}
            >
                <QrCode2RoundedIcon sx={{ fontSize: "2rem" }} />
                <Typography fontSize={"0.75rem"}>{t("SESSION_QR_CODE")}</Typography>
            </IconButton>
        </Box>
    ) : null;
}

export default SessionQrCode;
