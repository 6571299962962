import { Box, CircularProgress, Grid } from "@mui/material";

import { useForm } from "react-hook-form";

import { RoundButton } from "../../../../../_styles/StyledButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import { followUpFormSchema } from "../../../../../_validators/schemas/followUpForm.schema";
import { useStore } from "../../../../../stores/store";
import { FollowUpType } from "../../../../../data/models/session";
import FormInputSelect from "../../../../../components/form/FormInputSelect";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { followUpOptions } from "../../../../../data/static/followupOptions";

interface FollowUpFormProps {
    nextTimeline?: string;
    sessionId: string;
    previousValue?: FollowUpType;
}

function FollowUpForm({ nextTimeline, sessionId, previousValue }: FollowUpFormProps) {
    const { t } = useTranslation();
    const { sessionStore, modalStore, loadingStore } = useStore();
    const { setFollowUpType, loadAssessorSession } = sessionStore;
    const { closeModal } = modalStore;
    const { handleSubmit, control, watch } = useForm({
        resolver: yupResolver(followUpFormSchema()),
    });
    const { isLoading } = loadingStore
    const type = watch("followUp");

    const onSubmit = async (data: any) => {
        await setFollowUpType(sessionId, data.followUp);
        await loadAssessorSession(sessionId);
        closeModal();
    };

    return (
        <Box>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs={12} sx={{ mb: "1rem" }}>
                        <FormInputSelect
                            options={followUpOptions}
                            name={"followUp"}
                            defaultValue={previousValue}
                            control={control}
                            label={t("FOLLOWUP_TITLE", { timeline: nextTimeline ? `for ${nextTimeline}` : "" })}
                            labelKey={"label"}
                            valueKey={"value"}
                            helperText={type === FollowUpType.ONLINE ? t("FOLLOWUP_ONLINE_WARN") : ""}
                        />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                        <RoundButton variant="contained" type="submit" disabled={isLoading(setFollowUpType)}>
                            {isLoading(setFollowUpType) ?
                                <CircularProgress size={25} />
                                :
                                t("GENERAL_SUBMIT")
                            }
                        </RoundButton>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default observer(FollowUpForm);
