import { TextField, styled } from "@mui/material";

export const SurveyText = styled(TextField)(({ theme, multiline }) => ({
    width: multiline ? '100%' : '40%',
    marginBottom: '1.5rem',
    '& .MuiInputBase-root': {
        backgroundColor: 'transparent',
    },
    '& .MuiFilledInput-root:hover': {
        backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
            backgroundColor: 'transparent',
        },
    },
    '& .MuiFilledInput-root': {
        padding: multiline ? '5px 0' : '0',
        fontSize: '14px',
    },
    '& input': {
        padding: '5px 0',
        fontSize: '14px',
        backgroundColor: 'transparent',
    },
}));