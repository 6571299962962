import * as Yup from "yup";

export const adminVerifySchema = () => {
    const schema = Yup.object().shape({
        verification: Yup.string()
            .required("Verification required")
            .trim()
            .min(6, "Code must be 6 digit")
            .max(6, "Code must be 6 digit")
            .matches(/^[0-9]+$/, "Code must be a number"),
    });

    return schema;
};
