import { styled } from "@mui/material";

export interface Option {
    id: string;
    name: string;
    component: React.ReactNode;
    form?: JSX.Element;
    formLabel?: string;
    buttonLabel?: string;
    icon: React.ReactNode;
    selectedIcon?: React.ReactNode;
}

interface ContainerProps {
    selectedOption: Option;
}

const FlexContainer = styled('div')<ContainerProps>((props: any) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: props.theme.spacing(2),
    backgroundColor: '#f1f5f9',
    borderRadius: 4,
    flexGrow: 1,
    maxHeight: '100%',
    overflow: 'auto',
}));

export default FlexContainer;