import { Collapse, List, ListItemButton, Typography } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from "react";
import { EventStatus } from "../../../data/models/event";
import { useTranslation } from "react-i18next";

interface AttendeeListProps {
    attendees: any[];
}

function AttendeeList({ attendees }: AttendeeListProps) {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState<number[]>([]);
    const eventStatus = [EventStatus.Accepted, EventStatus.Declined, EventStatus.Pending];

    const getStatusLabel = (status: string) => {
        switch (status) {
            case EventStatus.Accepted:
                return t("GENERAL_YES")
            case EventStatus.Declined:
                return t("GENERAL_NO")
            case EventStatus.Pending:
                return t("EVENT_NO_RESP")
        }
    }

    const handleExpand = (index: number) => {
        if (expanded.includes(index)) {
            setExpanded(exp => exp.filter(e => e !== index));
        } else {
            setExpanded(exp => [...exp, index]);
        }
    }

    const getNumberOfAttendees = (status: string) => {
        return attendees.filter(a => a.status === status).length;
    }

    return (
        <>
            {eventStatus.map((es, index) =>
                <List key={es} sx={{ paddingLeft: 0 }}>
                    <ListItemButton onClick={() => handleExpand(index)} sx={{
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                        paddingLeft: 0
                    }}>
                        {expanded.includes(index) ? <KeyboardArrowDownIcon sx={{ pr: 2 }} /> : <KeyboardArrowRightIcon sx={{ pr: 2 }} />}
                        <Typography fontSize={14}>
                            {getStatusLabel(es)}: {getNumberOfAttendees(es)}
                        </Typography>
                    </ListItemButton>
                    <Collapse in={expanded.includes(index)} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding sx={{ marginLeft: 6 }}>
                            {attendees.filter(a => a.status === es).map(a => (
                                <Typography key={a.email} fontSize={13}>
                                    {a.firstName != null ? a.firstName + ' ' + a.lastName : a.email + `(${t("EVENT_EXTERNAL")})`}
                                </Typography>
                            ))}
                        </List>
                    </Collapse>
                </List>
            )}
        </>
    );
}

export default AttendeeList;