import * as Yup from "yup";
import { isAhvNumberValid } from "../../utils/utils";

export const ahvNumberSchema = () => {
    const schema = Yup.object().shape({
        ahvNumber: Yup.string()
            .matches(
                /^756\.(\d{4}\.){2}\d{2}$|^756\.____\.____\.__|^$/,
                "Invalid format"
            )
            .test(
                "is-ahv-valid",
                "Invalid AHV number",
                function (ahvNumber): boolean {
                    if (
                        !ahvNumber ||
                        (ahvNumber &&
                            ahvNumber.replaceAll(".", "").replaceAll("_", "") === "756")
                    ) {
                        return true;
                    }
                    return isAhvNumberValid(ahvNumber!);
                }
            )
    });

    return schema;
}