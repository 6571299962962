import { Box, Card, CardContent, Typography } from "@mui/material";
import { CSSProperties } from "react";

import "./styles.css";

interface StatusCardProps {
    header?: string | React.ReactNode;
    data?: string | React.ReactNode;
    className?: string;
    style?: CSSProperties;
    contentStyle?: CSSProperties;
    icon?: React.ReactNode;
}

function StatusCard({ header, data, className = "", style, contentStyle, icon }: StatusCardProps) {
    return (
        <Card className={`status-card ${className}`} sx={style}>
            <CardContent className="survey-card-content" sx={contentStyle}>
                <Box width="-webkit-fill-available">
                    {typeof header === "string" ?
                        <Typography fontWeight={400} fontSize={18} color="#808080">{header}</Typography>
                        :
                        header
                    }
                    {typeof data === "string" ?
                        <Typography fontWeight={500} fontSize={32} ml={"6px"}>{data}</Typography>
                        :
                        data
                    }
                </Box>
                <Box sx={{ mt: 4 }}>
                    {icon}
                </Box>
            </CardContent>
        </Card >
    );
}

export default StatusCard;