import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormInputRadio } from "../../../../../components/form/FormInputRadio";
import { RoundButton } from "../../../../../_styles/StyledButtons";
import { crisisAssessmentFormSchema } from "../../../../../_validators/schemas/crisisAssessmentForm.schema";
import { useStore } from "../../../../../stores/store";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useState } from "react";

interface CrisisAssesmentProps {
    sessionId: string;
}

function CrisisAssessmentForm({ sessionId }: CrisisAssesmentProps) {
    const { t } = useTranslation();
    const { sessionStore, modalStore } = useStore();
    const { setCrisisAssessment, loadAssessorSession } = sessionStore;
    const { closeModal } = modalStore;
    const { handleSubmit, control, watch } = useForm({
        resolver: yupResolver(crisisAssessmentFormSchema())
    });
    const [loading, setLoading] = useState(false);

    const selectedConclusion = watch("conclusion");

    const onSubmit = async (data: any) => {
        setLoading(true);
        try {
            const conclusion = data.conclusion === "yes";
            await setCrisisAssessment(sessionId, conclusion);
            await loadAssessorSession(sessionId);
            closeModal();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    }

    const options = [
        { label: t("GENERAL_YES"), value: "yes" },
        { label: t("GENERAL_NO"), value: "no" },
    ];

    return (
        <Box>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography>{t("CRISIS_ASSESSMENT_TITLE")}</Typography>
                        <FormInputRadio
                            options={options}
                            name={"conclusion"}
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                        <RoundButton variant="contained" type="submit" disabled={loading || (selectedConclusion !== "yes" && selectedConclusion !== "no")}>
                            {loading ?
                                <CircularProgress size={25} />
                                :
                                t("GENERAL_SUBMIT")
                            }
                        </RoundButton>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default observer(CrisisAssessmentForm);
