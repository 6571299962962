import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker/DatePicker";
import { InputLabel } from "@mui/material";

import { Controller } from "react-hook-form";
import { enGB } from "date-fns/locale";

import { FormInputProps } from "./FormInputProps";

const isMobileOrTablet = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

interface DateFieldProps extends FormInputProps {
    date?: Date | null;
    dateLimit?: Date | null;
    disabled?: boolean;
    onSelect?: (e: any) => void;
    isDatePickOnly?: boolean;
}

const FormInputDate = ({ name, label, date = null, control, dateLimit = null, disabled = false, onSelect, helperText = " ", isDatePickOnly = false }: DateFieldProps) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
            <Controller
                control={control}
                name={name}
                defaultValue={date}
                render={({ field: { ref, onBlur, onChange, name, ...field }, fieldState: { error } }) => (
                    <>
                        <InputLabel htmlFor={name} sx={{ fontSize: '14px' }}>{label}</InputLabel>
                        <DatePicker
                            disabled={disabled}
                            {...field}
                            inputRef={ref}
                            sx={{
                                width: "100%",
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                                '& input': {
                                    backgroundColor: 'white',
                                    padding: '8px 10px',
                                    fontSize: '13px'
                                },
                            }}
                            slotProps={{
                                textField: {
                                    helperText: error?.message || helperText,
                                    error: !!error,
                                    fullWidth: true,
                                    inputProps: {
                                        'data-testid': name,
                                    },
                                    disabled: isDatePickOnly && !isMobileOrTablet
                                },

                            }}
                            onChange={(e) => {
                                onSelect && onSelect(e);
                                onChange(e);
                            }}
                            minDate={dateLimit}
                        />
                    </>
                )}
            />
        </LocalizationProvider>
    );
};

export default FormInputDate;
