import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { rrulestr } from "rrule";
import { CalendarEvent } from "../../../data/models/event";
import { useStore } from "../../../stores/store";
import { getEventDetails } from "../../../utils/helpers/clientHelper";

interface ClientEventListProps {
    events: CalendarEvent[];
    setEvents: (events: CalendarEvent[]) => void;
}

function ClientEventList({ events, setEvents }: ClientEventListProps) {
    const { t } = useTranslation();
    const { bookingStore } = useStore();
    const { deleteClientEvent } = bookingStore;
    const [loadingEventId, setLoadingEventId] = useState<string | null>(null);

    const getTimeslots = (event: CalendarEvent) => {
        const eventSeries: { start: Date; end: Date }[] = [];

        const eventDuration = event.end.getTime() - event.start.getTime();
        const rrule = rrulestr(event.recurrence!);
        rrule.all().forEach((date) => {
            eventSeries.push({
                start: date,
                end: new Date(date.getTime() + eventDuration),
            });
        });

        return eventSeries;
    }

    const onEventRemove = (event: CalendarEvent) => {
        const newEvents = events.filter((e) => e !== event);
        setEvents(newEvents);
    }

    const onDelete = async (event: CalendarEvent) => {
        try {
            setLoadingEventId(event.id)
            await deleteClientEvent({ eventId: event.id, eventDate: new Date(), startDate: event.start }, null);
            onEventRemove(event);
        } catch (error) {
            setLoadingEventId(null)
            throw error;
        }
        finally {
            setLoadingEventId(null)
        }
    }

    return (
        <>
            <Box display="flex" alignItems="center">
                <Typography fontSize={"14px"} marginRight={0.5}>
                    {t("EVENT_SLOTS")}
                </Typography>
                <Typography fontSize={"11px"}>
                    {"("}{t("EVENT_SLOT_DESC")}{")"}
                </Typography>
            </Box>
            <Box
                display="flex"
                alignItems="flex-start"
                flexWrap="wrap"
                ml={1}
                sx={{ maxHeight: "186px", overflowY: "auto", overflowX: "hidden" }}
            >
                {events.length === 0 && (
                    <Typography fontSize="12px" ml={1}>
                        {t("EVENT_NO_SLOTS")}
                    </Typography>
                )}
                {events.map((event, index) => (
                    <Box
                        key={index}
                        display="flex"
                        flexDirection="column"
                        mr={2}
                        p={1}
                        className="client-slot-container"
                    >
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography fontSize="17px" fontWeight={500}>{t("EVENT_SLOT")} {index + 1}</Typography>
                            {loadingEventId === event.id ?
                                < CircularProgress size={20} /> :
                                < DeleteForeverOutlinedIcon sx={{ fontSize: "20px", padding: 1, color: "#FF3131", cursor: 'pointer' }} onClick={() => onDelete(event)} />

                            }
                        </Box>
                        {getEventDetails(event).map((eventDetail, index) => (
                            <Box key={index} display="flex" alignItems="center">
                                <Typography key={index} fontSize="16px">
                                    {eventDetail}
                                </Typography>
                                {eventDetail.includes("Repeating:") && (
                                    <Tooltip
                                        title={
                                            <Box sx={{ maxHeight: '150px', overflowY: 'auto', overflowX: 'hidden', pr: '8px' }}>
                                                {getTimeslots(event)?.map((timeslot, index) => (
                                                    (event.isAllDay ?
                                                        <Typography key={index} fontSize="12px">
                                                            {format(new Date(timeslot.start), "dd MMM")}
                                                            {" - "}
                                                            {format(new Date(timeslot.end), "dd MMM")}
                                                            {` (${t("EVENT_ALL_DAY")})`}
                                                        </Typography>
                                                        :
                                                        <Typography key={index} fontSize="12px">
                                                            {format(new Date(timeslot.start), "dd MMM, HH:mm")}
                                                            {" - "}
                                                            {format(new Date(timeslot.end), "dd MMM, HH:mm")}
                                                        </Typography>
                                                    )
                                                ))}
                                            </Box>
                                        }
                                        placement="right"
                                        arrow
                                    >
                                        <InfoOutlinedIcon
                                            sx={{
                                                fontSize: "20px",
                                                color: "#686868",
                                                marginLeft: "5px",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => getTimeslots(event)}
                                        />
                                    </Tooltip>
                                )}
                            </Box>
                        ))}
                    </Box>
                ))}
            </Box>
        </>
    );
}

export default ClientEventList;
