import * as Yup from "yup";
export const contactAgencySchema = () => {
    const schema = Yup.object().shape({
        contactWithReferringAgency: Yup.string(),
        contactAgency: Yup.object().when("contactWithReferringAgency", {
            is: (contactWithReferringAgency: string) => contactWithReferringAgency === "true",
            then: (schema) =>
                schema.shape({
                    agencyName: Yup.string().trim().required("Please provide the agency's name"),
                    agencyEmail: Yup.string()
                        .email("Invalid email address")
                        .trim()
                        .required("Please provide the agency's email"),
                    agencyPhone: Yup.string().optional()
                }),
        }),
    });

    return schema;
};

export const getDynamicSchema = (fieldName: string) => {
    return Yup.object().shape({
        [fieldName]: fieldName === "agencyEmail"
            ? Yup.string().email("Invalid email address").trim()
            : Yup.mixed().nullable(),
    });
};