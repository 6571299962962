import { Box, Typography } from "@mui/material";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStore } from "../../../stores/store";
import DropdownMenu from "../../../components/DropdownMenu";
import GenericTable from "../../../components/GenericTable";
import { Tenant } from "../../../data/models/tenant";
import TenantRegisterForm from "./TenantRegisterForm";
import { toast } from "react-toastify";
import { TableText } from "../../../_styles/table/TableText";
import { TableHeader } from "../../../_styles/table/TableHeader";
import { useTranslation } from "react-i18next";

function TenantTable() {
    const { t } = useTranslation();
    const { tenantStore, sidebarStore, dialogStore } = useStore();
    const { tenantList, getTenants, getTenant, deleteTenant } = tenantStore;
    const { openSidebar } = sidebarStore;
    const { openDialog, closeDialog } = dialogStore;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await getTenants();
            setLoading(false);
        }

        fetchData();
    }, [getTenants]);

    const onEditTenant = async (id: string) => {
        const tenant = await getTenant(id);

        openSidebar(<TenantRegisterForm tenant={tenant} />, t("EDIT_TENANT"));
    };

    const onDeleteTenant = (id: string) => {
        openDialog(
            t("DIALOG_TITLE_DELETE"),
            t("DIALOG_MSG_TENANT"),
            async () => {
                await deleteTenant(id);
                closeDialog();
                toast.success(t("TOAST_TENANT_DELETE"))
            },
            closeDialog);
    };

    const options = [
        {
            id: 'edit',
            name: t("GENERAL_EDIT"),
            icon: <ModeEditOutlinedIcon sx={{ position: 'absolute', right: 0, paddingRight: 1 }} color="primary" />,
            onClick: (id: string) => onEditTenant(id)
        },
        {
            id: 'delete',
            name: t("GENERAL_DELETE"),
            icon: <DeleteForeverOutlinedIcon sx={{ position: 'absolute', right: 0, paddingRight: 1 }} color="error" />,
            onClick: (id: string) => onDeleteTenant(id),
            customStyle: {
                backgroundColor: '#ffdfdf',
                color: '#FF0000',
                ":hover": { backgroundColor: '#FFABAB' }
            }
        }];

    return (
        <GenericTable<Tenant>
            columns={[
                {
                    header: <TableHeader>{t("GENERAL_NAME")}</TableHeader>, key: 'name',
                    render: (tenant) => <TableText>{tenant.name}</TableText>,
                    sort: (a, b) => {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    },
                },
                {
                    header: <Typography></Typography>,
                    key: 'actions',
                    render: (tenant) => (
                        <Box mr={2}>
                            <DropdownMenu
                                options={options.map((option) => {
                                    return {
                                        ...option,
                                        onClick: () => option.onClick(tenant.id),
                                    };
                                })}
                            />
                        </Box>
                    ),
                    className: "settings-table-actions"
                },
            ]}
            rows={tenantList}
            props={{
                containerProps: { className: "settings-table" },
                tableContainerProps: { className: "survey-table-container-data" },
                tableHeadProps: { className: "survey-table-header" },
                rowProps: { className: "survey-row" },
            }}
            usePagination
            rowKey="id"
            loading={loading}
        />
    );
}

export default observer(TenantTable);