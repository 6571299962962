import { InputAdornment, InputLabel, TextField } from "@mui/material";

import { PatternFormat } from 'react-number-format';
import { Controller } from "react-hook-form";

import { FormInputProps } from "./FormInputProps";

interface NumPatternFieldProps extends FormInputProps {
    initValue?: string;
    readOnly?: boolean;
    multipleLine?: boolean
    icon?: JSX.Element;
    format: string;
    emptyFormatting?: boolean;
    mask?: string;
}

export function FormInputNumPattern({
    name,
    control,
    label,
    initValue = '',
    readOnly = false,
    multipleLine = false,
    icon,
    helperText = " ",
    format,
    emptyFormatting = false,
    mask = "_"
}: NumPatternFieldProps) {
    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value = initValue },
                fieldState: { error },
            }) => (
                <>
                    <InputLabel htmlFor={name} sx={{ fontSize: '14px' }}>{label}</InputLabel>
                    <PatternFormat
                        id={name}
                        helperText={error?.message || helperText}
                        value={value}
                        valueIsNumericString={false}
                        customInput={TextField}
                        allowEmptyFormatting={emptyFormatting}
                        format={format}
                        mask={mask}
                        error={!!error}
                        onChange={onChange}
                        fullWidth
                        InputProps={{
                            readOnly: readOnly,
                            sx: {
                                backgroundColor: 'white',
                            },
                            startAdornment: icon ? (
                                <InputAdornment position="start">
                                    {icon}
                                </InputAdornment>
                            ) : null,
                        }}
                        sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                            marginRight: '10px'
                        }}
                        inputProps={{
                            'data-testid': name,
                            style: {
                                padding: multipleLine ? '0 10px 0 0' : '8px 10px',
                                backgroundColor: 'white',
                                fontSize: '13px'
                            }
                        }}
                    />
                </>
            )
            }
        />
    );
};