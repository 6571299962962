import {
    Box,
    CircularProgress,
    Container,
    Divider,
    FormControlLabel,
    IconButton,
    Switch,
    Typography,
} from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import EditOffRoundedIcon from "@mui/icons-material/EditOffRounded";

import { useForm } from "react-hook-form";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
    Answer,
    QuestionType,
    Respondent,
    RuleType,
    Survey,
    SurveyResponse,
} from "../../../../data/models/survey";
import { RoundButton } from "../../../../_styles/StyledButtons";
import SurveyField from "../SurveyFields/SurveyField";
import { surveyFormSchema } from "../../../../_validators/schemas/surveyForm.schema";
import { getDecodedToken } from "../../../../utils/jwtDecoder";
import QuestionContainer from "../SurveyFields/QuestionContainer";
import { useStore } from "../../../../stores/store";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import SurveyLanguageSelect from "./SurveyLanguageSelect";
import "./styles.css";
import { useTranslation } from "react-i18next";
import ErrorBanner from "./ErrorBanner";
import { SurveyContext } from "./SurveyInitializer";
import { FormInputText } from "../../../../components/form/FormInputText";

type Mode = "preview" | "form";
type View = "assessor" | "client" | "user" | "admin";
type Direction = "rtl" | "ltr";

interface SurveyFormProps {
    survey: Survey;
    mode?: Mode;
    view?: View;
    onLanguageChange: (languageCode: string) => void;
    direction?: Direction;
    reloadAnswers: () => void;
}

function SurveyForm({
    survey,
    mode = "form",
    view,
    onLanguageChange,
    reloadAnswers, direction = "ltr",
}: SurveyFormProps) {
    const { t } = useTranslation();
    const { surveyStore, authStore, dialogStore, sessionStore, institutionStore } = useStore();
    const { timelineId, sessionId } = useParams();
    const preview = mode === "preview";

    const { editMode, setEditMode } = useContext(SurveyContext);

    const { user } = authStore;
    const { openDialog, closeDialog } = dialogStore;
    const {
        surveyAnswerList,
        clearAnswer,
        submitSurvey,
        getAnswer,
        loadingSubmit,
        submitAnswerReqCount,
        saveError,
    } = surveyStore;
    const { getSession, getAssessorSession } = sessionStore;
    const { selectedUserInstitution } = institutionStore;
    const [page, setPage] = useState(1);
    const { handleSubmit, control } = useForm({
        resolver: yupResolver(
            surveyFormSchema(survey ? survey.pages[page - 1].questions : null)
        ),
    });
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const lang = params.get("lang");
    const decodedToken = getDecodedToken(token);

    const [submitFlag, setSubmitFlag] = useState(false);
    const [applyRules, setApplyRules] = useState(!preview);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [container, setContainer] = useState<HTMLElement | null>(null);
    const lastPageIndex = survey?.pages.length;

    useEffect(() => {
        if (submitAnswerReqCount <= 0 && submitFlag) {
            onSubmit(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitAnswerReqCount, submitFlag])

    useLayoutEffect(() => {
        const appContainer = document.querySelector(
            ".app-container"
        ) as HTMLElement;

        if (appContainer) {
            setContainer(appContainer);
        }

        const oldBg = appContainer.style.backgroundColor;
        appContainer.style.backgroundColor = "#f4fbff";
        const oldValue = appContainer.style.overflowY;
        appContainer.style.overflowY = "auto";
        // TODO: Add this in the future
        // appContainer.scrollTo(0, 0);

        return () => {
            appContainer.style.backgroundColor = oldBg;
            appContainer.style.overflowY = oldValue;
        };
    }, []);

    const showNextPage = () => {
        if (lastPageIndex && page > lastPageIndex) return;
        setPage((page) => page + 1);
        if (container) {
            container.scrollTo(0, 0);
        }
    };

    const showPreviousPage = () => {
        if (page === 1) return;
        setPage((page) => page - 1);
    };

    const isVisible = (questionId: string) => {
        if (isSubmitted) return false;
        if (!survey?.rules || survey.rules.length === 0) {
            return true;
        }

        const questionsDict = survey.pages
            .flatMap((p) =>
                p.questions.flatMap((q) => {
                    if (q.subQuestions && q.subQuestions.length > 0) {
                        return [...q.subQuestions];
                    }
                    return q;
                })
            )
            .reduce((dict: any, question) => {
                dict[question.id] = question;
                return dict;
            }, {});

        let visible = true;
        let rules = survey.rules.filter((r) => r.action === RuleType.SKIP);

        const evaluations: boolean[] = [];

        rules.forEach((rule) => {
            const sourceQuestion = questionsDict[rule.questionId];
            const targetQuestion = questionsDict[rule.targetId];
            const currentQuestion = questionsDict[questionId];

            if (sourceQuestion && targetQuestion && currentQuestion) {
                const hasRule = rules.find((r) => r.targetId === currentQuestion.id);
                if (
                    !hasRule &&
                    currentQuestion.order > sourceQuestion.order &&
                    currentQuestion.order < targetQuestion.order
                ) {
                    const answer = getAnswer(sourceQuestion.id)?.answer;
                    if (!answer) {
                        visible = false;
                    } else {
                        evaluations.push(rule.optionId !== answer);
                    }
                }
            }
        });

        rules = survey.rules.filter((r) => r.action === RuleType.SUBMITSURVEY);

        rules.forEach((rule) => {
            const triggerOption = getAnswer(rule.questionId);
            const submitQuestion = questionsDict[rule.questionId];
            const currentQuestion = questionsDict[questionId];
            if (submitQuestion && currentQuestion && triggerOption) {
                if (submitQuestion.order < currentQuestion.order) {
                    if (triggerOption.answer === rule.optionId) {
                        evaluations.push(false);
                    }
                }
            }
        });

        if (evaluations.some((e) => e === false) || !visible) {
            clearAnswer(questionId, {
                sessionId: sessionId!,
                timelineId: timelineId!,
                surveyId: survey.id,
                token: token ?? undefined,
            });
            return false;
        }

        rules = survey.rules.filter((r) => r.targetId === questionId);

        for (const rule of rules) {
            const triggerOption = getAnswer(rule.questionId);
            if (triggerOption) {
                const result = rule.optionId === triggerOption.answer;
                const type = rule.action;

                if (type === RuleType.SHOW || type === RuleType.SKIP) {
                    if (!result) {
                        visible = false;
                        continue;
                    }

                    visible = true;
                    break;
                } else if (type === RuleType.HIDE) {
                    if (result) {
                        visible = false;
                        continue;
                    }

                    visible = true;
                    break;
                }
            } else {
                if (rule.action === RuleType.SKIP || rule.action === RuleType.SHOW) {
                    visible = false;
                }
            }
        }

        if (!visible) {
            clearAnswer(questionId, {
                sessionId: sessionId!,
                timelineId: timelineId!,
                surveyId: survey.id,
                token: token ?? undefined,
            });
        }

        return visible;
    };

    const validateAnswers = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            const visibleQuestions = surveyAnswerList.filter((a) => isVisible(a.id));
            const answeredQuestions = visibleQuestions.filter((a) => a.answer);

            if (visibleQuestions.length !== answeredQuestions.length) {
                const unansweredCount =
                    visibleQuestions.length - answeredQuestions.length;
                const message = t("DIALOG_MSG_SURVEY", {
                    unansweredCount: unansweredCount,
                });

                openDialog(
                    t("DIALOG_TITLE_SURVEY"),
                    message,
                    () => {
                        resolve(true);
                        closeDialog();
                    },
                    () => {
                        resolve(false);
                        closeDialog();
                    },
                    { text: t("GENERAL_CONTINUE"), color: "primary" }
                );
            } else {
                resolve(true);
            }
        });
    };

    const onSubmit = async (data: any) => {
        if (page !== lastPageIndex) {
            showNextPage();
            return;
        }

        try {
            const confirmContinue = submitFlag || await validateAnswers();
            if (!confirmContinue) {
                return;
            }
            if (!submitFlag) {
                setSubmitFlag(true);
                return;
            }
            const questions = survey?.pages.flatMap((p) => p.questions);
            questions?.forEach((q) => {
                if (q.subQuestions && q.subQuestions?.length > 0) {
                    q.subQuestions?.forEach((subQ) => {
                        questions.push(subQ);
                    });
                }
            });
            const answers: Answer[] = [];
            const respondent: Respondent = {
                userId: decodedToken?.clientId ?? user?.id ?? "",
            };

            const optionTypes = [
                QuestionType.RADIO,
                QuestionType.RADIOWITHTEXT,
                QuestionType.MATRIX,
                QuestionType.LINEARSCALE,
            ];
            const textTypes = [
                QuestionType.TEXT,
                QuestionType.PARAGRAPH,
                QuestionType.DATE,
            ];

            surveyAnswerList.forEach((a) => {
                const question = questions?.find((q) => q.id === a.id);
                if (question) {
                    answers.push({
                        questionId: question.id,
                        optionId: optionTypes.includes(
                            question.questionType as QuestionType
                        )
                            ? a.answer
                            : null,
                        textAnswer: textTypes.includes(
                            question.questionType as QuestionType
                        )
                            ? a.answer
                            : question.questionType === QuestionType.RADIOWITHTEXT
                                ? a.additional
                                : null,
                    });
                }
            });

            const surveyResponse: SurveyResponse = {
                surveyId: survey?.id!,
                respondent: respondent,
                timelineId: timelineId!,
                sessionId: sessionId!,
                answers: answers,
                languageId: lang ?? "en",
            };
            await submitSurvey(surveyResponse, token ?? undefined);
            setIsSubmitted(true);

            if (sessionId) {
                if (location.state) {
                    const redirectUrl =
                        location.state.redirect ??
                        `${location.state.from.pathname}${location.state.from.search}`;
                    navigate(redirectUrl);
                } else {
                    if (user && selectedUserInstitution) {
                        const isAssessor = selectedUserInstitution.roles.includes("Assessor");
                        const session = isAssessor ? await getAssessorSession(sessionId) : await getSession(sessionId);
                        if (session.assessorId === user?.id) {
                            navigate(
                                `/timeline/${session.timelineId}/session/${session.sessionId}?clientId=${session.clientId}`
                            );
                        } else if (token) {
                            navigate(`/screening/session?token=${token}`);
                        } else {
                            navigate(`/screening/session?id=${session.sessionId}`);
                        }
                    } else if (token) {
                        navigate(`/screening/session?token=${token}`);
                    } else {
                        navigate("/screening");
                    }
                }
            } else {
                navigate("/");
            }
            setSubmitFlag(false);
        } catch (error) {
            setSubmitFlag(false);
            console.log(error);
            throw error;
        }
    };

    return (
        <Box className="creator-container" dir={direction}>
            <ConfirmDialog />
            <Container maxWidth="md" sx={{ pb: preview ? "1rem" : 0 }}>
                <Box component="form" position="relative" width="100%" onSubmit={handleSubmit(onSubmit)}>
                    {saveError && <ErrorBanner reloadAnswers={reloadAnswers} />}
                    <QuestionContainer>
                        <Box className="survey-header-container">
                            <Box width={editMode ? "80%" : "auto"}>
                                {(preview ||
                                    ["assessor", "user", "admin"].includes(view ?? "")) &&
                                    (editMode ? (
                                        <FormInputText
                                            label="Survey title"
                                            name={`${survey.id}.name`}
                                            control={control}
                                            initValue={survey?.name ?? ""}
                                        />
                                    ) : (
                                        <Typography fontSize={"32px"}>
                                            {survey?.name ?? "Untitled"}
                                        </Typography>
                                    ))}
                                <Typography fontSize={"14px"} className="survey-description">
                                    {survey?.pages[page - 1].description ?? ""}
                                </Typography>
                                <Typography fontSize={"14px"} className="survey-description">
                                    {survey?.description ?? ""}
                                </Typography>
                            </Box>
                            <Box display={"flex"}>
                                {["user", "admin"].includes(view ?? "") && mode === "preview" && <IconButton
                                    sx={{ height: "36px", width: "36px" }}
                                    onClick={() => {
                                        setEditMode(!editMode);
                                    }}
                                >
                                    {editMode ? (
                                        <EditOffRoundedIcon sx={{ fontSize: "18px" }} />
                                    ) : (
                                        <EditRoundedIcon sx={{ fontSize: "18px" }} />
                                    )}
                                </IconButton>}
                                <SurveyLanguageSelect onSelect={onLanguageChange} />
                            </Box>
                        </Box>
                        {(survey?.pages[page - 1].description || survey.description) && (
                            <Divider sx={{ margin: "1rem 0" }} />
                        )}
                        {!isSubmitted && (
                            <Box className="survey-header-info">
                                <Box>
                                    {["K-10", "SIDAS"].includes(survey.name) && <Typography fontSize={"14px"} sx={{ color: "#d93025" }} className="survey-description">
                                        * Indicates required question
                                    </Typography>}

                                    {preview && <Typography fontSize={"12px"} sx={{ color: "#808080", ml: 1 }} className="survey-description">
                                        (Preview mode - answers will not be saved)
                                    </Typography>}
                                </Box>
                                {preview && (
                                    <Box display={"flex"}>
                                        <FormControlLabel
                                            className="rules-switch"
                                            control={
                                                <Switch
                                                    size="small"
                                                    checked={applyRules}
                                                    onChange={(e) => setApplyRules(e.target.checked)}
                                                />
                                            }
                                            label={
                                                <Typography fontSize={"13px"} mb={"3px"}>
                                                    Apply rules
                                                </Typography>
                                            }
                                        />
                                    </Box>
                                )}
                            </Box>
                        )}
                    </QuestionContainer>
                    {survey?.pages[page - 1].questions.map((question) =>
                        question.subQuestions?.length! > 0 &&
                            question.questionType !== QuestionType.MATRIX ? (
                            <Box key={question.id}>
                                <SurveyField
                                    id={question.id}
                                    title={question.title}
                                    control={control}
                                    visible={!applyRules || isVisible(question.id)}
                                    disabled={view === "admin"}
                                    options={question.options}
                                    subQuestions={[]}
                                    preview={preview}
                                    required={question.required}
                                    type={question.questionType}
                                />
                                {question.subQuestions?.map((subQuestion) => (
                                    <SurveyField
                                        key={subQuestion.id}
                                        id={subQuestion.id}
                                        title={subQuestion.title}
                                        control={control}
                                        visible={!applyRules || isVisible(subQuestion.id)}
                                        disabled={view === "admin"}
                                        options={subQuestion.options}
                                        subQuestions={[]}
                                        preview={preview}
                                        required={subQuestion.required}
                                        type={subQuestion.questionType}
                                    />
                                ))}
                            </Box>
                        ) : (
                            <SurveyField
                                key={question.id}
                                id={question.id}
                                title={question.title}
                                control={control}
                                visible={!applyRules || isVisible(question.id)}
                                disabled={view === "admin"}
                                options={question.options}
                                preview={preview}
                                subQuestions={question.subQuestions}
                                required={question.required}
                                type={question.questionType}
                            />
                        )
                    )}
                    {page > 1 && !isSubmitted && (
                        <RoundButton
                            variant="outlined"
                            type="button"
                            sx={{ mb: "2rem", mr: "1rem" }}
                            onClick={() => showPreviousPage()}
                            disabled={loadingSubmit || submitFlag}
                        >
                            Back
                        </RoundButton>
                    )}
                    {isSubmitted ? null : lastPageIndex === page ? (
                        <RoundButton
                            disabled={loadingSubmit || preview || submitFlag || saveError}
                            variant="contained"
                            type="submit"
                            sx={{ mb: "2rem" }}
                        >
                            {loadingSubmit || submitFlag ? (
                                <CircularProgress size={25} sx={{ color: "#fff" }} />
                            ) : (
                                "Submit"
                            )}
                        </RoundButton>
                    ) : (
                        <RoundButton variant="contained" type="submit" sx={{ mb: "2rem" }}>
                            Next
                        </RoundButton>
                    )}
                </Box>
            </Container>
        </Box>
    );
}

export default observer(SurveyForm);
