import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";


const boxStyle = {
    height: '50px',
    textAlign: 'left',
    paddingLeft: '7px',
    paddingBottom: '7px',
};

const textStyle = {
    height: '100%',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'flex-end',
}

function MonthHeader({ date }: any) {
    const weekDay = dayjs(date);
    
    return (
        <Box sx={boxStyle}>
            <Typography sx={textStyle}>{weekDay.format('dddd')}</Typography>
        </Box>
    );
};

export default MonthHeader;