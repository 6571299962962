export const generateColor = (id: string) => {
    var hash = 0;
    for (var i = 0; i < id.length; i++) {
        hash = id.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var j = 0; j < 3; j++) {
        var value = (hash >> (j * 8)) & 0xFF;
        // TODO: Find replacement for substr
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}