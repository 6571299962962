import {
    Card,
    CardActions,
    CardContent,
    Container,
    Typography,
} from "@mui/material";
import { RoundButton } from "../../_styles/StyledButtons";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useStore } from "../../stores/store";
import { ClientSession } from "../../data/models/session";
import { useNavigate } from "react-router-dom";

function ClientPage() {
    const { t } = useTranslation();
    const { sessionStore } = useStore();
    const navigate = useNavigate();
    const { getClientSessions } = sessionStore;
    const [sessions, setSessions] = useState<ClientSession[]>([]);
    const hasActiveSession = sessions.some((s) => s.active);

    useEffect(() => {
        const fetchData = async () => {
            let clientSessions = await getClientSessions();
            clientSessions = clientSessions.map(s => {
                return {
                    ...s,
                    startTime: new Date(s.startTime),
                }
            });
            setSessions(clientSessions);
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container sx={{ display: "flex", justifyContent: "center", mt: "8rem" }}>
            <Card sx={{ minWidth: 350, maxWidth: 350, bgcolor: "#fff", p: "2rem" }}>
                <CardContent>
                    <Typography
                        sx={{ fontSize: 24, fontWeight: 600, textAlign: "center" }}
                        color="text.secondary"
                        gutterBottom
                    >
                        {hasActiveSession ?
                            t("CLIENT_ACTIVE_SESSION")
                            :
                            t("CLIENT_INACTIVE_SESSION")
                        }
                    </Typography>
                </CardContent>
                {hasActiveSession && <CardActions>
                    <RoundButton
                        size="small"
                        variant="contained"
                        color="info"
                        sx={{ width: "100%", color: "#fff" }}
                        onClick={() => {
                            const activeSession = sessions.find(s => s.active);
                            if (activeSession) {
                                navigate(`/screening/session?id=${activeSession.id}`)
                            }
                        }}
                    >
                        {t("CLIENT_GOTO")}
                    </RoundButton>
                </CardActions>}
            </Card>
        </Container>
    );
}

export default ClientPage;