import { Box, CircularProgress, Divider, Grid, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { observer } from "mobx-react-lite";

import FormInputDate from "../../../components/form/FormInputDate";
import FormInputTime from "../../../components/form/FormInputTime";
import FormInputSelect from "../../../components/form/FormInputSelect";
import { RoundButton } from "../../../_styles/StyledButtons";
import { FormInputText } from "../../../components/form/FormInputText";
import { useStore } from "../../../stores/store";
import { EventCreateDto } from "../../../data/models/event";
import { clientSessionFormSchema } from "../../../_validators/schemas/clientSession.schema";
import {
    Assessor,
    ClientSessionInfo,
} from "../../../data/models/session";
import ClientSessionTable from "./ClientSessionTable";
import { combineDateAndTime } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { isEmailValid } from "../../Booking/Form/AttendeeInput";

interface ClientSessionFormProps {
    visible: boolean;
}

function ClientSessionForm({ visible }: ClientSessionFormProps) {
    const { t } = useTranslation();
    const {
        bookingStore,
        roomStore,
        institutionStore,
        tenantStore,
        clientStore,
        sessionStore,
    } = useStore();
    const {
        createClientEvent,
        getEventTypes,
        eventTypes,
        loading: eventLoading
    } = bookingStore;
    const { getRoomsByInstitution, roomList } = roomStore;
    const { selectedTenant } = tenantStore;
    const { selectedUserInstitution } = institutionStore;
    const { client, getAssessors } = clientStore;
    const { getSessionsByClient } = sessionStore;
    const [clientSessions, setClientSessions] = useState<ClientSessionInfo[]>([]);
    const [assessorList, setAssessorList] = useState<Assessor[]>([]);

    const defaultValues = {
        title: "",
        description: "",
        assessor: "",
        startDate: new Date(),
        startTime: null,
        endDate: new Date(),
        endTime: null,
        room: "none",
    };

    const { control, watch, handleSubmit, setValue } = useForm({
        resolver: yupResolver(clientSessionFormSchema()),
        defaultValues,
    });

    const startDate = watch("startDate");
    const endDate = watch("endDate");

    useEffect(() => {
        const fetchData = async () => {
            if (client) {
                const clientSessions = await getSessionsByClient(client.id);

                setClientSessions(clientSessions);
            }

            if (
                clientSessions.length === 0 &&
                selectedUserInstitution &&
                selectedTenant
            ) {
                await getRoomsByInstitution(selectedUserInstitution.institutionId!);
                await getEventTypes(selectedTenant.id);
                const assessors = await getAssessors(selectedUserInstitution.institutionId);
                setAssessorList(assessors);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTenant, selectedUserInstitution, client]);

    useEffect(() => {
        if (startDate > endDate) {
            setValue("endDate", startDate);
        }
    }, [endDate, setValue, startDate]);

    useEffect(() => {
        setValue("title", eventTypes.find(e => e.name === "ASSESSMENT")?.translatedName + " / T1 (Screening/Baseline)" ?? "");
    }, [eventTypes])

    const onSubmit = async (data: any) => {
        const assessor = assessorList.find(
            (u) => u.id === data.assessor
        )?.email;
        if (!assessor) return;

        const event: EventCreateDto = {
            title: data.title,
            start: combineDateAndTime(data.startDate, data.startTime),
            end: combineDateAndTime(data.endDate, data.endTime),
            description: data.description,
            isAllDay: false,
            isScreening: true,
            roomId: data.roomId !== 'none' && data.roomId !== '' ? data.roomId : null,
            eventType: eventTypes.find((e) => e.name === "ASSESSMENT")?.id ?? "",
            attendees: [isEmailValid(client?.email!) ? client?.email! : client?.id!, assessor],
            recurrence: { type: 9 },
            subType: "T1 (Screening/Baseline)",
            reminders: [],
            institutionId: selectedUserInstitution?.institutionId!,
            IsConfirmed: true,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        await createClientEvent(event);
        if (client) {
            const clientSessions = await getSessionsByClient(client.id);
            setClientSessions(clientSessions);
        }
    };

    if (!client?.email)
        return (
            <Box
                sx={{
                    display: "flex",
                    p: "6rem 2rem",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography fontSize={16} textAlign={"center"}>
                    At this stage, client must have an email address in order to be a
                    participant in the process
                </Typography>
                <Typography
                    fontSize={13}
                    textAlign={"center"}
                    sx={{ color: "#808080" }}
                >
                    (At later stages, the options without email address will be added)
                </Typography>
            </Box>
        );

    if (clientSessions.length > 0) {
        return <ClientSessionTable sessions={clientSessions} />;
    }

    return (
        <Box sx={{ display: visible ? "block" : "none" }}>
            <Grid
                container
                columnSpacing={1}
                marginTop="1rem"
                padding="0.5rem 3rem"
                component="form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid item xs={6} mb={2}>
                    <Box display={"flex"} alignItems={"center"}>
                        <Typography fontSize={16} fontWeight={500}>
                            {t("SESSION_CREATE")}
                        </Typography>
                        &nbsp;
                        <Typography fontSize={14}>(optional)</Typography>
                    </Box>
                    <Typography fontSize={11}>
                        {t("SESSION_CREATE_BL")}
                    </Typography>
                    <Typography fontSize={11}>
                        {t("SESSION_CREATE_DESC")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormInputText name="title" control={control} label={t("ACC_TITLE")} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputDate
                        name="startDate"
                        control={control}
                        label={`${t("EVENT_START_DATE")} *`}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputTime
                        name="startTime"
                        control={control}
                        label={`${t("EVENT_START_TIME")} *`}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputDate name="endDate" control={control} label={`${t("EVENT_END_DATE")} *`} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputTime name="endTime" control={control} label={`${t("EVENT_END_TIME")} *`} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputSelect
                        name="assessor"
                        label={`${t("SESSION_ASSESSOR")} *`}
                        control={control}
                        options={assessorList.map((u) => {
                            return {
                                email: u.email,
                                id: u.id,
                                label: `${u.firstName} ${u.lastName} (${u.email})`,
                            };
                        })}
                        labelKey={"label"}
                        valueKey={"id"}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputSelect
                        name="roomId"
                        label={`${t("EVENT_ROOM")} *`}
                        control={control}
                        options={[
                            { id: "none", name: <i>None</i> },
                            ...roomList.filter(r => !r.isDeactivated).map((r) => {
                                return { id: r.id, name: r.name + " (" + r.capacity + ")" };
                            }),
                        ]}
                        defaultValue={"none"}
                        labelKey={"name"}
                        valueKey={"id"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormInputText
                        name="description"
                        rows={3}
                        control={control}
                        label={`${t("GENERAL_DESCRIPTION")} *`}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <RoundButton
                        variant="contained"
                        type="submit"
                        sx={{ marginRight: "1rem", width: "auto" }}
                        disabled={eventLoading}
                    >
                        {eventLoading ?
                            <CircularProgress size={25} sx={{ color: "#fff" }} />
                            :
                            t("GENERAL_CREATE")
                        }
                    </RoundButton>
                </Grid>
                <Grid item xs={12} mt={3} mb={3}>
                    <Divider />
                </Grid>
            </Grid>
        </Box>
    );
}

export default observer(ClientSessionForm);
