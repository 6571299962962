import { Box } from "@mui/material";

import { useLayoutEffect, useRef, useState } from "react";

interface ActivityDetailsProps {
    children?: React.ReactNode;
    className?: string;
    showFlag?: boolean;
}

function ActivityLayout({ children, className = "", showFlag = true }: ActivityDetailsProps) {
    const parentRef = useRef<HTMLDivElement>(null);
    const [iconHeight, setIconHeight] = useState(0);

    useLayoutEffect(() => {
        if (parentRef) {
            const parentHeight = parentRef!.current!.offsetHeight;
            setIconHeight(parentHeight)
        }
    }, [parentRef]);

    return (
        <Box className={`timeline-activity-container ${className}`} ref={parentRef}>
            {showFlag && <Box className="timeline-flag" sx={{ height: iconHeight }}></Box>}
            {children}
        </Box>
    );
}

export default ActivityLayout;