import Box from "@mui/material/Box/Box";
import Container from "@mui/material/Container/Container";
import Typography from "@mui/material/Typography/Typography";
import { CircularProgress, Grid } from "@mui/material";
import LockPersonIcon from "@mui/icons-material/LockPerson";

import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { observer } from "mobx-react-lite";

import { getDecodedToken } from "../../utils/jwtDecoder";
import { Totp } from "../../data/models/user";
import { useStore } from "../../stores/store";
import { adminVerifySchema } from "../../_validators/schemas/adminVerify.schema";
import { FormInputText } from "../../components/form/FormInputText";
import { RoundButton } from "../../_styles/StyledButtons";
import { router } from "../../router/Routes";
import { useTranslation } from "react-i18next";

function TwoFactorAuthorization() {
    const { t } = useTranslation();
    const { totpStore, authStore } = useStore();
    const { qrCodeAuthorization, loading } = totpStore;
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const rawToken = params.get("token");
    const token = getDecodedToken(rawToken);
    const { handleSubmit, control } = useForm({
        resolver: yupResolver(adminVerifySchema()),
    });
    const { state } = useLocation();

    const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
        const twoFactorObject: Totp = {
            totp: data.verification,
            email: token.email,
        };

        await qrCodeAuthorization(twoFactorObject, rawToken);
        if (state.redirect) {
            navigate(state.redirect);
        } else {
            navigate('/');
        }
    };

    useEffect(() => {
        if (token) {
            const expirationDate = new Date(token["expiry-date"]);
            const currentDate = new Date();

            if (expirationDate < currentDate) {
                navigate("/");
            }
        }
    }, [navigate, token]);

    useEffect(() => {
        if (!token || authStore.user) {
            navigate("/");
        }
    }, [token, navigate, authStore.user]);

    return (
        <Container
            maxWidth="sm"
            sx={{
                marginBottom: 3, marginTop: 10, textAlign: "center", bgcolor: "#fff",
                p: "2rem 1rem", border: "1px solid #ddd", borderRadius: "4px"
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            ></Box>
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Grid container spacing={3} sx={{ justifyContent: "center" }}>
                    <Grid item xs={12}>
                        <LockPersonIcon sx={{ fontSize: "80px" }} color="primary" />
                        <Typography>
                            {t("2FA_INPUT")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormInputText
                            name="verification"
                            label={`${t("2FA_INPUT_CODE")} *`}
                            control={control}
                        />
                    </Grid>
                </Grid>
                <Box
                    sx={{ display: "flex", alignItems: "center", mt: 3, mb: 2 }}
                >
                    <RoundButton variant="text"
                        sx={{ mr: 2, color: "#000", fontWeight: 400 }}
                        disableTouchRipple
                        onClick={() => {
                            // TODO: 
                            // On navigation back, clear the tab history, 
                            // in order to not allow the user to get back to the 2FA page
                            router.navigate("/");
                        }}
                    >
                        {t("GENERAL_CANCEL")}
                    </RoundButton>
                    <RoundButton
                        variant="contained"
                        type="submit"
                        title="Verify"
                        data-testid="verify-button"
                        disabled={loading}
                    >
                        {loading ?
                            <CircularProgress size={25} sx={{ color: "#fff" }} />
                            :
                            t("2FA_VERIFY")
                        }
                    </RoundButton>
                </Box>
            </Box>
        </Container>
    );
}

export default observer(TwoFactorAuthorization);
