import { TextField, styled } from "@mui/material";

export const TableSearch = styled(TextField)({
    "& legend": { display: "none" },
    "& fieldset": { top: 0 },
    marginRight: "10px",
    "& input:focus::placeholder": { color: "#808080" },
    "& input": {
        width: "140px",
        transition: "width 0.2s",
        padding: "8px 10px", // Added padding
        backgroundColor: "white", // Added background color
        fontSize: "13px", // Added font size
    },
    // "& input:focus": {
    //     width: "140px",
    // },
    // "& input::placeholder": { color: "transparent" },
    mr: 3,
});