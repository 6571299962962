import { Box } from "@mui/material";
import { QuestionProps } from "./QuestionProps";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB } from "date-fns/locale";
import { Controller } from "react-hook-form";
import { useStore } from "../../../../stores/store";
import { useLocation, useParams } from "react-router-dom";
import { AnswerSubmit } from "../../../../data/models/survey";
import { RoundButton } from "../../../../_styles/StyledButtons";
import { surveyLanguages } from "../../../../data/static/survey";

interface SurveyDateProps extends QuestionProps {
    date: Date | null;
}

function DateInput(props: SurveyDateProps) {
    const { surveyStore: { getAnswer, setAnswer, submitAnswer } } = useStore();
    const answer = getAnswer(props.id)?.answer;

    const { sessionId, surveyId, timelineId } = useParams();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    const searchParams = new URLSearchParams(window.location.search);
    const language = searchParams.get("lang");

    const isRtl = (): boolean => {
        return surveyLanguages.find((l) => l.code === language)
            ?.direction === "rtl"
    }

    const handleChange = (date: Date | null) => {
        if (props.preview) return;

        const answer: AnswerSubmit = {
            questionId: props.id,
            surveyId: surveyId!,
            timelineId: timelineId!,
            sessionId: sessionId!,
            date: date
        }

        submitAnswer(answer, token ?? undefined);
    }

    return (
        <Box width="100%" sx={{ position: "relative" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                <Controller
                    control={props.control}
                    name={`${props.id}.response`}
                    defaultValue={answer}
                    render={({ field: { ref, onBlur, onChange, name, ...field }, fieldState: { error } }) => (
                        <>
                            <DatePicker
                                {...field}
                                value={getAnswer(props.id)?.answer ?? null}
                                inputRef={ref}
                                sx={{
                                    width: "150px",
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                    '& input': {
                                        backgroundColor: 'white',
                                        padding: '8px 10px',
                                        fontSize: '11px'
                                    },
                                    "& .MuiInputBase-root": {
                                        backgroundColor: "#fff",
                                    },
                                    "& .MuiInputBase-root:hover": {
                                        backgroundColor: "#fff",

                                    }
                                }}
                                slotProps={{
                                    textField: {
                                        helperText: error?.message,
                                        error: !!error,
                                        variant: "filled",
                                        fullWidth: true,
                                        inputProps: {
                                            'data-testid': name
                                        },
                                    },
                                }}
                                onChange={(e) => {
                                    onChange(e);
                                    setAnswer(props.id, { answer: new Date(e) });
                                    handleChange(new Date(e));
                                }}
                                disabled={props.disabled}
                            />
                            {!props.disabled && answer && <RoundButton
                                variant="text"
                                sx={{
                                    width: "8.5rem",
                                    position: 'absolute',
                                    bottom: 0,
                                    left: isRtl() ? 0 : "auto",
                                    right: isRtl() ? "auto" : 0,
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                    },
                                }}
                                onClick={() => {
                                    onChange(null);
                                    setAnswer(props.id, { answer: null });
                                    handleChange(null);
                                }}
                            >
                                Clear section
                            </RoundButton>}
                        </>
                    )}
                />
            </LocalizationProvider>
        </Box>
    );
}

export function DateCreator(props: SurveyDateProps) {
    return (
        <Box width="100%" sx={{ position: "relative", mb: 3 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                <DatePicker
                    value={null}
                    sx={{
                        width: "150px",
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        '& input': {
                            backgroundColor: 'white',
                            padding: '8px 10px',
                            fontSize: '11px'
                        },
                        "& .MuiInputBase-root": {
                            backgroundColor: "#fff",
                        },
                        "& .MuiInputBase-root:hover": {
                            backgroundColor: "#fff",

                        }
                    }}
                    slotProps={{
                        textField: {
                            variant: "filled",
                            fullWidth: true,
                        },
                    }}
                    disabled={true}
                />
            </LocalizationProvider>
        </Box >
    );
}


export default DateInput;