import { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";

import { Box, Grid, List, ListItemButton, ListItemText } from '@mui/material';

import "./styles.css";
import TemplateVersions from './TemplateVersions';
import { useStore } from '../../stores/store';
import { hasRoles } from '../../utils/permissionEvaluator';
import { useNavigate } from 'react-router-dom';

const Templates = () => {
    const { templateStore,
        authStore: { user },
        institutionStore: { selectedUserInstitution },
    } = useStore();
    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        if (!user?.isSuperAdmin && !hasRoles(["USZ Coordinator"], selectedUserInstitution?.roles ?? [])) {
            navigate('/profile');
        }
        else {
            templateStore.getTemplates();
        }
    }, [templateStore, templateStore.getTemplates, navigate, selectedUserInstitution?.roles, user?.isSuperAdmin])

    const handleTemplateClick = (templateId: string) => {
        setSelectedTemplateId(templateId);
        templateStore.reset();
    };

    return (
        <Grid container className="templates-container" sx={{ backgroundColor: '#ffffff' }}>
            <Grid item md={2} lg={2} xl={1.65} className='vertical-divider'>
                <Box className="templates-first-menu-content">
                    <List className='templates-list-content'>
                        {templateStore.templateList.map((template, index) => (
                            <ListItemButton
                                key={index}
                                className='list-item-button'
                                onClick={() => handleTemplateClick(template.id)}
                                selected={selectedTemplateId === template.id}>
                                <ListItemText primary={template.name} />
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
            </Grid>
            {selectedTemplateId && <TemplateVersions selectedTemplateId={selectedTemplateId} />}
        </Grid>
    );
};

export default observer(Templates);
