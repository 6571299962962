import { Checkbox, FormControl, FormControlLabel, FormLabel, FormGroup, Typography, Box } from "@mui/material";

import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { FormInputProps } from "../../../components/form/FormInputProps";
import './styles.css';
import { Permission, PermissionLabel } from "../../../data/models/role";

interface CheckboxFieldProps<T> extends FormInputProps {
    options: T[];
    labelKey: keyof T;
    valueKey: keyof T;
    defaultValue?: Permission[];
}

export const RoleInputMultiCheckbox = <T extends { [key: string]: any }>({
    name,
    control,
    setValue,
    label,
    options,
    labelKey,
    valueKey,
    defaultValue
}: CheckboxFieldProps<T>) => {
    const [selectedItems, setSelectedItems] = useState<any>([]);

    useEffect(() => {
        if (defaultValue) {
            const defaultValues = defaultValue
                .filter(permission => permission.hasAccess)
                .map((item: Permission) => item.name);
            setSelectedItems(defaultValues);
        }
    }, [defaultValue, options]);

    useEffect(() => {
        setValue(name, selectedItems);
    }, [name, selectedItems, setValue, label]);

    const handleSelect = (value: any) => {
        const isChecked = selectedItems.indexOf(value);
        if (isChecked !== -1) {
            const remaining = selectedItems.filter((item: any) => item !== value);
            setSelectedItems(remaining);
        } else {
            setSelectedItems((prevItems: any) => [...prevItems, value]);

            if (value.includes("_ALL")) {
                const ownPermission = value.replace('_ALL', '_OWN');
                const ownIndex = selectedItems.indexOf(ownPermission);
                if (ownIndex === -1) {
                    setSelectedItems((prevItems: any) => [...prevItems, ownPermission]);
                }
            }
        }
    };

    const formatLabel = (label: string) => {
        if (label === "CLIENTS") return "Users";
        if (label.includes("_")) {
            const words = label.split("_");
            const firstWord = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
            const remainingWords = words.slice(1).join(" ").toLowerCase();
            return `${firstWord} ${remainingWords}`;
        } else {
            return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
        }
    };

    const isDisabled = (permission: T) => {
        if (permission[valueKey].includes("_OWN")) {
            const allPermission = permission[valueKey].replace('_OWN', '_ALL');
            return selectedItems.includes(allPermission);
        }
        return false;
    };


    return (
        <FormControl size={"small"} variant={"outlined"}>
            <FormLabel component="h5" sx={{ mb: '7px', fontWeight: 500, color: '#a9a9a9' }}>{label}</FormLabel>
            <FormGroup className="permission-form-group">
                {options.map((groups: T) => (
                    <FormControl key={groups.name} component="fieldset">
                        <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>{groups.name}</Typography>
                        <Box className="permission-checkbox-container">
                            {groups.permissions.map((permission: T) => (
                                <FormControlLabel
                                    key={permission[valueKey]}
                                    control={
                                        <Controller
                                            name={name}
                                            render={() => (
                                                <Checkbox
                                                    disabled={isDisabled(permission)}
                                                    checked={selectedItems.includes(permission[valueKey])}
                                                    onChange={() => handleSelect(permission[valueKey])}
                                                    color="primary"
                                                    style={{
                                                        color: isDisabled(permission) ? "#555555a8" : "#03b2ff",
                                                        fontSize: '13px'
                                                    }}
                                                />
                                            )}
                                            control={control}
                                        />
                                    }
                                    sx={{ color: isDisabled(permission) ? "#5A5A5A" : "inherit" }}
                                    label={
                                        <Typography variant="body2" style={{ fontSize: '13px' }}>
                                            {PermissionLabel[permission[labelKey]]}
                                        </Typography>
                                    }
                                />
                            ))}
                        </Box>
                    </FormControl>
                ))}
            </FormGroup>
        </FormControl>
    );
};
