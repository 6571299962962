import { Box, Container, Typography } from "@mui/material";
import { useEffect } from "react";
import { useStore } from "../../../../stores/store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

function SessionClientOnHold() {
    const { t } = useTranslation();
    const {
        sessionStore: { getSession },
        authStore: { user },
    } = useStore();
    const { sessionId } = useParams();
    const navigate = useNavigate();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    useEffect(() => {
        refreshSession();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId]);

    const refreshSession = async () => {
        if (sessionId) {
            const session = await getSession(sessionId, token ?? undefined);
            const isAssessmentSurvey =
                session.currentStep === "ASSESSMENT_OF_SUICIDE_ACTIVE" ||
                session.currentStep === "ASSESSMENT_OF_SUICIDE_COMPLETED";

            if (!isAssessmentSurvey) {
                if (user) {
                    navigate(`/screening/session?id=${sessionId}`);
                } else if (location.state) {
                    const redirectUrl = location.state.redirect ?? `${location.state.from.pathname}${location.state.from.search}`
                    navigate(redirectUrl);
                } else {
                    navigate(`/screening/session?token=${token}`);
                }
            } else {
                setTimeout(refreshSession, 4000);
            }
        }
    }

    return (
        <Container maxWidth="lg" sx={{ height: "100%" }}>
            <Box
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                mt="10rem"
            >
                <Typography component="h1" variant="h4">
                    {t("SESSION_ON_HOLD")}
                </Typography>
            </Box>
        </Container>
    );
}

export default observer(SessionClientOnHold);
