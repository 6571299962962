import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function RestrictedAccess() {
    const { t } = useTranslation();
    return (
        <Box display='flex' flexDirection='column' justifyContent='flex-start' alignItems='center' marginTop={5}>
            <Typography component='h1' variant='h2'>{t("RESTRICT_MODE_MSG")}</Typography>
            <Typography component='p' variant='h5'>{t("RESTRICT_MODE_INFO")}</Typography>
        </Box>
    );
}

export default RestrictedAccess;