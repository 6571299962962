import { CloudFile } from "./client";

export interface SessionSurvey {
    id: string;
    name: string;
    score: number;
    status: SessionSurveyStatus;
    completedAt: Date | null;
}

export interface SessionTimeline {
    timelineId: string;
    timelineName: string;
    surveys: SessionSurvey[];
}

export interface SessionDetailsDto {
    sessionId: string;
    stage: string;
    status: string;
    currentStep: string;
    assessorId: string;
    clientId: string;
    processStartTime: Date | null;
    sessionStartTime: Date | null;
    timeline: string;
    consentAgreement: boolean;
    k10Score: number | null;
    group: string | null;
    subgroup: string | null;

    assessmentOfSuicideSurveyId: string;
    mustCompleteAssessmentOfSuicide: boolean,
    isAssessmentOfSuicideCompleted: boolean,
    isAssessmentOfSuicideFormCompleted: boolean,
    surveys: string[];
    processId: string;
    timelineId: string;
    surveyName: string;
    surveyId: string;
    isImpairmentCompleted: boolean;
    isImpairmentFormCompleted: boolean;
    isImpairmentExcluded: boolean;
    timelineStartTime: Date | null;
    assessmentType: FollowUpType;
    startedByAssessor: boolean;
    clientBreaks: SessionBreak[];
    languageId: string;
    impairmentSurveyId: string;
    impairmentCanBeStarted: boolean;
    isTriageCompleted: boolean;
    isTriageConsentCompleted: boolean;
    baselineResultsConfirmed: boolean;
    secondICStatus?: string;

    // end notification changes
    tokenStateInfo: SessionTokenStateInfo,
    institutionalData: SessionInstitutionalData,
    voucherData: SessionVoucherData
}

export interface SessionTokenStateInfo {
    timelineCompleted: boolean;
    timelineAssessmentType?: FollowUpType;
    timelineName?: string;
}

export interface SessionInstitutionalData {
    study?: string,
    uszCoordinatorPhoneNumber?: string,
    uszCoordinatorEmail?: string
}

export interface SessionVoucherData {
    downloadLink?: string,
    authorizationCode?: string,
    valueInCHF: number
}

export interface SessionBreak {
    id: string;
    timelineId: string;
    surveyId: string;
    duration: number;
}

export interface BreakDuration {
    breakId: string;
    startTime: Date;
    endTime: Date;
    duration: number;
}

export interface ClientSessionInfo {
    id: string;
    title: string;
    status: SessionStatus;
    process: string;
    startTime: Date;
    clientId: string;
    assessorId: string;
}

export interface TimelineEvent {
    eventId: string;
    eventTitle: string;
    eventStartTime: Date;
    status: string;
}

export interface SessionStart {
    hasStarted: boolean;
}

export interface TimelineEventDetails {
    timelineId: string;
    timelineName: string;
    events: TimelineEvent[];
}

export interface SessionFile extends CloudFile {
    order: number,
}

export interface ClientSession {
    id: string;
    startTime: Date;
    active: boolean;
}

export interface SessionAttempt {
    numberOfAttempts: number;
    attemptLimit: number;
}

export interface Assessor {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface SessionEmergencyRequest {
    emergencyHold: boolean;
    reason?: string;
    timeframe?: string;
    timeline?: string;
}

export interface SessionDeactivateRequest {
    reason?: string;
    timeframe: string;
    timeline: string;
}

export interface SessionDialogData {
    action: SessionAction;
    sessionId: string;
    sessionData: SessionEmergencyRequest | SessionDeactivateRequest;
}

export enum SessionAction {
    EMERGENCY = 'EMERGENCY',
    DEACTIVATE = 'DEACTIVATE',
    RESUME = 'RESUME',
}

export enum FollowUpType {
    ONSITE = 'ONSITE',
    ONLINE = 'ONLINE',
    ASSISTED = "ASSISTED",
}

export enum SessionSurveyStatus {
    NOTSTARTED = "NOT_STARTED",
    INPROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED"
}

export enum SessionStatus {
    CANCELLED = "CANCELLED",
    COMPLETED = "COMPLETED",
    ABORT = "ABORT",
    EXCLUDED = "EXCLUDED",
    IN_PROGRESS = "IN_PROGRESS",
    ACTIVE = "ACTIVE",
    BLONLY = "BL_ONLY",
    EXCLUDED_SUICIDALITY = "EXCLUDED_SUICIDALITY",
    AWAITING_EVENT_CREATION = "AWAITING_EVENT_CREATION",
    AWAITING_BASELINE_EVENT_CREATION = "AWAITING_BASELINE_EVENT_CREATION",
    CLIENT_NO_SHOW_UP = "CLIENT_NO_SHOW_UP",
    EMERGENCY_HOLD = "EMERGENCY_HOLD",
    DEACTIVATED = "DEACTIVATED",
}

export const SessionStatusText: Record<SessionStatus, string> = {
    [SessionStatus.AWAITING_EVENT_CREATION]: "Timeline completed",
    [SessionStatus.AWAITING_BASELINE_EVENT_CREATION]: "Awaiting baseline event creation",
    [SessionStatus.COMPLETED]: "Completed",
    [SessionStatus.CANCELLED]: "Canceled",
    [SessionStatus.ABORT]: "Aborted",
    [SessionStatus.EXCLUDED]: "Excluded",
    [SessionStatus.IN_PROGRESS]: "IN_PROGRESS",
    [SessionStatus.EXCLUDED_SUICIDALITY]: "Excluded suicidality",
    [SessionStatus.ACTIVE]: "Active",
    [SessionStatus.BLONLY]: "Baseline Only",
    [SessionStatus.CLIENT_NO_SHOW_UP]: "Didn't attend",
    [SessionStatus.EMERGENCY_HOLD]: "Session on hold",
    [SessionStatus.DEACTIVATED]: "Deactivated",
};

export const SurveyStatusText: Record<SessionSurveyStatus, string> = {
    [SessionSurveyStatus.NOTSTARTED]: "Not started",
    [SessionSurveyStatus.INPROGRESS]: "Ongoing",
    [SessionSurveyStatus.COMPLETED]: "Completed",
};