import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { surveyLanguages } from "../../../../data/static/survey";
import "./styles.css";
import { SurveyLanguage } from "../../../../data/models/survey";

interface SurveyLanguageSelectProps {
    onSelect: (language: string) => void;
}

function SurveyLanguageSelect({ onSelect }: SurveyLanguageSelectProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const lang = params.get("lang");
    const [selectedLang, setSelectedLang] = useState<SurveyLanguage | null>(null);

    useEffect(() => {
        const selected = surveyLanguages.find(l => l.code === lang);
        if (selected) {
            setSelectedLang(selected);
        }
    }, [lang]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onLanguageChange = (language: SurveyLanguage) => {
        onSelect(language.code);
        setSelectedLang(language);
        handleClose();
    }

    return (
        <Box className="lang-menu-container">
            <IconButton
                onClick={handleClick}
                sx={{ borderRadius: "2rem" }}
                className="survey-lang-selector"
                disableTouchRipple
            >
                <Typography fontSize={"12px"} fontWeight={600} pr={"4px"} pl={"8px"}>{lang ? `${selectedLang?.name} (${lang})` : ""}</Typography>
                <LanguageRoundedIcon fontSize="small" />
                {anchorEl ?
                    <ArrowDropUpIcon />
                    :
                    <ArrowDropDownIcon />
                }
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transitionDuration={0}
            >
                {surveyLanguages.map((language) => (
                    <MenuItem key={language.code} selected={language.code === lang} onClick={() => onLanguageChange(language)}>
                        <Typography fontSize={12}>{language.name}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}

export default SurveyLanguageSelect;