
import { useEffect } from 'react';
import { useStore } from '../../stores/store';

export const useClearBrightTimeline = () => {
    const { clientStore } = useStore();
    const { clearBrightTimeline } = clientStore;

    useEffect(() => {
        return () => {
            clearBrightTimeline();
        };
    }, [clearBrightTimeline]);
}
