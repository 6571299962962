import { Box, Typography } from "@mui/material";
import { Fragment, ReactNode, useContext, useEffect, useState } from "react";
import { SurveyContext } from "../Form/SurveyInitializer";
import { FormInputText } from "../../../../components/form/FormInputText";
import { QuestionProps } from "./QuestionProps";
import { Question } from "../../../../data/models/survey";

function SurveyTitle({
    control,
    id,
    title
}: QuestionProps) {
    const { editMode } = useContext(SurveyContext);

    const parseText = (inputText: string): ReactNode[] => {
        const boldRegex = /\*\*(.*?)\*\*/g;
        let parts: ReactNode[] = [];
        let lastIndex = 0;

        inputText.replace(boldRegex, (match, p1: string, index: number) => {
            parts.push(inputText.substring(lastIndex, index));

            parts.push(<strong key={index}>{p1}</strong>);
            lastIndex = index + match.length;
            return match;
        });

        parts.push(inputText.substring(lastIndex));

        return parts;
    };

    const formattedTitle = title.split(/\r\n/).map((line, index) => (
        <Fragment key={index}>
            {editMode ?
                <FormInputText name={`questions.${id}.title`} control={control} rows={3} />
                :
                <Typography fontSize="14px" mb={"4px"}>{parseText(line)}</Typography>
            }
        </Fragment>
    ));

    return (
        <Box>
            {formattedTitle}
        </Box>
    );
}

export function TitleCreator({
    control,
    id,
    title
}: QuestionProps) {
    const { baseSurvey } = useContext(SurveyContext);
    const [question, setQuestion] = useState<Question>();

    useEffect(() => {
        if (baseSurvey) {
            baseSurvey.pages.forEach(page => {
                const currentQuestion = page.questions.find(q => q.id === id);
                if (currentQuestion) {
                    setQuestion(currentQuestion);
                }
            })
        }
    }, [baseSurvey, id]);

    return (
        <Box mb={3}>
            <Box mb={2}>
                <FormInputText name={`questions.${id}.title`} control={control} rows={3} initValue={title} helperText={question?.title ?? ""} />
            </Box>
        </Box>
    );
}

export default SurveyTitle;