import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { Box, Container, Grid, Typography } from "@mui/material";

import { AuditLog } from "../../data/models/auditLog";
import { TableHeader } from "../../_styles/table/TableHeader";
import { TableText } from "../../_styles/table/TableText";
import GenericTable from "../../components/GenericTable";

import ConfirmDialog from "../../components/ConfirmDialog";
import { useForm } from "react-hook-form"

import { useEffect, useRef, useState } from "react";
import { Pagination } from "../../data/models/pagination";
import { t } from "i18next";
import { statsFormSchema } from "../../_validators/schemas/statsForm.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { DateRange } from "../../data/models/stats";
import FormInputDate from "../../components/form/FormInputDate";
import JSONPretty from "react-json-pretty";
import { getDateAndTimeFormatByPlatformLanguage } from "../../utils/utils";
import { useTranslation } from "react-i18next";

function AuditLogsList() {
    const { i18n } = useTranslation();

    const {
        auditLogStore: { getAuditLogs, logList, loading },
    } = useStore();
    let date = new Date();
    const { control, watch } = useForm({
        resolver: yupResolver(statsFormSchema()),
        defaultValues: {
            startDate: new Date(date.getFullYear(), date.getMonth() - 1, date.getDate()),
            endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate())
        }
    });
    const paginationString = sessionStorage.getItem("log-pagination");
    const paginationParams = paginationString
        ? JSON.parse(paginationString!)
        : null

    const [pagination, setPagination] = useState<Pagination>({
        page: 1,
        pageSize: paginationParams?.PageSize ?? 10,
    });
    const maxPage = useRef(pagination.page);
    const [isDateChange, setIsDateChange] = useState(false);

    const firstDay = watch('startDate');
    const lastDay = watch('endDate');

    const handlePageChange = async (page: number, pageSize: number) => {
        setPagination({ page: page + 1, pageSize });
    }

    useEffect(() => {
        const fetchData = async () => {
            const now = new Date();
            now.setMonth(now.getMonth());

            const firstDate = new Date(firstDay);
            firstDate.setDate(firstDay.getDate() + 1)
            const lastDate = new Date(lastDay);
            lastDate.setDate(lastDate.getDate() + 1);


            const range: DateRange = {
                startDate: firstDate.toISOString(),
                endDate: lastDate.toISOString()
            }

            await getAuditLogs({
                paging: {
                    page: pagination.page,
                    pageSize: pagination.pageSize
                }
            }, range);

            setPagination({ page: 1, pageSize: 10 });
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, firstDay, lastDay])

    const handleSelect = async () => {
        setIsDateChange(true);
        setPagination({ page: 1, pageSize: 10 })
    }

    const fetchData = async () => {
        const now = new Date();
        now.setMonth(now.getMonth());

        const firstDate = new Date(firstDay);
        firstDate.setDate(firstDay.getDate() + 1)
        const lastDate = new Date(lastDay);
        lastDate.setDate(lastDate.getDate() + 1);

        const range: DateRange = {
            startDate: firstDate.toISOString(),
            endDate: lastDate.toISOString()
        }

        await getAuditLogs({
            paging: {
                page: pagination.page,
                pageSize: pagination.pageSize
            }
        }, range, isDateChange);
        setIsDateChange(false);
    }

    return (
        <Container maxWidth="xl">
            <Typography variant="h5" component="h1" sx={{ py: 2 }} >{t("NAV_AUDIT_LOGS")}</Typography>
            <Grid container component={"form"} sx={{ display: "flex", alignItems: "center", maxWidth: "500px" }}>
                <Grid item xs={4} mr={2}>
                    <FormInputDate name="startDate" control={control} date={new Date(date.getFullYear(), date.getMonth() - 1, date.getDate())} label="Start date" onSelect={handleSelect} />
                </Grid>
                <Grid item xs={4} mr={2}>
                    <FormInputDate name="endDate" control={control} date={new Date(date.getFullYear(), date.getMonth(), date.getDate())} label="End date" onSelect={handleSelect} />
                </Grid>
            </Grid>
            <Box sx={{ 
                overflowX: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: '#b0b0b0 #f0f0f0',
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#b0b0b0',
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#f0f0f0',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#909090',
                },
                }}>
                <GenericTable<AuditLog>
                    columns={[
                        {
                            header: <TableHeader>Id</TableHeader>,
                            key: "id",
                            render: (log) => (
                                <TableText>
                                    {log.id}
                                </TableText>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: <TableHeader>{t("ENTITY_NAME")}</TableHeader>,
                            key: "entityName",
                            render: (log) => (
                                <TableText>
                                    {log.entityName}
                                </TableText>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: <TableHeader>{t("ACTION")}</TableHeader>,
                            key: "action",
                            render: (log) => (
                                <TableText>
                                    {log.action}
                                </TableText>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: <TableHeader>{t("PARAMETERS")}</TableHeader>,
                            key: "parameters",
                            render: (log) => (
                                <TableText sx={{ overflow: 'auto' }}>
                                    <JSONPretty data={log.parameters.toString()} onJSONPrettyError={e => console.error(e)}></JSONPretty>
                                </TableText>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: <TableHeader>{t("OBJECT_UNIQUE_ID")}</TableHeader>,
                            key: "objectUniqueId",
                            render: (log) => (
                                <TableText>
                                    {log.objectUniqueId}
                                </TableText>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: <TableHeader>{t("TIMESTAMP")}</TableHeader>,
                            key: "timestamp",
                            render: (log) => (
                                <TableText>
                                    {getDateAndTimeFormatByPlatformLanguage(new Date(log.timestamp), i18n.language)}
                                </TableText>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: <TableHeader>{t("SUCCEEDED")}</TableHeader>,
                            key: "succeeded",
                            render: (log) => (
                                <TableText
                                    style={{
                                        color: log.success ? "green" : "red",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {log.success ? t("SUCCESS") : t("GENERAL_FAILED")}
                                </TableText>
                            ), className: "process-cell",
                        },
                        {
                            header: <TableHeader>{t("USER_LOG_EMAIL")}</TableHeader>,
                            key: "userEmail",
                            render: (log) => (
                                <TableText>
                                    {log.userEmail}
                                </TableText>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: <TableHeader>{t("AUDIT_LOGS_USER_ROLES")}</TableHeader>,
                            key: "userEmail",
                            render: (log) => (
                                <TableText>
                                    {log.userRoles.join(', ')}
                                </TableText>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: <TableHeader>{t("LOGS_IP_ADDRESS")}</TableHeader>,
                            key: "ipAddress",
                            render: (log) => (
                                <TableText>
                                    {log.ipAddress}
                                </TableText>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: <TableHeader>{t("LOGS_LOCATION_INFO")}</TableHeader>,
                            key: "locationInfo",
                            render: (log) => (
                                <TableText>
                                    {log.locationInfo}
                                </TableText>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: <TableHeader>{t("LOGS_DEVICE_INFO")}</TableHeader>,
                            key: "deviceInfo",
                            render: (log) => (
                                <TableText>
                                    {log.deviceInfo}
                                </TableText>
                            ),
                            className: "process-cell",
                        }
                    ]}
                    rows={logList || []}
                    props={{
                        containerProps: { className: "survey-table-container" },
                        tableContainerProps: { className: "survey-table-container-data"},
                        tableHeadProps: { className: "survey-table-header" },
                        rowProps: { className: "survey-row" },
                    }}
                    onPaginationModelChange={handlePageChange}
                    rowCount={paginationParams?.TotalCount ?? logList.length}
                    rowKey="id"
                    usePagination
                    loading={loading}
                />
            </Box>
            <ConfirmDialog />
        </Container>
    );
}

export default observer(AuditLogsList);