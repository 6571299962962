import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import ProcessOverview from "./Process/ProcessOverview";
import LoadingComponent from "../../components/LoadingComponent";
import { useStore } from "../../stores/store";
import { ProcessDetails } from "../../data/models/process";
import { RoundButton } from "../../_styles/StyledButtons";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import AssignClientForm from './Process/AssignClientForm';
import { useTranslation } from 'react-i18next';

function ProcessSection() {
    const { t } = useTranslation();
    const {
        processStore,
        modalStore,
        institutionStore
    } = useStore();
    const { getProcessList, loading } = processStore;
    const { openModal } = modalStore;
    const { selectedUserInstitution } = institutionStore;
    const [processList, setProcessList] = useState<ProcessDetails[]>([]);
    const [selectedProcess, setSelectedProcess] = useState<ProcessDetails | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            if (selectedUserInstitution) {
                const processes = await getProcessList(selectedUserInstitution.institutionId);
                if (processes.length > 0) {
                    setProcessList(processes);
                    setSelectedProcess(processes[0]);
                }
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserInstitution]);

    const handleProcessChange = (event: SelectChangeEvent) => {
        const processId = event.target.value;
        const process = processList.find(p => p.id === processId);
        if (process) {
            setSelectedProcess(process);
        }
    }

    const assignClients = () => {
        if (selectedProcess) {
            openModal(<AssignClientForm processId={selectedProcess.id} />, { size: "sm", persistent: true })
        }
    }

    if (loading) return (
        <LoadingComponent gridProps={{
            style: {
                height: "50vh"
            }
        }} />
    );

    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box className="process-select-container">
                <RoundButton variant="contained" sx={{ width: "auto", mr: 2 }} onClick={assignClients}>
                    {t("PROCESS_ADD_CLIENTS")}
                    <PersonAddAltRoundedIcon sx={{ fontSize: "18px", ml: "6px" }} />
                </RoundButton>
                <Select
                    value={selectedProcess?.id ?? ""}
                    onChange={handleProcessChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{ fontSize: "13px" }}
                    SelectDisplayProps={{
                        style: {
                            width: "140px",
                            padding: "8px 10px",
                            backgroundColor: "white",
                        },
                    }}
                    MenuProps={{
                        style: {
                            maxHeight: "300px",
                            maxWidth: "300px",
                        },
                    }}
                >
                    {processList.map(process => (
                        <MenuItem key={process.id} value={process.id} sx={{ fontSize: "13px" }}>{process.name}</MenuItem>
                    ))}
                </Select>
            </Box>
            {selectedProcess && <ProcessOverview process={selectedProcess} />}
        </Box >
    );
}

export default observer(ProcessSection);