import { Box, Grid, darken } from '@mui/material';

import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { CSSProperties, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import BookingHeader from '../Options/BookingHeader';
import { useStore } from '../../../stores/store';
import CalendarDashboard from './CalendarDashboard';
import { CalendarEvent } from '../../../data/models/event';
import { generateColor } from '../../../utils/colorGenerator';
import Event from '../Components/Event';
import EventPopper from '../Form/EventPopper';
import EventForm from '../Form/EventForm';
import { useParams } from 'react-router-dom';
import LoadingComponent from '../../../components/LoadingComponent';
import Toolbar from '../Components/Toolbar';
import '../styles.css';
import '../rbc.css';
import MonthHeader from '../Components/MonthHeader';
import WeekHeader from '../Components/WeekHeader';
import EventList from './EventList';
import { hasModulePermission } from '../../../utils/permissionEvaluator';
import { ModuleName } from '../../../data/models/role';

const localizer = momentLocalizer(moment);
moment.updateLocale('en', {
    week: {
        dow: 1, // Monday is the first day of the week
    },
});

function MyCalendar() {
    const {
        bookingStore: { setViewType, viewType, bookingList },
        modalStore: { openModal },
        commonStore: { calendarDate, setCalendarDate },
        institutionStore: { selectedUserInstitution }
    } = useStore();
    const onView = useCallback((newView: any) => setViewType(newView), [setViewType])
    const [date, setDate] = useState(calendarDate ? new Date(calendarDate) : new Date());
    const popperAnchorRef = useRef<HTMLButtonElement>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [popperOpener, setPopperOpener] = useState(false);
    const [showEventList, setShowEventList] = useState(false);
    const [eventList, setEventList] = useState<CalendarEvent[]>([]);
    const [calendarEvent, setCalendarEvent] = useState<CalendarEvent | null>();
    const start = moment().startOf('day').add(6, 'hours');
    const end = moment().startOf('day').add(22, 'hours');
    const min = start.toDate()
    const max = end.toDate()
    const handleCalendarEventSelect = (userEvent: CalendarEvent, e: SyntheticEvent<HTMLElement, Event>) => {
        if (userEvent!.id === '') {
            return;
        }

        setAnchorEl(e.currentTarget);
        setCalendarEvent(userEvent);
        setPopperOpener(true);
    };
    const { view } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (view === 'day') {
            setViewType(Views.DAY);
        } else if (view === 'week') {
            setViewType(Views.WEEK);
        } else if (view === 'month') {
            setViewType(Views.MONTH);
        } else {
            setViewType(Views.DAY);
        }
        setIsLoading(false);
    }, [setViewType, view]);

    const onDateChange = (date: any) => {
        const events = bookingList.filter((event) => {
            return event.start.getDate() === date.getDate()
                && event.start.getMonth() === date.getMonth()
                && event.start.getFullYear() === date.getFullYear();
        });
        setEventList(events);
        setDate(date);
        setCalendarDate(date);
    };

    useEffect(() => {
        if (showEventList) {
            const events = bookingList.filter((event) => {
                return event.start.getDate() === date.getDate()
                    && event.start.getMonth() === date.getMonth()
                    && event.start.getFullYear() === date.getFullYear();
            });
            setEventList(events);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingList, showEventList]);

    const handleSelectSlot = useCallback(
        (slot: any) => {
            if (slot.action === "select" || slot.action === "doubleClick") {
                openModal(<EventForm initialStartDate={slot.start} initialEndDate={slot.end} />)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [openModal])

    const eventStyleGetter = (event: CalendarEvent) => {
        let style: CSSProperties = {
            backgroundColor: 'transparent',
            opacity: 0.9,
            color: darken(generateColor(event.viewId), 0.3),
            border: '0px',
            display: 'block',
            width: '95%',
            marginLeft: '10px',
            padding: '0 5px 0 0',
            borderRadius: 0,
        };

        return {
            className: '',
            style: style,
        };
    };

    const components = {
        toolbar: Toolbar,
        event: Event,
        month: { header: MonthHeader },
        week: { header: WeekHeader, dow: 1 },
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#f1f4f9' }}>
            <BookingHeader />
            <Grid container sx={{ backgroundColor: '#f1f4f9', flexGrow: 1, flexWrap: 'inherit' }}>
                <Grid item >
                    <CalendarDashboard displayDate={onDateChange} date={date} className='dashboard-container' />
                </Grid>
                <Grid item className="calendar-view" sx={{ width: '82vw', padding: '17px 5px' }}>
                    {isLoading ? <LoadingComponent /> :
                        <Calendar<CalendarEvent, {}>
                            className="my-calendar"
                            localizer={localizer}
                            events={bookingList}
                            startAccessor="start"
                            endAccessor="end"
                            allDayMaxRows={1}
                            allDayAccessor={(event) => event.isAllDay}
                            onShowMore={(events, date) => {
                                if (viewType === Views.MONTH) {
                                    setShowEventList(true);
                                    setEventList(events);
                                    setDate(date);
                                }
                            }}
                            popup={viewType !== Views.MONTH}
                            onSelectEvent={handleCalendarEventSelect}
                            components={components}
                            eventPropGetter={eventStyleGetter}
                            onSelectSlot={(e) => handleSelectSlot(e)}
                            dayLayoutAlgorithm={'no-overlap'}
                            style={{
                                height: '76vh',
                                width: showEventList && viewType === Views.MONTH ? '60vw' : '100%',
                                backgroundColor: '#ffffff'
                            }}
                            defaultView={Views.WEEK}
                            view={viewType}
                            drilldownView={viewType}
                            onView={onView}
                            date={date}
                            onNavigate={newDate => onDateChange(newDate)}
                            selectable={selectedUserInstitution ? hasModulePermission(["EDIT_ALL", "EDIT_OWN"], ModuleName.RESOURCE_PLANNING, selectedUserInstitution, false) : false}
                            min={min}
                            max={max}
                            formats={{
                                dayFormat: (date, culture, localizer) =>
                                    localizer!.format(date, 'DD dddd'),
                                dateFormat: (date, culture, localizer) => {
                                    if (date.getDate() === 1) {
                                        return localizer!.format(date, 'MMM D');
                                    } else {
                                        return localizer!.format(date, 'D');
                                    }
                                },
                                timeGutterFormat: (date, culture, localizer) =>
                                    localizer!.format(date, 'HH:mm'),
                            }}
                        />}
                    <EventList
                        open={showEventList && viewType === Views.MONTH}
                        handleOpen={setShowEventList}
                        events={eventList}
                        date={date}
                        handleSelect={handleCalendarEventSelect}
                        view={viewType}
                    />
                    <Box ref={popperAnchorRef}></Box>
                    <EventPopper
                        open={popperOpener}
                        handleOpen={setPopperOpener}
                        anchorElement={anchorEl}
                        eventData={calendarEvent} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default observer(MyCalendar);