import { Box, Grid, Typography } from "@mui/material";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";

import { FormInputText } from "../../../../components/form/FormInputText";
import { RoundButton } from "../../../../_styles/StyledButtons";
import { useForm } from "react-hook-form";
import { useStore } from "../../../../stores/store";
import { PasswordUpdateDto } from "../../../../data/models/profile";
import { toast } from "react-toastify";
import { passwordFormSchema } from "../../../../_validators/schemas/passwordForm.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ChangePasswordForm() {
    const { t } = useTranslation();
    const { profileStore } = useStore();
    const { changePassword, loading } = profileStore;
    const navigate = useNavigate();
    const { control, handleSubmit } = useForm({
        resolver: yupResolver(passwordFormSchema()),
    });

    const onSubmit = async (data: any) => {
        const passwordData: PasswordUpdateDto = {
            currentPassword: data.currentPassword,
            newPassword: data.newPassword,
        };

        await changePassword(passwordData);
        toast.success(t("TOAST_USER_PW_CHANGE"));
    };

    const passwordIcon = () => {
        return (
            <KeyOutlinedIcon color="secondary" sx={{ transform: "rotate(-45deg)" }} />
        );
    };

    const onResetPassword = () => {
        navigate("/forgot-password");
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                columnGap={5}
                rowSpacing={1}
                className="account-container"
            >
                <Grid item xs={12} marginBottom={2}>
                    <Typography fontSize="24px" fontWeight="500">
                        {t("PW_CHANGE")}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8} lg={6}>
                    <FormInputText
                        name="currentPassword"
                        label={t("PW_CURRENT")}
                        control={control}
                        type="password"
                        icon={passwordIcon()}
                    />
                </Grid>
                <Grid item xs={12} md={8} lg={6}>
                    <FormInputText
                        name="newPassword"
                        label={t("PW_NEW")}
                        control={control}
                        type="password"
                        helperText={t("PASSWORD_POLICY")}
                        icon={
                            <KeyOutlinedIcon
                                color="secondary"
                                sx={{ transform: "rotate(-45deg)" }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} md={8} lg={6}>
                    <FormInputText
                        name="confirmPassword"
                        label={t("PW_NEW_CONFIRM")}
                        control={control}
                        type="password"
                        icon={
                            <KeyOutlinedIcon
                                color="secondary"
                                sx={{ transform: "rotate(-45deg)" }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                    <Box
                        display={"flex"}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <RoundButton
                            variant="text"
                            sx={{
                                width: "auto",
                                color: "#55557a",
                                borderRadius: "5px",
                                padding: "0 7px",
                                "&:hover": { backgroundColor: "#55557a17" },
                            }}
                            onClick={onResetPassword}
                            title="Cancel"
                            data-testid="forgot-password-button"
                        >
                            <Typography fontSize="13px">{t("FORGOT_PW")}</Typography>
                        </RoundButton>

                        <RoundButton
                            type="submit"
                            variant="contained"
                            disabled={loading}
                            title="Submit"
                            data-testid="submit-button"
                        >
                            <Typography fontSize="13px">{t("GENERAL_SAVE")}</Typography>
                        </RoundButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default observer(ChangePasswordForm);
