import { TFunction } from "i18next";

export const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "diverse", label: "Diverse" },
    { value: "none", label: "Don't want to specify" },
];

export function getGenderOptions(t?: TFunction<"translation", undefined>) {
    if (t) {
        genderOptions[0].label = t("GENERAL_MALE");
        genderOptions[1].label = t("GENERAL_FEMALE");
        genderOptions[2].label = t("GENDER_DIVERSE");
        genderOptions[3].label = t("GENDER_UNKNOWN");
    }
    return genderOptions;
}