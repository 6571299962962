import { Box, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';

import { useState } from "react";
import DrawerMenu from "../../../components/drawer-menu/DrawerMenu";
import CalendarDashboard from "./CalendarDashboard";
import '../styles.css';

interface CalendarDashboardMenuProps {
    displayDate: (e: any) => void;
    date: Date;
}

function CalendarDashboardMenu({ displayDate, date }: CalendarDashboardMenuProps) {
    const [open, setOpen] = useState(false);

    const handleMenu = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="dashboard-container-menu">
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                className="settings-menu-button"
                onClick={handleMenu}
                title="settings"
                data-testid="calendar-dashboard-button"
            >
                <MenuIcon />
            </IconButton>
            <DrawerMenu
                handleClose={handleClose}
                open={open}
                style={{
                    '@media (max-width: 900px)': { width: '40% !important' },
                    '@media (max-width: 600px)': { width: '80% !important' },
                }}
            >
                <>
                    <Box display="flex" justifyContent="flex-end" padding="5px 5px 0 0">
                        <IconButton onClick={handleClose} data-testid="close-dashboard-button">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <CalendarDashboard displayDate={displayDate} date={date} />
                </>
            </DrawerMenu>
        </Box>
    );
}

export default CalendarDashboardMenu;