import { Module, Permission, PermissionName, Role } from "../../data/models/role";
import { modules } from "../../pages/Settings/Role/roleOptions";

/**
 * Retrieves module permissions based on the user's data.
 * @param {any} data - The data object containing module permissions.
 * @returns {Module[]} Returns an array of modules with their permissions.
 */
export const getModulePermissions = (data: any): Module[] => {
    const roleModules: Module[] = Object.keys(data)
        .filter((key) => key.startsWith("module_"))
        .map((key) => {
            const moduleName = key.substring("module_".length);
            const isActive = data[key].includes("ACTIVATE");

            const permissions = modules!
                .find((module) => module.moduleName === moduleName)
                ?.permissionGroups.flatMap((permissionGroup: any) =>
                    permissionGroup.permissions
                        .filter((permission: any) => permission.name !== "ACTIVATE")
                        .map((permission: any) => ({
                            name: permission.name,
                            hasAccess: data[key].includes(permission.name),
                        }))
                ) || [];

            return {
                moduleName,
                isActive,
                permissions,
            };
        });

    return roleModules;
};


/**
 * Retrieves default permissions for a given module and role.
 * @param {string} moduleName - The name of the module to retrieve default permissions for.
 * @param {Role} role - The role object containing access information.
 * @returns {Permission[] | undefined} Returns an array of default permissions for the specified module and role, or undefined if not found.
 */
export const getDefaultPermissions = (moduleName: string, role: Role): Permission[] | undefined => {
    const foundModule = role?.access?.find(
        (m: Module) => m.moduleName === moduleName
    );

    if (foundModule) {
        return [...foundModule.permissions, { hasAccess: foundModule.isActive, name: PermissionName.ACTIVATE }];
    }
}