import { Link, Grid, Box, Typography, CircularProgress } from "@mui/material";

import { useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";

import { UserFormValues } from "../../../data/models/user";
import { useStore } from "../../../stores/store";
import { FormInputText } from "../../../components/form/FormInputText";
import { loginSchema } from "../../../_validators/schemas/loginForm.schema";
import { RoundButton } from "../../../_styles/StyledButtons";
import logo from "./logo.jpg";
import "./styles.css";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../../components/navbar/LanguageSelector";

function LoginForm() {
    const { t } = useTranslation();
    const {
        authStore: { login, user },
        loadingStore: { isLoading }
    } = useStore();
    const { handleSubmit, control } = useForm<UserFormValues>({
        resolver: yupResolver(loginSchema()),
    });
    const navigate = useNavigate();
    const { state: locationState } = useLocation();

    useEffect(() => {
        if (user) {
            navigate("/settings/tenant");
        }
    }, [user, navigate, locationState]);

    const onSubmit = async (data: UserFormValues) => {
        if (toast.isActive("sessionExpire")) {
            toast.dismiss("sessionExpire");
        }
        if (locationState) {
            const redirectUrl =
                locationState.redirect ?? locationState.from
                    ? `${locationState?.from?.pathname}${locationState?.from?.search}`
                    : null;
            await login(data, redirectUrl);
        } else {
            await login(data);
        }
    };

    return (
        <Box className="login-form-box">
            <Box display={"flex"} justifyContent={"center"}>
                <img src={logo} alt={"Spirit logo"} style={{ width: 180 }} />
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"flex-end"} pb={1}>
                <Typography
                    component="h1"
                    variant="h5"
                    fontWeight={"bold"}
                    mt={3}
                >
                    {t("LOGIN_LABEL")}
                </Typography>
                <LanguageSelector />
            </Box>
            <Box
                component="form"
                className="login-form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{ mt: 1 }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <FormInputText
                            name="email"
                            control={control}
                            label={t("GENERAL_EMAIL")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputText
                            name="password"
                            control={control}
                            label={t("USER_PASSWORD")}
                            type="password"
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs className="forgot-password">
                        <Link component={RouterLink} to="/forgot-password" variant="body2">
                            {t("FORGOT_PW")}
                        </Link>
                    </Grid>
                </Grid>
                <RoundButton
                    type="submit"
                    variant="contained"
                    size="large"
                    className="login-button"
                    disabled={isLoading(login)}
                    sx={{ width: "100%", marginTop: 3 }}
                    title="Sign-in"
                    data-testid="login-button"
                >
                    {(isLoading(login) && <CircularProgress color="info" size={25} />) || (
                        <Typography fontSize="13px">{t("LOGIN_LABEL")}</Typography>
                    )}
                </RoundButton>
            </Box>
        </Box>
    );
}

export default observer(LoginForm);
