import { Box, Button, Divider } from "@mui/material";
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import ViewWeekRoundedIcon from '@mui/icons-material/ViewWeekRounded';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import BookingMenu from "./BookingMenu";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../stores/store";
import { Views } from "react-big-calendar";
import CalendarFilter from "./CalendarFilter";
import '../styles.css';
import { hasModulePermission } from "../../../utils/permissionEvaluator";
import { useEffect, useState } from "react";
import { ModuleName, PermissionName } from "../../../data/models/role";
import { useTranslation } from "react-i18next";

const bookingHeaderStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px 24px',
}

const containerStyle = {
    width: '97%',
    margin: '10px 0',
    borderRadius: '5px',
    backgroundColor: '#fff'
}

const itemStyle = {
    borderRadius: '2rem',
    padding: '5px 13px !important',
    color: '#616161',
    marginRight: '7px',
    textTransform: "none",
    fontSize: "13px"
}

// TODO: Commented sections will be implemented in the future
function BookingHeader() {
    // const [anchorEl, setAnchorEl] = useState(null);
    // const open = Boolean(anchorEl);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { bookingStore, institutionStore } = useStore();
    const { viewType, setViewType } = bookingStore;
    const { selectedUserInstitution } = institutionStore;
    const [createPermission, setCreatePermission] = useState(false);

    useEffect(() => {
        if (selectedUserInstitution) {
            setCreatePermission(hasModulePermission([PermissionName.EDIT_ALL, PermissionName.EDIT_OWN], ModuleName.RESOURCE_PLANNING, selectedUserInstitution, false));
        }
    }, [selectedUserInstitution]);

    // const handleClick = (event: any) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const onNavigate = (path: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        navigate({ pathname: path, search: searchParams.toString() });
    }

    return (
        <Box sx={containerStyle} className="shadow">
            <Box sx={bookingHeaderStyle} className="calendar-header-items">
                {createPermission && <BookingMenu />}
                <Button
                    sx={{ ...itemStyle, ml: createPermission ? '7px' : 0, color: viewType === Views.DAY ? "#03b3ff" : "#616161" }}
                    className={viewType === Views.DAY ? 'selected-item' : ''}
                    data-testid="day-view-button"
                    onClick={() => {
                        setViewType(Views.DAY);
                        onNavigate(`/resource-planning/view/day`);
                    }}
                    title="Day">
                    {viewType === Views.DAY ?
                        <TodayRoundedIcon fontSize="small" sx={{ marginRight: '5px' }} />
                        :
                        <TodayOutlinedIcon fontSize="small" sx={{ marginRight: '5px' }} />
                    }
                    {t("EVENT_DAY")}
                </Button>
                <Button
                    sx={{ ...itemStyle, color: viewType === Views.WEEK ? "#03b3ff" : "#616161" }}
                    className={viewType === Views.WEEK ? 'selected-item' : ''}
                    data-testid="week-view-button"
                    onClick={() => {
                        setViewType(Views.WEEK);
                        onNavigate(`/resource-planning/view/week`);
                    }}
                    title="Week"
                >
                    {viewType === Views.WEEK ?
                        <ViewWeekRoundedIcon fontSize="small" sx={{ marginRight: '5px' }} />
                        :
                        <ViewWeekOutlinedIcon fontSize="small" sx={{ marginRight: '5px' }} />
                    }
                    {t("EVENT_WEEK")}
                </Button>
                <Button
                    sx={{ ...itemStyle, color: viewType === Views.MONTH ? "#03b3ff" : "#616161" }}
                    className={viewType === Views.MONTH ? 'selected-item' : ''}
                    onClick={() => {
                        setViewType(Views.MONTH);
                        onNavigate(`/resource-planning/view/month`);
                    }}
                    data-testid="month-view-button"
                    title="Month">
                    {viewType === Views.MONTH ?
                        <CalendarMonthRoundedIcon fontSize="small" sx={{ marginRight: '5px' }} />
                        :
                        <CalendarMonthOutlinedIcon fontSize="small" sx={{ marginRight: '5px' }} />
                    }
                    {t("EVENT_MONTH")}
                </Button>
                {/* <Button sx={itemStyle} onClick={handleClick}>
                    <DashboardOutlinedIcon fontSize="small" sx={{ marginRight: '5px' }} />
                    Board
                    <KeyboardArrowDownOutlinedIcon fontSize="small" />
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem onClick={handleClose}>New event 1</MenuItem>
                    <MenuItem onClick={handleClose}>New event 2</MenuItem>
                    <MenuItem onClick={handleClose}>New event 3</MenuItem>
                </Menu>
                <Button sx={itemStyle}>
                    <DynamicFeedOutlinedIcon fontSize="small" sx={{ marginRight: '5px' }} />
                    Split view
                </Button> */}
                <Divider orientation="vertical" flexItem />
                <CalendarFilter />
                {/* <Divider orientation="vertical" flexItem />
                <Button sx={itemStyle}>
                    <IosShareOutlinedIcon fontSize="small" sx={{ marginRight: '5px' }} />
                    Share
                </Button>
                <Button sx={itemStyle}>
                    <LocalPrintshopOutlinedIcon fontSize="small" sx={{ marginRight: '5px' }} />
                    Print
                </Button> */}
            </Box>
        </Box >
    );
}

export default observer(BookingHeader);