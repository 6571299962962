export interface Role {
    id?: number;
    name: string
    tenantId: string
    description: string
    access: Module[]
}

export interface Module {
    moduleName: string
    isActive: boolean
    permissions: Permission[];
}

export enum AppRoles {
    ADMIN = "Admin",
    TENANT_ADMIN = "Tenant Admin",
    USZ_COORDINATOR = "USZ Coordinator",
    HELPER = "Helper",
    CLIENT = "Client",
    ASSESSOR = "Assessor",
    FIELD_COORDINATOR = "Field Coordinator",
    RESEARCHER = "Researcher",
    CONTACT_MANAGER = "Contact Manager",
    FIELD_COORDINATOR_LIGHT = "Field Coordinator Light"
}

export enum PermissionName {
    ACTIVATE = 'ACTIVATE',
    VIEW_ALL = 'VIEW_ALL',
    VIEW_OWN = 'VIEW_OWN',
    EDIT_ALL = 'EDIT_ALL',
    EDIT_OWN = 'EDIT_OWN',
    DELETE_ALL = 'DELETE_ALL',
    DELETE_OWN = 'DELETE_OWN',
    ECRF_PDF = 'ECRF_PDF',
    RESEARCH_DATA_CSV = 'RESEARCH_DATA_CSV',
    BRIDGE_TABLE_CSV = 'BRIDGE_TABLE_CSV',
    CONSENT_FORMS = 'CONSENT_FORMS',
}

export const PermissionLabel: Record<string, string> = {
    [PermissionName.ACTIVATE]: "Activate",
    [PermissionName.VIEW_ALL]: "View all",
    [PermissionName.VIEW_OWN]: "View own",
    [PermissionName.EDIT_ALL]: "Edit all",
    [PermissionName.EDIT_OWN]: "Edit own",
    [PermissionName.DELETE_ALL]: "Delete all",
    [PermissionName.DELETE_OWN]: "Delete own",
    [PermissionName.ECRF_PDF]: "eCRF (PDF)",
    [PermissionName.RESEARCH_DATA_CSV]: "Research Data (csv)",
    [PermissionName.BRIDGE_TABLE_CSV]: "Bridge Table (csv)",
    [PermissionName.CONSENT_FORMS]: "Consent Forms",
};

export enum ModuleName {
    DASHBOARD = 'DASHBOARD',
    RESOURCE_PLANNING = 'RESOURCE_PLANNING',
    SCREENING = 'SCREENING',
    CLIENTS = 'CLIENTS',
    DOWNLOAD_PII = 'DOWNLOAD_PII',
    DOWNLOAD_NII = 'DOWNLOAD_NII',
    TEMPLATES = 'TEMPLATES'
}

export interface Permission {
    name: PermissionName;
    hasAccess: boolean;
}


export interface PermissionGroup {
    name: string;
    permissions: Permission[];
}

export interface ModuleDto {
    moduleName: ModuleName;
    isActive: boolean;
    permissionGroups: PermissionGroup[];
}


export interface RoleDto {
    id: number;
    name: string;
    description: string;
}

export interface PermissionDto {
    institutionId: string;
    permissions: InstitutionPermission[];
    roles: string[];
}

export interface InstitutionPermission {
    module: string;
    isActive: boolean;
    access: string[];
}