import { Box, Button, CircularProgress, Menu, SxProps } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

import { observer } from "mobx-react-lite";
import { useState } from "react";

import { useStore } from "../../../stores/store";
import "../styles.css";
import { RoleDto } from "../../../data/models/role";
import { useTranslation } from "react-i18next";
import { StyledMenuItem } from "../../../_styles/StyledMenu";

const container: SxProps = {
    display: "flex",
};

export interface FilterProps {
    onFilterChange: (newState: number) => void;
}

function ClientRoleFilter({ onFilterChange }: FilterProps) {
    const { t } = useTranslation();
    const {
        clientStore: { clientRoleList, getClientRoles },
        loadingStore: { isLoading }
    } = useStore();
    const [filterId, setFilterId] = useState<number>(-1);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getRoles = () => {
        const allRoles: RoleDto = {
            id: -1,
            name: "All",
            description: "All roles",
        };
        const adminRole: RoleDto = {
            id: 0,
            name: "Tenant admin",
            description: "Tenant admin role",
        };

        return [allRoles, adminRole, ...clientRoleList];
    };

    function handleRoleChange(roleId: number): void {
        setFilterId(roleId);
        onFilterChange(roleId);
        handleClose();
    }

    return (
        <Box sx={container}>
            <Button
                onClick={handleClick}
                className="filter-button"
                sx={{
                    color: anchorEl ? "#03b2ff" : "#808080",
                    bgcolor: anchorEl ? "#03b2ff0a" : "transparent",
                }}
            >
                <Box display={"flex"} alignItems={"center"} sx={{ fontWeight: 500 }}>
                    {t("SETTINGS_ROLES_NAME")}
                    <CircleRoundedIcon sx={{ fontSize: "6px", mt: "3px", ml: "2px", color: filterId !== -1 ? "#03b2ff" : "inherit" }} />
                </Box>
                {anchorEl ? (
                    <KeyboardArrowUpIcon sx={{ fontSize: "16px", pt: "3px" }} />
                ) : (
                    <KeyboardArrowDownIcon sx={{ fontSize: "16px", pt: "3px" }} />
                )}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                transitionDuration={0}
                onClose={handleClose}
            >

                {isLoading(getClientRoles) ?
                    <StyledMenuItem disabled sx={{ width: 100, display: "flex", justifyContent: "center" }}>
                        <CircularProgress size={18} />
                    </StyledMenuItem>
                    :
                    getRoles().map((role) => (
                        <StyledMenuItem
                            key={role.id}
                            value={role.id}
                            sx={{ fontSize: "13px" }}
                            data-testid={role.id}
                            disableTouchRipple
                            selected={role.id === filterId}
                            onClick={() => handleRoleChange(role.id)}
                        >
                            {role.name}
                        </StyledMenuItem>
                    ))}
            </Menu>
        </Box>
    );
}

export default observer(ClientRoleFilter);
