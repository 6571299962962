import { makeAutoObservable, runInAction } from "mobx";
import agent from "../utils/agent";
import { ProcessClientsAddDto } from "../data/models/process";
import { store } from "./store";

export class ProcessStore {
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    getProcessList = async (institutionId: string) => {
        store.loadingStore.startLoading(this.getProcessList);
        this.loading = true;
        try {
            const processes = await agent.Process.list(institutionId);

            runInAction(() => {
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getProcessList);
            return processes;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getProcessList);
            throw error;
        }
    }

    getProcess = async (id: string) => {
        store.loadingStore.startLoading(this.getProcess);
        this.loading = true;
        try {
            const process = await agent.Process.details(id);
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getProcess);

            return process;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getProcess);
            throw error;
        }
    }

    getClientList = async (institutionId: string) => {
        store.loadingStore.startLoading(this.getClientList);
        try {
            const clientList = await agent.Process.clientList(institutionId);
            store.loadingStore.stopLoading(this.getClientList);
            return clientList;
        } catch (error) {
            store.loadingStore.stopLoading(this.getClientList);
            throw error;
        }
    }

    addProcessClients = async (processClientsDto: ProcessClientsAddDto) => {
        store.loadingStore.startLoading(this.addProcessClients);
        try {
            await agent.Process.addClientsToProcess(processClientsDto);
            store.loadingStore.stopLoading(this.addProcessClients);
        } catch (error) {
            store.loadingStore.stopLoading(this.addProcessClients);
            throw error;
        }
    }
}