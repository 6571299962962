import React from "react";
import {
    Box,
    CircularProgress,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";
import { StyledMenuItem } from "../../_styles/StyledMenu";
import { t } from "i18next";

interface SelectFieldProps<T> extends FormInputProps {
    options: T[];
    labelKey: keyof T;
    valueKey: keyof T;
    icon?: JSX.Element | null;
    onSelect?: (e: any) => void;
    onClick?: (e: any) => void;
    labelFn?: (option: T) => string;
    defaultValue?: string | number | boolean | keyof T | null;
    isDisabled?: boolean;
    loading?: boolean;
    hasClearOption?: boolean; // New prop for indicating whether to include the clear option
}

function FormInputSelect<T extends { [key: string]: any }>({
    name,
    label,
    control,
    options,
    valueKey,
    labelKey,
    icon = null,
    onSelect,
    onClick,
    labelFn,
    defaultValue = null,
    helperText = " ",
    isDisabled = false,
    loading = false,
    hasClearOption = false, // Default to false if not provided
}: SelectFieldProps<T>) {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field, field: { onChange }, fieldState: { error } }) => (
                <Box width="100%">
                    <InputLabel sx={{ fontSize: "14px" }}>{label}</InputLabel>
                    <FormControl fullWidth>
                        <Select
                            {...field}
                            id={`select_${label}`}
                            data-testid={name}
                            error={!!error}
                            name={name}
                            sx={{ fontSize: "13px" }}
                            disabled={isDisabled}
                            SelectDisplayProps={{
                                style: {
                                    padding: "8px 10px",
                                    backgroundColor: "white",
                                },
                            }}
                            MenuProps={{
                                style: {
                                    maxHeight: "300px",
                                    minWidth: "fit-content",
                                },
                                transitionDuration: 0,
                            }}
                            onChange={(e) => {
                                onSelect && onSelect(e);
                                onChange(e);
                            }}
                            onClick={(e) => {
                                onClick && onClick(e);
                            }}
                            startAdornment={
                                icon ? (
                                    <InputAdornment position="start">{icon}</InputAdornment>
                                ) : null
                            }
                            defaultValue={defaultValue}
                        >
                            {hasClearOption && ( // Include the clear option if hasClearOption is true
                                <MenuItem value={''} sx={{ fontSize: "13px" }}>
                                    {`${t("GENERAL_CLEAR")}`}
                                </MenuItem>
                            )}
                            {loading ? (
                                <MenuItem
                                    disabled
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <CircularProgress size={18} />
                                </MenuItem>
                            ) : (
                                options.map((option: any) => (
                                    <StyledMenuItem
                                        key={option[valueKey]}
                                        value={option[valueKey]}
                                        sx={{ fontSize: "13px" }}
                                        data-testid={`${name}-option-${option[valueKey]}`}
                                        disabled={option.disabled}
                                        disableRipple
                                    >
                                        {labelFn ? labelFn(option) : option[labelKey]}
                                    </StyledMenuItem>
                                ))
                            )}
                        </Select>
                        <FormHelperText error={!!error}>
                            {error?.message || helperText}
                        </FormHelperText>
                    </FormControl>
                </Box>
            )}
        />
    );
}

export default FormInputSelect;
