import { Checkbox, FormControl, Typography } from "@mui/material";
import { InputHTMLAttributes } from "react";
import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";

const flexStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '15px',
    height: "100%",
};

interface TenantUserSelectProps extends FormInputProps {
    defaultValue: boolean;
}

const FormInputCheckbox = ({ control, name, defaultValue, label, isDisabled = false }: TenantUserSelectProps) => {
    return (
        <FormControl sx={flexStyle}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field: { onChange, value } }) => (
                    <Checkbox
                        onChange={onChange}
                        checked={value}
                        inputProps={
                            {
                                'data-testid': name,
                            } as InputHTMLAttributes<HTMLInputElement>
                        }
                        color="primary"
                        style={{
                            color: "#03b2ff",
                            marginRight: 8,
                            padding: 0
                        }}
                        size="small"
                        disabled={isDisabled}
                    />
                )}
            />
            <Typography sx={{ fontSize: '14px', wordBreak: 'break-word', hyphens: 'auto' }} >{label}</Typography>
        </FormControl >
    );
};

export default FormInputCheckbox;
