import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import EventNoteIcon from '@mui/icons-material/EventNote';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Chip, darken, lighten, Typography } from "@mui/material";
import { StudyOverviewEventStatus } from "../../../../data/models/studyOverview";

export function getDueDateStatusProps(status: StudyOverviewEventStatus, dueDate: Date): { icon: JSX.Element; color: string } {
    dueDate = new Date(dueDate);
    
    const currentDate = new Date();
    const currentDatePlus7Days =  new Date(currentDate.setDate(currentDate.getDate() + 7));
    

    if (status === StudyOverviewEventStatus.PLANNED) {
        return {
            icon: <EventNoteIcon sx={{ fontSize: "18px !important ", ml: "10px !important", color: `${darken("#87CEEB", 0.3)} !important` }} />,
            color: lighten("#87CEEB", 0.4),
        };
    }
    else if (status === StudyOverviewEventStatus.OVERDUE) {
        return {
            icon: <AssignmentLateIcon sx={{ fontSize: "18px !important ", ml: "10px !important", color: `  ${lighten("#FF0000", 0.3)}!important` }} />,
            color: lighten("#FF0000", 0.8),
        };
    }
    else if (status == StudyOverviewEventStatus.PROJECTED && dueDate <= currentDatePlus7Days) {
        return {
            icon: <AccessAlarmIcon sx={{ fontSize: "18px !important", ml: "10px !important", color: `${darken("#FFA500", 0.4)}!important` }} />,
            color: lighten("#FFA500", 0.7),
        };
    }
    else if (status == StudyOverviewEventStatus.PROJECTED && dueDate > currentDatePlus7Days) {
        return {
            icon: <TimelineIcon sx={{ fontSize: "18px !important ", ml: "10px !important", color: `${darken("#B19CD9", 0.4)} !important` }} />,
            color: lighten("#B19CD9", 0.7),
        };
    }

    return {
        icon: <></>,
        color: "#ffffff",
    };
}

