import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useStore } from "../../../../stores/store";
import { observer } from "mobx-react-lite";
import { $generateNodesFromDOM } from "@lexical/html";
import { $createParagraphNode, $getRoot } from "lexical";

function LoadContentPlugin() {
    const [editor] = useLexicalComposerContext();
    const { templateStore } = useStore();

    useEffect(() => {
        editor.update(() => {
            const parser = new DOMParser();
            const dom = parser.parseFromString(templateStore.content.replaceAll('\n', ''), 'text/html');
            const nodes = $generateNodesFromDOM(editor, dom);
            const root = $getRoot();
            root.clear();
            nodes.forEach(node => {
                if (node.getType() === 'element' || node.getType() === 'decorator' || node.getType() === 'paragraph' || node.getType() === 'link') {
                    root.append(node);
                } else {
                    const paragraphNode = $createParagraphNode();
                    paragraphNode.append(node);
                    root.append(paragraphNode);
                }
            });
        });
    }, [editor, templateStore.content]);

    return null;
};

export default observer(LoadContentPlugin)