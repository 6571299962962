import { Box, Divider, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { useState } from "react";

import DrawerMenu from "../../../components/drawer-menu/DrawerMenu";
import { profileOptions } from "./options";
import { SideBarItem } from "../../../_styles/ListItem";
import { Option } from "../../../_styles/layout/Container";
import './styles.css';
import { useTranslation } from "react-i18next";

interface ProfileMenuProps {
    selected: Option;
    handleChange: (option: Option) => void;
}

function ProfileMenu({ handleChange, selected }: ProfileMenuProps) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleMenu = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="profile-menu-container">
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                className="profile-menu-button"
                onClick={handleMenu}
                title="Profile Menu"
                data-testid="profile-menu-button"
            >
                <MenuIcon />
            </IconButton>
            <DrawerMenu handleClose={handleClose} open={open}>
                <Box>
                    {profileOptions.map((option) => (
                        <Box key={option.id}>
                            <SideBarItem
                                key={option.id}
                                onClick={() => {
                                    handleChange(option)
                                    setOpen(false);
                                }}
                                selected={selected?.id === option.id}
                                sx={{ marginBottom: 0, padding: '1.2rem 24px' }}
                                data-testid={`${option.id}-menu-link`}
                            >
                                {option.icon}
                                <div>
                                    <Typography paddingLeft={1} fontSize='18px'>{t(`PROFILE_${option.name}_NAME`)}</Typography>
                                </div>
                            </SideBarItem>
                            <Divider />
                        </Box>
                    ))}
                </Box>
            </DrawerMenu>
        </Box>
    );
}

export default ProfileMenu;