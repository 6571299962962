import { Box, IconButton, Menu, SxProps, Theme } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useState } from "react";

import { StyledMenuItem } from "../_styles/StyledMenu";
import { CSSProperties } from "@mui/material/styles/createMixins";

interface Props {
    options: {
        id: string,
        name: string,
        icon?: JSX.Element | undefined,
        onClick: (...args: any[]) => void;
        args?: any[];
        customStyle?: SxProps<Theme> | undefined
    }[];
    style?: CSSProperties;
}

export default function DropdownMenu({ options, style }: Props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={style}>
            <IconButton aria-label="morevert" onClick={handleClick} title="Actions">
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: { padding: 0 }
                }}
                data-testid="dropdown-menu"
            >
                {options.map(option => (
                    <Box sx={{ display: 'flex', alignItems: 'center', width: 170 }} key={option.id}>
                        <StyledMenuItem key={option.id} onClick={() => { handleClose(); option.onClick(...option.args || []); }} sx={option.customStyle} data-testid={option.id}>
                            {option.name}
                        </StyledMenuItem>
                        {option.icon ? option.icon : null}
                    </Box>
                ))}

            </Menu>
        </Box>
    );
}