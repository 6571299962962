import { ContactAgency } from "./client";
import { LanguageDto } from "./language";
import { PermissionDto } from "./role";
import { UserTenant } from "./tenant";

export interface User {
    id: string;
    token: string;
    email: string;
    userTenants: UserTenant[];
    userPermissions: PermissionDto[];
    twoFactorEnabled: boolean;
    isSuperAdmin: boolean;
    firstName: string,
    lastName: string
    platformLanguageId: string;
    requirePasswordChange: boolean;
    resetPasswordToken: string;
}

export interface UserFormValues {
    email?: string;
    password: string;
    firstName?: string;
    lastName?: string;
    birthdate?: Date | string;
    country?: string;
    city?: string;
    zipCode?: string;
    streetAddress?: string;
    houseNumber?: string;
}

export interface InvitationFormValues {
    email: string;
    role: string;
    tenant: string;
}

export interface Totp {
    totp: string;
    email: string;
}

export interface UserDto {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: string;
    isTenantAdmin: boolean;
    status: UserStatus,
    institutions: string[];
}

export enum UserStatus {
    ACTIVE = 'ACTIVE',
    DEACTIVATED = 'DEACTIVATED',
    ANON = 'ANON',
}

export interface TenantUserDto {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    isTenantAdmin: boolean;
    roles: string[];
}

export interface UserCreationDto {
    id?: string;
    firstName: string;
    lastName: string;
    gender?: string;
    birthdate?: Date;
    isUnaccompaniedMinor?: boolean;
    isIlliterate?: boolean;
    email: string;
    password: string;
    phoneNumber?: string;
    isAdmin: boolean;
    tenantId: string;
    platformLanguageAlpha2: string;
    communicationLanguage: string,
    userLanguages: string[];
    institutionsAssigned: UserInstitutions[];
    residenceStatus?: string;
    country?: string;
    city?: string;
    street?: string;
    number?: string;
    zip?: string;
    notes?: string;
    responsibleUsers?: ResponsiblePersonDto[];
}

export interface UserInstitutions {
    institutionId: string;
    roleIds: number[];
}

export interface AttendeeDto {
    email: string;
    name: string;
}

export interface UserDetailsDto {
    id?: string;
    firstName: string;
    lastName: string;
    gender?: string;
    birthdate?: Date;
    isUnaccompaniedMinor?: boolean;
    isIlliterate?: boolean;
    email: string;
    phoneNumber: string;
    isAdmin: boolean;
    tenantId: string;
    hasAccount: boolean;
    platformLanguage: LanguageDto;
    userLanguages: LanguageDto[];
    userInstitutions: UserInstitutionDto[];
    residenceStatus?: string;
    country?: string;
    city?: string;
    street?: string;
    number?: string;
    zip?: string;
    notes?: string;
    responsibleUsers: ResponsiblePersonDto[];
    zemisNumber?: string;
    ahvNumber?: string;
    preferredCommunicationChannel?: string;
    communicationLanguage?: string;
    accomodation?: string;
    healthInsurance?: string;
    originCountry?: string;
    helperGenderPreference?: string;
    needsChildCare?: boolean;
    contactWithReferringAgency?: boolean
    contactAgency: ContactAgency;

}

export interface UserInstitutionDto {
    id: string;
    name: string;
    belongsTo: boolean;
    roles: InstitutionRoleDto[];
}

export interface InstitutionRoleDto {
    id: number;
    name: string;
    isActive: boolean;
}

export interface ResponsiblePersonResponseDto {
    id?: string;
    institutionId?: string;
    institutionName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    roles?: string;
    status?: UserStatus
}

export interface ResponsiblePersonDto {
    responsibleUserId?: string,
    responsibleUserInstitutionId?: string
    responsibleUserName?: string,
}

export interface UserForAnotherTenant {
    exists: boolean;
    currentTenant: boolean;
    message: string;
    formDisable: boolean;
}

export class UserForAnotherTenantImplementation implements UserForAnotherTenant {
    exists: boolean;
    currentTenant: boolean;
    message: string;
    formDisable: boolean;

    constructor(
        exists: boolean = false,
        currentTenant: boolean = false,
        message: string = '',
        formDisable: boolean = true
    ) {
        this.exists = exists;
        this.currentTenant = currentTenant;
        this.message = message;
        this.formDisable = formDisable
    }
}

export interface AssignExistingUserToTenant {
    email: string,
    targetTenantId: string,
    isAdmin: boolean,
    responsibleUsers: ResponsiblePersonDto[];
    institutionsAssigned: UserInstitutions[];
}

export interface EventAttendee {
    id: string,
    firstName: string,
    lastName: string,
    email: string
}