import SubtitlesOutlinedIcon from "@mui/icons-material/SubtitlesOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DoorSlidingOutlinedIcon from "@mui/icons-material/DoorSlidingOutlined";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import {
    Box,
    CircularProgress,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import { useStore } from "../../../stores/store";
import FormInputSelect from "../../../components/form/FormInputSelect";
import { FormInputText } from "../../../components/form/FormInputText";
import FormInputDate from "../../../components/form/FormInputDate";
import FormInputTime from "../../../components/form/FormInputTime";
import {
    Event,
    EventDetailsDto,
    EventTypeDto,
    PMSessionEventToCreate,
    Reminder,
    SubType,
    View,
} from "../../../data/models/event";
import { brightTimelines, repeatOptions, spiritTimelines } from "../../../data/static/booking";
import RecurrenceFormDialog from "./RecurrenceForm";
import AttendeeInput from "./AttendeeInput";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    eventFormSchema,
    eventUpdateFormSchema,
} from "../../../_validators/schemas/eventForm.schema";
import { RoundButton } from "../../../_styles/StyledButtons";
import ReminderInput from "./ReminderInput";
import {
    getAttendeeEmails,
    getEventCreateValues,
    getEventUpdateValues,
    isReminderValid,
} from "../../../utils/helpers/eventHelper";
import { EventIcon } from "../Components/EventTypeIcons";
import "../styles.css";
import { generateColor } from "../../../utils/colorGenerator";
import {
    getFrequencyNumber, getRecurrenceInterval, getRecurrenceMonthDay, getRecurrenceWeekDays,
} from "../../../utils/eventRecurrence";
import FormInputSwitch from "../../../components/form/FormInputSwitch";
import { AttendeeDto } from "../../../data/models/user";
import { useTranslation } from "react-i18next";
import { hasOtherRoles } from "../../../utils/permissionEvaluator";
import { capitalizeFirstLetter } from "../../../utils/utils";
import { Room } from "../../../data/models/room";
import CreateAllPMSessionsForm from "./CreateAllPMSessionsForm";
import { sub } from "date-fns";

interface Props {
    initialStartDate: Date;
    initialEndDate: Date;
    event?: EventDetailsDto;
    allRecurrences?: boolean;
}

const iconColor = { color: "#0000008a" };

const gridItemStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
};

function EventForm({
    initialStartDate,
    initialEndDate,
    event,
    allRecurrences,
}: Props) {
    const { t } = useTranslation();
    const {
        roomStore: { getRoomsByInstitution, roomList, loading: roomLoading },
        bookingStore: {
            createEvent,
            getEvents,
            getEventTypes,
            eventTypes,
            loading,
            loadingTypes,
            updateEvent,
            getRoomEvents,
            userEventsId,
            assessmentId,
            interventionId,
            getUserEvents,
            updateExceptionEvent,
            confirmScheduledAppointment,
            getPMEventsToCreate
        },
        institutionStore: { selectedUserInstitution, getInstitution },
        commonStore: { getCalendarViews },
        tenantStore: { selectedTenant },
        authStore: { user },
        modalStore: { closeModal, modal, openModal },
        loadingStore: { isLoading },
    } = useStore();
    const [timelines, setTimelines] = useState<SubType>();

    const defaultReminders: Reminder[] = [{ value: "15", unit: "minutes" }];

    const defaultValues = {
        id: event?.id ?? "",
        title: event?.title ?? "",
        description: event?.description ?? "",
        isAllDay: event?.isAllDay ?? false,
        allPms: false,
        attendees: event?.attendeeEmails ?? [],
        roomId: event?.room.id ?? "none",
        eventType: event?.eventTypeId,
        subType: event?.subType ?? "",
        reminders: event?.reminders ?? defaultReminders,
        start: initialStartDate,
        startTime: dayjs(initialStartDate),
        end: initialEndDate,
        endTime: dayjs(initialEndDate),
        frequency:
            (event?.recurrence && getFrequencyNumber(event.recurrence as string)) ||
            9,
        recurrence: {
            byDays: event?.recurrence ? getRecurrenceWeekDays(event.recurrence as string) : undefined,
            byMonthDay: event?.recurrence ? getRecurrenceMonthDay(event.recurrence as string) : undefined,
            interval: event?.recurrence ? getRecurrenceInterval(event.recurrence as string) : 1
        }
    };
    const editMode = event !== undefined;
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { isDirty },
    } = useForm<Event>({
        defaultValues,
        resolver: yupResolver(
            editMode ? eventUpdateFormSchema([assessmentId ?? "", interventionId ?? ""]) : eventFormSchema([assessmentId ?? "", interventionId ?? ""])
        ),
    });
    // const [allDay, setAllDay] = useState(false);
    const [frequency, setFrequency] = useState(
        (event?.recurrence && getFrequencyNumber(event.recurrence as string)) || 9
    );
    const reminderValues = watch("reminders", []);
    const { fields, append, remove } = useFieldArray({
        control,
        name: "reminders",
    });
    const startDate = watch("start");
    const endDate = watch("end");
    const recurrenceDate = watch("recurrence.endDate");
    const roomId = watch("roomId");
    const attendees = watch("attendees");
    const allDay = watch("isAllDay");
    const type = watch("eventType");
    const subType = watch("subType");
    const isScreeningType = ([assessmentId, interventionId].includes(type));
    const [confirmed, setIsConfirmed] = useState<boolean>(false);

    const handleAddReminder = () => {
        append({ value: "", unit: "minutes" });
    };

    useEffect(() => {
        if (isScreeningType && subType && timelines) {
            let eventSubType = null;
            switch (type) {
                case interventionId:
                    eventSubType = timelines?.intervention.find(i => i.name === subType)?.name || null;
                    break;
                case assessmentId:
                    eventSubType = timelines?.assessment.find(a => a.name === subType)?.name || null;
                    break;
            }
            if (!eventSubType) {
                setValue("title", "");
                setValue("subType", "");
            } else {
                const eventType = eventTypes.find(e => e.id === type)?.translatedName ?? "";
                setValue("title", `${capitalizeFirstLetter(eventType)}${eventSubType ? " / " + eventSubType : ""}`);
            }
        }
    }, [type, subType, timelines]);

    useEffect(() => {
        if (selectedUserInstitution) {
            if (!hasOtherRoles(['Helper', 'Assessor'], selectedUserInstitution.roles)) {
                const meetingType = eventTypes.find((e) => e.name === "AVAILABLE");
                if (!event?.eventTypeId && meetingType) {
                    setValue("eventType", meetingType.id);
                }
            }
            else {
                const meetingType = eventTypes.find((e) => e.name === "MEETING");
                if (!event?.eventTypeId && meetingType) {
                    setValue("eventType", meetingType.id);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event?.eventTypeId, eventTypes, setValue]);

    const handleRemoveReminder = (index: number) => {
        remove(index);
    };
    const [defaultAvailableTypeAttendeEmail, setDefaultAvailableTypeAttendeEmail] = useState<string[]>([]);

    useEffect(() => {
        if (editMode) return;
        const isAvailableEventType = eventTypes.find(e => e.id === type)?.name == 'AVAILABLE';
        if (isAvailableEventType) {
            setValue("title", `${t("AVAILABLE")}`);
            const userEmail = user?.email ? [user.email] : []
            setDefaultAvailableTypeAttendeEmail(userEmail);
            setValue("attendees", userEmail);
        }
        else {
            setDefaultAvailableTypeAttendeEmail([]);
            setValue("attendees", []);
        }
    }, [type]);

    useEffect(() => {
        if (startDate > endDate) {
            setValue("end", startDate);
        }
        if (recurrenceDate! < endDate) {
            setValue("recurrence.endDate", endDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endDate, startDate]);

    useEffect(() => {
        if (!editMode) {
            const start = new Date(startDate).getTime();
            const currentDate = new Date().setHours(0, 0, 0, 0);

            if (start < currentDate) {
                toast.warn(t("TOAST_EVENT_START_PAST"));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode, startDate]);

    useEffect(() => {
        modal.confirmClose = isDirty;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty]);

    useEffect(() => {
        if (selectedUserInstitution) {
            getRoomsByInstitution(selectedUserInstitution.institutionId);
            const fetchInstitution = async () => {
                const institution = await getInstitution(selectedUserInstitution.institutionId);
                const timelines = institution.cantonTeam === "BRIGHT" ? brightTimelines : spiritTimelines;
                setTimelines(timelines);
            }

            fetchInstitution();
        }
    }, [getInstitution, getRoomsByInstitution, selectedUserInstitution]);

    useEffect(() => {
        const fetchData = async () => {
            if (selectedTenant) {
                getEventTypes(selectedTenant.id);
            }
        };

        fetchData();
    }, [getEventTypes, selectedTenant]);

    useEffect(() => {
        if (type && isScreeningType) {
            remove();
            const screeningReminders: Reminder[] = [
                { value: "24", unit: "hours" },
                { value: "72", unit: "hours" },
            ]
            append(screeningReminders);
        }
        else {
            remove();
            append(defaultReminders);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isScreeningType]);

    const handleSelection = (e: any) => {
        setFrequency(e.target.value);
    };

    const onSubmit = async (calendarEvent: Event) => {
        const isRoomSelected = roomId !== "none";
        const isAvailableType = eventTypes.find(e => e.id === type)?.name === 'AVAILABLE';
        const isInterventionType = eventTypes.find(e => e.id === type)?.name === 'INTERVENTION';
        const containstNoAttendes = calendarEvent.attendees.length === 0;

        if (isAvailableType && containstNoAttendes && !isRoomSelected) {
            if (!hasOtherRoles(["Helper", "Assessor"], selectedUserInstitution!.roles)) {
                toast.error(t("REQ_ERROR_EVENT_TYPE_AVAILABLE_NO_ATTENDE"));
            } else {
                toast.error(t("REQ_ERROR_EVENT_TYPE_AVAILABLE_NO_ATTENDE_OR_ROOM"));
            }
            return;
        }
        const areRemindersValid = reminderValues.every((reminder: Reminder) =>
            isReminderValid(reminder)
        );
        if (!areRemindersValid) {
            return;
        }
        let reminders = reminderValues.map((reminder: Reminder) => ({
            value: +reminder.value,
            unit: reminder.unit,
        }));

        if (eventTypes.find(e => e.id === type)?.name === 'AVAILABLE') {
            reminders = [];
        }

        const shouldAddAttendee = (attendees: AttendeeDto[]): boolean =>
            attendees.length === 0 && !(isRoomSelected && isAvailableType);

        if (editMode) {
            const date = allRecurrences ? null : calendarEvent.start.toISOString();
            const updatedEvent = getEventUpdateValues(
                calendarEvent,
                reminders,
                event.recurrence as string
            );

            if (shouldAddAttendee(updatedEvent.attendees as AttendeeDto[])) {
                updatedEvent.attendees = [user?.id!];
            } else {
                updatedEvent.attendees = getAttendeeEmails(updatedEvent.attendees as AttendeeDto[]);
            }

            updatedEvent.isScreening = [assessmentId].includes(
                calendarEvent.eventType
            );

            defaultValues.frequency !== 9 && !allRecurrences
                ? await updateExceptionEvent(updatedEvent, date as string)
                : await updateEvent(updatedEvent);
        } else {
            const newEvent = getEventCreateValues(calendarEvent, reminders, confirmed);

            if (shouldAddAttendee(newEvent.attendees as AttendeeDto[])) {
                newEvent.attendees = [user?.email!];
            } else {
                newEvent.attendees = getAttendeeEmails(newEvent.attendees as AttendeeDto[]);
            }

            newEvent.institutionId = selectedUserInstitution?.institutionId!;
            newEvent.isScreening = [assessmentId].includes(
                calendarEvent.eventType
            );

            if (isInterventionType && calendarEvent.allPms) {
                const response = await getPMEventsToCreate(newEvent.attendees, newEvent.start.toISOString(), newEvent.end.toISOString(), newEvent.institutionId, calendarEvent.subType!, newEvent.timeZone)

                if (response.filter(r => !r.hasConflict).length === 0) {
                    toast.error("Helper is not available for none of PM sessions");
                    return;
                }

                openModal(
                    <CreateAllPMSessionsForm response={response} newEvent={newEvent} />,
                    { confirmClose: true }
                )

                return;
            }
            else {
                await createEvent(newEvent);
            }
        }

        if (!isInterventionType || !calendarEvent.allPms) {
            toast.success(editMode ? t("TOAST_EVENT_UPDATE") : t("TOAST_EVENT_CREATE"));
        }

        getCalendarViews().forEach(async (view) => {
            if (view.type === View.USER) {
                if (view.id === userEventsId) {
                    await getUserEvents();
                } else {
                    await getEvents(view.id);
                }
            } else {
                await getRoomEvents(view.id);
            }
        });

        modal.confirmClose = false;
        closeModal();
    };

    const isRoomAvailable = (roomId: string | null) => {
        if (!roomList || roomList.length === 0) {
            console.warn('Room list is empty or undefined.');
            return false;
        }
        if (roomId && roomId !== "none") {
            const room = roomList.find((room) => room.id === roomId);
            if (!room) {
                console.warn(`Room with ID ${roomId} not found.`);
                return false;
            }
            return room.capacity >= attendees.length;
        }
        return true;
    };


    const filterEventTypeForRole = (): EventTypeDto[] => {
        if (!hasOtherRoles(['Helper', 'Assessor'], selectedUserInstitution!.roles)) {
            return eventTypes.filter(et => et.name === 'AVAILABLE').map((e) => {
                const eventType: EventTypeDto = {
                    id: e.id,
                    name: (
                        <Box className="event-type-select-label">
                            <EventIcon
                                type={e.name as any}
                                bgColor={generateColor(user?.id!)}
                                className="event-type-select-icon"
                            />
                            <Typography sx={{ fontSize: "12px" }}>{e.translatedName}</Typography>
                        </Box>
                    ),
                };
                return eventType;
            })
        }
        else {
            return eventTypes.map((e) => {
                const eventType: EventTypeDto = {
                    id: e.id,
                    name: (
                        <Box className="event-type-select-label">
                            <EventIcon
                                type={e.name as any}
                                bgColor={generateColor(user?.id!)}
                                className="event-type-select-icon"
                            />
                            <Typography sx={{ fontSize: "12px" }}>{e.translatedName}</Typography>
                        </Box>
                    ),
                };
                return eventType;
            })
        }
    }

    const filterDeactivatedRooms = (list: Room[]) => {
        if (editMode) {
            return list.filter(r => !r.isDeactivated || r.id === defaultValues.roomId);
        }
        else {
            return list.filter(r => !r.isDeactivated);
        }
    }

    return (
        <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column" }}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Grid container columnGap={2} rowGap={0.5} sx={{ width: "100%" }}>
                <Grid item xs={11.5} lg={[assessmentId, interventionId].includes(type) ? 5.5 : 3} columnGap={2} sx={gridItemStyle}>
                    <EventNoteOutlinedIcon className="event-form-icon" sx={iconColor} />
                    <FormInputSelect
                        name="eventType"
                        helperText=""
                        options={filterEventTypeForRole()}
                        valueKey="id"
                        labelKey="name"
                        control={control}
                        loading={loadingTypes}
                        label={`${t("EVENT_TYPE")} *`}
                    />
                    {isScreeningType && timelines &&
                        (
                            assessmentId === type ?
                                <FormInputSelect
                                    options={timelines.assessment}
                                    labelKey={"name"}
                                    valueKey={"name"}
                                    name={"subType"}
                                    label={`Sub-type`}
                                    control={control}
                                    helperText=""
                                />
                                :
                                <FormInputSelect
                                    options={timelines.intervention}
                                    labelKey={"name"}
                                    valueKey={"name"}
                                    name={"subType"}
                                    label={`Sub-type`}
                                    control={control}
                                    helperText=""
                                />
                        )
                    }
                </Grid>
                <Grid item xs={11.5} columnGap={2} sx={gridItemStyle}>
                    <SubtitlesOutlinedIcon className="event-form-icon" sx={iconColor} />
                    <Box width="100%">
                        <FormInputText
                            name="title"
                            label={`${t("ACC_TITLE")} *`}
                            control={control}
                            helperText=""
                        />
                    </Box>
                </Grid>
                {selectedUserInstitution!.roles &&
                    <Grid item xs={11.5} columnGap={2} sx={gridItemStyle}>
                        <GroupAddOutlinedIcon className="event-form-icon" sx={iconColor} />
                        <Box width="100%">
                            <AttendeeInput
                                name="attendees"
                                control={control}
                                label={t("EVENT_ATTENDEES")}
                                defaultValues={event?.attendeeEmails ?? defaultAvailableTypeAttendeEmail}
                            />
                        </Box>
                    </Grid>
                }
                <Grid item xs={11.5} sm={6} columnGap={2} sx={gridItemStyle}>
                    <AccessTimeOutlinedIcon className="event-form-icon" sx={iconColor} />
                    <Box width="100%">
                        <FormInputDate
                            name="start"
                            label={`${t("EVENT_START_DATE")} *`}
                            date={initialStartDate}
                            control={control}
                            helperText=""
                        />
                    </Box>
                    {!allDay && (
                        <Box width="70%">
                            <FormInputTime
                                control={control}
                                name="startTime"
                                label={`${t("EVENT_START_TIME")} *`}
                                defaultTime={dayjs(initialStartDate)}
                                helperText=""
                            />
                        </Box>
                    )}
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={3}
                    columnGap={3}
                    sx={{ ...gridItemStyle, display: "flex", justifyContent: "flex-start", alignItems: 'center' }}>
                    <Box width="100%">
                        <FormInputSwitch
                            name="isAllDay"
                            label={t("EVENT_ALL_DAY")}
                            control={control}
                            defaultValue={event?.isAllDay ?? false}
                        />
                    </Box>
                    {!editMode && eventTypes.find(e => e.id === type)?.name === 'INTERVENTION' && subType !== undefined && subType.includes("PM") && (
                        <Box width="100%">
                            <FormInputSwitch
                                name="allPms"
                                label={t("CREATE_REMAINING_PM_SESSIONS")}
                                control={control}
                                defaultValue={defaultValues.allPms}
                            />
                        </Box>
                    )
                    }
                </Grid>
                <Grid item xs={11.5} sm={6} columnGap={2} sx={gridItemStyle}>
                    <AccessTimeOutlinedIcon className="event-form-icon" sx={iconColor} />
                    <Box width="100%">
                        <FormInputDate
                            name="end"
                            label={`${t("EVENT_END_DATE")} *`}
                            date={initialEndDate}
                            dateLimit={startDate}
                            control={control}
                            helperText=""
                        />
                    </Box>
                    {!allDay && (
                        <Box width="70%">
                            <FormInputTime
                                control={control}
                                name="endTime"
                                label={`${t("EVENT_END_TIME")} *`}
                                defaultTime={dayjs(initialEndDate)}
                                helperText=""
                            />
                        </Box>
                    )}
                </Grid>
                <Grid item xs={11.5} sm={5} columnGap={3} sx={gridItemStyle}>
                    {!isScreeningType && <Box width="160px" paddingTop="23px">
                        <FormInputSelect
                            name="frequency"
                            icon={<RepeatOutlinedIcon sx={iconColor} />}
                            defaultValue={frequency}
                            control={control}
                            options={repeatOptions.map((r) => {
                                if (
                                    editMode &&
                                    r.id === 9 &&
                                    event?.recurrence !== "no-repeat"
                                ) {
                                    return { ...r, name: t(`EVENT_${r.name}`), disabled: true };
                                }
                                return { ...r, name: t(`EVENT_${r.name}`), disabled: false };
                            })}
                            onSelect={handleSelection}
                            valueKey="id"
                            labelKey="name"
                            helperText=""
                        />
                    </Box>}
                </Grid>
                <RecurrenceFormDialog
                    control={control}
                    initialEndDate={endDate}
                    frequency={frequency}
                    rrule={event?.recurrence as string}
                />

                {hasOtherRoles(["Helper", "Assessor"], selectedUserInstitution!.roles) &&
                    <Grid item xs={11.5} sm={6} columnGap={2} sx={gridItemStyle}>
                        <DoorSlidingOutlinedIcon className="event-form-icon" sx={iconColor} />
                        <Box width="100%">
                            <FormInputSelect
                                name="roomId"
                                control={control}
                                options={[
                                    { id: "none", name: t("GENERAL_NONE") },
                                    ...filterDeactivatedRooms(roomList).map((r) => {
                                        return {
                                            id: r.id, name: (
                                                <Box>
                                                    <Typography fontSize={"13px"}>{r.name}</Typography>
                                                    <Box display={"flex"} alignItems={"center"}>
                                                        <Typography fontSize={"10px"} sx={{ color: "gray" }}>{t('ROOM_SIZE')}: {r?.capacity}</Typography>
                                                        <PersonIcon sx={{ fontSize: "12px", color: 'gray', ml: '2px' }} />
                                                    </Box>
                                                </Box>
                                            ),
                                            disabled: r.isDeactivated
                                        };
                                    }),
                                ]}
                                valueKey="id"
                                labelKey="name"
                                helperText=""
                                defaultValue={"none"}
                                loading={roomLoading}
                                label={`${t("EVENT_ROOM")} ${isScreeningType ? "*" : ""}`}
                            />
                            <Box
                                sx={{
                                    marginTop: `${isRoomAvailable(roomId) ? "-14px !important" : "0px !imporant"
                                        }`,
                                    color: "#f4a261 !important",
                                    display: "flex",
                                    width: "250px",
                                }}
                            >
                                {attendees && isRoomAvailable(roomId) ? (
                                    <Typography height="20px"> </Typography>
                                ) : (
                                    <>
                                        <Typography
                                            fontSize="12px"
                                            display="flex"
                                            marginLeft={"6px"}
                                            alignItems="center"
                                        >
                                            {t("EVENT_ATTENDEE_LIMIT")} {"("}
                                            {
                                                roomList?.length > 0 && roomId
                                                    ? roomList.find((r) => r.id === roomId)?.capacity ?? "N/A"
                                                    : "N/A"
                                            }
                                            {")"}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                }
                {
                    eventTypes.find(e => e.id === type)?.name !== 'AVAILABLE' &&
                    <Grid
                        item
                        xs={11.5}
                        columnGap={2}
                        sx={{ ...gridItemStyle, alignItems: "flex-start" }}
                    >
                        <AccessAlarmOutlinedIcon className="event-form-icon" sx={{ ...iconColor, marginTop: 4 }} />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography fontSize="14px" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                                {t("EVENT_REMINDERS")}
                            </Typography>
                            {fields.map((reminder, index) => (
                                <ReminderInput
                                    key={reminder.id}
                                    name={`reminders.${index}`}
                                    control={control}
                                    label={t("EVENT_REMINDERS")}
                                    onCancel={() => handleRemoveReminder(index)}
                                    defaultValues={fields[index]}
                                    disabled={isScreeningType}
                                />
                            ))}
                        </Box>
                    </Grid>
                }
                <Grid
                    item
                    xs={5}
                    sx={{
                        marginLeft: { md: 5, xs: 0 },
                        marginTop: { md: fields.length > 0 ? 0 : -5 },
                        ...gridItemStyle,
                    }}
                >                    {eventTypes.find(e => e.id === type)?.name !== 'AVAILABLE' &&
                    fields.length < 5 &&
                    !isScreeningType && (
                        <RoundButton
                            sx={{
                                width: "auto",
                                padding: 0,
                                borderRadius: 0,
                                "&:hover": {
                                    backgroundColor: "transparent",
                                },
                                fontSize: "12px",
                            }}
                            onClick={handleAddReminder}
                            variant="text"
                            title="Add Reminder"
                            data-testid="add-reminder-button"
                        >
                            <Typography fontSize="13px">{t("EVENT_ADD_REM")}</Typography>
                        </RoundButton>
                    )}
                </Grid>
                <Grid item xs={11.5} columnGap={2} sx={gridItemStyle}>
                    <ArticleOutlinedIcon className="event-form-icon" sx={iconColor} />
                    <Box width="100%">
                        <FormInputText
                            name="description"
                            rows={3}
                            control={control}
                            label={t("GENERAL_DESCRIPTION")}
                        />
                    </Box>
                </Grid>
                <Grid item xs={11.5} columnGap={2} sx={gridItemStyle}>
                    <Divider sx={{ width: "100%" }} />
                </Grid>
                <Grid
                    item
                    xs={11.5}
                    sx={{
                        ...gridItemStyle,
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        flexDirection: "column",
                        marginTop: 1,
                    }}
                >
                    <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"} mb={1} gap={.5} >
                        <RoundButton
                            color="inherit"
                            variant="text"
                            onClick={() => {
                                modal.confirmClose = false;
                                closeModal();
                            }}
                            disabled={loading}
                            title="Cancel"
                            data-testid="cancel-eventForm-button"
                            disableTouchRipple
                        >
                            <Typography fontSize="13px">{t("GENERAL_CANCEL")}</Typography>
                        </RoundButton>
                        <Box display={"flex"} gap={1}>
                            {isScreeningType && !editMode
                                ? (
                                    <><RoundButton
                                        type="submit"
                                        color="primary"
                                        variant="outlined"
                                        disabled={loading || (!isDirty && editMode)}
                                        title="Submit"
                                        data-testid="submit-eventForm-button"
                                        onClick={() => setIsConfirmed(false)}
                                    >
                                        {isLoading(createEvent) && !confirmed ? (
                                            <CircularProgress size={25} />
                                        ) : (
                                            <Typography fontSize="13px">{t("GENERAL_SCHEDULE")}</Typography>
                                        )}
                                    </RoundButton></>)
                                : ""
                            }
                            <RoundButton
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={loading || (!isDirty && editMode)}
                                title="Submit"
                                data-testid="submit-eventForm-button"
                                onClick={() => setIsConfirmed(true)}
                            >
                                {isLoading([confirmScheduledAppointment, updateEvent, createEvent]) && confirmed ? (
                                    <CircularProgress size={25} />
                                ) : editMode ? (
                                    <Typography fontSize="13px">{t("GENERAL_UPDATE")}</Typography>
                                ) : (
                                    <Typography fontSize="13px">{isScreeningType ? t("GENERAL_CONFIRM") : t("GENERAL_SAVE")}</Typography>
                                )}
                            </RoundButton>
                        </Box>
                    </Box>
                    {editMode && allRecurrences && (
                        <Typography fontSize={12} sx={{ color: "#808080" }}>
                            {t("EVENT_REC_UPDATE")}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Box >
    );
}

export default observer(EventForm);
