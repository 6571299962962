import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { useState } from 'react';
import ContentEditable from './ContentEditable';
import FloatingLinkEditorPlugin from './FloatingLinkEditorPlugin/FloatingLinkEditorPlugin';

import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { Box } from '@mui/material';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LoadContentPlugin from './Plugin/LoadContentPlugin';
import HandleChangePlugin from './Plugin/HandleChangePlugin';
import ToolbarPlugin from './Plugin/ToolbarPlugin';
import { ControllerRenderProps, UseFormSetValue } from 'react-hook-form';
import { TemplateContentDto } from '../../../data/models/notification';

interface Props {
    field: ControllerRenderProps<TemplateContentDto, 'body'>,
    setValue: UseFormSetValue<TemplateContentDto>
}

export default function Editor({field, setValue}: Props): JSX.Element {
    const [floatingAnchorElem, setFloatingAnchorElem] =
        useState<HTMLDivElement | null>(null);
    const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };

    return (
        <>
            <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />
            <Box className="editor-inner" sx={{ height: '85%' }}>
                <RichTextPlugin
                    contentEditable={
                        <div className="editor-scroller">
                            <div className="editor" ref={onRef}>
                                <ContentEditable placeholder={''} />
                            </div>
                        </div>
                    }
                    ErrorBoundary={LexicalErrorBoundary}
                />
                <HistoryPlugin />
                <LoadContentPlugin />
                <LinkPlugin />

                <HandleChangePlugin
                    onChange={(htmlString) => {
                        setValue('body', htmlString);
                        field.onChange(htmlString);
                    }}
                />
                {floatingAnchorElem && (
                        <>
                            <FloatingLinkEditorPlugin
                                anchorElem={floatingAnchorElem}
                                isLinkEditMode={isLinkEditMode}
                                setIsLinkEditMode={setIsLinkEditMode}
                            />
                        </>
                    )}
            </Box>
        </>
    );
}
