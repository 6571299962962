import { IconButton, InputAdornment, InputLabel, TextField } from "@mui/material";

import { Controller } from "react-hook-form";

import { FormInputProps } from "./FormInputProps";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type VariantType = "outlined" | "standard" | "filled";

interface TextFieldProps extends FormInputProps {
    type?: string;
    variant?: VariantType;
    initValue?: string;
    readOnly?: boolean;
    rows?: number
    icon?: JSX.Element;
    isDisabled?: boolean;
}

export function FormInputText({
    name,
    control,
    label,
    type = 'text',
    variant = 'outlined',
    initValue = '',
    readOnly = false,
    rows,
    icon,
    helperText = " ",
    isDisabled = false
}: TextFieldProps) {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value = initValue },
                fieldState: { error },
            }) => (
                <>
                    <InputLabel htmlFor={name} sx={{ fontSize: '14px' }}>{label}</InputLabel>
                    <TextField
                        id={name}
                        helperText={error?.message || helperText}
                        error={!!error}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        variant={variant}
                        type={showPassword ? 'text' : type}
                        multiline={!!rows}
                        rows={rows}
                        InputProps={{
                            readOnly: readOnly,
                            disabled: isDisabled,
                            sx: {
                                backgroundColor: 'white',
                            },
                            startAdornment: icon ? (
                                <InputAdornment position="start">
                                    {icon}
                                </InputAdornment>
                            ) : null,
                            endAdornment: type === 'password' ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        title={showPassword ? 'Show' : 'Hide'}
                                    >
                                        {showPassword ? <Visibility color="secondary" /> : <VisibilityOff color="secondary" />}
                                    </IconButton>
                                </InputAdornment>) : null,
                        }}
                        sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                            marginRight: '10px'
                        }}

                        inputProps={{
                            textarea: {
                                resize: "both"
                            },
                            'data-testid': name,
                            style: {
                                padding: rows ? '0 10px 0 0' : '8px 10px',
                                backgroundColor: 'white',
                                fontSize: '13px',
                                resize: 'vertical',
                            }
                        }}
                    />
                </>
            )
            }
        />
    );
};