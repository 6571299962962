import { Collapse, List, ListItemButton, Typography } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface AttendeeListProps {
    attendees: any[];
}

function ScreeningEventAttendees({ attendees }: AttendeeListProps) {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState<boolean>(false);

    const handleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <List sx={{ paddingLeft: 0 }}>
                <ListItemButton onClick={handleExpand} sx={{
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    paddingLeft: 0
                }}>
                    {expanded ? <KeyboardArrowDownIcon sx={{ pr: 2 }} /> : <KeyboardArrowRightIcon sx={{ pr: 2 }} />}
                    <Typography fontSize={14}>
                        {t("EVENT_ATTENDEES")} ({attendees.length})
                    </Typography>
                </ListItemButton>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ marginLeft: 6 }}>
                        {attendees.map((attendee, index) => (
                            <Typography key={index} fontSize={13}>
                                {attendee?.firstName + ' ' + attendee?.lastName}
                            </Typography>
                        ))}
                    </List>
                </Collapse>
            </List>
        </>
    );
}

export default ScreeningEventAttendees;
