import * as Yup from "yup";

export const clientProcessForm = () => {
    const schema = Yup.object().shape({
        clients: Yup.array()
            .of(Yup.string().required("Client name is required"))
            .min(1, 'Please select at least one client')
            .required('Clients field is required'),
    });

    return schema;
}