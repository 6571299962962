import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#03b2ff",
            light: "#5ca6d1",
            dark: "#004e7c",
            contrastText: "#fff"
        },
        secondary: {
            main: "#ced4da",
            light: "#e5f5f5",
            dark: "#bfbfbf",
            contrastText: "#fff"
        },
        text: {
            primary: "#2A2A2A",
            secondary: "#555",
            disabled: "#888"
        },
        background: {
            default: "#f1f4f9",
            paper: "#f1f4f9"
        },
        action: {
            active: "#0077b6",
            hover: "#00b4d8",
            selected: "#eee",
            disabled: "#6c757d",
            disabledBackground: '#ced4da',
        },
        error: {
            main: "#f44336"
        },
        warning: {
            main: "#ffc107"
        },
        success: {
            main: "#4caf50"
        },
        info: {
            main: "#2196f3"
        },
    },
    typography: {
        fontFamily: [
            'Poppins',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif',
            'Apple Color Emoji',
            'Segoe UI Emoji',
            'Segoe UI Symbol',
        ].join(','),
        h6: {
            fontWeight: 600,
            fontSize: '18px'
        },
        body1: {
            fontWeight: 400,
            fontSize: '18px'
        }
    },
});
