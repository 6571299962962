import { Box, CircularProgress } from "@mui/material";

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { RoundButton } from "../../../_styles/StyledButtons";
import { useStore } from "../../../stores/store";
import FormInputChipSelect from "../../../components/form/FormInputChipSelect";
import { useForm } from "react-hook-form";
import { ProcessClientsAddDto } from "../../../data/models/process";
import { toast } from "react-toastify";
import { ProcessClient } from "../../../data/models/client";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { clientProcessForm } from "../../../_validators/schemas/clientProcessForm.schema";

interface AssignClientFormProps {
    processId: string;
}

function AssingClientForm({ processId }: AssignClientFormProps) {
    const { t } = useTranslation();
    const {
        processStore: { addProcessClients, getClientList },
        institutionStore: { selectedUserInstitution },
        modalStore: { closeModal }
    } = useStore()
    const { control, handleSubmit } = useForm({
        resolver: yupResolver(clientProcessForm())
    });
    const [clients, setClients] = useState<ProcessClient[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        if (selectedUserInstitution) {
            const fetchData = async () => {
                setLoading(true);
                const clientList = await getClientList(selectedUserInstitution.institutionId);
                setClients(clientList);
                setLoading(false);
            }

            fetchData();
        }
    }, [selectedUserInstitution, getClientList]);

    const onSubmit = async (data: any) => {
        setSubmitLoading(true);
        const processClients: ProcessClientsAddDto = {
            processId: processId,
            clientIds: data.clients,
            institutionId: selectedUserInstitution?.institutionId!
        }

        try {
            await addProcessClients(processClients);
            toast.success(t("TOAST_PROCESS_CLIENTS_ADD"));
            closeModal();
        } catch (err) {
            console.log(err);
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <FormInputChipSelect
                name="clients"
                label={t("PROCESS_CLIENT_ASSIGN")}
                control={control}
                options={clients?.map(client => {
                    return {
                        value: client.id,
                        label: client.firstName + ' ' + client.lastName
                    }
                }) || []}
                labelKey='label'
                valueKey='value'
                search
                loading={loading}
            />
            <Box display="flex" justifyContent="flex-end" alignItems={"center"}>
                <RoundButton variant="text" sx={{ color: "#000", mr: 2 }} disableTouchRipple onClick={closeModal}>
                    {t("GENERAL_CLOSE")}
                </RoundButton>
                <RoundButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={submitLoading || loading}
                    title="Add"
                    data-testid="assign-client"
                >
                    {submitLoading
                        ? <CircularProgress size={25} />
                        : t("GENERAL_ADD")
                    }
                </RoundButton>
            </Box>
        </Box>
    );
}

export default observer(AssingClientForm);