import { Box, Grid, Typography } from "@mui/material";

import { format } from "date-fns";

import { ClientSessionInfo } from "../../../data/models/session";
import GenericTable from "../../../components/GenericTable";
import { TableHeader } from "../../../_styles/table/TableHeader";
import { TableText } from "../../../_styles/table/TableText";
import { getSessionStatusChip } from "../../Screening/Process/Session/statusProps";
import { useTranslation } from "react-i18next";

interface ClientSessionTableProps {
    sessions: ClientSessionInfo[];
}

function ClientSessionTable({ sessions }: ClientSessionTableProps) {
    const { t } = useTranslation();
    return (
        <Grid container columnSpacing={1} marginTop="1rem" padding="0.5rem 3rem">
            <Grid item xs={12} mb={2}>
                <Typography fontWeight={500}>{t("SESSIONS_LABEL")}</Typography>
            </Grid>
            <Grid item xs={12}>
                <GenericTable<ClientSessionInfo>
                    columns={[
                        {
                            header: (
                                <TableHeader fontSize={"13px !important"}>{t("ACC_TITLE")}</TableHeader>
                            ),
                            key: "name",
                            render: (session) => <TableText>{session.title}</TableText>,
                            className: "process-cell",
                        },
                        {
                            header: (
                                <TableHeader fontSize={"13px !important"}>{t("CLIENT_PROCESS_STATUS")}</TableHeader>
                            ),
                            key: "status",
                            render: (session) => (
                                <>
                                    <Box>{getSessionStatusChip(session.status, t)}</Box>
                                </>
                            ),
                            className: "process-cell",
                        },
                        {
                            header: (
                                <TableHeader fontSize={"13px !important"}>
                                    {t("EVENT_START_TIME")}
                                </TableHeader>
                            ),
                            key: "startTime",
                            render: (session) => (
                                <TableText>
                                    {format(
                                        new Date(session.startTime),
                                        "dd MMM, yyyy 'at' HH:mm"
                                    )}
                                </TableText>
                            ),
                            className: "process-cell",
                        },
                    ]}
                    rows={sessions || []}
                    props={{
                        containerProps: {
                            style: {
                                padding: 0,
                            },
                        }
                    }}
                    rowKey="id"
                />
            </Grid>
        </Grid>
    );
}

export default ClientSessionTable;
