import { Box } from "@mui/material";

interface FieldContainerProps {
    header: React.ReactNode;
    body: React.ReactNode;
    footer: React.ReactNode;
}

function FieldContainer({ header, body, footer }: FieldContainerProps) {
    return (
        <Box className="field-container">
            <Box className="full-width">
                {header}
            </Box>
            <Box className="full-width">
                {body}
            </Box>
            {footer && <Box className="full-width question-footer">
                {footer}
            </Box>}
        </Box>
    );
}

export default FieldContainer;