import { QuestionType, Survey, SurveyTranslateField } from "../../data/models/survey";

/**
 * Retrieves translations for survey elements based on provided data and language.
 * @param {Survey} survey - The survey object containing pages and questions.
 * @param {any} data - The data object containing translations for survey elements.
 * @param {string} lang - The language code for the translations.
 * @returns {SurveyTranslateField[]} Returns an array of translation fields for the survey elements.
 */
export const getTranslations = (survey: Survey, data: any, lang: string): SurveyTranslateField[] => {
    const translations: SurveyTranslateField[] = [];

    survey?.pages.forEach(p => {
        const page = data[p.id];
        if (hasValue(page.description)) {
            translations.push({
                id: p.id,
                entity: "SurveyPage",
                property: "Description",
                value: page.description,
                languageId: lang,
            })
        }
        p.questions.filter(q => q.questionType !== QuestionType.CONTROL).forEach(q => {
            const question = data.questions[q.id]
            if (hasValue(question.title)) {
                translations.push({
                    id: q.id,
                    entity: "Question",
                    property: "Title",
                    value: question.title,
                    languageId: lang,
                })
            }

            const options = q.options;
            if (hasValue(options)) {
                options!.forEach(o => {
                    const option = data.questions[q.id]['options'][o.id]
                    if (hasValue(option.title)) {
                        translations.push({
                            id: o.id,
                            entity: "QuestionOption",
                            property: "Title",
                            value: option.title,
                            languageId: lang,
                        })
                    }

                    if (hasValue(o.conditionalText)) {
                        const conditionalText = data.questions[q.id]['conditionals'][o.id].text;
                        if (hasValue(conditionalText)) {
                            translations.push({
                                id: o.id,
                                entity: "QuestionOption",
                                property: "ConditionalText",
                                value: conditionalText,
                                languageId: lang,
                            })
                        }
                    }
                })
            }

            if (q.subQuestions && q.subQuestions.length > 0) {
                const options = q.subQuestions[0].options;
                const qId = q.questionType === QuestionType.MATRIX ? q.id : q.subQuestions[0].id;

                if (hasValue(options)) {
                    options!.forEach(o => {
                        const option = data.questions[qId]['options'][o.id]
                        if (hasValue(option.title)) {
                            translations.push({
                                id: o.id,
                                entity: "QuestionOption",
                                property: "Title",
                                value: option.title,
                                languageId: lang,
                            })
                        }
                    })
                }

                q.subQuestions.forEach(sq => {
                    const subQuestion = q.questionType === QuestionType.MATRIX ? data.questions[q.id][sq.id] : data.questions[sq.id];

                    if (hasValue(subQuestion.title)) {
                        translations.push({
                            id: sq.id,
                            entity: "Question",
                            property: "Title",
                            value: subQuestion.title,
                            languageId: lang,
                        })
                    }
                })
            }
        })
    })

    return translations;
}

/**
 * Checks if the provided data has a non-null and non-undefined value.
 * @param {any} data - The data to check.
 * @returns {boolean} Returns true if the data has a non-null and non-undefined value, otherwise false.
 */
const hasValue = (data: any): boolean => {
    return data !== undefined && data !== null;
}