import { Avatar, Box, Tabs, Tab, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoIcon from '@mui/icons-material/Info';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import ClientTimeline from "./ClientTimeline";
import ClientAbout from "./ClientAbout";
import ClientToDoList from "./ClientToDoList";
import { useStore } from "../../../stores/store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

interface ClientInfoProps {
    onPageParamsChange?: () => void;
}

function ClientInfo({ onPageParamsChange }: ClientInfoProps) {
    const { t } = useTranslation();
    const { clientStore: { client } } = useStore();
    const { id, view } = useParams();
    const [value, setValue] = useState(view || "about");
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === `/users/${id}`) {
            navigate(`/users/${id}/${value}`);
        }
    }, [id, location.pathname, navigate, value])

    const handleChange = (event: any, newValue: string) => {
        setValue(newValue);
        navigate(`/users/${id}/${newValue}`);
    };

    const handleNavigate = () => {
        navigate("/users");
    }

    return (
        <Box className="client-details-info">
            <Box className="client-profile-details">
                <Box className="details-back-link" onClick={handleNavigate}>
                    <ArrowBackIcon sx={{ fontSize: "16px" }} />
                    <Typography fontSize="13px" fontWeight="600">
                        {t("ROLE_CLIENTS")}
                    </Typography>
                </Box>
                <Box>
                    <Avatar
                        className="client-avatar client-avatar-details"
                    >
                        <Typography
                            variant="h5"
                            sx={{ color: "white" }}
                        >
                            {client?.firstName.charAt(0).toUpperCase()}
                            {client?.lastName.charAt(0).toUpperCase()}
                        </Typography>
                    </Avatar>
                </Box>
                <Box className="item-info-container">
                    <Typography fontSize="20px" fontWeight="500">
                        {client && client.firstName} {client && client.lastName}
                    </Typography>
                    <Typography
                        fontSize="13px"
                        fontWeight="400"
                        sx={{ color: "#2a2a2a" }}
                    >
                        {client?.fullAddress}
                    </Typography>
                </Box>
            </Box>
            <Box className="tabs-container">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{
                        "& .MuiButtonBase-root": {
                            flexDirection: 'row',
                            svg: {
                                marginBottom: 0,
                                paddingRight: 0.5
                            }
                        },
                        "& .MuiTabs-indicator": {
                            left: 1,
                            width: '120px',
                            backgroundColor: '#0096c7',
                        },
                        "& .Mui-selected": {
                            color: '#0096c7 !important',
                        }
                    }}
                    className="tabs-component"
                >
                    <Tab
                        sx={{
                            minHeight: '20px',
                            fontSize: '12px',
                            "& .MuiTouchRipple-root": {
                                display: 'none'
                            }
                        }}
                        value="about"
                        label={t("CLIENT_ABOUT_TAB")}
                        icon={value === "about" ? <InfoIcon sx={{ fontSize: "18px" }} /> : <InfoOutlinedIcon sx={{ fontSize: "18px" }} />}
                    />
                    <Tab
                        sx={{
                            minHeight: '20px',
                            fontSize: '12px',
                            "& .MuiTouchRipple-root": {
                                display: 'none'
                            }
                        }}
                        value="timeline"
                        label={t("CLIENT_TIMELINE_TAB")}
                        icon={value === "timeline" ? <ViewTimelineIcon sx={{ fontSize: "18px" }} /> : <ViewTimelineOutlinedIcon sx={{ fontSize: "18px" }} />}
                    />
                    <Tab
                        sx={{
                            minHeight: '20px',
                            fontSize: '12px',
                            "& .MuiTouchRipple-root": {
                                display: 'none'
                            }
                        }}
                        value="tasks"
                        label={t("CLIENT_TASKS_TAB")}
                        icon={value === "tasks" ? <AssignmentTurnedInIcon sx={{ fontSize: "18px" }} /> : <AssignmentTurnedInOutlinedIcon sx={{ fontSize: "18px" }} />}
                    />
                </Tabs>
            </Box>
            <Box className="client-data-container">
                {value === "timeline" && <ClientTimeline onPageParamsChange={onPageParamsChange} />}
                {value === "about" && <ClientAbout />}
                {value === "tasks" && <ClientToDoList />}
            </Box>
        </Box >
    );
}

export default observer(ClientInfo);
