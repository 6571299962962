import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import agent from "../../../../../utils/agent";
import { Box, CircularProgress } from "@mui/material";
import { t } from "i18next";

export default function SessionCodeShow() {
    const [isLoading, setLoading] = useState(true);
    const [code, setCode] = useState('');
    const { sessionId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            if (sessionId) {
                const responsebody = await agent.Session.getSessionCode(sessionId);
                setCode(responsebody.code);
                setLoading(false)
            }
        }

        fetchData();
    }, [sessionId]);

    if (isLoading) return (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} minHeight={200}>
            <CircularProgress size={35} />
        </Box>
    )

    return (
        <>
            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} minHeight={200}>
                <h3>{t("SESSION_CODE")}</h3>
                <div style={{ "border": "solid", "borderRadius": "10px", "borderWidth": "1px", "padding": "0px 50px" }}>
                    <p style={{"fontSize": "2rem"}}>{code}</p>
                </div>
            </Box>
        </>
    )
}