import * as Yup from 'yup';

export const institutionFormSchema = () => {
    const schema = Yup.object().shape({
        name: Yup.string().required('Name is required').trim(),
        shortName: Yup.string().required('Short name is required').trim(),
        cantonTeam: Yup.string().required('Please select a project').trim(),
        description: Yup.string().required('Description is required').trim(),
        country: Yup.string().required('Country is required').trim(),
    });

    return schema;
}