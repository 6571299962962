import { Box, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

import { useState } from "react";

import NavbarOptions from "./NavBarOptions";
import DrawerMenu from "../drawer-menu/DrawerMenu";
import './styles.css';

function NavBarMenu() {
    const [open, setOpen] = useState(false);

    const handleMenu = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="navbar-menu-container">
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleMenu}
                title="Navigate"
                data-testid="navbar-navigate-button"
            >
                <MenuIcon sx={{ color: '#66717d' }} />
            </IconButton>
            <DrawerMenu handleClose={handleClose} open={open}>
                <NavbarOptions handleClose={handleClose} />
            </DrawerMenu>
        </Box>
    );
}

export default NavBarMenu;