import { CircularProgress, Box, Grid, Typography, Divider } from "@mui/material";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormInputText } from "../../../components/form/FormInputText";
import { TenantDto, TenantFormValues } from "../../../data/models/tenant";
import { useStore } from "../../../stores/store";
import { RoundButton } from "../../../_styles/StyledButtons";
import { tenantFormSchema } from "../../../_validators/schemas/tenantForm.schema";
import FormInputSelect from "../../../components/form/FormInputSelect";
import { countries } from "../../../data/static/countries";
import { useTranslation } from "react-i18next";

interface Props {
    tenant?: TenantDto;
}

function TenantRegisterForm({ tenant }: Props) {
    const { t } = useTranslation();
    const {
        tenantStore: { createTenant, loading, updateTenat, getTenants },
        sidebarStore: { closeSidebar },
        profileStore: { getUserTenants }
    } = useStore();
    const { handleSubmit, control } = useForm<TenantFormValues>({
        resolver: yupResolver(tenantFormSchema()), defaultValues: tenant
    });
    const editMode = tenant;

    const onSubmit = async (data: any) => {
        const tenantData: TenantFormValues = {
            ...data,
            id: tenant?.id,
        };

        const saveAction = editMode ? updateTenat : createTenant;

        await saveAction(tenantData);
        closeSidebar();
        toast.success(editMode ? t("TOAST_TENANT_UPDATE") : t("TOAST_TENANT_CREATE"));
        await getUserTenants()
        await getTenants()
    };


    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ display: 'flex', flexDirection: 'column', mb: "1.5rem" }}
        >
            <Typography component="h1" variant="h5" sx={{ mb: 1, fontWeight: '500', fontSize: '18px' }}>
                {t("SETTINGS_BASIC_INFO")}
            </Typography>
            <Grid container columnSpacing={1}>
                <Grid item xs={12}>
                    <FormInputText name="name" control={control} label={`${t("GENERAL_NAME")} *`} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputText name="shortName" control={control} label={`${t("GENERAL_SHORT_NAME")} *`} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputText name="description" control={control} label={`${t("GENERAL_DESCRIPTION")} *`} rows={3} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInputSelect
                        name="country"
                        control={control}
                        label={`${t("GENERAL_COUNTRY")}`}
                        options={countries}
                        labelKey="name"
                        valueKey="name"
                        defaultValue="Switzerland"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInputText name="city" control={control} label={`${t("GENERAL_CITY")} *`} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputText name="street" control={control} label={`${t("GENERAL_STREET_ADDRESS")} *`} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInputText name="number" control={control} label={`${t("GENERAL_STREET_NUMBER")} *`} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInputText name="zip" control={control} label={`${t("GENERAL_ZIP")} *`} />
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <RoundButton
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                    disabled={loading}
                    title="Submit"
                    data-testid="submit-button"
                >
                    {loading ? (
                        <CircularProgress size={25} />
                    ) : (
                        editMode ?
                            <Typography fontSize="13px">{t("GENERAL_UPDATE")}</Typography>
                            :
                            <Typography fontSize="13px">{t("GENERAL_CREATE")}</Typography>
                    )}
                </RoundButton>
            </Box>
        </Box>
    );
}

export default observer(TenantRegisterForm);