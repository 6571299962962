import { Box, Divider, Typography } from "@mui/material";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useCallback, useState } from "react";
import { Accept, FileRejection, useDropzone } from "react-dropzone";

import { RoundButton } from "../../_styles/StyledButtons";
import "./styles.css";
import FileInfo from "../FileInfo";

const dropzoneStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#dddddd80",
    borderRadius: "1rem",
    width: "100%",
    padding: "2rem 0",
    border: "2px dashed #cbcbcb",
};

interface FormInputFileProps {
    files: File[];
    setFiles: (files: File[]) => void;
    limit?: number;
    allowImages?: boolean
}

function FormInputFile({ files, setFiles, limit = 20, allowImages = false }: FormInputFileProps) {
    let acceptedFileTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/plain",
        "text/csv"
    ];

    if (allowImages) {
        acceptedFileTypes = [
            ...acceptedFileTypes,
            "image/png",
            "image/jpeg",
            "image/jpg"
        ];
    }

    let acceptObject: Accept = {
        "application/pdf": [".pdf"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
        "text/plain": [".txt"],
        "text/csv": [".csv"]
    };

    if (allowImages) {
        acceptObject = {
            ...acceptObject,
            "image/png": [".png"],
            "image/jpeg": [".jpeg", ".jpg"]
        };
    }

    const [errors, setErrors] = useState<
        { fileName: string; message: string }[] | null
    >(null);

    const fileValidator = (file: File) => {
        if (file.size > 5 * 1024 * 1024) {
            return {
                code: "file-size-too-large",
                message: `File size exceeds 5MB limit`,
            };
        } else if (!acceptedFileTypes.includes(file.type)) {
            return {
                code: "invalid-file-type",
                message: `File type not supported`,
            };
        }

        return null;
    };

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        noClick: true,
        noKeyboard: true,
        maxFiles: limit,
        accept: acceptObject,
        onDrop: useCallback(
            (acceptedFiles: File[]) => {
                if (files.length >= limit) {
                    let newErrors: { fileName: string; message: string }[] = [];
                    const message = `You cannot upload more than ${limit} file${limit > 1 ? "s" : ""}`;
                    const fileName = "Limit exceeded";
                    const error = { fileName, message };
                    newErrors.push(error);
                    setErrors(newErrors);
                    return;
                }
                const newFiles = [...files, ...acceptedFiles];
                setFiles(newFiles);
                setErrors(null);
            },
            [files, limit, setFiles]
        ),
        onDropRejected: useCallback((rejectedFiles: FileRejection[]) => {
            let newErrors: { fileName: string; message: string }[] = [];
            rejectedFiles.forEach((rejectedFile) => {
                const message = rejectedFile.errors[1].message;
                const fileName = rejectedFile.file.name;
                const error = { fileName, message };
                newErrors.push(error);
            });
            setErrors(newErrors);
        }, []),
        validator: fileValidator,
        maxSize: 5 * 1024 * 1024, // 5MB
    });

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                {...getRootProps({ className: "dropzone" })}
                sx={{
                    ...dropzoneStyle,
                    borderColor: isDragActive ? "#818181" : "#cbcbcb",
                }}
            >
                <input {...getInputProps()} />
                <Box display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="center">
                        <CloudUploadIcon
                            sx={{ color: isDragActive ? "#ddd" : "#03b2ff" }}
                        />
                        <Typography
                            fontWeight={500}
                            sx={{
                                marginLeft: "10px",
                                color: isDragActive ? "#ddd" : "#2a2a2a",
                            }}
                        >
                            Drag &amp; Drop to Upload
                        </Typography>
                    </Box>
                    <Divider orientation="horizontal" sx={{ margin: "10px 0" }}>
                        <Typography
                            fontSize="14px"
                            sx={{ color: isDragActive ? "#ddd" : "#000" }}
                        >
                            OR
                        </Typography>
                    </Divider>
                    <RoundButton
                        type="button"
                        variant="contained"
                        sx={{ width: "auto", transition: "none", marginBottom: "5px" }}
                        disabled={isDragActive}
                        onClick={open}
                    >
                        Select files
                    </RoundButton>
                    <Typography
                        component="p"
                        variant="body2"
                        fontSize="14px"
                        sx={{
                            color: isDragActive ? "#ddd" : "#858585",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        {allowImages
                            ? "(.png, .jpg, .jpeg, .pdf, .doc, .docx, .xlsx, .txt, .csv)"
                            : "(.pdf, .doc, .docx, .xlsx, .txt, .csv)"
                        }
                    </Typography>
                    <Typography
                        component="p"
                        variant="body2"
                        fontSize="12px"
                        sx={{
                            color: isDragActive ? "#ddd" : "#858585",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Max. file size: 5MB
                    </Typography>
                </Box>
            </Box>
            <Box className="file-error-container">
                {errors?.map((error) => (
                    <Box display="flex" alignItems="center" width="100%">
                        <Typography
                            fontSize="14px"
                            sx={{ color: "#d62828", marginRight: "5px" }}
                            className="file-error-message"
                        >
                            Error: "{error.fileName}"
                        </Typography>
                        <Typography
                            fontSize="14px"
                            fontWeight={500}
                            sx={{ color: "#d62828" }}
                        >
                            {"("}
                            {error.message}
                            {")"}
                        </Typography>
                    </Box>
                ))}
            </Box>
            <FileInfo files={files} setFiles={setFiles} limit={limit} />
        </Box>
    );
}

export default FormInputFile;
