import { Box, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import FieldContainer from "./FieldContainer";
import { FieldHeaderPreview } from "./FieldHeader";
import QuestionContainer, {
    QuestionCreatorContainer,
} from "./QuestionContainer";
import { TextCreator, TextInput } from "../QuestionTypes/SurveyText";
import RadioInput, { RadioCreator } from "../QuestionTypes/SurveyRadio";
import { QuestionProps } from "../QuestionTypes/QuestionProps";
import {
    CheckboxCreator,
    CheckboxInput,
} from "../QuestionTypes/SurveyCheckbox";
import SurveyMatrix, { MatrixCreator } from "../QuestionTypes/SurveyMatrix";
import SurveyDropdown from "../QuestionTypes/SurveyDropdown";
import SurveyTitle, { TitleCreator } from "../QuestionTypes/SurveyTitle";
import {
    Question,
    QuestionType,
    RuleType,
} from "../../../../data/models/survey";
import DateInput, { DateCreator } from "../QuestionTypes/SurveyDate";
import { FormInputText } from "../../../../components/form/FormInputText";
import { useContext, useEffect, useState } from "react";
import { SurveyContext } from "../Form/SurveyInitializer";

interface SurveyFieldErrorProps {
    message: React.ReactNode | string;
}

const getFieldComponent = (props: QuestionProps, editMode: boolean = false) => {
    if (
        props.type === "radio" &&
        (!props.options || props.options.length === 0)
    ) {
        throw new Error('Options are required for "radio" type questions.');
    }

    switch (props.type.toLowerCase()) {
        case "text":
            return editMode ? (
                <TextCreator {...props} />
            ) : (
                <TextInput {...props} disabled={editMode} />
            );
        case "paragraph":
            return editMode ? (
                <TextCreator {...props} />
            ) : (
                <TextInput {...props} multiline />
            );
        case "number":
            return editMode ? (
                <TextCreator {...props} />
            ) : (
                <TextInput {...props} type="number" />
            );
        case "radio":
            return editMode ? (
                <RadioCreator {...props} options={props.options!} />
            ) : (
                <RadioInput {...props} options={props.options!} />
            );
        case "dropdown":
            return <SurveyDropdown {...props} />;
        case "linearscale":
            return editMode ? (
                <RadioCreator {...props} options={props.options!} />
            ) : (
                <RadioInput {...props} options={props.options!} orientation="row" />
            );
        case "radiowithtext":
            return editMode ? (
                <RadioCreator {...props} options={props.options!} />
            ) : (
                <RadioInput {...props} options={props.options!} />
            );
        case "matrix":
            return editMode ? (
                <MatrixCreator {...props} subQuestions={props.subQuestions} />
            ) : (
                <SurveyMatrix {...props} subQuestions={props.subQuestions} />
            );
        case "readonly":
            return editMode ? (
                <TitleCreator {...props} />
            ) : (
                <SurveyTitle {...props} />
            );
        case "multiselect":
            return editMode ? (
                <CheckboxCreator {...props} options={props.options!} />
            ) : (
                <CheckboxInput {...props} options={props.options!} />
            );
        case "date":
            return editMode ? (
                <DateCreator {...props} date={null} />
            ) : (
                <DateInput date={null} {...props} />
            );
        default:
            return null;
    }
};

function SurveyField(props: QuestionProps) {
    const { editMode } = useContext(SurveyContext);

    return props.type !== RuleType.CONTROL && props.visible ? (
        <QuestionContainer visible={props.visible ?? true}>
            <FieldContainer
                header={
                    editMode ? (
                        <FormInputText
                            name={`questions.${props.id}.title`}
                            control={props.control}
                            initValue={props.title}
                            rows={3}
                        />
                    ) : (
                        <FieldHeaderPreview required={props.required} title={props.title} />
                    )
                }
                body={
                    props.type === QuestionType.READONLY ? null : getFieldComponent(props)
                }
                footer={null}
            />
        </QuestionContainer>
    ) : null;
}

export function SurveyCreatorField(props: QuestionProps) {
    const { baseSurvey } = useContext(SurveyContext);
    const [question, setQuestion] = useState<Question>();

    useEffect(() => {
        if (baseSurvey) {
            baseSurvey.pages.forEach((page) => {
                const currentQuestion = page.questions.find((q) => q.id === props.id);
                if (currentQuestion) {
                    setQuestion(currentQuestion);
                }
            });
        }
    }, [baseSurvey, props.id]);

    return props.type !== RuleType.CONTROL && props.visible ? (
        <QuestionCreatorContainer>
            <FieldContainer
                header={
                    props.type === QuestionType.READONLY ? null : (
                        <Box mb={2}>
                            <FormInputText
                                name={`questions.${props.id}.title`}
                                control={props.control}
                                initValue={props.title}
                                rows={3}
                                helperText={question?.title ?? ""}
                            />
                        </Box>
                    )
                }
                body={getFieldComponent(props, true)}
                footer={null}
            />
        </QuestionCreatorContainer>
    ) : null;
}

export function SurveyFieldError({ message }: SurveyFieldErrorProps) {
    return (
        <>
            {typeof message === "string" ? (
                message.length > 0 ? (
                    <Box display="flex" alignItems="center">
                        <ErrorOutlineIcon
                            sx={{ fontSize: "14px", color: "#d62828", mr: 0.5 }}
                        />
                        <Typography
                            fontSize="14px"
                            fontWeight={400}
                            sx={{ color: "#d62828" }}
                        >
                            {message}
                        </Typography>
                    </Box>
                ) : null
            ) : (
                message
            )}
        </>
    );
}

export default SurveyField;
