import { makeAutoObservable, runInAction } from "mobx";
import { PasswordUpdateDto, ProfileDetailsDto, ProfileUpdateDto, ResetPasswordDto } from "../data/models/profile";
import agent from "../utils/agent";
import { UserTenant } from "../data/models/tenant";
import { store } from "./store";

export class ProfileStore {
    profile?: ProfileDetailsDto;
    userTenants: Map<string, UserTenant> = new Map<string, UserTenant>();
    emailReset: string | null = null;
    loading = false;


    constructor() {
        makeAutoObservable(this)
    }

    setEmailReset = (email: string | null) => {
        this.emailReset = email;
    }

    get userTenantList() {
        return Array.from(this.userTenants.values());
    }

    get defaultUserInstitution() {
        const defaultTenant = this.userTenantList.find((userTenant) => userTenant.isDefault);
        const defaultInstitution = defaultTenant?.institutions.find(institution => institution.isDefault);

        return defaultInstitution;
    }

    getProfile = async () => {
        store.loadingStore.startLoading(this.getProfile);
        this.loading = true;
        try {
            const profile = await agent.Profile.get();
            runInAction(() => {
                this.profile = profile
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getProfile);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getProfile);
            throw error;
        }
    }

    updateProfile = async (profile: ProfileUpdateDto) => {
        store.loadingStore.startLoading(this.updateProfile);
        this.loading = true;
        try {
            await agent.Profile.update(profile);
            runInAction(() => {
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.updateProfile);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.updateProfile);
            throw error;
        }
    }

    changePassword = async (password: PasswordUpdateDto) => {
        store.loadingStore.startLoading(this.changePassword);
        this.loading = true;
        try {
            await agent.Profile.updatePassword(password);
            runInAction(() => {
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.changePassword);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.changePassword);
            throw error;
        }
    }

    loadTenants = (userTenants: UserTenant[]) => {
        userTenants.forEach((userTenant: UserTenant) => {
            const selectedInstitution = userTenant.institutions.find(i => i.id === store.commonStore.selectedInstitution);
            const defaultInstitution = userTenant.institutions.find(i => i.isDefault);
            if (selectedInstitution) {
                store.institutionStore.setSelectedUserInstitution(selectedInstitution.id);
            } else if (defaultInstitution) {
                store.institutionStore.setSelectedUserInstitution(defaultInstitution.id);
            }
            this.userTenants.set(userTenant.id, userTenant);
        });
        store.tenantStore.setDefaultTenant(userTenants);
    }

    getUserTenants = async () => {
        store.loadingStore.startLoading(this.getUserTenants);
        this.loading = true;
        try {
            const tenants: UserTenant[] = await agent.Profile.getTenants();
            runInAction(() => {
                this.userTenants.clear();
                tenants.forEach(tenant => this.userTenants.set(tenant.id, tenant));
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getUserTenants);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getUserTenants);
            throw error;
        }
    }

    sendPasswordResetEmail = async (email: string) => {
        store.loadingStore.startLoading(this.sendPasswordResetEmail);
        this.loading = true;
        try {
            await agent.Profile.sendPasswordResetEmail(email);
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.sendPasswordResetEmail);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.sendPasswordResetEmail);
            throw error;
        }
    }

    resetPassword = async (resetPassword: ResetPasswordDto) => {
        store.loadingStore.startLoading(this.resetPassword);
        this.loading = true;
        try {
            await agent.Profile.sendResetPassword(resetPassword);
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.resetPassword);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.resetPassword);
            throw error;
        }
    }

    changeLanguage = async (lang: string) => {
        store.loadingStore.startLoading(this.changeLanguage);
        try {
            await agent.Profile.changeLang(lang);
            store.loadingStore.stopLoading(this.changeLanguage);
        } catch (error) {
            store.loadingStore.stopLoading(this.changeLanguage);
            throw error;
        }
    }
}