import {
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";

import { Controller } from "react-hook-form";
import { useCallback, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useParams } from "react-router-dom";
import debounce from "lodash.debounce";

import { QuestionProps } from "./QuestionProps";
import { useStore } from "../../../../stores/store";
import { SurveyText } from "../../../../_styles/survey/TextInput";
import { RoundButton } from "../../../../_styles/StyledButtons";
import { SurveyFieldError } from "../SurveyFields/SurveyField";
import { AnswerSubmit, Question, QuestionOption } from "../../../../data/models/survey";
import { FormInputText } from "../../../../components/form/FormInputText";
import { SurveyContext } from "../Form/SurveyInitializer";
import "./styles.css";

type Orientation = "row" | "column";
interface RadioInputProps extends QuestionProps {
    options: QuestionOption[];
    orientation?: Orientation;
}

function RadioInput({
    options,
    orientation = "column",
    disabled = false,
    control,
    id,
    preview,
}: RadioInputProps) {
    const {
        surveyStore: { getAnswer, setAnswer, submitAnswer, incrementCount, decrementCount },
    } = useStore();
    const question = getAnswer(id);
    const { width } = useContext(SurveyContext);

    const { sessionId, surveyId, timelineId } = useParams();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    const onAnswerSelect = async (optionId: string | null, text?: string) => {
        if (preview) return;

        const answerSubmit: AnswerSubmit = {
            questionId: id,
            optionId: optionId,
            surveyId: surveyId!,
            timelineId: timelineId!,
            sessionId: sessionId!,
            text: text === "" ? null : text,
        };
        await submitAnswer(answerSubmit, token ?? undefined);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceFn = useCallback(debounce(onAnswerSelect, 400), []);

    const generateRadioOptions = () => {
        return options.map((singleOption) => (
            <FormControlLabel
                key={singleOption.id}
                value={singleOption.id}
                sx={{ ml: "14px", mr: "14px" }}
                label={
                    <Typography sx={{ fontSize: "14px" }}>
                        {singleOption.title}
                    </Typography>
                }
                control={
                    <Radio
                        disabled={disabled}
                        onClick={() => onAnswerSelect(singleOption.id)}
                        size={width <= 1000 ? "small" : "medium"}
                    />
                }
                labelPlacement={`${orientation === "column" || width <= 830 ? "end" : "top"}`}
            />
        ));
    };

    return (
        <>
            <FormControl
                component="fieldset"
                sx={{ width: "100%", pb: `${orientation === "row" ? "2rem" : 0}` }}
                disabled={disabled}
            >
                <Controller
                    name={`${id}.response`}
                    control={control}
                    defaultValue={getAnswer(id) ? getAnswer(id).answer : null}
                    render={({ field: { onChange }, fieldState: { error } }) => (
                        <>
                            <RadioGroup
                                sx={{ flexDirection: orientation, fontSize: "14px", flexWrap: "nowrap" }}
                                value={question ? question.answer : null}
                                className="survey-radio-group"
                                onChange={(e) => {
                                    onChange(e);
                                    let question = getAnswer(id);
                                    const newAnswer = { ...question, answer: e.target.value };
                                    setAnswer(id, newAnswer);
                                    onAnswerSelect(e.target.value);
                                }}
                            >
                                {generateRadioOptions()}
                            </RadioGroup>
                            {!disabled && question && question.answer && (
                                <RoundButton
                                    variant="text"
                                    sx={{
                                        width: "8.5rem",
                                        alignSelf: "flex-end",
                                        position: "absolute",
                                        bottom: 0,
                                        padding: 0,
                                        "&:hover": {
                                            backgroundColor: "transparent",
                                        },
                                    }}
                                    onClick={() => {
                                        onChange(null);
                                        setAnswer(id, { answer: null, additional: null });
                                        onAnswerSelect(null);
                                    }}
                                >
                                    Clear section
                                </RoundButton>
                            )}
                            {!!error && <SurveyFieldError message="This field is required" />}
                        </>
                    )}
                />
                {options.map(
                    (option) =>
                        question?.answer === option.id &&
                        option.conditionalText !== null && (
                            <Controller
                                key={option.title}
                                name={`${id}.additional`}
                                control={control}
                                render={({ field: { onChange, value = "" } }) => (
                                    <Box>
                                        <SurveyText
                                            placeholder={
                                                option.conditionalText !== null
                                                    ? option.conditionalText
                                                    : "Answer here..."
                                            }
                                            variant="filled"
                                            disabled={disabled}
                                            multiline={true}
                                            defaultValue={question.additional}
                                            onChange={(e) => {
                                                const text = e.target.value;

                                                onChange(e);
                                                let question = getAnswer(id);
                                                const newAnswer = {
                                                    ...question,
                                                    additional: e.target.value,
                                                };
                                                setAnswer(id, newAnswer);
                                                incrementCount();
                                                setTimeout(() => decrementCount(), 400);
                                                debounceFn(option.id, text);
                                            }}
                                            size="small"
                                        />
                                    </Box>
                                )}
                            />
                        )
                )}
            </FormControl>
        </>
    );
}

export function RadioCreator({
    options,
    orientation = "column",
    control,
    id,
}: RadioInputProps) {
    const { baseSurvey } = useContext(SurveyContext);
    const [question, setQuestion] = useState<Question>();

    useEffect(() => {
        if (baseSurvey) {
            baseSurvey.pages.forEach(page => {
                const currentQuestion = page.questions.find(q => q.id === id);
                if (currentQuestion) {
                    setQuestion(currentQuestion);
                }
            })
        }
    }, [baseSurvey, id]);

    const generateRadioOptions = () => {
        return options.map((singleOption) => (
            <FormControlLabel
                key={singleOption.id}
                value={singleOption.id}
                sx={{ ml: "14px", mr: "14px", mb: 1 }}
                label={
                    <Box maxWidth={"fit-content"}>
                        <FormInputText
                            name={`questions.${id}.options.${singleOption.id}.title`}
                            control={control}
                            initValue={singleOption.title}
                            helperText={question?.options?.find(option => option.id === singleOption.id)
                                ?.title ?? ""}
                        />
                    </Box>
                }
                control={<Radio disabled={true} sx={{ pt: 0 }} />}
                labelPlacement={`${orientation === "column" ? "end" : "top"}`}
            />
        ));
    };

    return (
        <>
            <RadioGroup
                sx={{ flexDirection: "orientation", fontSize: "14px", mb: 3 }}
                value={false}
            >
                {generateRadioOptions()}
            </RadioGroup>
            {options.map(
                (option) =>
                    option.conditionalText !== null && (
                        <Box mb={3} mt={1.5}>
                            <FormInputText
                                name={`questions.${id}.conditionals.${option.id}.text`}
                                control={control}
                                initValue={option.conditionalText}
                                helperText={option.conditionalText}
                                label={`Displayed if "${option.title}" is selected`}
                            />
                        </Box>
                    )
            )}
        </>
    );
}

export default observer(RadioInput);
