import {
    Typography,
    Box,
    Breadcrumbs,
} from "@mui/material";

import { observer } from "mobx-react-lite";

import { RoundButton } from "../../_styles/StyledButtons";
import { useStore } from "../../stores/store";
import Sidebar from "../../components/sidebar/Sidebar";
import SettingsMenu from "./SettingsMenu";
import { Option } from "../../_styles/layout/Container";
import "./styles.css";
import { useTranslation } from "react-i18next";
import TenantMenu from "../../components/TenantMenu";

interface Props {
    componentForm: JSX.Element;
    label: string;
    selected: Option;
    path: string;
    handleChange: (option: Option) => void;
}

function SettingsHeader({
    componentForm,
    label,
    selected,
    handleChange,
    path,
}: Props) {
    const { t } = useTranslation();
    const {
        sidebarStore: { openSidebar },
        authStore: { user },
        tenantStore: { tenantList }
    } = useStore();

    return (
        <Box>
            <Sidebar />
            <Box className="settings-header">
                <Box className="header-breadcrumbs">
                    <Breadcrumbs
                        separator={<Typography fontSize={"10pt"}>/</Typography>}
                        aria-label="breadcrumb"
                    >
                        <Typography fontSize={"10pt"}>{t("SETTINGS_NAME")}</Typography>,
                        <Typography color="primary" fontSize={"10pt"}>
                            {path}
                        </Typography>
                        ,
                    </Breadcrumbs>
                    <SettingsMenu handleChange={handleChange} selected={selected} />
                </Box>
                <Box
                    className="header-menu-container"
                    display="flex"
                    flexDirection="row"
                    flexGrow={2}
                >
                    <Typography className="selected-text">{t(`SETTINGS_${selected.name}_NAME`)}</Typography>
                </Box>
                <Box
                    className="settings-header-options"
                    display="flex"
                    flexDirection="row"
                    justifyContent={selected.id === "tenants" ? "flex-end" : "space-between"}
                    flexGrow={7}
                    sx={{ marginBottom: selected.id === "tenants" ? "0 !important" : "0" }}
                >
                    {tenantList.length > 0 && selected.id !== "tenants" && (
                        <TenantMenu />
                    )}
                    {componentForm &&
                        (user?.isSuperAdmin || selected.id !== "tenants") && (
                            //NOTE - Roles will not have create functionality until furthure notice.
                            selected.name !== 'ROLES' ?
                                <RoundButton
                                    variant="contained"
                                    sx={{ marginRight: "15px", width: `${selected.name === 'USERS' ? '11rem' : '7rem'}` }}
                                    onClick={() => openSidebar(componentForm, label)}
                                    title="Create"
                                    data-testid="create-settings-button"
                                >
                                    {t("GENERAL_CREATE")}
                                    {selected.name === "USERS" ? `/${t("SETTINGS_USER_ASSIGN")}` : ""}
                                </RoundButton>
                                : null
                        )}
                </Box>
            </Box>
        </Box>
    );
}

export default observer(SettingsHeader);
