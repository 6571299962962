import { Box, CircularProgress } from "@mui/material";
import * as Yup from "yup";
import "yup-phone-lite";
import { useState } from "react";
import { observer } from "mobx-react-lite";

import { RoundButton } from "../../../_styles/StyledButtons";
import { useStore } from "../../../stores/store";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInputText } from "../../../components/form/FormInputText";
import agent from "../../../utils/agent";

const schema = () => {
    const schema = Yup.object().shape({ //
        phoneNumber: Yup.string()
            .trim()
            .phone("CH", "Please enter a valid phone number")
            .required(),
    });

    return schema;
}

interface Props {
    message: string
}

function PreviewWhatsAppMessageForm({message}: Props) {
    const { t } = useTranslation();
    const {
        modalStore: { closeModal }
    } = useStore()
    const { control, handleSubmit, formState: { isSubmitting, isValid } } = useForm({
        resolver: yupResolver(schema()),
        mode: 'onChange'
      });

    const onSubmit = async (data: any) => {
        try {
            await agent.Notifications.previewWhatsappNotification(data.phoneNumber, message);
            toast.success(t("PREVIEW_NOTIFICATION_SENT")); 
        }
        catch(error){
            console.error(error);
        }
        finally{
            closeModal();
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <p>{t('PREVIEW_NOTIFICAITON_MODAL_INTRO')}</p>
                <FormInputText
                    name="phoneNumber"
                    helperText="e.g. +41xxxxxxxxx"
                    control={control}
                    label={t("WHATSAPP_NUMBER")}
                />
                <RoundButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={!isValid || isSubmitting}
                    title="Add"
                >
                    {isSubmitting
                        ? <CircularProgress size={25} />
                        : t("GENERAL_SEND")
                    }
                </RoundButton>
            
        </Box>
    );
}

export default observer(PreviewWhatsAppMessageForm);