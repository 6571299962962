import { Box, lighten } from "@mui/material";
interface EventIconProps {
    type: IconType;
    bgColor: string;
    className?: string;
    style?: React.CSSProperties;
}

type IconType =
    | "MEETING"
    | "ASSESSMENT"
    | "HOLIDAY"
    | "BUSY"
    | "AVAILABLE"
    | "INTERVENTION";

export const EventIcon = ({
    type,
    bgColor,
    className,
    style,
}: EventIconProps) => {
    switch (type) {
        case "MEETING":
            return (
                <EventMeetingIcon
                    type={type}
                    bgColor={bgColor}
                    className={className}
                    style={style}
                />
            );
        case "ASSESSMENT":
            return (
                <EventAssessmentIcon
                    type={type}
                    bgColor={bgColor}
                    className={className}
                    style={style}
                />
            );
        case "INTERVENTION":
            return (
                <EventCustom2Icon
                    type={type}
                    bgColor={bgColor}
                    className={className}
                    style={style}
                />
            );
        case "HOLIDAY":
            return (
                <EventHolidayIcon
                    type={type}
                    bgColor={bgColor}
                    className={className}
                    style={style}
                />
            );
        case "BUSY":
            return (
                <EventBusyIcon
                    type={type}
                    bgColor={bgColor}
                    className={className}
                    style={style}
                />
            );
        case "AVAILABLE":
            return (
                <EventAvailableIcon
                    type={type}
                    bgColor={bgColor}
                    className={className}
                    style={style}
                />
            );
        default:
            return null;
    }
};

export const EventMeetingIcon = ({
    bgColor,
    className,
    style,
}: EventIconProps) => {
    return (
        <Box
            className={className}
            height="100%"
            width="6px"
            sx={{
                ...style,
                backgroundColor: bgColor,
                "--fbBackgroundColor": "transparent",
                "--fbHighlightColor": "#fff",
                backgroundImage:
                    "linear-gradient(-45deg,var(--fbHighlightColor) 19%,var(--fbBackgroundColor) 0,var(--fbBackgroundColor) 50%,var(--fbHighlightColor) 0,var(--fbHighlightColor) 69%,var(--fbBackgroundColor) 0,var(--fbBackgroundColor))",
                backgroundSize: "6px 6px",
                borderImage:
                    "linear-gradient(-45deg,var(--fbHighlightColor) 19%,var(--fbBackgroundColor) 0,var(--fbBackgroundColor) 50%,var(--fbHighlightColor) 0,var(--fbHighlightColor) 69%,var(--fbBackgroundColor) 0,var(--fbBackgroundColor))",
            }}
        ></Box>
    );
};

export const EventAssessmentIcon = ({
    bgColor,
    className,
    style,
}: EventIconProps) => {
    return (
        <Box
            className={className}
            height="100%"
            width="6px"
            sx={{
                ...style,
                "--fbBackgroundColor": "transparent",
                "--fbHighlightColor": "#fff",
                backgroundColor: bgColor,
                backgroundImage: `
                linear-gradient(45deg,${lighten(
                    bgColor,
                    0.6
                )} 12%,transparent 0,transparent 50%,${lighten(
                    bgColor,
                    0.6
                )} 0,${lighten(bgColor, 0.6)} 62%,transparent 0,transparent),
                linear-gradient(-45deg,${lighten(
                    bgColor,
                    0.6
                )} 12%,transparent 0,transparent 50%,${lighten(
                    bgColor,
                    0.6
                )} 0,${lighten(bgColor, 0.6)} 62%,transparent 0,transparent)
                `,
                backgroundSize: "6px 6px",
            }}
        ></Box>
    );
};

export const EventBusyIcon = ({
    bgColor,
    className,
    style,
}: EventIconProps) => {
    return (
        <Box
            className={className}
            height="100%"
            width="6px"
            sx={{
                ...style,
                backgroundColor: bgColor,
            }}
        ></Box>
    );
};

export const EventAvailableIcon = ({
    bgColor,
    className,
    style,
}: EventIconProps) => {
    return (
        <Box
            className={className}
            height="100%"
            width="6px"
            sx={{
                ...style,
                backgroundColor: "transparent",
                border: `1px solid ${bgColor}`,
                boxSizing: "border-box",
            }}
        ></Box>
    );
};

export const EventHolidayIcon = ({
    bgColor,
    className,
    style,
}: EventIconProps) => {
    return (
        <Box
            className={className}
            height="100%"
            width="6px"
            sx={{
                ...style,
                backgroundColor: "#b4009e",
                borderColor: "#b4009e",
                "--fbBackgroundColor": "transparent",
                "--fbHighlightColor": "#fff",
                backgroundImage: `linear-gradient(0deg,var(--fbBackgroundColor) 25%,var(--fbHighlightColor) 0,var(--fbHighlightColor) 50%,var(--fbBackgroundColor) 0,var(--fbBackgroundColor) 75%,var(--fbHighlightColor) 0,var(--fbHighlightColor)),linear-gradient(90deg,var(--fbBackgroundColor) 25%,var(--fbHighlightColor) 0,var(--fbHighlightColor) 50%,var(--fbBackgroundColor) 0,var(--fbBackgroundColor) 75%,var(--fbHighlightColor) 0,var(--fbHighlightColor)),linear-gradient(45deg,var(--fbHighlightColor) 38%,var(--fbBackgroundColor) 0),linear-gradient(45deg,var(--fbHighlightColor) 38%,var(--fbBackgroundColor) 0)`,
                backgroundPosition: "0 0,1px 0,0 0,2px 2px",
                backgroundSize: "4px 4px",
                borderStyle: "solid",
                borderWidth: "1px",
                boxSizing: "border-box",
            }}
        ></Box>
    );
};

export const EventCustom1Icon = ({
    bgColor,
    className,
    style,
}: EventIconProps) => {
    return (
        <Box
            className={className}
            height="100%"
            width="6px"
            sx={{
                ...style,
                backgroundColor: bgColor,
                "--customBackground": "#f0f0f0",
                "--customHighlight": "#000",
                backgroundImage: `
                    linear-gradient(60deg, var(--customHighlight) 25%, transparent 25%, transparent 50%, var(--customHighlight) 50%, var(--customHighlight) 75%, transparent 75%, transparent),
                    linear-gradient(60deg, var(--customHighlight) 25%, transparent 25%, transparent 50%, var(--customHighlight) 50%, var(--customHighlight) 75%, transparent 75%, transparent)`,
                backgroundSize: "12px 12px",
                backgroundPosition: "0 0, 6px 6px",
                border: "1px solid var(--customHighlight)",
            }}
        ></Box>
    );
};

export const EventCustom2Icon = ({
    bgColor,
    className,
    style,
}: EventIconProps) => {
    return (
        <Box
            className={className}
            height="100%"
            width="6px"
            sx={{
                ...style,
                backgroundColor: bgColor,
                "--fbBackgroundColor": "transparent",
                "--fbHighlightColor": "#fff",
                backgroundImage:
                    "linear-gradient(270deg,var(--fbHighlightColor) 0%,var(--fbBackgroundColor) 0%,var(--fbBackgroundColor) 20%,var(--fbHighlightColor) 35%,var(--fbHighlightColor) 50%,var(--fbBackgroundColor) 80%,var(--fbBackgroundColor))",
                backgroundSize: "6px 6px",
                borderImage:
                    "linear-gradient(-45deg,var(--fbHighlightColor) 19%,var(--fbBackgroundColor) 0,var(--fbBackgroundColor) 50%,var(--fbHighlightColor) 0,var(--fbHighlightColor) 69%,var(--fbBackgroundColor) 0,var(--fbBackgroundColor))",
            }}
        ></Box>
    );
};
