import { Button, IconButton, styled } from "@mui/material";

export const RoundButton = styled(Button)(({ variant }) => ({
    borderRadius: '2rem',
    height: '1.2rem',
    width: '7rem',
    padding: '1.2rem',
    fontSize: '13px',
    ...(variant === 'text' && {
        disableFocusRipple: true,
        disableTouchRipple: true,
        padding: 0,
        width: "auto",
        "&:hover": {
            backgroundColor: "transparent"
        }
    }),
}));

export const MenuButton = styled(Button)({
    backgroundColor: '#f1f5f9',
    color: '#2a2a2a',
    '&:hover': {
        backgroundColor: 'transparent',
    },
});

export const TenantMenuButton = styled(Button)({
    color: '#2a2a2a',
    backgroundColor: "#dddddd24",
    textTransform: "none",
    fontWeight: 400,
    width: '8rem',
    '&:hover': {
        backgroundColor: '#dfe3e8',
    },
})

export const CircleButton = styled(IconButton)({
    backgroundColor: '#94a3b8',
    color: 'white',
    padding: '15px',
    fontSize: '12px',
    fontWeight: '500',
    margin: '10px 5px',
    '&:hover': {
        backgroundColor: '#8592a5',
    },
    boxShadow: '0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f'
})