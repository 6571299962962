import * as Yup from "yup";
import { combineDateAndTime } from "../../utils/utils";

export const clientSessionFormSchema = () => {
    const schema = Yup.object().shape({
        startDate: Yup.date().typeError("Start date is required").required(),
        endDate: Yup.date()
            .typeError("End date is required")
            .test(
                "is-after-start",
                "Must be after start date",
                function (endDate): boolean {
                    const startDate: Date = this.resolve(Yup.ref("startDate"));
                    startDate.setHours(0, 0, 0, 0);
                    endDate!.setHours(0, 0, 0, 0);

                    return startDate <= endDate!;
                }
            ),
        startTime: Yup.date()
            .typeError("Start time is required")
            .required()
            .when("startDate", {
                is: (startDate: any) => {
                    return !isNaN(+startDate)
                },
                then: (schema) =>
                    schema
                        .test(
                            "is-in-future",
                            "Start time should not be in the past (will change on future versions)",
                            function (): boolean {
                                const startDate: Date = this.resolve(Yup.ref("startDate"));
                                const startTime: Date = this.resolve(Yup.ref("startTime"));
                                const startTimeDate = combineDateAndTime(
                                    startDate as Date,
                                    new Date(startTime)
                                );

                                return startTimeDate >= new Date();
                            }
                        ),
            }),
        endTime: Yup.date().when("startTime", {
            is: (startTime: Date) => startTime !== null,
            then: (schema) =>
                schema
                    .typeError("End time is required")
                    .required()
                    .test(
                        "is-after-start",
                        "Must be after start time",
                        function (endTime): boolean {
                            const startTime: Date = this.resolve(Yup.ref("startTime"));
                            const startDate: Date = this.resolve(Yup.ref("startDate"));
                            const startTimeDate = combineDateAndTime(
                                startDate,
                                new Date(startTime)
                            );

                            const endDate: Date = this.resolve(Yup.ref("endDate"));
                            const endTimeDate = combineDateAndTime(
                                endDate,
                                new Date(endTime)
                            );

                            return endTimeDate > startTimeDate;
                        }
                    ),
        }),
        assessor: Yup.string().required("Please select an assessor"),
        roomId: Yup.string().test('is-not-none', 'Room selection is required', value => value !== 'none'),
        description: Yup.string().required("Description is required"),
    });

    return schema;
};
