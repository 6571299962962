import * as Yup from 'yup';

export const tenantFormSchema = () => {
    const schema = Yup.object().shape({
        name: Yup.string().trim().max(255, 'Name must be at most 255 characters').required('Name is required'),
        shortName: Yup.string().trim().required('Short name is required'),
        description: Yup.string().trim().max(255, 'Description must be at most 255 characters').required('Description is required'),
        country: Yup.string().trim().required('Country is required'),
        city: Yup.string().trim().required('City is required'),
        street: Yup.string().trim().required('Street is required'),
        number: Yup.string().trim().required('Number is required'),
        zip: Yup.string().trim().required('Zip is required'),
    });

    return schema;
}