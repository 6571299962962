import * as Yup from 'yup';
import { Question } from '../../data/models/survey';

export const surveyFormSchema = (questions: Question[] | null) => {
    if (!questions) {
        return Yup.object().shape({});
    }
    const schemaObject: Record<string, any> = {};

    questions.forEach((question) => {
        if (question.required) {
            schemaObject[`${question.id}`] = Yup.object().shape({
                response: Yup.string().required(`This field is required.`),
            })
        }
        if (question.subQuestions) {
            question.subQuestions.forEach(subQuestion => {
                if (subQuestion.required) {
                    schemaObject[`${subQuestion.id}`] = Yup.object().shape({
                        response: Yup.string().required("This field is required"),
                    })
                }
            })
        }
    });

    const schema = Yup.object().shape(schemaObject);

    return schema;
}