import { t } from "i18next";
import * as Yup from "yup";

const emptyHtmlRegex = /^(<p>|<p\s*[^>]*>)?\s*(<br\s*\/?>|\s|<span\s*[^>]*>\s*<\/span>)*(<\/p>)?$/;

const isEmptyHtml = (value: string) => {
    return emptyHtmlRegex.test(value);
};

export const editorFormSchema = () => {
    return Yup.object().shape({
        title: Yup.string().required(t('SCHEMA_GENERAL_REQUIRED')),
        study: Yup.string().required(t('SCHEMA_GENERAL_REQUIRED')),
        commChannel: Yup.string().required(t('SCHEMA_GENERAL_REQUIRED')),
        subject: Yup.string()
            .when('commChannel', {
                is: (value: string) => value === 'EMAIL',
                then: (schema: any) => schema.required(t('SCHEMA_GENERAL_REQUIRED')),
            }),
        body: Yup.string()
            .test('is-empty-html', t('SCHEMA_GENERAL_REQUIRED'), function (value) {
                return value ? !isEmptyHtml(value) : false;
            })
            .required(t('SCHEMA_GENERAL_REQUIRED')),
    });
};
