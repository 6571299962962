import { Box, Container, Typography } from "@mui/material";

import { useEffect, useState } from "react";

import ProcessStepper from "./ProcessStepper";
import SurveyTable from "./SurveyTable";
import { ProcessDetails, ProcessTimeline } from "../../../data/models/process";
import SessionTable from "./SessionTable";
import "./styles.css";
import { useTranslation } from "react-i18next";

interface ProcessOvreviewProps {
    process: ProcessDetails;
}

function ProcessOverview({ process }: ProcessOvreviewProps) {
    const { t } = useTranslation();
    const [selectedTimeline, setSelectedTimeline] =
        useState<ProcessTimeline | null>(null);

    useEffect(() => {
        if (process && process?.timelines.length > 0) {
            setSelectedTimeline(process.timelines[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onTimelineChange = (id: string) => {
        process?.timelines.forEach((t) => {
            if (t.id === id) {
                setSelectedTimeline(t);
            }
        });
    };

    return (
        <Container maxWidth="xl" className="process-overview">
            <Box className="process-title">
                <Typography fontSize={32}>{process?.name}</Typography>
                <Typography fontSize={12} pl={1} mt={2} sx={{ color: "gray" }}>
                    ({selectedTimeline?.monthsTo === 0
                        ? t("PROCESS_ACTIVE")
                        : t("PROCESS_START", { date: selectedTimeline?.monthsTo })})
                </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
                <ProcessStepper
                    steps={
                        process?.timelines.map((t) => {
                            return {
                                name: t.name,
                                id: t.id,
                            };
                        }) || []
                    }
                    onProcessChange={onTimelineChange}
                    selectedProcess={selectedTimeline?.id!}
                />
            </Box>
            <Box className="process-data-container">
                <Box className="process-session-dashboard">
                    <SessionTable />
                </Box>
                <Box className="process-survey-table">
                    <SurveyTable timeline={selectedTimeline || undefined} />
                </Box>
            </Box>
        </Container>
    );
}

export default ProcessOverview;
