import { styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RoundButton } from '../_styles/StyledButtons';
import { router } from '../router/Routes';
import { useStore } from '../stores/store';

const Root = styled('div')(({ theme }) => ({
    textAlign: 'center',
    marginTop: theme.spacing(10),
}));

const NotFound = () => {
    const { authStore } = useStore();
    const { user } = authStore;
    const { t } = useTranslation();
    return (
        <Root>
            <Typography variant="h1" fontSize="6rem" marginBottom={3}>
                404
            </Typography>
            <Typography variant="h4" component="h4" gutterBottom>
                {t("NOT_FOUND_TITLE")}
            </Typography>
            <Typography variant="subtitle1" component="p">
                {t("NOT_FOUND_DESC")}
            </Typography>
            {!user && <RoundButton variant="contained" color="info" disableRipple sx={{ mt: 3 }} onClick={() => router.navigate("/")}>
                Go back
            </RoundButton>}
        </Root>
    );
};

export default NotFound;
