import { useEffect, useState } from "react";
import {
    Menu,
    MenuItem,
    ListItemText,
    Button,
    Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useStore } from "../../../stores/store";
import DoneIcon from "@mui/icons-material/Done";
import { getFilteredViews } from "../../../utils/helpers/eventHelper";
import { observer } from "mobx-react-lite";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { useTranslation } from "react-i18next";

export const buttonStyle = {
    borderRadius: "2rem",
    color: "#616161",
    marginLeft: "5px",
    textTransform: "none",
    fontSize: "13px",
};

const CalendarFilter = () => {
    const { t } = useTranslation();
    const {
        bookingStore,
        commonStore,
        authStore,
        languageStore,
        clientStore,
        tenantStore,
    } = useStore();
    const {
        getUsersCalendar,
        usersCalendar,
        userEventsId,
        filterEvents,
        roleFilters,
        addRoleFilter,
        removeRoleFilter,
        clearRoleFilters,
    } = bookingStore;
    const { setCalendarViews, getCalendarViews } = commonStore;
    const { filterLanguageList } = languageStore;
    const { user } = authStore;
    const { getClientRoles, clientRoleList } = clientStore;
    const { selectedTenant } = tenantStore;
    const [anchorEl, setAnchorEl] = useState(null);
    const [langAnchorEl, setLangAnchorEl] = useState(null);
    const [roleAnchorEl, setRoleAnchorEl] = useState(null);
    const [index, setIndex] = useState(0);
    const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const searchParams = new URLSearchParams(window.location.search);
            const languages = searchParams.getAll("languages");
            if (languages.length > 0) {
                await getUsersCalendar(languages);
                setSelectedLanguages(languages);
            } else {
                await getUsersCalendar();
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (selectedTenant && clientRoleList.length === 0) {
                await getClientRoles(selectedTenant.id);
            }
        };

        fetchData();
    }, [clientRoleList.length, getClientRoles, selectedTenant]);

    const handleMainClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLangMenu = async (event: any, newIndex: number) => {
        if (newIndex === -1) {
            handleLangMenuClose();
        } else {
            setLangAnchorEl(event.currentTarget);
        }
        setIndex(newIndex);
    };

    const handleRoleMenu = (event: any, newIndex: number) => {
        if (newIndex === -1) {
            handleRoleMenuClose();
        } else {
            setRoleAnchorEl(event.currentTarget);
        }
        setIndex(newIndex);
    };

    const onClearFilters = async () => {
        await getUsersCalendar();
        setSelectedLanguages([]);
        clearRoleFilters();
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("languages");
        const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
        window.history.pushState({}, "", newUrl);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setLangAnchorEl(null);
        setRoleAnchorEl(null);
    };

    const handleLangMenuClose = () => {
        setLangAnchorEl(null);
    };

    const handleRoleMenuClose = () => {
        setRoleAnchorEl(null);
    };

    const handleLanguageSelect = async (lang: string) => {
        let updatedLanguages;
        if (selectedLanguages.includes(lang)) {
            updatedLanguages = selectedLanguages.filter(
                (language) => language !== lang
            );
        } else {
            updatedLanguages = [...selectedLanguages, lang];
        }

        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("languages");
        updatedLanguages.forEach((language) => {
            searchParams.append("languages", language);
        });

        // Update the URL with the new query parameters
        const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
        window.history.pushState({}, "", newUrl);

        await getUsersCalendar(updatedLanguages);
        setSelectedLanguages(updatedLanguages);

        const views = getCalendarViews();
        const userIds = Array.from(usersCalendar.values())
            .map((user) => user.id)
            .concat(userEventsId)
            .concat(user?.id!);

        const updatedViews = getFilteredViews(views, userIds);
        filterEvents(updatedViews.map((view) => view.id));
        setCalendarViews(updatedViews);
    };

    const handleRoleSelect = (role: string) => {
        const isPresent = roleFilters.includes(role);
        if (isPresent) {
            removeRoleFilter(role);
        } else if (!isPresent) {
            addRoleFilter(role);
        }
    };

    const isActive = () => {
        return selectedLanguages.length > 0 || roleFilters.length > 0;
    };

    return (
        <div>
            <Button
                onClick={handleMainClick}
                className={`${isActive() ? "selected-item" : ""}`}
                sx={{ ...buttonStyle, color: isActive() ? "#03b3ff" : "#616161" }}
                title="Filter"
                data-testid="calendar-filter-button"
            >
                <FilterListOutlinedIcon fontSize="small" sx={{ marginRight: "5px" }} />
                {t("GENERAL_FILTER")}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                transitionDuration={0}
            >
                <MenuItem
                    className={`menu-item-option`}
                    data-testid="clear-filters-button"
                    onMouseOver={(e) => handleLangMenu(e, -1)}
                    sx={{ fontSize: "14px" }}
                    onClick={() => {
                        onClearFilters();
                        handleMenuClose();
                    }}
                >
                    {t("GENERAL_CLEAR")}
                </MenuItem>
                <MenuItem
                    className={`menu-item-option ${index === 1 ? "menu-selected-option" : ""
                        }`}
                    data-testid="language-filter-button"
                    onMouseOver={(e) => handleLangMenu(e, 1)}
                    onClick={(e) => handleLangMenu(e, 1)}
                >
                    <ListItemText
                        primary={
                            <Typography variant="body1" sx={{ fontSize: "14px" }}>
                                {t("GENERAL_LANGUAGES")}
                            </Typography>
                        }
                        sx={{ mr: 1 }}
                    />
                    <ArrowForwardIosIcon sx={{ fontSize: "14px", marginTop: "2px" }} />
                </MenuItem>
                <MenuItem
                    className={`menu-item-option ${index === 2 ? "menu-selected-option" : ""
                        }`}
                    data-testid="language-filter-button"
                    onMouseOver={(e) => handleRoleMenu(e, 2)}
                    onClick={(e) => handleRoleMenu(e, 2)}
                >
                    <ListItemText
                        primary={
                            <Typography variant="body1" sx={{ fontSize: "14px" }}>
                                {t("SETTINGS_ROLES_NAME")}
                            </Typography>
                        }
                        sx={{ mr: 1 }}
                    />
                    <ArrowForwardIosIcon sx={{ fontSize: "14px", marginTop: "2px" }} />
                </MenuItem>
                <Menu
                    open={Boolean(langAnchorEl) && index === 1}
                    anchorEl={langAnchorEl}
                    hideBackdrop={true}
                    transitionDuration={0}
                    sx={{
                        height: "265px",
                        width: "240px",
                    }}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    {filterLanguageList.map((language) => (
                        <MenuItem
                            key={language.alpha2}
                            data-testid={`language-${language.alpha2}-button`}
                            className="language-list menu-item-option"
                            onClick={() => handleLanguageSelect(language.alpha2)}
                        >
                            <Typography sx={{ paddingRight: "10px", fontSize: "14px" }}>
                                {language.name}
                            </Typography>
                            <DoneIcon
                                fontSize="small"
                                sx={{
                                    color: selectedLanguages.includes(language.alpha2)
                                        ? "#03b2ff"
                                        : "transparent",
                                }}
                            />
                        </MenuItem>
                    ))}
                </Menu>
                <Menu
                    open={Boolean(roleAnchorEl) && index === 2}
                    anchorEl={roleAnchorEl}
                    hideBackdrop={true}
                    transitionDuration={0}
                    sx={{
                        height: "265px",
                        width: "240px",
                    }}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    {[{ id: -1, name: "Tenant Admin" }, ...clientRoleList].map((role) => (
                        <MenuItem
                            key={role.name}
                            data-testid={`language-${role.name}-button`}
                            className="language-list menu-item-option"
                            onClick={() => handleRoleSelect(role.name)}
                        >
                            <Typography sx={{ paddingRight: "10px", fontSize: "14px" }}>
                                {role.name}
                            </Typography>
                            <DoneIcon
                                fontSize="small"
                                sx={{
                                    color: roleFilters.includes(role.name)
                                        ? "#03b2ff"
                                        : "transparent",
                                }}
                            />
                        </MenuItem>
                    ))}
                </Menu>
            </Menu>
        </div>
    );
};

export default observer(CalendarFilter);
