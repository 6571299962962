import { CircularProgress, CircularProgressProps } from "@mui/material";
import Grid from "@mui/material/Grid/Grid";

interface LoadingComponentProps {
    gridProps?: React.HTMLAttributes<HTMLDivElement>;
    loaderProps?: CircularProgressProps;
    rowLength?: number;
}

export default function LoadingComponent({ gridProps, loaderProps, rowLength }: LoadingComponentProps) {
    return (
        <Grid {...gridProps} container justifyContent="center" alignItems="center" sx={{
            height: '100%', marginTop: rowLength === 0 ? '2.5rem' : 0,
        }}>
            <CircularProgress {...loaderProps} />
        </Grid >
    );
}