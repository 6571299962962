import { Box, IconButton, Typography } from "@mui/material";
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

import { ProcessTimeline } from "../../../data/models/process";
import GenericTable from "../../../components/GenericTable";
import { TableHeader } from "../../../_styles/table/TableHeader";
import { TableText } from "../../../_styles/table/TableText";
import "./styles.css";
import { useTranslation } from "react-i18next";

interface SurveyTableProps {
    timeline?: ProcessTimeline
}

function SurveyTable({ timeline }: SurveyTableProps) {
    const { t } = useTranslation();
    const onSurveyView = (id: string) => {
        window.open(`${window.location.origin}/timeline/${timeline?.id}/survey/${id}`)
    }

    return (
        <Box className="process-table">
            <GenericTable<{
                id: string;
                name: string;
            }>
                columns={
                    [
                        {
                            header: <TableHeader>{t("GENERAL_NAME")}</TableHeader>,
                            key: "name",
                            render: (survey) => <TableText>{survey.name}</TableText>,
                            sort: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                        },
                        {
                            header: <></>,
                            key: "actions",
                            render: (survey) => (
                                <IconButton
                                    sx={{ p: 0, color: "#457b9d" }}
                                    onClick={() => onSurveyView(survey.id)}
                                    title="Open in new tab"
                                >
                                    <OpenInNewRoundedIcon sx={{ fontSize: "18px" }} />
                                </IconButton>
                            ),
                        },
                    ]}
                rows={
                    timeline?.surveys.map((survey) => {
                        return {
                            id: survey.id,
                            name: survey.name,
                        };
                    }) || []
                }
                props={{
                    containerProps: { className: "survey-table-container" },
                    tableContainerProps: { className: "survey-table-container-data", style: { maxHeight: 400 } },
                    tableHeadProps: { className: "survey-table-header" },
                    rowProps: { className: "survey-row" },
                }}
                rowKey="id"
                usePagination
                toolbar={<Typography fontWeight={500} pb="8px">{timeline?.name} {t("SCREENING_QUESTIONNAIRES").toLowerCase()}</Typography>}
            />
        </Box >
    );
}

export default SurveyTable;