import Typography from "@mui/material/Typography/Typography";
import { observer } from "mobx-react-lite";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import GenericTable from "../../../components/GenericTable";
import DropdownMenu from "../../../components/DropdownMenu";
import { useStore } from "../../../stores/store";
import { Room } from "../../../data/models/room";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RoomForm from "./RoomForm";
import { getUserInstitution } from "../../../utils/helpers/institutionHelper";
import { TableText } from "../../../_styles/table/TableText";
import { TableHeader } from "../../../_styles/table/TableHeader";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

function RoomTable() {
    const { t } = useTranslation();
    const { roomStore, tenantStore, sidebarStore, dialogStore, profileStore } = useStore();
    const { getRooms, roomList, getRoom, deleteRoom, activateRoom } = roomStore;
    const { userSettingsTenant } = tenantStore;
    const { openSidebar } = sidebarStore;
    const { openDialog, closeDialog } = dialogStore;
    const { userTenantList } = profileStore;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (userSettingsTenant) {
                const fetchData = async () => {
                    setLoading(true);
                    await getRooms(userSettingsTenant.id);
                    setLoading(false);
                }

                fetchData();
            }
        }

        fetchData();
    }, [userSettingsTenant, getRooms, userTenantList]);

    const onEditRoom = async (roomId: string) => {
        const room = await getRoom(roomId);

        openSidebar(<RoomForm room={room} />, t("EDIT_ROOM"))
    }

    const onDeleteRoom = async (id: string) => {
        openDialog(
            t("DIALOG_TITLE_DELETE"),
            t("DIALOG_MSG_ROOM"),
            async () => {
                await deleteRoom(id);
                closeDialog();
                toast.success(t("TOAST_ROOM_DELETE"))
            },
            closeDialog);
    }

    const onActivateRoom = async (id: string) => {
        openDialog(
            t("DIALOG_TITLE_ACTIVATE"),
            t("DIALOG_MSG_ROOM_ACTIVATE"),
            async () => {
                await activateRoom(id);
                closeDialog();
                toast.success(t("Room activated successfully"))
            },
            closeDialog,
        {
            text: t("ACTIVATE_DIALOG_TEXT"),
            color: "primary"
        });
    }

    const options = [
        {
            id: 'edit',
            name: t("GENERAL_EDIT"),
            icon: <ModeEditOutlinedIcon sx={{ position: 'absolute', right: 0, paddingRight: 1 }} color="primary" />,
            onClick: (roomId: string) => onEditRoom(roomId),
        },
        {
            id: 'delete',
            name: t("GENERAL_DELETE"),
            onClick: (roomId: string) => onDeleteRoom(roomId),
            icon: <DeleteForeverOutlinedIcon sx={{ position: 'absolute', right: 0, paddingRight: 1 }} color="error" />,
            customStyle: {
                backgroundColor: '#ffdfdf',
                color: '#FF0000',
                ":hover": { backgroundColor: '#FFABAB' }
            }
        },
        {
            id: 'activate',
            name: t("GENERAL_ACTIVATE"),
            icon: <CheckCircleOutlineIcon sx={{ position: 'absolute', right: 0, paddingRight: 1 }} color="success" />,
            onClick: (roomId: string) => onActivateRoom(roomId),
        },
    ]

    const filterOptions = (room: Room, options: any) => {
        if (room.isDeactivated) {
            return options.filter((option: any) => option.id !== 'delete')
        }

        return options.filter((option: any) => option.id !== 'activate');
    }

    return (
        <GenericTable<Room>
            columns={[
                {
                    header: <TableHeader>{t("GENERAL_NAME")}</TableHeader>, key: 'name',
                    render: (room) => <TableText>{room.name}</TableText>,
                    sort: (a, b) => {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    },
                },
                {
                    header: <TableHeader>{t("ROOM_RESOURCE_TYPE")}</TableHeader>, key: 'type',
                    render: (room) => <TableText>{room.resourceType}</TableText>,
                    sort: (a, b) => {
                        return a.resourceType.toLowerCase().localeCompare(b.resourceType.toLowerCase());
                    },
                },
                {
                    header: <TableHeader>{t("ROOM_LOCATION")}</TableHeader>, key: 'location',
                    render: (room) =>
                        <Box>
                            <TableText title="Street & number">{room.street} {room.number ? `${room.number}, ` : ""} {room.zipCode} {room.city} </TableText>
                        </Box>,
                    sort: (a, b) => {
                        if (a.city && b.city) {
                            return a.city.toLowerCase().localeCompare(b.city.toLowerCase());
                        } else if (a.city) {
                            return -1;
                        } else if (b.city) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                },
                {
                    header: <TableHeader>{t("ROOM_SIZE")}</TableHeader>, key: 'capacity',
                    render: (room) => <TableText>{room.capacity}</TableText>,
                    sort: (a, b) => {
                        return a.capacity - b.capacity;
                    },
                },
                {
                    header: <TableHeader>{t("Status")}</TableHeader>, key: 'status',
                    render: (room) => <TableText>{room.isDeactivated ? "Deactive" : "Active"}</TableText>
                },
                {
                    header: <TableHeader>{t("GENERAL_INST")}</TableHeader>, key: 'institutionName',
                    render: (room) => <TableText>{getUserInstitution(userTenantList, room.institutionId)?.name}</TableText>,
                    sort: (a, b) => {
                        const aInst = getUserInstitution(userTenantList, a.institutionId);
                        const bInst = getUserInstitution(userTenantList, b.institutionId);

                        if (aInst !== undefined) {
                            if (bInst !== undefined) {
                                return aInst?.name.toLowerCase().localeCompare(bInst?.name.toLowerCase());
                            }
                            return -1;
                        } else if (bInst !== undefined) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                },
                {
                    header: <Typography></Typography>,
                    key: 'actions',
                    render: (room) => (
                        <Box mr={2}>
                            <DropdownMenu
                                options={filterOptions(room, options).map((option: any) => {
                                    return {
                                        ...option,
                                        onClick: () => option.onClick(room.id),
                                    };
                                })}
                            />
                        </Box>
                    ),
                    className: "settings-table-actions"
                },
            ]}
            rows={roomList}
            props={{
                containerProps: { className: "settings-table" },
                tableContainerProps: { className: "survey-table-container-data" },
                tableHeadProps: { className: "survey-table-header" },
                rowProps: { className: "survey-row" },
            }}
            usePagination
            rowKey="id"
            loading={loading}
        />
    );
}

export default observer(RoomTable)