import { Box, Typography } from "@mui/material";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStore } from "../../../stores/store";
import DropdownMenu from "../../../components/DropdownMenu";
import GenericTable from "../../../components/GenericTable";
import { Institution } from "../../../data/models/institution";
import InstitutionRegisterForm from "./InstitutionRegisterForm";
import { toast } from "react-toastify";
import { TableText } from "../../../_styles/table/TableText";
import { TableHeader } from "../../../_styles/table/TableHeader";
import { useTranslation } from "react-i18next";

function InstitutionTable() {
    const { t } = useTranslation();
    const {
        institutionStore: { institutionList, getInstitutions, getInstitution, deleteInstitution },
        tenantStore: { userSettingsTenant },
        sidebarStore: { openSidebar },
        dialogStore: { openDialog, closeDialog }
    } = useStore();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userSettingsTenant) {
            const fetchData = async () => {
                setLoading(true);
                await getInstitutions(userSettingsTenant!.id);
                setLoading(false);
            }

            fetchData();
        }
    }, [getInstitutions, userSettingsTenant]);

    const onEditInstitution = async (id: string) => {
        const institution = await getInstitution(id);

        openSidebar(<InstitutionRegisterForm institution={institution} />, t("EDIT_INSTITUTION"))
    }

    const onDeleteInstitution = (id: string) => {
        openDialog(
            t("DIALOG_TITLE_DELETE"),
            t("DIALOG_MSG_INSTITUTION"),
            async () => {
                await deleteInstitution(id)
                closeDialog();
                toast.success(t("TOAST_INSTITUTION_DELETE"))
            },
            closeDialog);
    }

    const options = [
        {
            id: 'edit',
            name: t("GENERAL_EDIT"),
            icon: <ModeEditOutlinedIcon sx={{ position: 'absolute', right: 0, paddingRight: 1, fontSize: '18px' }} color="primary" />,
            onClick: (id: string) => onEditInstitution(id),
            customStyle: {
                color: "#03b2ff",
            },
        },
        {
            id: 'delete',
            name: t("GENERAL_DELETE"),
            icon: <DeleteForeverOutlinedIcon sx={{ position: 'absolute', right: 0, paddingRight: 1, fontSize: '18px' }} color="error" />,
            onClick: (id: string) => onDeleteInstitution(id),
            customStyle: {
                backgroundColor: '#ffdfdf',
                color: '#FF0000',
                ":hover": { backgroundColor: '#FFABAB' }
            }
        },
    ]

    return (
        <GenericTable<Institution>
            columns={[
                {
                    header: <TableHeader>{t("GENERAL_NAME")}</TableHeader>, key: 'name',
                    render: (institution) => <TableText>{institution.name}</TableText>,
                    sort: (a, b) => {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    },
                },
                {
                    header: <Typography></Typography>,
                    key: 'actions',
                    render: (institution) => (
                        <Box mr={2}>
                            <DropdownMenu
                                options={options.map((option) => {
                                    return {
                                        ...option,
                                        onClick: () => option.onClick(institution.id),
                                    };
                                })}
                            />
                        </Box>
                    ),
                    className: "settings-table-actions"
                },
            ]}
            rows={institutionList}
            props={{
                containerProps: { className: "settings-table" },
                tableContainerProps: { className: "survey-table-container-data" },
                tableHeadProps: { className: "survey-table-header" },
                rowProps: { className: "survey-row" },
            }}
            usePagination
            rowKey={"id"}
            loading={loading}
        />
    );
}

export default observer(InstitutionTable);