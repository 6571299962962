import { Box, Grid, Typography } from "@mui/material";
import FormInputSelect from "../../../components/form/FormInputSelect";
import { FormInputText } from "../../../components/form/FormInputText";
import { FormInputRadio } from "../../../components/form/FormInputRadio";

import { getGenderOptions } from "../../../data/static/user";
import { useTranslation } from "react-i18next";

interface ContactAgencyFormProps {
    control: any;
}

interface ClientDetailsFormFieldsProps {
    control: any;
    watch: any;
}

function ContactAgencyForm({ control }: ContactAgencyFormProps) {
    const { t } = useTranslation();

    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={12}>
                <FormInputText
                    name="contactAgency.agencyName"
                    control={control}
                    label={`${t("CLIENT_AGENCY_NAME")} *`}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputText
                    name="contactAgency.agencyEmail"
                    control={control}
                    label={`${t("CLIENT_AGENCY_EMAIL")} *`}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputText
                    name="contactAgency.agencyPhone"
                    control={control}
                    label={`${t("CLIENT_AGENCY_PHONE")}`}
                />
            </Grid>
        </Grid>
    );
}

function ClientDetails({ control, watch }: ClientDetailsFormFieldsProps) {
    const { t } = useTranslation();
    const shouldContactAgency = watch("contactWithReferringAgency") || "false";
    const confirmOptions = [
        { value: true, label: t("GENERAL_YES") },
        { value: false, label: t("GENERAL_NO") },
    ];

    return (
        <Grid container columnSpacing={1} marginTop="1rem">
            <Grid item xs={12} lg={6}>
                <FormInputSelect
                    name="helperGenderPreference"
                    control={control}
                    label={t("CLIENT_HELPER")}
                    options={getGenderOptions(t)}
                    valueKey={"value"}
                    labelKey={"label"}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormInputSelect
                    name="needsChildCare"
                    control={control}
                    label={t("CLIENT_CHILDCARE")}
                    options={confirmOptions}
                    valueKey={"value"}
                    labelKey={"label"}
                    defaultValue={false}
                />
            </Grid>
            <Grid item xs={12}>
                <Box width="100%" display="flex" alignItems="center">
                    <Typography sx={{ marginRight: "2rem" }}>
                        {t("CLIENT_AGENCY")}
                    </Typography>
                    <FormInputRadio
                        name="contactWithReferringAgency"
                        control={control}
                        options={confirmOptions}
                        defaultValue={shouldContactAgency}
                    />
                </Box>
            </Grid>
            {String(shouldContactAgency) === "true" && (
                <Grid item xs={12}>
                    <ContactAgencyForm control={control} />
                </Grid>
            )}
        </Grid>
    );
}

export default ClientDetails;