import Drawer from '@mui/material/Drawer/Drawer';
import { Box, Divider, SxProps } from '@mui/material';

import { Fragment } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../stores/store';
import SidebarHeader from './SidebarHeader';
import './styles.css';

interface SidebarProps {
    sidebarSx?: SxProps,
    onClose?: () => void;
}

const sidebarStyle = {
    height: 'calc(100% - 64px)',
    top: 66,
    padding: '10px 30px',
    backgroundColor: 'white',
    '@media (max-width: 900px)': {
        padding: 0,
        alignItems: 'center',
        marginTop: '1.5rem',
    }
}
function Sidebar({ sidebarSx, onClose }: SidebarProps) {
    const { sidebarStore: { sidebar } } = useStore();
    const combinedStyles = {
        ...sidebarStyle,
        ...sidebarSx
    };

    return (
        <Box>
            <Fragment >
                <Drawer
                    anchor={sidebar.position}
                    open={sidebar.open}
                    variant='persistent'
                    key={sidebar.position}
                    PaperProps={{
                        sx: combinedStyles,
                        className: 'sidebar',
                    }}
                >
                    <SidebarHeader label={sidebar.label} />
                    <Divider variant="middle" style={{ margin: '10px 0' }} />
                    <Box className="sidebar-content">
                        {sidebar.body}
                    </Box>
                </Drawer>
            </Fragment>
        </Box>
    );
}

export default observer(Sidebar);