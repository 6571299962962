import { Box, Skeleton } from "@mui/material";

import "./styles.css";

function SurveyLoader() {
    return (
        <Box sx={{ width: "100%" }}>
            <Skeleton height={240} animation="wave" sx={{ mt: "-2rem" }} />
            <Skeleton height={240} animation="wave" sx={{ mt: "-5rem" }} />
            <Skeleton
                height={240}
                animation="wave"
                sx={{
                    mt: "-5rem",
                }}
            />
            <Skeleton height={70} width={110} sx={{ mt: "-2rem" }} animation={false} />
        </Box>
    );
}

export default SurveyLoader;
