// useStudyOverviewActions.ts

import { useStore } from '../../../../stores/store';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { SessionAction, SessionDeactivateRequest, SessionDialogData, SessionEmergencyRequest } from '../../../../data/models/session';

export const useStudyOverviewActions = (fetchStudyOverviewData: () => Promise<void>, institutionStudyType: string) => {
    const { t } = useTranslation();
    const { sessionStore, dialogStore } = useStore();
    const { openDialog, closeDialog } = dialogStore;
    const { noShowUp, sessionEmergency, deactivateSession } = sessionStore;

    const [displaySessionActionDialog, setDisplaySessionActionDialog] = useState(false);


    const handleStatusChange = async (sessionId: string, action: SessionAction) => {
        setDisplaySessionActionDialog(true);

        if (action === SessionAction.RESUME) {
            const resumeEmergencyObject: SessionEmergencyRequest = {
                emergencyHold: false,
            };
            try {
                await sessionEmergency(sessionId, resumeEmergencyObject);
                await fetchStudyOverviewData();
            } catch (error) {
                console.error("Error resuming session:", error);
            }
            return;
        }

        // Dialog settings for DEACTIVATE and EMERGENCY actions
        const dialogTitle = action === SessionAction.DEACTIVATE
            ? t("DIALOG_CLIENT_SESSION_DEACTIVATE")
            : t("DIALOG_CLIENT_SESSION_EMERGENCY");

        const dialogDescription = action === SessionAction.DEACTIVATE
            ? t("DIALOG_CLIENT_SESSION_DEACTIVATE_DESCRIPTION")
            : t("DIALOG_CLIENT_SESSION_EMERGENCY_DESCRIPTION");

        openDialog(
            dialogTitle,
            dialogDescription,
            async (data: SessionDialogData) => {
                await handleConfirm(data, sessionId);
            },
            closeDialog,
            {
                text: t("GENERAL_CONFIRM"),
                color: "warning",
            },
            { sessionId, study: institutionStudyType },
            action
        );
    };

    const handleConfirm = async (data: SessionDialogData, sessionId: string) => {
        try {
            if (data.action === SessionAction.DEACTIVATE) {
                await deactivateSession(sessionId, data.sessionData as SessionDeactivateRequest);
            } else if (data.action === SessionAction.EMERGENCY) {
                await sessionEmergency(sessionId, data.sessionData as SessionEmergencyRequest);
            }
            await fetchStudyOverviewData();
        } catch (error) {
            console.error(`${data.action} action failed:`, error);
        } finally {
            closeDialog();
        }
    };

    const onNoShow = async (sessionId: string) => {
        setDisplaySessionActionDialog(false);
        openDialog(
            t("SESSION_NOSHOW_TITLE"),
            t("SESSION_NOSHOW_DESC"),
            async () => {
                try {
                    await noShowUp(sessionId);
                    await fetchStudyOverviewData();
                } catch (error) {
                    console.error("No-show action failed:", error);
                }
                finally {
                    closeDialog();
                }
            },
            closeDialog,
            {
                text: t("GENERAL_CONFIRM"),
                color: "warning",
            },
            sessionId,
        );
    };

    return {
        handleStatusChange,
        handleConfirm,
        onNoShow,
        displaySessionActionDialog,
        setDisplaySessionActionDialog,
    };
};
