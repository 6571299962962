import { Box, Container, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { EventStatusDto } from "../../../data/models/event";
import { useStore } from "../../../stores/store";
import { toast } from 'react-toastify';
import { Trans } from "react-i18next";

function EventStatus() {
    const location = useLocation();
    const navigate = useNavigate();

    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const status = params.get('status');
    const { bookingStore } = useStore();

    useEffect(() => {
        if ((!token && !status) || (status !== "accepted" && status !== "declined")) {
            navigate('/not-found');
        }

        const eventStatus: EventStatusDto = {
            token: token,
            status: status
        }

        bookingStore.changeEventStatus(eventStatus).then(() => {
            console.log('success');
        })
            .catch((error) => {
                toast.error(error.response.data)
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingStore])

    return (
        <Container component="main" maxWidth="xs" sx={{ marginBottom: 3, marginTop: 10, textAlign: "center", }}>
            <Box
                component="form"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                    <Grid item xs={12}>
                        <Typography>
                            <Trans i18nKey={"EVENT_STATUS"} values={{ status: status }} />
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default observer(EventStatus);