import { Box, CircularProgress, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useStore } from "../../../../../stores/store";
import { RoundButton } from "../../../../../_styles/StyledButtons";
import "../styles.css";
import { useTranslation } from "react-i18next";
import StatusCard from "../../../../../components/StatusCard";
import { observer } from "mobx-react-lite";

function BaselineResults() {
    const { t } = useTranslation();
    const { sessionStore, modalStore, loadingStore } = useStore();
    const { getSessionTimelines, getAssessorSession, confirmBaselineAllocation, loadAssessorSession } = sessionStore;
    const { closeModal } = modalStore;
    const { isLoading } = loadingStore;
    const [group, setGroup] = useState<{ group: string, subgroup: string }>({ group: "-", subgroup: "-" })

    const { sessionId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            if (sessionId) {
                const session = await getAssessorSession(sessionId)
                setGroup({ group: session.group ?? "-", subgroup: session.subgroup ?? "-" });
            }
        }

        fetchData();
    }, [getAssessorSession, getSessionTimelines, sessionId]);

    const onConfirm = async () => {
        if (sessionId) {
            await confirmBaselineAllocation(sessionId);
            await loadAssessorSession(sessionId);
            closeModal();
        }
    }

    return (
        <Box>
            <Box>
                <Typography fontWeight={600} fontSize={"1.25rem"}>{t("BL_RESULTS")}</Typography>
            </Box>
            <Box mt="10px" display="flex">
                <StatusCard
                    header={t("GENERAL_GROUP")}
                    data={group.group.replace("Group ", "")}
                    style={{
                        boxShadow: "none !important"
                    }}
                />
                <StatusCard
                    header={t("BL_SUBSAMPLE")}
                    data={group.subgroup.replace("Subsample ", "")}
                    style={{
                        boxShadow: "none !important"
                    }}
                />
            </Box>
            <Box display={"flex"} justifyContent={"flex-end"} mt="10px">
                <RoundButton
                    variant="contained"
                    disableTouchRipple
                    disableFocusRipple
                    onClick={onConfirm}
                    disabled={isLoading([confirmBaselineAllocation])}
                >
                    {isLoading([confirmBaselineAllocation]) ?
                        <CircularProgress color="secondary" size={25} />
                        :
                        t("GENERAL_CONFIRM")
                    }
                </RoundButton>
            </Box>
        </Box>
    );
}

export default observer(BaselineResults);