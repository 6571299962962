import { Box, IconButton, List, ListItem, Typography } from "@mui/material";
import { RoundButton } from "../_styles/StyledButtons";
import CloseIcon from "@mui/icons-material/Close";

interface FileInfoProps {
    files: File[];
    setFiles: (files: File[]) => void;
    limit?: number;
}

function FileInfo({ files, setFiles, limit = 5 }: FileInfoProps) {
    function formatBytes(bytes: number, decimals: number = 2) {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const getFileStyling = (file: File) => {
        const ext = getExtension(file);

        return ext.toLowerCase();
    };

    const getExtension = (file: File) => {
        return file!.name.split(".").pop()!.toUpperCase();
    };

    const onRemove = (file: File) => {
        const newFiles = files.filter((f) => f !== file);
        setFiles(newFiles);
    };

    const clearAll = () => {
        setFiles([]);
    };

    return (
        <Box className="file-info-container">

            <Box display="flex" alignItems="center">
                <Typography fontWeight={500} fontSize="16px" sx={{ marginRight: "10px" }}>
                    Uploaded files
                </Typography>
                <Typography fontWeight={500} fontSize="11px" sx={{ marginRight: "10px", mt: "5px" }}>
                    (Max. {limit} file{`${limit > 1 ? "s" : ""}`})
                </Typography>
                {files.length > 0 && <RoundButton
                    sx={{ padding: "5px", width: "auto" }}
                    onClick={clearAll}
                >
                    <Typography fontSize="13px" fontWeight="400">
                        CLEAR ALL
                    </Typography>
                </RoundButton>}
            </Box>
            {files.length === 0 && (
                <Typography fontSize="13px" sx={{ color: "#a2a2a2", marginLeft: "1rem" }}>
                    No files are uploaded.
                </Typography>
            )}
            {files.length > 0 && <List className="file-info-list">
                {files.map((file, index) => (
                    <ListItem key={index} className="file-info-item">
                        <Box className={"file-info-details " + getFileStyling(file)}>
                            <Typography fontSize="11px" fontWeight={600}>
                                {getExtension(file)}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                        >
                            <Typography
                                className="file-info-name"
                                fontSize={"14px"}
                                fontWeight={600}
                                title={file.name}
                                sx={{ margin: "0 7px" }}
                            >
                                {file.name}
                            </Typography>
                            <Typography fontSize={"11px"} fontWeight={400}>
                                {formatBytes(file.size)}
                            </Typography>
                        </Box>
                        <IconButton
                            sx={{ marginLeft: "auto" }}
                            onClick={() => onRemove(file)}
                        >
                            <CloseIcon sx={{ fontSize: "15px" }} />
                        </IconButton>
                    </ListItem>
                ))}
            </List>}
        </Box>
    );
}

export default FileInfo;