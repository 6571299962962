import { Institution, InstitutionDetailsDto, InstitutionDto, InstitutionUsers } from "../../data/models/institution";
import { RoleDto } from "../../data/models/role";
import { UserTenant } from "../../data/models/tenant";
import { InstitutionRoleDto, TenantUserDto } from "../../data/models/user";

/**
 * Retrieves user roles based on selected data, institution user list, and role list.
 * @param {any} data - The selected data containing user IDs, role selections, and checkbox statuses.
 * @param {TenantUserDto[]} institutionUserList - The list of users associated with the institution.
 * @param {RoleDto[]} roleList - The list of available roles.
 * @returns {InstitutionUsers[]} Returns an array of institution user roles.
 */
export const getUserRoles = (data: any, institutionUserList: TenantUserDto[], roleList: RoleDto[]): InstitutionUsers[] => {
    const selectedUserRoles: InstitutionUsers[] = [];

    institutionUserList.forEach(user => {
        if (!data[user.id]["isChecked"]) return;
        const userId = institutionUserList.find(i => i.id === user.id)?.id;
        const roles = data[user.id]["multiSelectValue"];
        const institutionRoles: number[] = [];
        roles.forEach((roleName: any) => {
            const roleId = roleList.find(r => r.name === roleName)?.id;
            institutionRoles.push(roleId!);
        });

        selectedUserRoles.push({
            userId: userId!,
            roleIds: institutionRoles!,
        });
    });

    return selectedUserRoles;
}

/**
 * Retrieves default values for a user based on the user, institution, and edit mode.
 * @param {TenantUserDto} user - The user for whom to retrieve default values.
 * @param {InstitutionDetailsDto} institution - The institution associated with the user.
 * @param {boolean} editMode - Indicates whether the user is in edit mode.
 * @returns {{ isChecked: boolean, multiSelectValue: string[] }} Returns an object containing default checkbox status and selected role values.
 */
export function getDefaultValues(user: TenantUserDto, institution: InstitutionDetailsDto, editMode: boolean): { isChecked: boolean, multiSelectValue: string[] } {
    const users = institution?.users.find(u => u.id === user.id);
    if (!editMode || !users) {
        return { isChecked: false, multiSelectValue: [] };
    }

    const defaultValues: string[] = [];
    users.roles.forEach((role: InstitutionRoleDto) => {
        if (role.isActive) {
            defaultValues.push(role.name);
        }
    });
    const checkboxDefault = { isChecked: users.belongsTo };
    const defaultValuesObject = { isChecked: checkboxDefault.isChecked, multiSelectValue: defaultValues };
    return defaultValuesObject;
}

/**
 * Retrieves the institution associated with a user from a list of user tenants.
 * @param {UserTenant[]} tenants - The array of user tenants containing institutions.
 * @param {string} institutionId - The ID of the institution to retrieve.
 * @returns {InstitutionDto} Returns the institution associated with the specified ID.
 */
export function getUserInstitution(tenants: UserTenant[], institutionId: string): InstitutionDto {
    let institution: InstitutionDto | undefined;
    tenants.forEach((tenant) => {
        tenant.institutions.forEach((inst) => {
            if (inst.id === institutionId) {
                institution = inst;
            }
        });
    });

    return institution!;
}

/**
 * Retrieves the name of an institution based on its ID from a list of institutions.
 * @param {string} institutionId - The ID of the institution to retrieve the name for.
 * @param {Institution[]} institutionList - The array of institutions containing institution data.
 * @returns {string} Returns the name of the institution with parentheses, or an empty string if not found.
 */
export const getInstitutionName = (institutionId: string, institutionList: Institution[]) => {
    return institutionList.find(i => i.id === institutionId)?.name ?? "";
}