import { Box, Container, Typography } from "@mui/material";

import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { RoundButton } from "../../../_styles/StyledButtons";
import SearchBar from "../../../components/form/SearchBar";
import Sidebar from "../../../components/sidebar/Sidebar";
import { useStore } from "../../../stores/store";
import "../styles.css";
interface DashboardHeaderProps {
    onSearch: (searchValue: string) => void;
}

function DashboardHeader({ onSearch }: DashboardHeaderProps) {
    const { t } = useTranslation();
    const {
        commonStore,
        institutionStore: { selectedUserInstitution },
        tenantStore: { selectedTenant },
        authStore: { user }
    } = useStore();
    const { clientPagination } = commonStore;
    const navigate = useNavigate();

    const onClientAdd = () => {
        navigate("/users/create");
    };
    return (
        <Container className="client-dashboard-header" maxWidth="xl">
            <Sidebar />
            <Box className="header-container">
                <Box display="flex" flexDirection="column" flexGrow={1}>
                    <Typography sx={{ fontWeight: '700', fontSize: '28px' }}>{t("ROLE_CLIENTS")}</Typography>
                    <Typography sx={{ fontWeight: '400', fontSize: '13px', color: "#6b7483" }}>{(clientPagination && JSON.parse(clientPagination).TotalCount) ?? 0} {t("CLIENT_TOTAL")}</Typography>
                </Box>
                <SearchBar
                    placeholder="Search by name or email"
                    onSearch={onSearch}
                />
                <Box display="flex" flexGrow={1} justifyContent="flex-end">
                    {(selectedUserInstitution?.roles!.some(role => role.includes("Field Coordinator")) || selectedTenant?.isAdmin || user?.isSuperAdmin) ?
                        <RoundButton className="media-button" variant="contained" sx={{ marginRight: '15px' }} title="Create" onClick={onClientAdd}>
                            {t("CLIENT_ADD")}
                        </RoundButton>
                        : ''
                    }
                </Box>
            </Box>
        </Container >
    );
}

export default observer(DashboardHeader);