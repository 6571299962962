import { Box } from "@mui/system";

import { useEffect, useState } from "react";
import { useParams } from "react-router";

import {
    SessionTimeline,
    TimelineEventDetails,
} from "../../../../../data/models/session";
import { useStore } from "../../../../../stores/store";
import SessionEventList from "./SessionEventList";
import LoadingComponent from "../../../../../components/LoadingComponent";

interface DashboardProps {
    selectedTimeline: SessionTimeline | null;
}

function SessionEventDashboard({ selectedTimeline }: DashboardProps) {
    const { sessionStore } = useStore();
    const { getTimelineEvents } = sessionStore;
    const [timelineEvents, setTimelineEvents] =
        useState<TimelineEventDetails | null>(null);

    const { sessionId } = useParams();

    useEffect(() => {
        if (sessionId && selectedTimeline) {
            const fetchData = async () => {
                const events = await getTimelineEvents(
                    sessionId,
                    selectedTimeline.timelineId
                );
                const timelineEvents: TimelineEventDetails = {
                    timelineId: selectedTimeline.timelineId,
                    timelineName: selectedTimeline.timelineId,
                    events: events,
                };

                setTimelineEvents(timelineEvents);
            };
            fetchData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId, selectedTimeline]);

    if (!timelineEvents) return <LoadingComponent />;

    return (
        <Box className="session-event-dashboard">
            <SessionEventList timelineDetails={timelineEvents} />
        </Box>
    );
}

export default SessionEventDashboard;
