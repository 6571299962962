import {
    Box,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import ShortTextIcon from '@mui/icons-material/ShortText';
import SubjectIcon from '@mui/icons-material/Subject';

import { Fragment, ReactNode, useState } from "react";
import "../styles.css";

interface FieldHeaderProps {
    preview: boolean;
    title: string;
    required?: boolean;
}

interface HeaderPreviewProps {
    title: string;
    required?: boolean;
}

export function FieldCreatorHeader({ preview, title, required = false }: FieldHeaderProps) {
    const [type, setType] = useState("text");

    const handleChange = (event: SelectChangeEvent) => {
        setType(event.target.value as string);
    };

    if (preview) return <FieldHeaderPreview title={title} required={required} />;

    return (
        <Box className="field-header-container">
            <FormControl className="title-control">
                <TextField
                    placeholder="Question title"
                    variant="filled"
                    inputProps={{
                        style: {
                            padding: "12px",
                        },
                    }}
                />
            </FormControl>
            <FormControl className="type-control">
                <Select
                    value={type}
                    onChange={handleChange}
                    sx={{
                        "& .MuiSelect-select": {
                            padding: "8px 12px",
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                        },
                    }}
                >
                    <MenuItem value={"text"} className="field-header-menu-item">
                        <ShortTextIcon sx={{ fontSize: "24px", color: "#5f6368" }} />
                        <Typography fontSize={"14px"} sx={{ paddingLeft: "0.7rem" }}>Short answer</Typography>
                    </MenuItem>
                    <MenuItem value={"paragraph"} className="field-header-menu-item">
                        <SubjectIcon sx={{ fontSize: "24px", color: "#5f6368" }} />
                        <Typography fontSize={"14px"} sx={{ paddingLeft: "0.7rem" }}>Paragraph</Typography>
                    </MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

export function FieldHeaderPreview({ title, required }: HeaderPreviewProps) {
    const parseText = (inputText: string): ReactNode[] => {
        if (inputText === "") return [<br />];
        const boldRegex = /\*\*(.*?)\*\*/g;
        let parts: ReactNode[] = [];
        let lastIndex = 0;

        inputText.replace(boldRegex, (match, p1: string, index: number) => {
            parts.push(inputText.substring(lastIndex, index));

            parts.push(<strong key={index}>{p1}</strong>);
            lastIndex = index + match.length;
            return match;
        });

        parts.push(inputText.substring(lastIndex));

        return parts;
    };

    const formattedTitle = title.split(/\r\n|\n/).map((line, index) => (
        <Fragment key={index}>
            <Typography fontSize="14px" mb={"4px"}>{parseText(line)}</Typography>
        </Fragment>
    ));

    return (
        <Box className="header-container-preview">
            <Box>
                {formattedTitle}
            </Box>
            {required && <>&nbsp;</>}
            <Typography sx={{ color: "red", lineHeight: '1' }}>{required ? "*" : ""}</Typography>
        </Box>
    );
}

export function HeaderCreator({ title, required }: HeaderPreviewProps) {
    const parseText = (inputText: string): ReactNode[] => {
        if (inputText === "") return [<br />];
        const boldRegex = /\*\*(.*?)\*\*/g;
        let parts: ReactNode[] = [];
        let lastIndex = 0;

        inputText.replace(boldRegex, (match, p1: string, index: number) => {
            parts.push(inputText.substring(lastIndex, index));

            parts.push(<strong key={index}>{p1}</strong>);
            lastIndex = index + match.length;
            return match;
        });

        parts.push(inputText.substring(lastIndex));

        return parts;
    };

    const formattedTitle = title.split(/\r\n|\n\n/).map((line, index) => (
        <Fragment key={index}>
            <Typography fontSize="14px" mb={"4px"}>{parseText(line)}</Typography>
        </Fragment>
    ));

    return (
        <Box className="header-container-preview">
            <Box>
                {formattedTitle}
            </Box>
            {required && <>&nbsp;</>}
            <Typography sx={{ color: "red", lineHeight: '1' }}>{required ? "*" : ""}</Typography>
        </Box>
    );
}