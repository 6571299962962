import { Typography, styled } from "@mui/material";

export const TableText = styled(Typography)({
    fontSize: '12px',
    fontVariant: 'body2',
    fontWeight: '400',
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});