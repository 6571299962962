import { Box, Grid, Typography } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from "react-router-dom";
import { RoundButton } from "../../../_styles/StyledButtons";
import ProcessList from "./ProcessList";
import { useStore } from "../../../stores/store";
import ProcessAddForm from "./ProcessAddForm";
import { observer } from "mobx-react-lite";
import Sidebar from "../../../components/sidebar/Sidebar";
import { useEffect, useState } from "react";

function ProcessDashboard() {
    const { sidebarStore: { openSidebar, closeSidebar } } = useStore();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [drawerWidth, setdrawerWidth] = useState<number | undefined>(undefined);
    const navigate = useNavigate();

    const addForm = () => {
        setIsSidebarOpen(true)
        setdrawerWidth(35);
        openSidebar(<ProcessAddForm />, 'Add process')
    }

    function handleClose() {
        closeSidebar();
        setdrawerWidth(undefined);
    }

    return (
        <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
            <Sidebar onClose={handleClose} />
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}>
                <Grid container gap={2} sx={{ marginBottom: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                        <ChevronLeftIcon onClick={() => navigate('/screening')} /><Typography>Screening</Typography>
                    </Grid>
                    <Grid item sx={{ display: 'flex' }}>
                        <RoundButton variant="contained" sx={{ marginRight: '15px', width: 140 }} title="NewGroup">
                            <Typography variant="body2">
                                + New Group
                            </Typography>
                        </RoundButton>
                        <RoundButton variant="contained" sx={{ marginRight: '15px', width: 140 }} title="SubGroup" disabled={true}>
                            <Typography variant="body2">
                                + Sub Group
                            </Typography>
                        </RoundButton>
                        <RoundButton variant="contained" sx={{ marginRight: '15px' }} title="Process" onClick={addForm}>
                            <Typography variant="body2">
                                + Process
                            </Typography>
                        </RoundButton>
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                    <ProcessList />
                </Box>
            </Box>
        </Box>
    );
}

export default observer(ProcessDashboard);