import { makeAutoObservable } from "mobx"
import { store } from "./store";
import { t } from "i18next";
type ModalSize = 'md' | 'sm' | 'lg' | 'xl' | 'xs';

interface Modal {
    open: boolean;
    body: JSX.Element | null;
    size: ModalSize;
    confirmClose: boolean;
    persistent: boolean;
    className?: string;
}

interface ModalProps {
    size?: ModalSize;
    confirmClose?: boolean;
    persistent?: boolean;
    className?: string;
}

export default class ModalStore {
    modal: Modal =
        {
            open: false,
            body: null,
            size: 'md',
            confirmClose: false,
            persistent: false,
        }

    constructor() {
        makeAutoObservable(this);
    }

    openModal = (content: JSX.Element, props?: ModalProps) => {
        const {
            size = 'md',
            confirmClose = false,
            persistent = false,
            className = "",
        } = props || {};

        this.modal.open = true;
        this.modal.body = content;
        this.modal.size = size ?? 'md';
        this.modal.confirmClose = confirmClose ?? false;
        this.modal.persistent = persistent ?? false;
        this.modal.className = className ?? ""

        return 'open';
    }

    closeModal = () => {
        if (this.modal.confirmClose) {
            store.dialogStore.openDialog(t("DIALOG_DISCARD_EVENT"), t("DIALOG_DISCARD_ARE_YOU_SURE"),
                () => {
                    this.modal.open = false;
                    this.modal.body = null;
                    store.dialogStore.closeDialog();
                },
                store.dialogStore.closeDialog,
                { text: t("DIALOG_DISCARD"), color: 'info' })
        } else {
            this.modal.open = false;
            this.modal.body = null;
        }
        return 'close';
    }

    closePmModal = () => {
        this.modal.open = false;
        this.modal.body = null;

        return 'close';
    }
}