import { Box } from "@mui/material";

import "../styles.css";

interface QuestionContainerProps {
    children: React.ReactNode;
    visible?: boolean;
}

function QuestionContainer({ children, visible = true }: QuestionContainerProps) {
    return (
        <Box className={`question-container preview-container animated-component ${!visible ? "hidden" : ""}`}>
            <Box className="question-field-container">
                {children}
            </Box>
        </Box>
    );
}

export function QuestionCreatorContainer({ children }: QuestionContainerProps) {
    return (
        <Box className="question-container preview-container animated-component">
            <Box className="question-field-creator-container">
                {children}
            </Box>
        </Box>
    );
}

export default QuestionContainer;