import dayjs from "dayjs";
import { Event, CalendarView, EventUpdateDto, EventCreateDto, Recurrence } from "../../data/models/event";
import { Reminder, View } from "../../data/models/event";
import { combineDateAndTime } from "../utils";
import { assignRecurrence, parseRrule } from "../eventRecurrence";
import { AttendeeDto } from "../../data/models/user";

export function isReminderValid(reminder: Reminder): boolean {
    if (reminder.value === '') {
        return true;
    }

    let valueInMinutes = convertUnitToMinutes(reminder);

    return valueInMinutes >= 0 && valueInMinutes <= 40320;
}

/**
 * Converts a reminder value from its unit to minutes.
 * @param {Reminder} reminder - The reminder object containing the value and unit.
 * @returns {number} Returns the value converted to minutes. Returns -1 if the unit is invalid.
 */
function convertUnitToMinutes(reminder: Reminder): number {
    const value = parseInt(reminder.value as string);
    if (reminder.unit === "minutes") {
        return value;
    } else if (reminder.unit === "hours") {
        return value * 60;
    } else if (reminder.unit === "days") {
        return value * 60 * 24;
    } else if (reminder.unit === "weeks") {
        return value * 60 * 24 * 7;
    }

    return -1;
}

/**
 * Converts minutes to the specified unit.
 * @param {number} minutes - The number of minutes to convert.
 * @param {string} unit - The unit to convert to ("minutes", "hours", "days", "weeks").
 * @returns {number} Returns the value converted to the specified unit. Returns -1 if the unit is invalid.
 */
export function convertMinutesToUnit(minutes: number, unit: string): number {
    if (unit === "minutes") {
        return minutes;
    } else if (unit === "hours") {
        return minutes / 60;
    } else if (unit === "days") {
        return minutes / 60 / 24;
    } else if (unit === "weeks") {
        return minutes / 60 / 24 / 7;
    }

    return -1;
}

/**
 * Filters calendar views based on type and user IDs.
 * @param {CalendarView[]} views - The array of calendar views to filter.
 * @param {string[]} userIds - The array of user IDs to filter user views.
 * @returns {CalendarView[]} Returns the filtered array of calendar views.
 */
export function getFilteredViews(views: CalendarView[], userIds: string[]): CalendarView[] {
    const roomViews = views.filter((view) => view.type === View.ROOM);
    const userViews = views
        .filter((view) => view.type === View.USER)
        .filter((view) => userIds.includes(view.id));

    return [...roomViews, ...userViews];
}

export function getEventUpdateValues(event: Event, reminders: Reminder[], recurrence: string): EventUpdateDto {
    let utcStartTime = dayjs(event.startTime).toDate();
    let utcStartDate = event.start;
    let utcEndTime = dayjs(event.endTime).toDate();
    let utcEndDate = event.end;
    let start = event.isAllDay ?
        new Date(utcStartDate.getFullYear(), utcStartDate.getMonth(), utcStartDate.getDate()) :
        combineDateAndTime(utcStartDate, utcStartTime);
    let end = event.isAllDay ?
        new Date(utcEndDate.getFullYear(), utcEndDate.getMonth(), utcEndDate.getDate() + 1) :
        combineDateAndTime(utcEndDate, utcEndTime);
    const rrule =
        (event.recurrence && assignRecurrence(event))
        ||
        parseRrule(
            recurrence,
            start,
            end
        );

    const updatedEvent: EventUpdateDto = {
        id: event.id,
        title: event.title,
        start: start,
        end: end,
        description: event.description,
        isAllDay: event.isAllDay,
        isScreening: event.isScreening,
        attendees: event.attendees,
        roomId: event.roomId === 'none' || event.roomId === '' ? null : event.roomId,
        eventTypeId: event.eventType,
        reminders: reminders,
        subType: event.subType,
        recurrence: rrule,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    return updatedEvent;
}

/**
 * Generates an RFC 5545 compliant iCalendar recurrence rule string.
 * @param {Recurrence} recurrence - The recurrence object containing recurrence rule parameters.
 * @returns {string | null} Returns the generated recurrence rule string, or null if the recurrence type is "none".
 * @throws {Error} Throws an error if the recurrence type is invalid.
 */
export const generateRRuleString = (recurrence: Recurrence): string | null => {
    // Return null if recurrence type is "none"
    if (recurrence.type === 9) {
        return null;
    }
    const { type, startDate, endDate, interval, byDays, byMonthDay } = recurrence;

    const freqMap: Record<number, string> = {
        9: 'RRULE',
        0: 'YEARLY',
        1: 'MONTHLY',
        2: 'WEEKLY',
        3: 'DAILY',
        4: 'HOURLY',
        5: 'MINUTELY',
        6: 'SECONDLY',
    };

    const ruleType = freqMap[type];
    if (!ruleType) {
        throw new Error('Invalid recurrence type.');
    }

    const options: string[] = [];

    if (endDate) {
        const endDateString = endDate.toISOString().replace(/[-:]/g, '').split('.')[0];
        options.push(`UNTIL=${endDateString}`);
    }

    if (interval && interval > 1) {
        options.push(`INTERVAL=${interval}`);
    }

    if (byDays && byDays.length > 0) {
        const byDaysString = byDays.join(',');
        options.push(`BYDAY=${byDaysString}`);
    }

    if (byMonthDay) {
        options.push(`BYMONTHDAY=${byMonthDay}`);
    }

    // Convert startDate to ISO string without dashes or colons
    const startDateString = startDate!.toISOString().replace(/[-:]/g, '').split('.')[0];
    const optionsString = options.join(';');

    return `RRULE:FREQ=${ruleType};DTSTART=${startDateString};${optionsString ? `${optionsString}` : ''}`;
};

export function getEventCreateValues(event: Event, reminders: Reminder[], isConfimed: boolean): EventCreateDto {
    let utcStartTime = dayjs(event.startTime).toDate();
    let utcStartDate = event.start;
    let utcEndTime = dayjs(event.endTime).toDate();
    let utcEndDate = event.end;
    let start = event.isAllDay ?
        new Date(utcStartDate.getFullYear(), utcStartDate.getMonth(), utcStartDate.getDate()) :
        combineDateAndTime(utcStartDate, utcStartTime);
    let end = event.isAllDay ?
        new Date(utcEndDate.getFullYear(), utcEndDate.getMonth(), utcEndDate.getDate() + 1) :
        combineDateAndTime(utcEndDate, utcEndTime);

    const newEvent: EventCreateDto = {
        title: event.title,
        start: start,
        end: end,
        description: event.description,
        attendees: event.attendees,
        isAllDay: event.isAllDay,
        isScreening: event.isScreening,
        roomId: event.roomId === 'none' || event.roomId === '' ? null : event.roomId,
        eventType: event.eventType,
        reminders: reminders,
        recurrence: assignRecurrence(event),
        subType: event.subType,
        institutionId: '',
        IsConfirmed: isConfimed,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    if (newEvent.recurrence.type !== 9) {
        newEvent.recurrence.startDate = newEvent.start;
    }

    return newEvent;
}

export const getAttendeeEmails = (attendees: AttendeeDto[]): string[] => {
    const attendeeEmails = attendees.reduce((result: string[], item) => {
        if (typeof item === 'object' && item.email) {
            result.push(item.email);
        } else if (typeof item === 'string') {
            result.push(item);
        }
        return result;
    }, []);

    return attendeeEmails;
};