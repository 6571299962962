import { SurveyLanguage } from "../models/survey"

export const surveyLanguages: SurveyLanguage[] = [
    { name: "English", code: "en", direction: "ltr" },
    { name: "Arabic", code: "ar", direction: "rtl" },
    { name: "German", code: "de", direction: "ltr" },
    { name: "French", code: "fr", direction: "ltr" },
    { name: "Farsi/Dari", code: "fay", direction: "rtl" },
    { name: "Kurmanji", code: "kurk", direction: "ltr" },
    { name: "Russian", code: "ru", direction: "ltr" },
    { name: "Tamil", code: "ta", direction: "ltr" },
    { name: "Tigrinya", code: "ti", direction: "ltr" },
    { name: "Turkish", code: "tr", direction: "ltr" },
    { name: "Ukranian", code: "uk", direction: "ltr" },
    { name: "Pashto", code: "ps", direction: "rtl" },
]