import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Container, Tab } from "@mui/material";

import { useLayoutEffect, useState } from "react";

import ProcessSection from "./ProcessSection";
import SurveySection from "./SurveySection";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";

function ScreeningDashboard() {
    const { t } = useTranslation();
    const { institutionStore } = useStore();
    const { selectedUserInstitution } = institutionStore;
    const [value, setValue] = useState("1");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useLayoutEffect(() => {
        const appContainer = document.querySelector(
            ".app-container"
        ) as HTMLElement;

        const oldOverflowY = appContainer.style.overflowY;
        const oldOverflowX = appContainer.style.overflowX;
        appContainer.style.overflowY = "auto";
        appContainer.style.overflowX = "hidden";

        return () => {
            appContainer.style.overflowY = oldOverflowY;
            appContainer.style.overflowX = oldOverflowX;
        };
    }, []);

    if (selectedUserInstitution?.roles.find(r => r.toUpperCase() === "CLIENT")) {
        return (
            <Box>Client page</Box>
        );
    }

    return (
        <Container maxWidth="xl" className="screening-container">
            <Box>
                <TabContext value={value}>
                    <Box>
                        <TabList
                            sx={{
                                "& .MuiTabs-indicator": {
                                    display: "none",
                                },
                                margin: "16px 0",
                            }}
                            className="screening-tab-list"
                            onChange={handleChange}
                        >
                            <Tab
                                label={t("SCREENING_STUDY")}
                                value="1"
                                disableRipple
                                disableFocusRipple
                                disableTouchRipple
                                className={`screening-tab ${value === "1" && "active-tab"}`}
                                sx={{
                                    mr: 1,
                                }}
                            />
                            <Tab
                                label={t("SCREENING_QUESTIONNAIRES")}
                                disableRipple
                                disableFocusRipple
                                disableTouchRipple
                                value="2"
                                className={`screening-tab ${value === "2" && "active-tab"}`}
                            />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ p: 0 }}>
                        <ProcessSection />
                    </TabPanel>
                    <TabPanel value="2" sx={{ pt: 0 }}>
                        <SurveySection />
                    </TabPanel>
                </TabContext>
            </Box>
        </Container >
    );
}

export default observer(ScreeningDashboard);
