import { Box } from "@mui/material";
import PeoplesCalendar from "./PeoplesCalendar";
import '../styles.css';
import RoomsCalendar from "./RoomsCalendar";
import CalendarDatePicker from "./CalendarDatePicker";
import { CSSProperties } from "react";

interface Props {
    displayDate: (e: any) => void;
    date: Date;
    className?: string;
}

const datePickerStyle: CSSProperties = {
    backgroundColor: '#ffffff',
    scale: '0.87',
    width: '75%',
}

function CalendarDashboard({ displayDate, date, className = '' }: Props) {
    return (
        <Box className={className}>
            <CalendarDatePicker displayDate={displayDate} date={date} style={datePickerStyle} />
            <Box className="calendar-lists">
                <PeoplesCalendar />
                <RoomsCalendar />
            </Box>
        </Box >
    );
}

export default CalendarDashboard;