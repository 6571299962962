import { Chip, lighten } from "@mui/material";
import { UserStatus } from "../data/models/user"

interface Props {
    status: UserStatus
}

export default function UserStatusChip({ status }: Props) {
    const color = status === UserStatus.ACTIVE
        ? '#2a9d8f'
        : status === UserStatus.DEACTIVATED
            ? '#e63946'
            : '#ffd500';

    return (
        <Chip size='small' label={toPascalCase(status)} sx={{ bgcolor: lighten(color, 0.8), height: "26px", fontSize: '11px', border: `1px solid ${color}` }} />
    )
}

export function toPascalCase(input: string): string {
    if (!input) return '';
    return input
        .toLowerCase()
        .replace(/(?:^|\s|_|-)(\w)/g, (_, c) => c.toUpperCase());
}
